import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import './CommissionResults.scss';

import CommissionResult from './CommissionResult';
import Loader from '../Loader/Loader';

class CommissionResults extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    commissions: PropTypes.array.isRequired,
    updateCommission: PropTypes.func.isRequired,
    openArtistModal: PropTypes.func.isRequired,
    fetchNextPage: PropTypes.func.isRequired,
    isFetchingNextPage: PropTypes.bool.isRequired
  };

  cancelCommission = async commission => {
    await this.props.updateCommission(commission, { isCancelled: true });
    cogoToast.success(`Marked order ${commission.order_id} as cancelled`);
  };

  revertCommission = async commission => {
    await this.props.updateCommission(commission, { isCancelled: false });
    cogoToast.success(`Reverted order ${commission.order_id}`);
  };

  getCommissionAction = commission => {
    const { status_badge, invoiceRaw } = commission;

    switch (status_badge) {
      case 'PENDING':
        return {
          action: this.cancelCommission,
          action_label: 'MARK AS CANCELLED'
        };

      case 'CANCELLED':
        return {
          action: this.revertCommission,
          action_label: 'REVERT'
        };

      case 'PAID':
      case 'OWED':
        const invoiceStatus = invoiceRaw ? JSON.parse(invoiceRaw) : {};
        const link = _.get(invoiceStatus, 'detail.metadata.recipient_view_url');
        if (link) {
          return {
            action: () => window.open(link, '_blank'),
            action_label: status_badge === 'PAID' ? 'View Invoice' : 'Pay Invoice'
          };
        }
        break;

      case 'LOCKED':
      default:
        break;
    }

    return {
      action: null,
      action_label: '-'
    };
  };

  render() {
    const { commissions, isFetchingNextPage } = this.props;
    return (
      <div className='commission-results'>
        <div className='commission-results-row'>
          <div className='commission-results-cell'>DATE</div>
          <div className='commission-results-cell desktop-only'>ORDER ID</div>
          <div className='commission-results-cell'>ORDER AMOUNT</div>
          <div className='commission-results-cell'>COMMISSION</div>
          <div className='commission-results-cell'>NAME</div>
          <div className='commission-results-cell desktop-only'>CODE</div>
          <div className='commission-results-cell desktop-only'>STATUS</div>
          <div className='commission-results-cell'>ACTION</div>
        </div>
        {commissions.map(commission => {
          const { action, action_label } = this.getCommissionAction(commission);
          return (
            <CommissionResult
              key={commission.id}
              commission={commission}
              openArtistModal={this.props.openArtistModal}
              action={action}
              action_label={action_label}
            />
          );
        })}
        {isFetchingNextPage && (
          <div className='fetching-next-page-loader'>
            <Loader size={80} />
          </div>
        )}
      </div>
    );
  }
}

export default CommissionResults;
