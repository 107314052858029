import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './LatestSections.scss';

import LatestSection from './LatestSection';

const LatestSections = props => {
  const { user } = props;
  const { newsletter, activeTab } = props.latest;

  let visibleSections = _.orderBy(newsletter.sections, 'sortOrderRank', 'asc').filter(section => {
    if (activeTab) return section.tabs.find(t => t.id === activeTab.id);
    return true;
  });
  return (
    <div className='latest-sections-container'>
      <div className='latest-sections'>
        {visibleSections.map(section => {
          return (
            <LatestSection
              key={section.id}
              section={section}
              user={user}
              openBrandModal={props.openBrandModal}
              lastViewedContent={props.lastViewedContent}
              activeTab={activeTab}
            />
          );
        })}
      </div>
    </div>
  );
};

LatestSections.propTypes = {
  user: PropTypes.object.isRequired,
  latest: PropTypes.object.isRequired,
  lastViewedContent: PropTypes.any, // object or string as date
  openBrandModal: PropTypes.func.isRequired
};

export default LatestSections;
