import React, { Fragment, useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import cn from 'classnames';

import Loader from '../Loader/Loader';
import ConfirmPrompt from '../General/ConfirmPrompt';
import UploadImage from '../General/UploadImage';
import DropUploader from '../Uploader/DropUploader';
import Tooltip from '../General/Tooltip';
import ChatMessage from './ChatMessage';
import ChatMessageHeader from './ChatMessageHeader';

import { isBrand, isBanned, getRequests, getUserId, getBrandId, getCodeForBrandId, isSubscribedBrand } from '../../Helpers/user_helpers';
import { getPrettyDate } from '../../Helpers/formatting';
import { isSubscribedToFeature } from '../../Helpers/subscription_helpers';
import { getCurrentManager } from '../../Helpers/manager_helpers';
import { isUserIdPromoterForOutreachPurposes } from '../../Helpers/talent_helpers';
import { getBrandRequests, getCustomCodeForUserId } from '../../Helpers/brand_helpers';
import { matchScrollHeight } from '../../Helpers/helpers';

import './ChatMessages.scss';

let DISPLAYED_OLD_MSG_WARNING;
const ChatMessages = props => {
  const { sendMessage, user, ui, manager, openArtistModal, analytics, activeChat, editMessage, deleteMessage, typingMessage } = props;
  const { messages, typingData } = activeChat;
  const { brand } = activeChat || {};
  const { subscription_basic } = brand || {};
  const { length } = messages || {};
  const [curMessage, setCurMessage] = useState('');
  const [sentTyping, setSentTyping] = useState(false);
  const messagesAreaEl = useRef(null);

  const showTypingIndicator =
    typingData?.isTyping && (isBrand(user) ? typingData.User_id === activeChat.User_id : typingData.Brand_id === activeChat.Brand_id);

  const viewProfile = user => {
    openArtistModal({ id: user.User_id || user.id });
  };

  const scrollToBottom = () => {
    const offset = messagesAreaEl.current.scrollHeight - messagesAreaEl.current.offsetHeight;
    messagesAreaEl.current.scrollTo(0, offset); //, { behavior: 'smooth' });
  };
  useEffect(() => {
    length > 4 && scrollToBottom();
  }, [length]);

  useEffect(() => {
    !!showTypingIndicator && scrollToBottom();
  }, [showTypingIndicator]);

  useEffect(() => {
    messagesAreaEl.current.addEventListener('scroll', e => {
      const pixelsFromTop = messagesAreaEl?.current?.scrollTop;
      if (length >= 250 && pixelsFromTop > 1 && pixelsFromTop < 250 && !DISPLAYED_OLD_MSG_WARNING) {
        cogoToast.warn(`We only display the past ${250} messages. If you would like to see older messages, please reach out to us directly.`);
        DISPLAYED_OLD_MSG_WARNING = true;
      }
    });
  }, [messagesAreaEl, length]);

  const attemptSend = e => {
    e && e.preventDefault();

    if (curMessage.length) {
      sendMessage(curMessage);
      setCurMessage('');
      // handle socket typing updates
      if (!!typingMessage) typingMessage({ isTyping: false });
      setSentTyping(false);
    }

    if (filesToUpload.length) {
      filesToUpload.forEach((file, idx) => {
        setTimeout(() => {
          sendMessage(file);
        }, idx * 100 + 100); // stagger the messages so they don't all send at once
      });
      setFilesToUpload([]);
      window.newFilesToUpload = null;
    }
  };

  const handleIsTyping = stagedMessage => {
    if (stagedMessage && !!typingMessage && !sentTyping) {
      setSentTyping(true);
      const typingData = {
        isTyping: true,
        ...(isBrand(user) ? { Brand_id: getBrandId(user) } : { User_id: getUserId(user) })
      };
      typingMessage(typingData);
    } else if (!stagedMessage && !!typingMessage && sentTyping) {
      setSentTyping(false);
      typingMessage({ isTyping: false });
    }
  };

  const clickToSendMessageOnMobile = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          forceAutoFocus
          header='Write your message'
          placeholder='Write your message here'
          onCancel={onClose}
          onSubmit={additionalMessage => {
            if (!additionalMessage.length) return;
            sendMessage(additionalMessage);
            setCurMessage('');
          }}
        />
      )
    });
  };

  /*
    Allow users to upload images, PDFs, and other files
  */
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const onFileDrop = (acceptedFiles, rejectedFiles) => {
    setIsUploading(true);
    rejectedFiles.length && window.ALERT.error('Only PNG, JPG, JPEG and Gif files are supported.');
    window.totalFilesToUploadCount = acceptedFiles.length + filesToUpload.length;
  };

  const handlePreprocessFileDrop = (file, next) => {
    setIsUploading(true);
    next(file);
  };
  const onUploadFileProgress = progress => {
    setUploadProgress(progress);
    progress < 100 && setIsUploading(true); // In case of multi-upload
  };
  const completeFileUpload = async newImage => {
    const newFilesToUpload = [...(window.newFilesToUpload || filesToUpload), newImage];
    setFilesToUpload(newFilesToUpload);
    setIsUploading(false);
    setUploadProgress(0);
    window.newFilesToUpload = newFilesToUpload.length === window.totalFilesToUploadCount ? null : newFilesToUpload; // To make multi-upload work, due to scoping
  };

  useEffect(() => {
    setFilesToUpload([]);
    window.newFilesToUpload = null;
  }, [activeChat.id]);

  /*
    Allow direct messaging to only:

    1) Users talking to brands
    2) Brands who have had a positive response from a user
    3) Premium Plus Brands
  */
  const relevantRequests = isBrand(user)
    ? getBrandRequests(analytics).filter(r => r.User_id === activeChat.User_id)
    : getRequests(user).filter(r => r.Brand_id === activeChat.Brand_id);
  const hasAcceptedRequest = !!relevantRequests.find(r => (isBrand(user) ? r.userAccepted : r.brandAccepted));
  const hasResponderMessage = !!activeChat?.messages?.find(r => (isBrand(user) ? r?.isUserMessage : !r?.isUserMessage));

  let canMessage = false;
  let cannotMessageAlert;

  if (!isBrand(user)) {
    // Users
    const brandOffersChat = activeChat.brand?.settings?.offersChat ?? true;

    if (!isBanned(user) && brandOffersChat) canMessage = true;
    if (isBanned(user)) cannotMessageAlert = 'Your account is currently restricted from messaging brands.';
    if (!brandOffersChat) cannotMessageAlert = 'This brand has disabled messaging.';

    if (subscription_basic) {
      if (subscription_basic.status === 'cancelled') {
        canMessage = false;
        cannotMessageAlert = `${brand.name} is no longer available for chat on ShopMy.`;
      }
    }
  } else {
    // Brands
    if (hasAcceptedRequest) canMessage = true;
    if (hasResponderMessage) canMessage = true;
    if (isUserIdPromoterForOutreachPurposes(props.talent, activeChat?.user?.id) && isSubscribedToFeature(user, 'CHAT_WITH_PROMOTERS'))
      canMessage = true;
    if (isSubscribedToFeature(user, 'UNLIMITED_CHAT')) canMessage = true;
    if (!isSubscribedBrand(user)) canMessage = false;
    cannotMessageAlert = canMessage
      ? null
      : !isSubscribedBrand(user)
      ? `Your subscription is currently inactive, please reactivate it on the Invoices & Subscriptions tab`
      : `You can only message this user once they respond to your initial request, or begin promoting your brand. To message all talent, please subscribe to the Communications module.`;
  }

  // For Actions
  const requests = isBrand(user) ? getBrandRequests(analytics) : getRequests(user);
  const brandCode = isBrand(user) ? getCustomCodeForUserId(analytics, activeChat.User_id) : getCodeForBrandId(user, activeChat.Brand_id);

  const upsideDownMessages = activeChat?.messages?.slice().reverse();
  const currentManager = getCurrentManager(manager);
  const recipientName = activeChat[isBrand(user) ? 'user' : 'brand']?.name;
  const senderName = activeChat[isBrand(user) ? 'brand' : 'user']?.name;

  const getInputTextArea = () => (
    <textarea
      autoFocus
      rows={1}
      ref={ref => matchScrollHeight(ref)}
      disabled={!canMessage}
      placeholder={`Write a message to ${recipientName}${currentManager && !isMobile ? ` as ${currentManager.name} on behalf of ${senderName}` : ''}`}
      onChange={({ target }) => {
        setCurMessage(target.value);
        handleIsTyping(target.value);
      }}
      className='on-computer'
      onKeyPress={e => e.key === 'Enter' && e.ctrlKey && attemptSend()} /* For debugging */
      value={curMessage}
    />
  );

  const hasContent = curMessage.length > 0 || filesToUpload.length > 0;
  const isDismissed = isBrand(user) ? activeChat.isDismissedByBrand : activeChat.isDismissedByUser;
  const canSend = hasContent && canMessage;
  const clickToViewProfile = () => viewProfile(activeChat.user);
  return (
    <DropUploader className='drop-form-wrapper' onDrop={onFileDrop} onProgress={onUploadFileProgress} onUpload={completeFileUpload}>
      <div className='chat-messages-outer-container'>
        <ChatMessageHeader
          activeChat={activeChat}
          user={user}
          analytics={analytics}
          openFulfillmentModal={props.openFulfillmentModal}
          requestGiftingToUser={props.requestGiftingToUser}
          requestOpportunityToUser={props.requestOpportunityToUser}
          offerCodeToUser={() => props.offerCodeToUser(brandCode)}
          setCustomRate={props.setCustomRate}
          updateChat={props.updateChat}
          createCollaborationWithUser={props.createCollaborationWithUser}
          createCollaborationWithBrand={props.createCollaborationWithBrand}
          openArtistModal={props.openArtistModal}
          openBrandModal={props.openBrandModal}
          openBonusModal={props.openBonusModal}
        />
        <div className={cn('chat-messages', { dismissed: isDismissed })} ref={messagesAreaEl}>
          {activeChat.messages ? (
            upsideDownMessages.map((msg, idx) => {
              const prevMsg = upsideDownMessages[idx - 1];
              const showTimestamp =
                !prevMsg ||
                moment(msg.createdAt)
                  .local()
                  .diff(moment(prevMsg.createdAt).local(), 'hours') > 24;

              return (
                <Fragment key={msg.id}>
                  {showTimestamp && <div className='message-timestamp'>{getPrettyDate(msg.createdAt, 'dddd, MMMM Do', 'MMMM Do, YYYY')}</div>}
                  <ChatMessage
                    ui={ui}
                    user={user}
                    analytics={analytics}
                    manager={manager}
                    history={props.history}
                    chat={activeChat}
                    message={msg}
                    requests={requests}
                    brandCode={brandCode}
                    viewProfile={clickToViewProfile}
                    acceptGiftingFromBrand={props.acceptGiftingFromBrand}
                    requestGiftingToUser={props.requestGiftingToUser}
                    openFulfillmentModal={props.openFulfillmentModal}
                    offerCodeToUser={() => props.offerCodeToUser(brandCode)}
                    editMessage={editMessage}
                    deleteMessage={deleteMessage}
                  />
                </Fragment>
              );
            })
          ) : (
            <div>
              <Loader />
            </div>
          )}
          <div className={cn('chat-typing-wrapper', { active: !!showTypingIndicator })}>
            <ChatMessage
              message={{
                isUserMessage: isBrand(user),
                message: ''
              }}
              isTypingIndicator={true}
              chat={activeChat}
              editMessage={() => {}}
              deleteMessage={() => {}}
              requestGiftingToUser={() => {}}
              acceptGiftingFromBrand={() => {}}
              openFulfillmentModal={() => {}}
              manager={manager}
              user={user}
            />
          </div>
        </div>
        <form onSubmit={attemptSend}>
          <UploadImage
            basicContent={
              <div className='upload-icn'>
                <FontAwesomeIcon icon={faPaperclip} />
              </div>
            }
            basicUploader
            onUploadProgress={onUploadFileProgress}
            completeUpload={completeFileUpload}
            handlePreprocess={handlePreprocessFileDrop}
          />
          <div className='search-result-input'>
            <div onClick={clickToSendMessageOnMobile} className='on-mobile fake-input'>
              Click here to write a message
            </div>
            {canMessage ? getInputTextArea() : <Tooltip message={cannotMessageAlert} getIconDiv={getInputTextArea} />}
            {uploadProgress > 0 && isUploading && <div className='upload-progress-bar' style={{ width: `${uploadProgress}%` }} />}
          </div>
          {filesToUpload.length > 0 && (
            <div className='uploaded-files'>
              {filesToUpload.map((url, i) => {
                const remove = e => {
                  e.stopPropagation();
                  setFilesToUpload(filesToUpload.filter(f => f !== url));
                };
                const view = () => window.open(url, '_blank');
                const isImage = url.includes('.png') || url.includes('.jpg') || url.includes('.jpeg');
                const isPDF = url.includes('.pdf');
                return (
                  <div onClick={view} key={url} className='file'>
                    {isImage ? <img src={url} alt='uploaded' /> : <div className='file-label'>{isPDF ? 'PDF' : 'File'}</div>}
                    <div onClick={remove} className='remove'>
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {canSend && (
            <div onClick={attemptSend} className='submit-btn'>
              SEND
            </div>
          )}
        </form>
      </div>
    </DropUploader>
  );
};

ChatMessages.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  sendMessage: PropTypes.func.isRequired,
  editMessage: PropTypes.func.isRequired,
  deleteMessage: PropTypes.func.isRequired,
  typingMessage: PropTypes.func,
  updateChat: PropTypes.func.isRequired,
  createCollaborationWithBrand: PropTypes.func.isRequired,
  createCollaborationWithUser: PropTypes.func.isRequired,
  requestGiftingToUser: PropTypes.func.isRequired,
  requestOpportunityToUser: PropTypes.func.isRequired,
  openBrandModal: PropTypes.func.isRequired,
  openBonusModal: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openFulfillmentModal: PropTypes.func.isRequired,
  activeChat: PropTypes.object.isRequired
};

export default withRouter(ChatMessages);
