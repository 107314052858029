import React from 'react';
import PropTypes from 'prop-types';
import { copyToClipboard } from '../../../Helpers/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import { orderTrackingCodeShopify } from '../../../Helpers/brand_setup_helpers';

const BrandSetupShopify = props => {
  const { brand, updateBrand, clickTrackingCode, developerKey, generalCancellationEndpoint, generalUpdateEndpoint } = props;

  const { integrationStatus } = brand || {};
  const isIntegrationStatusPending = integrationStatus === 'PENDING';
  const isIntegrationStatusComplete = integrationStatus === 'COMPLETE';

  return (
    <>
      <div className='section'>
        <h2 className='section-header'>Step One: Integrate Click Tracking</h2>
        <ol className='section-step'>
          <li className='step'>Navigate to Online Store {'>'} Themes</li>
          <li className='step'>Click “Customize” button</li>
          <li className='step'>Click “Theme Actions” in the bottom left, then “Edit Code”</li>
          <li className='step'>Click “theme.liquid” in the left panel</li>
          <li className='step'>Add the one-line script below to the end of this HTML file just before the {`</body>`} tag</li>
          <div onClick={() => copyToClipboard(clickTrackingCode, true)} className='code'>
            {clickTrackingCode}
            <div className='copy-overlay'>
              <div>Click to Copy</div>
              <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
            </div>
          </div>
          <li className='step'>Click Save</li>
        </ol>
      </div>

      <div className='section'>
        <h2 className='section-header'>Step Two: Integrate Order Tracking</h2>
        <ol className='section-step'>
          <li className='step'>Click “Settings” in the bottom left corner</li>
          <li className='step'>Click “Checkout”</li>
          <li className='step'>Scroll to the Additional scripts section</li>
          <li className='step'>
            Add the following code to “Additional Scripts” (if you already have something in this section, just add it to the very bottom of the file,
            after all other text).
          </li>
          <div onClick={() => copyToClipboard(orderTrackingCodeShopify, true)} className='code'>
            {orderTrackingCodeShopify.split('\t').map((t, idx) => (
              <span key={idx} className={t ? 'text' : 'space'}>
                {t || ' '}
              </span>
            ))}
            <div className='copy-overlay'>
              <div>Click to Copy</div>
              <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
            </div>
          </div>
          <li className='step'>Click Save</li>
        </ol>
      </div>

      <div className='section'>
        <h2 className='section-header'>Step Three: Handle Order Cancellations</h2>
        <div className='section-step'>
          <div className='step'>
            Configure a POST request to be sent to the following endpoint whenever an order is cancelled. This is crucial for maintaining accurate
            tracking and reporting in our system.
          </div>
          <div className='step code' onClick={() => copyToClipboard(generalCancellationEndpoint, true)}>
            {generalCancellationEndpoint}
            <div className='copy-overlay'>
              <div>Click to Copy</div>
              <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
            </div>
          </div>
          <div className='step'>
            Use your unique brand developer key, <b>{developerKey}</b>, in the request headers. This key should be included as a bearer token for
            authorization. Keep this key confidential to ensure security.
          </div>
          <div className='step' onClick={() => copyToClipboard(developerKey, true, 'Copied Bearer Token')}>
            Example header format:
            <div className='code'>
              Authorization: Bearer {developerKey}
              <div className='copy-overlay'>
                <div>Click to Copy</div>
                <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
              </div>
            </div>
          </div>
          <div className='step'>
            The request body should be structured as follows:
            <div className='code'>{JSON.stringify({ order_id: '123456' })}</div>
          </div>
        </div>
      </div>

      <div className='section'>
        <h2 className='section-header'>Step Four: Handle Order Updates</h2>
        <div className='section-step'>
          <div className='step'>
            Configure a POST request to be sent to the following endpoint whenever an order is updated. We define an order update as whenever a order
            has new total amount, or currency change. This is crucial for maintaining accurate tracking and reporting in our system.
          </div>
          <div className='step code' onClick={() => copyToClipboard(generalUpdateEndpoint, true)}>
            {generalUpdateEndpoint}
            <div className='copy-overlay'>
              <div>Click to Copy</div>
              <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
            </div>
          </div>
          <div className='step'>
            Use your unique brand developer key, <b>{developerKey}</b>, in the request headers. This key should be included as a bearer token for
            authorization. Keep this key confidential to ensure security.
          </div>
          <div className='step' onClick={() => copyToClipboard(developerKey, true, 'Copied Bearer Token')}>
            Example header format:
            <div className='code'>
              Authorization: Bearer {developerKey}
              <div className='copy-overlay'>
                <div>Click to Copy</div>
                <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
              </div>
            </div>
          </div>
          <div className='step'>
            The request body should be structured as follows:
            <div className='code'>{JSON.stringify({ order_id: '123456', currency: 'USD', new_order_amount: 1234.32 })}</div>
          </div>
        </div>
      </div>

      <div className='section'>
        <h2 className='section-header'>Step Five: Request Testing</h2>

        <div>
          Please make a discount code <b>SHOPMYSHELF_TEST</b> for 100% off then {brand ? 'click the button below' : 'email us'} and we will test the
          integration end-to-end and let you know the order ID so we can cancel it afterwards.
        </div>

        {brand && (
          <div
            className={cn('integration-btn', { complete: isIntegrationStatusComplete, pending: isIntegrationStatusPending })}
            onClick={async () => {
              if (isIntegrationStatusPending) {
                window.ALERT.info(
                  'We are currently in the process of reviewing your integration. If you have any further questions please reach us at team@shopmy.us'
                );
              } else {
                confirmAlert({
                  title: 'Just confirming',
                  message:
                    'Did you make a discount code SHOPMYSHELF_TEST for 100% off? We will use this to test the integration and then cancel the order upon completion of the test.',
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: async () => {
                        await updateBrand(brand, { integrationStatus: 'PENDING' });
                        window.ALERT.success('We will review your integration and reach out shortly!');
                      }
                    },
                    { label: 'No', onClick: () => {} }
                  ]
                });
              }
            }}
          >
            {isIntegrationStatusPending ? 'In Review' : isIntegrationStatusComplete ? 'Integration Complete!' : 'Request Integration Review'}
          </div>
        )}
      </div>
    </>
  );
};

BrandSetupShopify.propTypes = {
  brand: PropTypes.object.isRequired,
  updateBrand: PropTypes.func.isRequired,
  clickTrackingCode: PropTypes.string.isRequired,
  developerKey: PropTypes.string,
  generalCancellationEndpoint: PropTypes.string.isRequired,
  generalUpdateEndpoint: PropTypes.string.isRequired
};

export default BrandSetupShopify;
