import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import Switch from 'react-switch';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './DiscoverSidebar.scss';

import { addDiscoverList } from '../../APIClient/discover';
import {
  syncActiveDiscoverGroup,
  updateDiscoverGroup,
  createDiscoverGroup,
  deleteDiscoverGroup,
  createDiscoverFeature
} from '../../Actions/DiscoverActions';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { getDiscoverGroups, getActiveDiscoverGroup } from '../../Helpers/discover_helpers';

import DiscoverSidebarGroup from './DiscoverSidebarGroup';
import DiscoverTagGroups from './Elements/DiscoverTagGroups';
import DiscoverAddFeatureList from './Elements/DiscoverAddFeatureList';
import SortableList from '../General/SortableList';

const DiscoverSidebar = props => {
  const { isEditing, discover } = props;

  const searchInputRef = React.useRef(null);

  // Sync Data
  const groups = _.filter(getDiscoverGroups(discover), g => (isEditing ? true : !g.isHidden && g.features?.length));
  const activeGroup = getActiveDiscoverGroup(discover);

  // Primary Actions
  const addNewGroup = () => {
    const title = prompt('What would you like to name this group?');
    if (!title) return;
    props.createDiscoverGroup({ title });
  };

  const addNewList = async () => {
    const resp = await addDiscoverList();
    window.location.href = `/discover/${resp.id}`;
  };

  const getCard = (group, additionalProps = {}) => {
    const { rearrangeHandle } = additionalProps;
    return (
      <DiscoverSidebarGroup
        key={group.id}
        group={group}
        activeGroup={activeGroup}
        isEditing={props.isEditing}
        rearrangeHandle={rearrangeHandle}
        checkSubscriptionLevelThenRunFn={props.checkSubscriptionLevelThenRunFn}
        {...props}
      />
    );
  };

  const toggleSearchMode = () => {
    // Allow non partner+ subscribed partners to see this page
    props.setInSearchMode(!props.inSearchMode);
  };

  const updateSearch = newVal => {
    props.checkSubscriptionLevelThenRunFn(() => {
      props.setPage(0);
      props.setCurSearchVal(newVal);
    });
  };

  return (
    <div className='discover-sidebar'>
      {props.inSearchMode && (
        <>
          <div onClick={toggleSearchMode} className='back-from-search-mode-icn'>
            <FontAwesomeIcon className='back-chevron icon' icon={faChevronLeft} />
            Back to Discover Home
          </div>
          {/* Search Experience */}
          <div className='search-container'>
            <input
              autoFocus
              value={props.curSearchVal}
              onChange={e => updateSearch(e.target.value)}
              placeholder='Creators, brands, tags or lists'
              className='search-input'
              ref={searchInputRef}
            />
            <div className={cn('search-options', { searching: props.isSearching })}>
              <FontAwesomeIcon
                onClick={() => (props.curSearchVal ? updateSearch('') : searchInputRef.current.focus())}
                className='search-icon icon'
                icon={props.curSearchVal ? faTimes : faSearch}
              />
            </div>
          </div>
          {/* Tags */}
          <DiscoverTagGroups
            user={props.user}
            ui={props.ui}
            discover={props.discover}
            results={props.results}
            curSearchVal={props.curSearchVal}
            setCurSearchVal={props.setCurSearchVal}
            setSelectedTags={props.setSelectedTags}
            selectedTags={props.selectedTags}
            isSearchingTags={props.isSearchingTags}
            isSearchingUsers={props.isSearchingUsers}
            setPage={props.setPage}
            checkSubscriptionLevelThenRunFn={props.checkSubscriptionLevelThenRunFn}
          />
        </>
      )}
      {!props.inSearchMode && (
        <>
          <div onClick={toggleSearchMode} className='go-to-search-mode-btn'>
            <div className='label'>Search or filter</div>
            <FontAwesomeIcon className='search-icon icon' icon={faSearch} />
          </div>
          <SortableList
            axis='y'
            isEditing={props.isEditing}
            containerClassName='discover-groups'
            items={groups}
            updateItem={props.updateDiscoverGroup}
            props={props}
            getCard={getCard}
          />
          <DiscoverSidebarGroup
            isMyLists
            user={props.user}
            isEditing={props.isEditing}
            onListsTab={props.onListsTab}
            setOnListsTab={props.setOnListsTab}
            checkSubscriptionLevelThenRunFn={props.checkSubscriptionLevelThenRunFn}
          />
        </>
      )}
      {/* Admin based features to edit Discover Home Page */}
      {isAdminControlMode(props.ui) && (
        <div className='admin-section'>
          {props.isEditing && (
            <div onClick={addNewGroup} className='add-new'>
              + ADD NEW GROUP
            </div>
          )}
          <div className='edit-toggle'>
            <div className='label'>Edit Discover</div>
            <Switch
              onChange={() => props.setIsEditing(!props.isEditing)}
              checked={props.isEditing}
              height={14}
              width={24}
              onColor='#11835a'
              offColor='#999'
              className='switch'
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
          <DiscoverAddFeatureList
            user={props.user}
            discover={props.discover}
            createDiscoverFeature={props.createDiscoverFeature}
            syncActiveDiscoverGroup={props.syncActiveDiscoverGroup}
          />
          <div onClick={addNewList} className='create-new'>
            + NEW LIST
          </div>
        </div>
      )}
    </div>
  );
};

DiscoverSidebar.propTypes = {
  // From Outside
  results: PropTypes.array.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  inSearchMode: PropTypes.bool.isRequired,
  setInSearchMode: PropTypes.func.isRequired,
  isSearchingUsers: PropTypes.bool.isRequired,
  curSearchVal: PropTypes.string.isRequired,
  selectedTags: PropTypes.array.isRequired,
  setCurSearchVal: PropTypes.func.isRequired,
  setSelectedTags: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  onListsTab: PropTypes.bool.isRequired,
  setOnListsTab: PropTypes.func.isRequired,
  checkSubscriptionLevelThenRunFn: PropTypes.func.isRequired,

  // From Redux
  user: PropTypes.object.isRequired,
  discover: PropTypes.object.isRequired,
  syncActiveDiscoverGroup: PropTypes.func.isRequired,
  createDiscoverFeature: PropTypes.func.isRequired,
  updateDiscoverGroup: PropTypes.func.isRequired,
  createDiscoverGroup: PropTypes.func.isRequired,
  deleteDiscoverGroup: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { ui, user, discover } = state;
  return { ui, user, discover };
};

export default connect(mapStateToProps, {
  syncActiveDiscoverGroup,
  createDiscoverFeature,
  updateDiscoverGroup,
  createDiscoverGroup,
  deleteDiscoverGroup
})(DiscoverSidebar);
