import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './LatestHeader.scss';

import { setUrlParam } from '../../Helpers/helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';

const LatestHeader = props => {
  const { ui, latest, setActiveTab } = props;
  const { newsletter, activeTab } = latest;
  const tabs = _.orderBy(newsletter?.tabs || [], 'sortOrderRank');
  const isFetching = !newsletter;

  // Used for animation controls
  const [isInitialLoad, setIsInitialLoad] = React.useState(!newsletter);
  React.useEffect(() => {
    setTimeout(() => setIsInitialLoad(false), 1000);
  }, []);

  // Handle Admin Reloads
  const [isReloading, setIsReloading] = React.useState(false);
  const reloadPage = async () => {
    setIsReloading(true);
    await props.syncNewsletter();
    setIsReloading(false);
  };

  const additionalClasses = { fetching: isFetching, 'initial-load': isInitialLoad };
  return (
    <div className='latest-header-container'>
      {isAdminControlMode(ui) && (
        <div className='reload-btn' onClick={reloadPage}>
          {isReloading ? 'Reloading' : 'Reload'}
          <FontAwesomeIcon icon={faSync} spin={isReloading} />
        </div>
      )}
      <div className={cn('header-container', additionalClasses)}>
        <div className='badge'>{moment().format('MMMM Do')}</div>
        <div className={cn('header', additionalClasses)}>Latest on ShopMy</div>
      </div>
      <div className='tabs'>
        {isFetching ? (
          <>
            <div className='tab-container empty'>
              <div className='tab'>Loading</div>
            </div>
            <div className='tab-container empty'>
              <div className='tab'>Loading</div>
            </div>
            <div className='tab-container empty'>
              <div className='tab'>Loading</div>
            </div>
          </>
        ) : (
          tabs.map(tab => {
            const { id, title } = tab;
            const isActive = activeTab?.id === id;
            const select = () => {
              setActiveTab(isActive ? tabs[0] : tab);
              setUrlParam('tab', title);
              window.__ADD_EVENT__('Latest - Click Tab', { tab: title });
            };
            return (
              <div key={id} onClick={select} className={cn('tab-container', { active: isActive, ...additionalClasses })}>
                <div className={isActive ? 'tab active' : 'tab'}>{title}</div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

LatestHeader.propTypes = {
  ui: PropTypes.object.isRequired,
  latest: PropTypes.object.isRequired,
  syncNewsletter: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  activeTab: PropTypes.object
};

export default LatestHeader;
