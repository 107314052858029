import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faEye, faEyeSlash, faEmptySet, faLightbulb, faLightbulbSlash } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './DiscoverSidebarGroup.scss';

const DiscoverSidebarGroup = props => {
  /*
    isMyLists: Brand Lists (boolean)
    group: The featured group of Discover Lists to display
  */
  const { isMyLists, group, activeGroup, isEditing, rearrangeHandle } = props;

  let isActive, select;
  if (isMyLists) {
    isActive = props.onListsTab;
    // Allow non partner+ subscribed partners to see this page
    select = () => props.setOnListsTab(true);
  } else {
    isActive = !props.onListsTab && activeGroup?.id === group?.id;
    select = () => {
      if (group.isDefault) {
        props.syncActiveDiscoverGroup(group);
        props.setOnListsTab(false);
      } else {
        props.checkSubscriptionLevelThenRunFn(() => {
          props.syncActiveDiscoverGroup(group);
          props.setOnListsTab(false);
        });
      }
    };
  }

  const remove = e => {
    e.stopPropagation();
    if (!window.confirm(`Are you sure you want to permanantly delete ${group.title}?`)) return;
    props.deleteDiscoverGroup(group);
  };
  const update = e => {
    e.stopPropagation();
    const title = prompt('What would you like to name this group?', group.title);
    if (!title) return;
    props.updateDiscoverGroup(group, { title });
  };

  const toggleVisibility = e => {
    e.stopPropagation();
    props.updateDiscoverGroup(group, { isHidden: !group.isHidden });
  };
  const toggleNew = e => {
    e.stopPropagation();
    props.updateDiscoverGroup(group, { isNew: !group.isNew });
  };
  return (
    <div onClick={select} key={isMyLists ? 'lists' : group?.id} className={cn('discover-sidebar-group', { active: isActive })}>
      {isEditing && group && (
        <div className='action-icons'>
          {rearrangeHandle}
          <div className={cn({ 'always-show': false })} onClick={remove}>
            <FontAwesomeIcon icon={faTrash} />
          </div>
          <div className={cn({ 'always-show': false })} onClick={update}>
            <FontAwesomeIcon icon={faPencil} />
          </div>
          <div className={cn({ 'always-show': group.isHidden })} onClick={toggleVisibility}>
            <FontAwesomeIcon icon={group.isHidden ? faEyeSlash : faEye} />
          </div>
          {!group.features.length && (
            <div className={cn({ 'always-show': true })} onClick={toggleNew}>
              <FontAwesomeIcon icon={faEmptySet} />
            </div>
          )}
          <div className={cn({ 'always-show': false })} onClick={toggleNew}>
            <FontAwesomeIcon icon={group.isNew ? faLightbulb : faLightbulbSlash} />
          </div>
        </div>
      )}
      {!!group?.isNew && <div className='new-badge'>NEW</div>}
      {isMyLists ? 'My Lists' : group.title}
    </div>
  );
};

DiscoverSidebarGroup.propTypes = {
  // From Outside
  user: PropTypes.object,
  discover: PropTypes.object,
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
  checkSubscriptionLevelThenRunFn: PropTypes.func,

  // If My Lists Tab
  isMyLists: PropTypes.bool,
  onListsTab: PropTypes.bool,
  setOnListsTab: PropTypes.func,

  // From Redux
  syncActiveDiscoverGroup: PropTypes.func,
  updateDiscoverGroup: PropTypes.func,
  createDiscoverGroup: PropTypes.func,
  deleteDiscoverGroup: PropTypes.func
};

export default DiscoverSidebarGroup;
