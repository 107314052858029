import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPencil } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';
import './TalentSidebar.scss';

import { getTalentActionEnumDetails, getTalentStatusEnumDetails, isTopTalent } from '../../Helpers/talent_helpers';
import { getGiftingRequestsForLookbookId } from '../../Helpers/brand_helpers';
import { getBrandListById, getLookbooks, getLookbookById } from '../../Helpers/user_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { blockOnRequiringSubscriptionToFeature, isSubscribedToFeatureOrAdmin } from '../../Helpers/subscription_helpers';
import { openUpdateListModal } from '../../Helpers/list_helpers';

const SHOW_ALL_ENUM = false;
// const SHOW_ALL_ENUM = true;

const TalentSidebar = props => {
  const { results, ui, user, analytics, curListId, curLookbookId, curStatusEnum, curActionEnum, showTopTalentOnly, showBottomTalentOnly } = props;

  // Allowed Results are all those that are not only visible to admins
  const allowedResultsForLists = _.filter(results, result => {
    const hasNonAdminAction = result.statusEnums.find(ae => !getTalentStatusEnumDetails(ae)?.isAdmin);
    const onlyAdminActions = !hasNonAdminAction;
    if (onlyAdminActions && !isAdminControlMode(ui)) return false;
    return true;
  });
  // Filtered Results are all those that are allowed based on global filters
  const filteredResultsForNonLists = _.filter(allowedResultsForLists, result => {
    if (showTopTalentOnly && !isTopTalent(result)) return false;
    if (showBottomTalentOnly && isTopTalent(result)) return false;
    return true;
  });
  // Ui Handling
  const isOptionSelected = !!curListId || !!curLookbookId || !!curStatusEnum || !!curActionEnum;
  const talentFilterActive = showTopTalentOnly || showBottomTalentOnly;
  const canAccess = isSubscribedToFeatureOrAdmin(user, 'CORE');

  const recommendationResults = _.filter(allowedResultsForLists, r => r.recommendation);
  const listUserResults = _.filter(allowedResultsForLists, r => r.listUsers?.length);
  const actionGroupEnums = _.uniq(_.flatten(_.map(filteredResultsForNonLists, 'actionEnums')));
  const statusGroupEnums = _.uniq(_.flatten(_.map(filteredResultsForNonLists, 'statusEnums')));

  // const allActionGroupEnums = _.orderBy(actionGroupEnums, e => getTalentActionEnumDetails(e).rank);
  const allStatusGroupEnums = _.orderBy(statusGroupEnums, e => getTalentStatusEnumDetails(e).rank);

  // Group by Enum
  const recruitingEnums = _.filter(allStatusGroupEnums, e => getTalentStatusEnumDetails(e).section === 'recruiting');
  const contactingEnums = _.filter(allStatusGroupEnums, e => getTalentStatusEnumDetails(e).section === 'contacting');
  const otherEnums = _.filter(
    allStatusGroupEnums,
    e =>
      getTalentStatusEnumDetails(e).section !== 'recommending' &&
      getTalentStatusEnumDetails(e).section !== 'lists' &&
      getTalentStatusEnumDetails(e).section !== 'contacting' &&
      getTalentStatusEnumDetails(e).section !== 'recruiting'
  );

  // Final Sections
  const recommendationsBrandListIds = _.uniq(_.map(recommendationResults, r => r.recommendation?.BrandList_id));
  const ourListBrandListIds = _.uniq(_.flatten(_.map(listUserResults, r => r.listUsers.map(l => l.BrandList_id))));
  const ourLookbookIds = getLookbooks(user).map(l => l.id);
  const lowPriorityListIds = recommendationsBrandListIds.filter(BrandList_id => !getBrandListById(user, BrandList_id)?.isHighPriority);
  const highPriorityListIds = recommendationsBrandListIds.filter(BrandList_id => getBrandListById(user, BrandList_id)?.isHighPriority);
  const statusSections = [
    {
      display: 'ShopMy Recommendations',
      statusEnums: [],
      BrandList_ids: lowPriorityListIds.filter(id => getBrandListById(user, id)?.isVisibleOnTalentTab)
    },
    {
      display: 'Our Lists',
      statusEnums: [],
      BrandList_ids: ourListBrandListIds
    },
    {
      display: 'Waiting On Response',
      statusEnums: contactingEnums
    },
    {
      display: 'Currently Recruiting',
      statusEnums: recruitingEnums
    },
    {
      display: 'Our Promoters',
      statusEnums: otherEnums
    },
    {
      display: 'Our Lookbooks',
      Lookbook_ids: ourLookbookIds
    }
  ];

  const getBulkActionsButton = ({ BrandList_id, talent = [], statusEnum, actionEnum }) => {
    const brandList = BrandList_id && getBrandListById(user, BrandList_id);
    const isGifting = brandList?.recommendationListType === 'gifting' || actionEnum?.includes('GIFTING');
    const bulkActionDisplay = isGifting ? 'Gift' : 'Message';
    const giftingBulkButton = e => {
      e.stopPropagation();
      props.openRequestModal({ params: { BrandList_id } });
    };
    const messagingBulkButton = e => {
      e.stopPropagation();
      props.openBulkTalentModal({ BrandList_id, talent, outreachType: 'general' });
    };

    return (
      <div className='bulk-actions'>
        {isGifting ? (
          <>
            <div onClick={messagingBulkButton} className='bulk-action'>
              Message
            </div>
            <div onClick={giftingBulkButton} className='bulk-action'>
              Gift
            </div>
          </>
        ) : (
          <>
            <div onClick={messagingBulkButton} className='bulk-action'>
              {bulkActionDisplay}
            </div>
          </>
        )}
      </div>
    );
  };

  const getBrandListElement = BrandList_id => {
    const brandList = getBrandListById(user, BrandList_id);
    if (!brandList) return null;
    if (!brandList.isVisibleOnTalentTab) return null;

    // Talent can either be in recommendations or lists
    const talent = _.uniqBy(
      [
        ...recommendationResults.filter(r => r.recommendation?.BrandList_id === BrandList_id),
        ...listUserResults.filter(r => !!r.listUsers?.find(u => u.BrandList_id === BrandList_id))
      ],
      'id'
    );

    const isSelected = props.curListId === BrandList_id;
    const anotherSelected = !isSelected && isOptionSelected;
    const select = () => {
      if (blockOnRequiringSubscriptionToFeature('CORE')) return null;
      props.setCurStatusEnum(null);
      props.setCurActionEnum(null);
      props.setCurLookbookId(null);
      props.setCurListId(isSelected ? null : BrandList_id);
    };
    const openEditModal = e => {
      e.stopPropagation();
      openUpdateListModal(
        brandList,
        props.updateBrandList,
        props.deleteBrandList,
        () => props.syncTalent(),
        () => {}
      );
    };

    const bulkActionsButton = getBulkActionsButton({ BrandList_id });
    const classes = {
      alert: brandList.isHighPriority,
      selected: isSelected,
      'another-selected': anotherSelected,
      'has-actions': !!bulkActionsButton,
      'cannot-view': !canAccess
    };

    return (
      <div onClick={select} className={cn('item-container', classes)} key={BrandList_id}>
        <div onClick={openEditModal} className='edit-btn'>
          <FontAwesomeIcon icon={faPencil} />
        </div>
        <div className={cn('item-text', classes)}>
          {brandList.title}
          {isSelected && <FontAwesomeIcon icon={faTimes} />}
        </div>
        <div className={cn('item-count', classes)}>{talent.length}</div>
        {bulkActionsButton}
      </div>
    );
  };

  const getLookbookElement = Lookbook_id => {
    const lookbook = getLookbookById(user, Lookbook_id);
    if (!lookbook) return null;

    // Talent can either be in recommendations or lists
    const requests = getGiftingRequestsForLookbookId(analytics, Lookbook_id);
    const User_ids = new Set(_.map(requests, 'User_id'));
    const talent = results.filter(r => User_ids.has(r.id));
    if (!talent.length) return null;

    const isSelected = props.curLookbookId === Lookbook_id;
    const anotherSelected = !isSelected && isOptionSelected;
    const select = () => {
      if (blockOnRequiringSubscriptionToFeature('CORE')) return null;
      props.setCurStatusEnum(null);
      props.setCurActionEnum(null);
      props.setCurListId(null);
      props.setCurLookbookId(isSelected ? null : Lookbook_id);
    };

    const bulkActionsButton = getBulkActionsButton({ talent });
    const classes = {
      selected: isSelected,
      'another-selected': anotherSelected,
      'has-actions': !!bulkActionsButton,
      'cannot-view': !canAccess
    };

    return (
      <div onClick={select} className={cn('item-container', classes)} key={lookbook.id}>
        <div className={cn('item-text', classes)}>
          {lookbook.title} {lookbook.id}
          {isSelected && <FontAwesomeIcon icon={faTimes} />}
        </div>
        <div className={cn('item-count', classes)}>{talent.length}</div>
        {bulkActionsButton}
      </div>
    );
  };

  const visibleActionEnums = _.filter(actionGroupEnums, e => getTalentActionEnumDetails(e).isVisible || SHOW_ALL_ENUM);
  const hasActionsRequired = !!(visibleActionEnums.length || highPriorityListIds.length);
  return (
    <div className='talent-sidebar-outer-container'>
      {hasActionsRequired ? (
        <div className='section action-required'>
          <div className='section-header'>Action Required</div>
          <div className='section-body'>
            {_.map(visibleActionEnums, actionEnum => {
              const { display, showAlert, talentFilterForceOverride } = getTalentActionEnumDetails(actionEnum);
              const talent = filteredResultsForNonLists.filter(r =>
                talentFilterForceOverride ? talentFilterForceOverride(r) : r.actionEnums?.includes(actionEnum)
              );
              const isSelected = props.curActionEnum === actionEnum;
              const anotherSelected = !isSelected && isOptionSelected;

              const bulkActionsButton = getBulkActionsButton({ talent, actionEnum });
              const select = () => {
                if (blockOnRequiringSubscriptionToFeature('CORE')) return null;
                props.setCurActionEnum(isSelected ? null : actionEnum);
                props.setCurStatusEnum(null);
                props.setCurListId(null);
                props.setCurLookbookId(null);
              };
              const classes = {
                alert: showAlert,
                selected: isSelected,
                'another-selected': anotherSelected,
                'has-actions': !!bulkActionsButton,
                'cannot-view': !canAccess
              };
              return (
                <div onClick={select} className={cn('item-container', classes)} key={actionEnum}>
                  <div className={cn('item-text', classes)}>
                    {display}
                    {isSelected && <FontAwesomeIcon icon={faTimes} />}
                  </div>
                  <div className={cn('item-count', classes)}>{talent.length}</div>
                  {bulkActionsButton}
                </div>
              );
            })}
            {_.map(highPriorityListIds, BrandList_id => {
              return getBrandListElement(BrandList_id);
            })}
          </div>
        </div>
      ) : (
        <div className='section no-action-required'>
          <div className='section-header'>All Caught Up</div>
          <div className='section-subheader'>
            There are no urgent actions required at this time. For additional insights on your account reach out to your success manager.
          </div>
        </div>
      )}
      {statusSections.map(data => {
        const { display, statusEnums, BrandList_ids, Lookbook_ids } = data;

        // If grouping by status enum
        const visibleStatusEnums = _.filter(statusEnums, e => getTalentStatusEnumDetails(e).isVisible || SHOW_ALL_ENUM);

        if (visibleStatusEnums.length) {
          const anotherSectionSelected = isOptionSelected && !visibleStatusEnums.includes(curStatusEnum);
          return (
            <div className='section' key={display}>
              <div className={cn('section-header', { 'another-selected': anotherSectionSelected })}>{display}</div>
              <div className='section-body'>
                {_.map(visibleStatusEnums, statusEnum => {
                  const sed = getTalentStatusEnumDetails(statusEnum);
                  const { display, showAlert, talentFilterForceOverride } = sed;
                  const talent = filteredResultsForNonLists.filter(r =>
                    talentFilterForceOverride ? talentFilterForceOverride(r) : r.statusEnums?.includes(statusEnum)
                  );
                  const isSelected = props.curStatusEnum === statusEnum;
                  const anotherSelected = !isSelected && isOptionSelected;
                  const bulkActionsButton = getBulkActionsButton({ talent, statusEnum });

                  const select = () => {
                    if (blockOnRequiringSubscriptionToFeature('CORE')) return null;
                    props.setCurStatusEnum(isSelected ? null : statusEnum);
                    props.setCurActionEnum(null);
                    props.setCurListId(null);
                    props.setCurLookbookId(null);
                  };
                  const classes = {
                    alert: showAlert,
                    selected: isSelected,
                    'another-selected': anotherSelected,
                    'has-actions': !!bulkActionsButton,
                    'cannot-view': !canAccess
                  };
                  return (
                    <div onClick={select} className={cn('item-container', classes)} key={statusEnum}>
                      <div className={cn('item-text', classes)}>
                        {display}
                        {isSelected && <FontAwesomeIcon icon={faTimes} />}
                      </div>
                      <div className={cn('item-count', classes)}>{talent.length}</div>
                      {bulkActionsButton}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        } else if (BrandList_ids?.length) {
          const anotherSectionSelected =
            !!props.curStatusEnum || !!props.curActionEnum || !!props.curLookbookId || (props.curListId && !BrandList_ids.includes(props.curListId));
          return (
            <div className='section' key={display}>
              <div className={cn('section-header', { 'another-selected': anotherSectionSelected })}>{display}</div>
              {talentFilterActive && (
                <div className={cn('section-subheader', { 'another-selected': anotherSectionSelected })}>Showing All Results</div>
              )}
              <div className='section-body'>
                {_.map(BrandList_ids, BrandList_id => {
                  return getBrandListElement(BrandList_id);
                })}
              </div>
            </div>
          );
        } else if (Lookbook_ids?.length) {
          const anotherSectionSelected =
            !!props.curStatusEnum || !!props.curActionEnum || props.curListId || (props.curLookbookId && !Lookbook_ids.includes(props.curLookbookId));

          const lookbookElements = _.filter(_.map(Lookbook_ids, getLookbookElement));
          if (!lookbookElements.length) return null;
          return (
            <div className='section' key={display}>
              <div className={cn('section-header', { 'another-selected': anotherSectionSelected })}>{display}</div>
              {talentFilterActive && (
                <div className={cn('section-subheader', { 'another-selected': anotherSectionSelected })}>Showing All Results</div>
              )}
              <div className='section-body'>{lookbookElements}</div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

TalentSidebar.propTypes = {
  user: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  results: PropTypes.array.isRequired,

  // Filtering
  setCurActionEnum: PropTypes.func.isRequired,
  setCurStatusEnum: PropTypes.func.isRequired,
  setCurListId: PropTypes.func.isRequired,
  curActionEnum: PropTypes.string,
  curStatusEnum: PropTypes.string,
  curListId: PropTypes.number,
  showTopTalentOnly: PropTypes.bool,
  showBottomTalentOnly: PropTypes.bool,

  // List management
  updateBrandList: PropTypes.func.isRequired,
  deleteBrandList: PropTypes.func.isRequired,
  openBulkTalentModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  syncTalent: PropTypes.func.isRequired
};

export default TalentSidebar;
