import APIClient from './index';
import _ from 'lodash';

export const createCollection = body => {
  return APIClient.post('/Collections', body)
    .then(response => response.data)
    .catch(error => console.error(error));
};

export const duplicateCollection = collection => {
  return new Promise((resolve, reject) => {
    return APIClient.post(`/Collections/duplicate/${collection.id}`)
      .then(response => resolve(response?.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getCollection = id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Collections/${id}`)
      .then(response => resolve(response?.data))
      .catch(err => reject(false));
  });
};

export const deleteCollection = (id, body) => {
  return new Promise((resolve, reject) => {
    return APIClient.delete(`/Collections/${id}`, body)
      .then(response => resolve(response?.data))
      .catch(error => reject(error.data));
  });
};

export const saveCollection = (id, body) => {
  return APIClient.put(`/Collections/${id}`, body)
    .then(response => response.data)
    .catch(error => console.error(error));
};

export const getCollectionAnalytics = id => {
  return APIClient.get(`/Collections/${id}/payout_analytics`)
    .then(response => response.data)
    .catch(error => console.error(error));
};
