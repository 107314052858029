import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import commaNumber from 'comma-number';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Mousetrap from 'mousetrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faArrowAltToBottom } from '@fortawesome/pro-solid-svg-icons';
import './Payouts.scss';

import Tooltip from '../General/Tooltip';
import RequiresPermissions from '../../Components/Managers/RequiresPermissions';

import { downloadCsvFromUrl } from '../../Helpers/helpers';
import { getPrettyPriceDisplay, getPrettyDate } from '../../Helpers/formatting';
import {
  isBrand,
  isSMSAffiliatePartnerBrand,
  isAgent,
  isAdmin,
  isShopifyUserRequiredForAppAcceptance,
  getUserId,
  getUsername,
  isLegacyPaypalBrand,
  getBrandBudgetRemaining,
  getStripeAccountId,
  getBrandBudgetIncreaseTakeRate,
  getReferrals,
  getReferringUser
} from '../../Helpers/user_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { REFERRAL_LENGTH_DAYS, REFERRAL_PAYOUT_RATE, REFERRAL_LENGTH_MONTHS_DISPLAY } from '../../Helpers/referral_helpers';
import { getUserTierDisplay } from '../../Helpers/tier_helpers';
import { goToStripeBillingDashboard } from '../../Helpers/stripe_helpers';
import { downloadCommissions } from '../../APIClient/payouts';

import PayoutsAccounts from './PayoutsAccounts';

class Payouts extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    linkPaypalAccount: PropTypes.func.isRequired,
    linkInvoiceEmailAccount: PropTypes.func.isRequired,
    unlinkPaymentAccount: PropTypes.func.isRequired,
    openBrandBudgetModal: PropTypes.func.isRequired,
    getUserPayouts: PropTypes.func.isRequired,
    getUserPayments: PropTypes.func.isRequired,
    getBrandInvoices: PropTypes.func.isRequired,
    updateCurrentUser: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { getUserPayouts, getUserPayments, getBrandInvoices, user } = this.props;
    getUserPayouts(user);
    getUserPayments(user);
    isBrand(user) && getBrandInvoices(user);
    if (isAdmin(user)) {
      /* Allow admins to mock the data for alternative users. */
      Mousetrap.bind('ctrl+a', () => {
        const resp = window.prompt('Input a User ID to simulate this panel for that user.');
        const userId = parseInt(resp);
        if (userId) {
          const mockUser = { id: userId };
          getUserPayouts(mockUser);
          getUserPayments(mockUser);
        }
      });
    }
  }

  componentWillUnmount() {
    Mousetrap.unbind('ctrl+a');
  }

  state = {
    itemsVisible: 10,
    consultResultsVisible: 10,
    contractsVisible: 10,
    monthsVisible: 5,
    paymentsVisible: 5,
    invoicesVisible: 5,
    contractInvoicesVisible: 5,
    budgetInvoicesVisible: 5,
    referralsVisible: 5,
    isDownloading: false
  };

  downloadCommissions = async () => {
    if (this.state.isDownloading) return;
    this.setState({ isDownloading: true });
    const offsetFromUTC = new Date().getTimezoneOffset();
    try {
      const resp = await downloadCommissions({ User_id: getUserId(this.props.user), timezoneOffset: offsetFromUTC });
      downloadCsvFromUrl(resp.url);
      cogoToast.success('Successfully Downloaded!');
    } catch (error) {
      cogoToast.error(error || `Error downloading, please try again.`);
    }
    this.setState({ isDownloading: false });
  };

  openAddToBudgetModal = () => this.props.openBrandBudgetModal();
  changeBudgetTakeRate = () => {
    const newRateResp = window.prompt('Enter the new take rate for budget increases.', getBrandBudgetIncreaseTakeRate(this.props.user));
    const newRate = parseFloat(newRateResp);
    this.props.updateBrandSettings({ budgetIncreaseTakeRate: newRate });
  };

  render() {
    const { user } = this.props;
    const {
      itemsVisible,
      monthsVisible,
      paymentsVisible,
      invoicesVisible,
      contractInvoicesVisible,
      budgetInvoicesVisible,
      consultResultsVisible,
      referralsVisible,
      contractsVisible
    } = this.state;
    const payments = _.get(user, 'payments', []);
    const paymentAccounts = _.get(user, 'payment_accounts', []);
    const invoices = _.get(user, 'invoices', []);
    const contractInvoices = _.get(user, 'contractInvoices', []);
    const budgetInvoices = _.get(user, 'budgetInvoices', []);
    const items = this.getItemsArray();
    const referrals = this.getReferralsArray();
    const consultResults = this.getConsultResultsArray();
    const contracts = this.getContractsArray();
    const monthlyTotals = this.getMonthlyTotalsArray();
    const hasMoreItems = items.length > itemsVisible;
    const hasMoreReferrals = referrals.length > referralsVisible;
    const hasMorePayouts = monthlyTotals.length > monthsVisible;
    const hasMorePayments = payments.length > paymentsVisible;
    const hasMoreInvoices = invoices.length > invoicesVisible;
    const hasMoreContractInvoices = contractInvoices.length > contractInvoicesVisible;
    const hasMoreBudgetInvoices = budgetInvoices.length > budgetInvoicesVisible;
    const hasMoreConsultResults = consultResults.length > consultResultsVisible;
    const hasMoreContracts = contracts.length > contractsVisible;

    const earnings = _.sum(_.map(monthlyTotals, 'amount'));
    const paidEarnings = _.sum(_.map(monthlyTotals, 'paidAmount'));
    const lockedEarnings = _.sum(_.map(monthlyTotals, payout => payout.lockedAmount - payout.paidAmount));
    const pendingEarnings = _.sum(_.map(monthlyTotals, payout => payout.amount - payout.lockedAndNotPaidAmount - payout.paidAmount));

    const smsAffiliatePartnerBrand = isSMSAffiliatePartnerBrand(user) || invoices.length; // Either a current SMS Affiliate Partner or a legacy brand with invoices
    const legacyPaypalBrand = isLegacyPaypalBrand(user);
    const stripeAccountId = getStripeAccountId(user);
    const brandBudgetRemaining = getBrandBudgetRemaining(user);

    const hasPaymentAccount = !!paymentAccounts.length || !!stripeAccountId;
    const showBrandPackagesGrid = isBrand(user) && !legacyPaypalBrand && !isShopifyUserRequiredForAppAcceptance(user);
    return (
      <RequiresPermissions permission='canViewEarnings'>
        <div className='payouts-outer-container'>
          {!isBrand(user) && !!earnings && (
            <div className='payout-cells'>
              <div className='payout-cell'>
                <div className='label desktop-only'>Upcoming Payment</div>
                <div className='label mobile-only'>Upcoming</div>
                <div className='number'>{lockedEarnings && lockedEarnings > 0 ? `$${commaNumber(lockedEarnings.toFixed(0))}` : '-'}</div>
              </div>
              <div className='payout-cell'>
                <div className='label desktop-only'>Pending Payouts</div>
                <div className='label mobile-only'>Pending</div>
                <div className='number'>{pendingEarnings ? `$${commaNumber(pendingEarnings.toFixed(0))}` : '-'}</div>
              </div>
              <div className='payout-cell'>
                <div className='label desktop-only'>Amount Paid</div>
                <div className='label mobile-only'>Paid</div>
                <div className='number'>{paidEarnings ? `$${commaNumber(paidEarnings.toFixed(0))}` : '-'}</div>
              </div>
              <div className='payout-cell'>
                <div className='label desktop-only'>Lifetime Earnings</div>
                <div className='label mobile-only'>Lifetime</div>
                <div className='number'>{earnings ? `$${commaNumber(earnings.toFixed(0))}` : '-'}</div>
              </div>
            </div>
          )}
          {!isBrand(user) && (
            <div className='payouts-card'>
              <div className='payout-card-header-container'>
                <div className='payout-card-header'>Monthly Breakdown</div>
              </div>
              <div className='column-row-container'>
                <div className='column-row header'>
                  <div className='column-cell'>DATE</div>
                  <div className='column-cell desktop-only'>TOTAL AMOUNT</div>
                  <div className='column-cell mobile-only'>AMOUNT</div>
                  <div className='column-cell desktop-only'>AMOUNT LOCKED</div>
                  <div className='column-cell mobile-only'>LOCKED</div>
                  <div className='column-cell desktop-only'>AMOUNT PAID</div>
                  <div className='column-cell mobile-only'>PAID</div>
                </div>
                {monthlyTotals.slice(0, monthsVisible).map(({ date, amount, lockedAmount, paidAmount, lockedTooltip }, idx) => {
                  return (
                    <div key={idx} className='column-row'>
                      <div className='column-cell'>{moment(new Date(date)).format("MMMM 'YY")}</div>
                      <div className='column-cell'>{amount ? `$${commaNumber(amount.toFixed(2))}` : '-'}</div>
                      <div
                        className={classnames('column-cell', {
                          'with-icon': lockedTooltip
                        })}
                      >
                        {lockedAmount ? `$${commaNumber(lockedAmount.toFixed(2))}` : '-'}
                        {lockedTooltip && (
                          <Tooltip message={lockedTooltip} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />
                        )}
                      </div>
                      <div className='column-cell'>{paidAmount ? `$${commaNumber(paidAmount.toFixed(2))}` : '-'}</div>
                    </div>
                  );
                })}
                {hasMorePayouts ? (
                  <div className='payouts-show-more-btn' onClick={() => this.setState({ monthsVisible: monthsVisible + 5 })}>
                    SHOW MORE
                  </div>
                ) : (
                  monthlyTotals.length > 5 && (
                    <div className='payouts-show-more-btn' onClick={() => this.setState({ monthsVisible: 5 })}>
                      SHOW LESS
                    </div>
                  )
                )}
              </div>
            </div>
          )}
          {!isBrand(user) && !!referrals.length && (
            <div className='payouts-card'>
              <div className='payout-card-header-container'>
                <div className='payout-card-header'>Referrals</div>
                <div className='payout-card-subheader'>
                  Give and earn additional {REFERRAL_PAYOUT_RATE}% of all commissions on referred users for a their first{` `}
                  {REFERRAL_LENGTH_MONTHS_DISPLAY} months using your referral page
                  <Link to={`/invite/${getUsername(user)}`} className='column-cell'>
                    here
                  </Link>
                  .
                </div>
              </div>
              <div className='column-row-container'>
                <div className='column-row header'>
                  <div className='column-cell'>JOINED</div>
                  <div className='column-cell'>NAME</div>
                  <div className='column-cell desktop-only'>BONUS EARNED</div>
                  <div className='column-cell mobile-only'>EARNED</div>
                  <div className='column-cell desktop-only'>BONUS LOCKED</div>
                  <div className='column-cell mobile-only'>LOCKED</div>
                  <div className='column-cell desktop-only'>BONUS PAID</div>
                  <div className='column-cell mobile-only'>PAID</div>
                  <div className='column-cell desktop-only'>CREATOR TIER</div>
                </div>
                {referrals.slice(0, referralsVisible).map((referral, idx) => {
                  const { createdAt, name, username, tier, is_valid, user_payout_total, user_payout_total_locked, user_payout_total_paid } = referral;
                  const daysSinceAdded = moment().diff(createdAt, 'days');
                  const isReferralWindowComplete = daysSinceAdded > REFERRAL_LENGTH_DAYS;
                  const isYou = getUserId(user) === referral.id;
                  return (
                    <div key={idx} className={classnames('column-row', { inactive: isReferralWindowComplete })}>
                      <div className='column-cell'>{moment(createdAt).format('MMM Do, YYYY')}</div>
                      {isYou ? (
                        <div className='column-cell with-icon'>
                          Your 10% Referral Bonus
                          <Tooltip
                            message={`This represents the additional 10% you earn since you signed up through a referral link from ${
                              getReferringUser(user)?.name
                            }`}
                            getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>}
                          />
                        </div>
                      ) : (
                        <a href={`/${username}`} target='_blank' rel='noopener nofollow noreferrer' className='column-cell'>
                          {name}
                        </a>
                      )}
                      <div className='column-cell'>{user_payout_total ? getPrettyPriceDisplay(user_payout_total) : '-'}</div>
                      <div className='column-cell'>{user_payout_total_locked ? getPrettyPriceDisplay(user_payout_total_locked) : '-'}</div>
                      <div className='column-cell'>{user_payout_total_paid ? getPrettyPriceDisplay(user_payout_total_paid) : '-'}</div>
                      <div className='column-cell with-icon desktop-only'>
                        {getUserTierDisplay(tier.tier)}
                        {!is_valid && (
                          <Tooltip
                            message={`This user does not meet the threshold to be considered a valid referral, their score is currently ${tier.score.toFixed(
                              0
                            )}/100.`}
                            getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
                {hasMoreReferrals ? (
                  <div className='payouts-show-more-btn' onClick={() => this.setState({ referralsVisible: referralsVisible + 10 })}>
                    SHOW MORE
                  </div>
                ) : (
                  referrals.length > 5 && (
                    <div className='payouts-show-more-btn' onClick={() => this.setState({ referralsVisible: 5 })}>
                      SHOW LESS
                    </div>
                  )
                )}
              </div>
            </div>
          )}
          {!isBrand(user) && !!consultResults.length && (
            <div className='payouts-card'>
              <div className='payout-card-header-container'>
                <div className='payout-card-header'>Consultations</div>
              </div>
              <div className='column-row-container'>
                <div className='column-row header'>
                  <div className='column-cell'>DATE</div>
                  <div className='column-cell lg'>CONSULT</div>
                  <div className='column-cell'>AMOUNT EARNED</div>
                  <div className='column-cell'>STATUS</div>
                </div>
                {consultResults.slice(0, consultResultsVisible).map((item, idx) => {
                  const { title, stub, clientName, amountEarned, createdAt, statusDisplay, infoTooltip } = item;
                  return (
                    <div key={idx} className='column-row'>
                      <div className='column-cell'>{moment(createdAt).format('MMM Do')}</div>
                      {title ? (
                        <a
                          href={`/consults/results/${stub}`}
                          target='_blank'
                          rel='noopener nofollow noreferrer'
                          className='column-cell lg desktop-only'
                        >
                          {title || 'Consult'} for {clientName}
                        </a>
                      ) : (
                        <div className='column-cell lg blank desktop-only'>-</div>
                      )}
                      <div className='column-cell desktop-only'>{amountEarned ? getPrettyPriceDisplay(amountEarned) : '-'}</div>
                      <div
                        className={classnames('column-cell', {
                          'with-icon': infoTooltip
                        })}
                      >
                        {statusDisplay}
                        {infoTooltip && <Tooltip message={infoTooltip} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />}
                      </div>
                    </div>
                  );
                })}
                {hasMoreConsultResults ? (
                  <div className='payouts-show-more-btn' onClick={() => this.setState({ consultResultsVisible: consultResultsVisible + 10 })}>
                    SHOW MORE
                  </div>
                ) : (
                  consultResults.length > 15 && (
                    <div className='payouts-show-more-btn' onClick={() => this.setState({ consultResultsVisible: 15 })}>
                      SHOW LESS
                    </div>
                  )
                )}
              </div>
            </div>
          )}
          {!isBrand(user) && !!contracts.length && (
            <div className='payouts-card'>
              <div className='payout-card-header-container'>
                <div className='payout-card-header'>Collaborations</div>
              </div>
              <div className='column-row-container'>
                <div className='column-row header'>
                  <div className='column-cell'>COMPLETED</div>
                  <div className='column-cell lg desktop-only'>TITLE</div>
                  <div className='column-cell'>AMOUNT EARNED</div>
                  <div className='column-cell'>STATUS</div>
                </div>
                {contracts.slice(0, contractsVisible).map((item, idx) => {
                  const { title, id, brandName, amountEarned, statusDisplay, infoTooltip } = item;
                  const dateLabel = this.getContractCompletionDate(item);
                  return (
                    <div key={idx} className='column-row'>
                      <div className='column-cell'>{dateLabel ? getPrettyDate(dateLabel) : '-'}</div>
                      {title ? (
                        <a href={`/collaboration/${id}`} target='_blank' rel='noopener nofollow noreferrer' className='column-cell lg desktop-only'>
                          {item.title || `Collaboration with ${brandName}`}
                        </a>
                      ) : (
                        <div className='column-cell lg blank desktop-only'>-</div>
                      )}
                      <div className='column-cell'>{amountEarned ? getPrettyPriceDisplay(amountEarned) : '-'}</div>
                      <div
                        className={classnames('column-cell', {
                          'with-icon': infoTooltip
                        })}
                      >
                        {statusDisplay}
                        {infoTooltip && <Tooltip message={infoTooltip} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />}
                      </div>
                    </div>
                  );
                })}
                {hasMoreContracts ? (
                  <div className='payouts-show-more-btn' onClick={() => this.setState({ contractsVisible: contractsVisible + 10 })}>
                    SHOW MORE
                  </div>
                ) : (
                  contracts.length > 15 && (
                    <div className='payouts-show-more-btn' onClick={() => this.setState({ contractsVisible: 10 })}>
                      SHOW LESS
                    </div>
                  )
                )}
              </div>
            </div>
          )}
          {!isBrand(user) && (
            <div className='payouts-card'>
              <div className='payout-card-header-container'>
                <div className='payout-card-header'>Commissions</div>
                <div className='payout-card-actions'>
                  <div onClick={this.downloadCommissions} className='action desktop-only'>
                    {this.state.isDownloading ? (
                      'Downloading...'
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faArrowAltToBottom} />
                        Download
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='column-row-container'>
                <div className='column-row header'>
                  <div className='column-cell'>DATE</div>
                  <div className='column-cell lg desktop-only'>PRODUCT</div>
                  <div className='column-cell desktop-only'>MERCHANT</div>
                  <div className='column-cell desktop-only'>ORDER TOTAL</div>
                  <div className='column-cell'>COMMISSION</div>
                  <div className='column-cell desktop-only'>CODE</div>
                  <div className='column-cell'>STATUS</div>
                </div>
                {items.length ? (
                  items.slice(0, itemsVisible).map((item, idx) => {
                    const {
                      Collection_id,
                      title,
                      merchant,
                      code,
                      statusDisplay,
                      amountEarned,
                      infoTooltip,
                      transaction_date,
                      order_amount,
                      locked_user_percentage
                    } = item;
                    const bonusTooltip = locked_user_percentage && locked_user_percentage > 100 ? `Includes a Bonus` : null;
                    return (
                      <div key={idx} className='column-row'>
                        <div className='column-cell'>{moment(transaction_date).format('MMM Do')}</div>
                        {title ? (
                          <a
                            href={Collection_id ? `/collections/${Collection_id}` : '/links'}
                            target='_blank'
                            rel='noopener nofollow noreferrer'
                            className='column-cell lg desktop-only'
                          >
                            {title}
                          </a>
                        ) : (
                          <div className='column-cell lg blank desktop-only'>-</div>
                        )}
                        <div className='column-cell desktop-only'>{merchant}</div>
                        <div className='column-cell desktop-only'>${order_amount && order_amount.toFixed(2)}</div>
                        <div
                          className={classnames('column-cell', {
                            'with-icon': bonusTooltip
                          })}
                        >
                          ${amountEarned && amountEarned.toFixed(2)}
                          {bonusTooltip && (
                            <Tooltip message={bonusTooltip} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />
                          )}
                        </div>
                        <div className='column-cell desktop-only'>{code || '-'}</div>
                        <div
                          className={classnames('column-cell', {
                            'with-icon': infoTooltip
                          })}
                        >
                          {statusDisplay}
                          {infoTooltip && (
                            <Tooltip message={infoTooltip} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className='payouts-empty-message'>PURCHASES MADE THROUGH YOUR SHOP & LINKS WILL APPEAR HERE</div>
                )}
                {hasMoreItems ? (
                  <div className='payouts-show-more-btn' onClick={() => this.setState({ itemsVisible: itemsVisible + 10 })}>
                    SHOW MORE
                  </div>
                ) : (
                  items.length > 15 && (
                    <div className='payouts-show-more-btn' onClick={() => this.setState({ itemsVisible: 15 })}>
                      SHOW LESS
                    </div>
                  )
                )}
              </div>
            </div>
          )}
          {!isBrand(user) && (
            <div className='payouts-card'>
              <div className='payout-card-header-container'>
                <div className='payout-card-header'>Payments</div>
              </div>
              <div className='column-row-container'>
                <div className='column-row header'>
                  <div className='column-cell'>SENT ON</div>
                  {isAgent(user) ? (
                    <>
                      <div className='column-cell'>NAME</div>
                      <div className='column-cell'>ARTIST AMOUNT</div>
                      <div className='column-cell'>AGENT AMOUNT</div>
                    </>
                  ) : (
                    <div className='column-cell'>AMOUNT</div>
                  )}
                  <div className='column-cell'>SOURCE</div>
                </div>
                {payments.length ? (
                  payments
                    .slice(0, paymentsVisible)
                    .map(({ amount, user_amount, agent_amount, transaction_fee, source, sent_date, name, User_id }, idx) => {
                      const agentHandlesTake = !user_amount && agent_amount;
                      const userEarningsAmount = agentHandlesTake ? agent_amount : user_amount;
                      const transactionFeeScaled =
                        (transaction_fee * (agentHandlesTake || isAgent(user) ? user_amount + agent_amount : user_amount)) /
                        (amount - transaction_fee);
                      const infoTooltip = transactionFeeScaled && `$${transactionFeeScaled.toFixed(2)} Transaction Fee`;
                      return (
                        <div key={idx} className='column-row'>
                          <div className='column-cell'>{moment(sent_date).format('MMM Do')}</div>
                          {isAgent(user) ? (
                            User_id === getUserId(user) ? (
                              <>
                                <div className='column-cell'>{name}</div>
                                <div className='column-cell'>-</div>
                                <div className='column-cell'>${user_amount && user_amount.toFixed(2)}</div>
                              </>
                            ) : (
                              <>
                                <div className='column-cell'>{name}</div>
                                <div className='column-cell'>${user_amount && user_amount.toFixed(2)}</div>
                                <div className='column-cell'>{agent_amount ? `$${agent_amount.toFixed(2)}` : '-'}</div>
                              </>
                            )
                          ) : (
                            <div className='column-cell'>
                              <div>${userEarningsAmount && userEarningsAmount.toFixed(2)}</div>
                            </div>
                          )}
                          <div className='column-cell with-icon'>
                            <div>{source}</div>
                            {!!infoTooltip && (
                              <Tooltip message={infoTooltip} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />
                            )}
                          </div>
                        </div>
                      );
                    })
                ) : !hasPaymentAccount ? (
                  <div className='payouts-empty-message'>PLEASE LINK AN ACCOUNT BELOW TO ENABLE PAYMENTS</div>
                ) : earnings > 10 ? (
                  <div className='payouts-empty-message'>PAYMENTS ARE SUBMITTED ON FRIDAY AFTERNOONS</div>
                ) : (
                  <div className='payouts-empty-message'>PAYMENTS START ONCE YOUR SHOP EARNS AT LEAST $10</div>
                )}
                {hasMorePayments && (
                  <div className='payouts-show-more-btn' onClick={() => this.setState({ paymentsVisible: paymentsVisible + 5 })}>
                    SHOW MORE
                  </div>
                )}
              </div>
            </div>
          )}
          {isBrand(user) && smsAffiliatePartnerBrand && (
            <div className='payouts-card'>
              <div className='payout-card-header-container'>
                <div className='payout-card-header'>Affiliate Invoices</div>
              </div>
              <div className='column-row-container'>
                <div className='column-row header'>
                  <div className='column-cell'>SENT ON</div>
                  <div className='column-cell'>AMOUNT</div>
                  <div className='column-cell desktop-only'>NETWORK FEE</div>
                  <div className='column-cell'>STATUS</div>
                  <div className='column-cell'>LINK</div>
                </div>
                {invoices.length ? (
                  invoices.slice(0, invoicesVisible).map(({ isUpcoming, createdAt, raw, status, amount, network_fee }, idx) => {
                    const invoiceStatus = raw ? JSON.parse(raw) : {};
                    const paypalLink = _.get(invoiceStatus, 'detail.metadata.recipient_view_url');
                    const goToInvoice = () => (paypalLink ? window.open(paypalLink, '_blank') : goToStripeBillingDashboard(user));
                    return (
                      <div key={idx} className='column-row'>
                        <div className='column-cell'>
                          {isUpcoming
                            ? `${moment(Date.now())
                                .add(1, 'month')
                                .format('MMM')} 1st`
                            : moment(createdAt).format('MMM Do')}
                        </div>
                        <div className='column-cell'>${amount && amount.toFixed(2)}</div>
                        <div className='column-cell desktop-only'>{network_fee ? '$' + network_fee.toFixed(2) : '-'}</div>
                        <div className='column-cell'>{status ? status.toUpperCase() : '-'}</div>
                        {isUpcoming ? (
                          <div className='column-cell'>UPCOMING</div>
                        ) : (
                          <div onClick={goToInvoice} className='column-cell link'>
                            {status === 'unpaid' ? 'PAY INVOICE' : 'VIEW INVOICE'}
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : !invoices.length ? (
                  <div className='payouts-empty-message'>
                    INVOICES WILL BE SENT MONTHLY AND WILL BEGIN ONCE THE FIRST COMMISSION PASSES THE CANCELLATION WINDOW
                  </div>
                ) : null}
                {hasMoreInvoices && (
                  <div className='payouts-show-more-btn' onClick={() => this.setState({ invoicesVisible: invoicesVisible + 5 })}>
                    SHOW MORE
                  </div>
                )}
              </div>
            </div>
          )}
          {isBrand(user) && !smsAffiliatePartnerBrand && legacyPaypalBrand && (
            <div className='payouts-card'>
              <div className='payout-card-header-container'>
                <div className='payout-card-header'>Invalid Brand Configuration.</div>
                <div className='payout-card-subheader'>
                  It appears as if you are currently configured on PayPal, this is no longer a supported method, please reach out to your account
                  manager
                </div>
              </div>
            </div>
          )}
          {isBrand(user) && !!contractInvoices.length && (
            <div className='payouts-card'>
              <div className='payout-card-header-container'>
                <div className='payout-card-header'>Collaboration Invoices</div>
              </div>
              <div className='column-row-container'>
                <div className='column-row header'>
                  <div className='column-cell'>SENT ON</div>
                  <div className='column-cell'>AMOUNT</div>
                  <div className='column-cell'>STATUS</div>
                  <div className='column-cell'>LINK</div>
                </div>
                {contractInvoices.slice(0, contractInvoicesVisible).map(invoice => {
                  const { createdAt, amount, sms_amount, user_amount, budget_amount, status } = invoice;
                  const goToInvoice = () => goToStripeBillingDashboard(user);
                  const paidByBudget = budget_amount === amount;
                  const amountOwed = paidByBudget ? user_amount + sms_amount : user_amount + sms_amount - budget_amount;
                  return (
                    <div key={invoice.id} className='column-row'>
                      <div className='column-cell'>{moment(createdAt).format('MMM Do')}</div>
                      <div className='column-cell'>${amountOwed ? amountOwed.toFixed(2) : '-'}</div>
                      <div className='column-cell'>{paidByBudget ? 'DEDUCTED FROM BUDGET' : status ? status.toUpperCase() : '-'}</div>
                      <div onClick={goToInvoice} className='column-cell link'>
                        {status === 'unpaid' ? 'PAY INVOICE' : 'VIEW INVOICE'}
                      </div>
                    </div>
                  );
                })}
                {hasMoreContractInvoices && (
                  <div className='payouts-show-more-btn' onClick={() => this.setState({ contractInvoicesVisible: contractInvoicesVisible + 5 })}>
                    SHOW MORE
                  </div>
                )}
              </div>
            </div>
          )}
          {isBrand(user) && (
            <div className='payouts-card'>
              <div className='payout-card-header-container'>
                <div className='payout-card-header'>{budgetInvoices.length ? 'Budget Increase Invoices' : 'Manage Budget'}</div>
                <div className='payout-card-subheader'>
                  You have <b>{getPrettyPriceDisplay(brandBudgetRemaining)}</b> remaining in your budget. Click{' '}
                  <span onClick={this.openAddToBudgetModal} className='button'>
                    here
                  </span>{' '}
                  to add more.
                  {isAdminControlMode(this.props.ui) && (
                    <>
                      {' '}
                      Current take rate is {getBrandBudgetIncreaseTakeRate(user)}% click{' '}
                      <span onClick={this.changeBudgetTakeRate} className='button'>
                        here
                      </span>{' '}
                      to change.
                    </>
                  )}
                </div>
              </div>
              {!!budgetInvoices.length && (
                <div className='column-row-container'>
                  <div className='column-row header'>
                    <div className='column-cell'>SENT ON</div>
                    <div className='column-cell'>AMOUNT</div>
                    <div className='column-cell'>STATUS</div>
                    <div className='column-cell'>LINK</div>
                  </div>
                  {budgetInvoices.slice(0, budgetInvoicesVisible).map(invoice => {
                    const { createdAt, amount, status } = invoice;
                    const goToInvoice = () => goToStripeBillingDashboard(user);
                    return (
                      <div key={invoice.id} className='column-row'>
                        <div className='column-cell'>{moment(createdAt).format('MMM Do')}</div>
                        <div className='column-cell'>${amount && amount.toFixed(2)}</div>
                        <div className='column-cell'>{status ? status.toUpperCase() : '-'}</div>
                        <div onClick={goToInvoice} className='column-cell link'>
                          {status === 'unpaid' ? 'PAY INVOICE' : 'VIEW INVOICE'}
                        </div>
                      </div>
                    );
                  })}
                  {hasMoreBudgetInvoices && (
                    <div className='payouts-show-more-btn' onClick={() => this.setState({ budgetInvoicesVisible: budgetInvoicesVisible + 5 })}>
                      SHOW MORE
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {!isBrand(user) && (
            <div className='payouts-card'>
              <div className='payout-card-header-container'>
                <div className='payout-card-header'>Linked Accounts</div>
                {!hasPaymentAccount && !isBrand(user) && (
                  <div className='payout-card-subheader'>
                    Please link your PayPal account below. If you cannot successfully link your PayPal account, please go to{' '}
                    <Link to='settings'>account settings</Link> to link a bank account using Stripe.
                  </div>
                )}
              </div>
              <PayoutsAccounts
                user={user}
                linkInvoiceEmailAccount={this.props.linkInvoiceEmailAccount}
                linkPaypalAccount={this.props.linkPaypalAccount}
                unlinkPaymentAccount={this.props.unlinkPaymentAccount}
                updateCurrentUser={this.props.updateCurrentUser}
              />
            </div>
          )}
          {isShopifyUserRequiredForAppAcceptance(user) && <div>Since you have connected a Shopify account, the platform is free to use!</div>}
        </div>
      </RequiresPermissions>
    );
  }

  getSortedPayments = () => {
    const { user } = this.props;
    const payments = _.get(user, 'payments', []);
    return _.orderBy(payments, 'sent_date');
  };

  getItemsArray = () => {
    const { user } = this.props;
    const items = _.get(user, 'payouts.payout_summaries.payouts', []);
    const positiveEarningItems = _.filter(items, item => item && item.commission_amount);
    const orderedItems = _.orderBy(positiveEarningItems, item => new Date(item.transaction_date), 'desc');
    return orderedItems.map(item => {
      const { statusDisplay, isLocked, isPaid, isInReview } = item;
      return {
        ...item,
        statusDisplay: statusDisplay,
        infoTooltip: isPaid
          ? null
          : isLocked
          ? 'This payout is locked and will be paid to you at the end of the week.'
          : isInReview
          ? `This payout is currently under review to ensure it is valid. We have a review for large orders to ensure merchants are correctly reporting commissions.`
          : `Pending means this is what you are on track to earn, but the order hasn't passed the retailer's return window (this can be up to 105 days). If the order is not cancelled or returned, you should receive this commission.`
      };
    });
  };

  getReferralsArray = () => {
    const { user } = this.props;
    const referrals = getReferrals(user);
    const referringUser = getReferringUser(user);
    const referralEarnings = _.get(user, 'payouts.payout_summaries.referralTotals', {});
    const sortedReferrals = _.concat(_.orderBy(referrals, 'createdAt', 'desc'), referringUser ? user.profile : []);
    return sortedReferrals.map(referral => {
      const earnings = _.get(referralEarnings, referral.id, {});
      return {
        ...referral,
        ...earnings
      };
    });
  };

  getConsultResultsArray = () => {
    const { user } = this.props;
    const results = _.get(user, 'payouts.payout_summaries.consultResults', []);
    const orderedItems = _.orderBy(results, item => new Date(item.createdAt), 'desc');
    return orderedItems.map(item => {
      const { statusDisplay, paymentRefundedAt, isLocked, completedAt, isPaid } = item;
      return {
        ...item,
        statusDisplay: statusDisplay,
        infoTooltip: isPaid
          ? null
          : paymentRefundedAt
          ? 'Refunds can occur due to an extended delay in response time or a complaint on behalf of the client.'
          : isLocked
          ? 'This consultation payout is locked and will be paid to you at the end of the week.'
          : completedAt
          ? `This consultation payout is still in the two week refund window.`
          : null
      };
    });
  };

  getContractCompletionDate = contract => contract.activeDate || contract.closedDate;
  getContractsArray = () => {
    const { user } = this.props;
    const contracts = _.get(user, 'payouts.payout_summaries.contracts', []);
    const orderedItems = _.orderBy(contracts, this.getContractCompletionDate, 'desc');
    return orderedItems.map(item => {
      const { statusDisplay, isLocked, isPaid } = item;
      return {
        ...item,
        statusDisplay: statusDisplay,
        infoTooltip: isPaid
          ? null
          : isLocked
          ? 'This collaboration payment is locked and will be paid to you at the end of the week.'
          : `This collaboration has not yet been paid by the brand, we guarantee payment after 30 days and will cover the payment at that point.`
      };
    });
  };

  getMonthlyTotalsArray = () => {
    const { user } = this.props;
    const payout_summaries = _.get(user, 'payouts.payout_summaries.months', {});
    return _.orderBy(
      _.reduce(
        payout_summaries,
        (result, val, key) => {
          const { user_payout_total, user_payout_total_locked, user_payout_total_paid } = val;
          const lockedTooltip =
            user_payout_total !== user_payout_total_locked && user_payout_total_locked
              ? `Some of this month's payouts have not yet locked. Commissions can take up to 105 days to pass the retailer's return window and consult payouts must pass a seven day refund window.`
              : null;
          return [
            ...result,
            {
              date: key,
              amount: user_payout_total,
              lockedAmount: user_payout_total_locked,
              lockedAndNotPaidAmount: user_payout_total_locked - user_payout_total_paid,
              paidAmount: user_payout_total_paid,
              lockedTooltip
            }
          ];
        },
        []
      ),
      ({ date }) => new Date(date),
      'desc'
    );
  };
}

export default Payouts;
