import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExternalLink, faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import cn from 'classnames';
import './BudgetChangesPanel.scss';

import { updateBrandBudgetInvoiceById } from '../../APIClient/brands';

import { getPrettyDate, getPrettyPriceDisplay } from '../../Helpers/formatting';
import { getTalentById } from '../../Helpers/talent_helpers';
import { getBrandContracts, getBrandBudgetInvoices, getBrandContractFromId } from '../../Helpers/user_helpers';

import { openArtistModal } from '../../Actions/UIActions';

import Tooltip from '../General/Tooltip';

const BudgetChangesPanel = props => {
  const { user, talent, isFetchingData } = props;

  const budgetInvoices = getBrandBudgetInvoices(user);
  const invoices = props.contractInvoices.filter(c => c.budget_amount);
  const bonuses = props.creatorBonuses;
  const opportunityBonuses = props.opportunityBonuses;
  const contracts = getBrandContracts(user);
  const getDataForContractInvoice = invoice => {
    const contract = contracts.find(c => c.id === invoice.Contract_id);
    return {
      user: getTalentById(talent, contract?.User_id),
      contract
    };
  };

  const columns = [
    {
      display: 'Date',
      getValue: data => getPrettyDate(data.timestamp)
    },
    {
      display: 'Creator',
      getOnClick: data => {
        if (data.is_bonus) return () => props.openArtistModal({ id: data.User_id });
        if (data.is_opportunity_bonus) return () => props.openArtistModal({ id: data.User_id });
        if (data.is_contract_invoice) return () => props.openArtistModal({ id: getBrandContractFromId(user, data.Contract_id)?.User_id });
        return null;
      },
      getValue: data => {
        if (data.is_contract_invoice) return getDataForContractInvoice(data)?.user?.name || 'Talent';
        if (data.is_bonus) return data.User_name || 'Talent';
        if (data.is_opportunity_bonus) return data.User_name || 'Talent';
        if (data.is_budget_invoice) return '-';
        return 'Unknown';
      }
    },
    {
      display: 'Type',
      getTo: data => {
        if (data.Contract_id) return '/collaboration/' + data.Contract_id;
        return null;
      },
      getValue: data => {
        if (data.is_contract_invoice) return 'Collaboration';
        if (data.is_bonus) return 'Bonus';
        if (data.is_opportunity_bonus) return 'Opportunity Payment';
        if (data.is_budget_invoice) return 'Budget Invoice';
        return 'Unknown';
      }
    },
    {
      display: 'Amount',
      getTooltip: data => {
        if (data.is_budget_invoice && data.status !== 'paid') {
          if (data.wasFronted && data.stripeHostedUrl) return `ShopMy fronted this budget, click to pay invoice.`;
          if (data.wasFronted) return `ShopMy fronted this budget, awaiting deposit.`;
          if (data.stripeHostedUrl) return `Click to pay invoice to add to budget.`;
          return 'Not yet paid, not yet deposited into budget.';
        }
        return null;
      },
      getHref: data => {
        if (data.stripeHostedUrl) return data.stripeHostedUrl;
        return null;
      },
      getAdditionalClasses: data => {
        if (data.is_contract_invoice) return 'negative';
        if (data.is_bonus) return 'negative';
        if (data.is_opportunity_bonus) return 'negative';
        if (data.is_budget_invoice)
          return {
            positive: data.status === 'paid',
            pending: data.status !== 'paid' && !data.wasFronted,
            fronted: data.status !== 'paid' && data.wasFronted
          };
        return '';
      },
      getValue: data => {
        if (data.is_contract_invoice) return '-' + getPrettyPriceDisplay(data.budget_amount);
        if (data.is_bonus) return '-' + getPrettyPriceDisplay(data.commission_amount);
        if (data.is_opportunity_bonus) return '-' + getPrettyPriceDisplay(data.commission_amount);
        if (data.is_budget_invoice) return '+' + getPrettyPriceDisplay(data.brand_amount);
        return 'Unknown';
      }
    }
  ];

  const allChanges = [
    ...invoices.map(c => ({ ...c, is_contract_invoice: true, timestamp: c.createdAt })),
    ...bonuses.map(b => ({ ...b, is_bonus: true, timestamp: b.createdAt })),
    ...opportunityBonuses.map(b => ({ ...b, is_opportunity_bonus: true, timestamp: b.createdAt })),
    ...budgetInvoices.map(b => ({ ...b, is_budget_invoice: true, timestamp: b.stripeInvoicePaidAt || b.createdAt }))
  ];
  const changes = _.orderBy(allChanges, c => new Date(c.timestamp), 'desc');

  // Handle Paging
  const numVisibleToStart = 10;
  const [numVisible, setNumVisible] = React.useState(numVisibleToStart);
  const showLess = () => setNumVisible(numVisible - 8);
  const showMore = () => setNumVisible(numVisible + 8);
  const showAll = () => setNumVisible(changes.length);
  const showMoreButton = numVisible < changes.length;
  const showLessButton = numVisible > numVisibleToStart;
  const showAllButton = numVisible < changes.length;
  const showPagingButtons = showMoreButton || showLessButton;
  const isEmpty = !changes.length && !isFetchingData;

  return (
    <div className='budget-changes-container'>
      <div className={cn('budget-changes', { empty: isEmpty })}>
        <React.Fragment>
          {columns.map(d => (
            <div className='header cell' key={d.display}>
              {d.display}
            </div>
          ))}
        </React.Fragment>
        {isFetchingData
          ? columns.map(d => (
              <div className='cell' key={d.display}>
                ...
              </div>
            ))
          : changes.slice(0, numVisible).map(data => {
              const change = data;

              let adminActionOverlay;
              if (data.is_budget_invoice && data.status !== 'paid' && !data.wasFronted) {
                const clickToFront = e => {
                  e.preventDefault();
                  const confirm = window.confirm(
                    `Are you sure you want to front this budget? This means we will increase the brands budget now by $${data.brand_amount} and will need to ensure collection occurs.`
                  );
                  confirm &&
                    updateBrandBudgetInvoiceById(change.id, { wasFronted: true })
                      .then(resp => {
                        // Since this is an admin only call, we can just reload the page to show the change as opposed to going through redux.
                        window.ALERT.success('Budget fronted successfully, going to refresh the page in 3s to show this in the UI.');
                        setTimeout(() => window.location.reload(), 3000);
                      })
                      .catch(error => window.ALERT.error(error || 'Unknonw error fronting budget.'));
                };
                adminActionOverlay = window.__ADMIN_CONTROL_MODE__ && (
                  <div className='admin-actions'>
                    <div className='admin-action'>
                      <Tooltip message='Front this budget'>
                        <FontAwesomeIcon icon={faDollarSign} onClick={clickToFront} />
                      </Tooltip>
                    </div>
                  </div>
                );
              }

              return (
                <React.Fragment key={data.id}>
                  {columns.map((d, idx) => {
                    const isLast = idx === columns.length - 1;
                    const to = d.getTo && d.getTo(data);
                    const href = d.getHref && d.getHref(data);
                    const onClick = d.getOnClick && d.getOnClick(data);
                    const additionalClasses = d.getAdditionalClasses ? d.getAdditionalClasses(data) : '';
                    const tooltip = d.getTooltip ? d.getTooltip(data) : '';
                    const innerContent = tooltip ? (
                      <>
                        <Tooltip key={d.display} message={tooltip}>
                          {d.getValue(data)}
                          {href && <FontAwesomeIcon className='external' icon={faExternalLink} />}
                          <FontAwesomeIcon className='info' icon={faInfoCircle} />
                        </Tooltip>
                        {isLast && adminActionOverlay}
                      </>
                    ) : (
                      <>
                        {d.getValue(data)}
                        {href && <FontAwesomeIcon className='external' icon={faExternalLink} />}
                        {isLast && adminActionOverlay}
                      </>
                    );

                    return (
                      <>
                        {href ? (
                          <a
                            className={cn('cell external-link', additionalClasses)}
                            href={href}
                            key={d.display}
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            {innerContent}
                          </a>
                        ) : to ? (
                          <Link className={cn('cell clickable', additionalClasses)} to={to} key={d.display}>
                            {innerContent}
                          </Link>
                        ) : (
                          <div onClick={onClick} className={cn('cell', additionalClasses, { clickable: onClick })} key={d.display}>
                            {innerContent}
                          </div>
                        )}
                      </>
                    );
                  })}
                </React.Fragment>
              );
            })}
      </div>
      {isEmpty && <div className='empty-results'>No Budget Changes</div>}
      {showPagingButtons && (
        <div className='show-more-container'>
          {showLessButton && (
            <div className='show-btn' onClick={showLess}>
              Show Less
            </div>
          )}
          {showMoreButton && (
            <div className='show-btn' onClick={showMore}>
              Show More
            </div>
          )}
          {showAllButton && (
            <div className='show-btn' onClick={showAll}>
              Show All
            </div>
          )}
        </div>
      )}
    </div>
  );
};

BudgetChangesPanel.propTypes = {
  // From inside
  user: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,

  // From outside
  isFetchingData: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const { user, talent } = state;
  return { user, talent };
};

export default connect(mapStateToProps, {
  openArtistModal
})(BudgetChangesPanel);
