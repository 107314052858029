import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import cn from 'classnames';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './UserManagersPanel.scss';

import ConfirmPrompt from '../General/ConfirmPrompt';

import { sendUserManagerInvite } from '../../APIClient/managers';
import { updateUserManager, deleteUserManager } from '../../Actions/ManagerActions';
import { syncCurrentUser } from '../../Actions/UserActions';

import {
  userManagerPermissions,
  userManagerPayoutConfigs,
  getPermissionsForSpecificUserManager,
  getPayoutConfigsForSpecificUserManager
} from '../../Helpers/manager_helpers';
import { isCurrentlyManaging } from '../../Helpers/manager_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { getManagers, getManages, isManager, getUserId } from '../../Helpers/user_helpers';
import { getInitialsForUser, isValidEmail } from '../../Helpers/formatting';

const UserManagersPanel = props => {
  const { user, manager } = props;
  const managers = getManagers(user);
  const manages = getManages(user);

  const addManager = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Configure Their Permissions'
          subheader='Please enter the email address for your manager. If they already have an account, we will match you to them. If they do not have an account we will send them an email to get them signed up as your manager.'
          placeholder='Add your message here.'
          onCancel={onClose}
          customInputFields={[
            {
              placeholder: 'Email Address',
              value: 'email',
              isSingleLine: true
              // preloaded: `hrein+${Math.floor(Math.random() * 100000)}@alum.mit.edu`
            },
            ...userManagerPermissions
              .filter(m => !m.isRestrictionOnUser)
              .map(permission => ({
                isBoolean: true,
                label: permission.display,
                value: permission.variable,
                preloaded: permission.default
              })),
            ...userManagerPayoutConfigs.map(config => ({
              display: config.display,
              value: config.variable,
              isSingleLine: true,
              placeholder: config.placeholder
            }))
          ]}
          onSubmit={async responseValues => {
            const email = responseValues.email;
            const permissions = {};
            userManagerPermissions.forEach(p => {
              if (responseValues[p.variable] !== undefined) permissions[p.variable] = responseValues[p.variable];
            });
            userManagerPayoutConfigs.forEach(c => {
              if (responseValues[c.variable] !== undefined) permissions[c.variable] = +responseValues[c.variable];
            });

            if (!isValidEmail(email)) return window.ALERT.warn(`Please enter a valid email address.`);

            window.ALERT.info(`Sending invitation...`, { hideAfter: 0.75 });
            try {
              const resp = await sendUserManagerInvite({ email, permissions, User_id: getUserId(user) });
              await props.syncCurrentUser(); // In case we matched to an existing user
              window.ALERT.success(resp.message, { hideAfter: 8 });
            } catch (error) {
              window.ALERT.error(typeof error === 'string' ? error : 'There was an error sending this invitation, please try again.');
            }
          }}
        />
      )
    });
  };

  let title, subtitle;
  if (isManager(user)) {
    title = 'Accounts You Manage';
    subtitle = 'You can manage any of the following accounts by toggling the profile using the profile photo in the navigation bar.';
  } else if (manages.length) {
    title = 'Shop Managers & Accounts You Manage';
    subtitle = '';
  } else {
    title = 'Your Shop Managers';
    subtitle = 'The following accounts can manage your account based on the level of control you configure for them.';
  }
  return (
    <div className='user-managers-panel-outer-container'>
      <div className='title-container'>
        <div className='title'>{title}</div>
        <div className='subtitle'>{subtitle}</div>
        {!isManager(user) && !isCurrentlyManaging(manager) && (
          <div onClick={addManager} className='add-new-manager-btn'>
            <FontAwesomeIcon icon={faPlus} />
            Add Manager
          </div>
        )}
      </div>
      {!!managers.length && (
        <div className='rows'>
          {managers.map(userManager => (
            <UserManagerRow key={userManager.id} {...props} userManager={userManager} />
          ))}
        </div>
      )}
      {!!manages.length && (
        <div className='rows'>
          {manages.map(userManager => (
            <UserManagerRow key={userManager.id} {...props} userManager={userManager} />
          ))}
        </div>
      )}
    </div>
  );
};

const UserManagerRow = props => {
  const { userManager, manager, ui } = props;
  const [isEditing, setIsEditing] = useState(false);
  const youAreTheManager = getUserId(props.user) === userManager.Manager_id;
  const userObject = youAreTheManager ? userManager.user : userManager.manager;
  const allManagerPermissions = getPermissionsForSpecificUserManager(userManager);
  const allPayoutConfigs = getPayoutConfigsForSpecificUserManager(userManager);
  const managerPermissions = allManagerPermissions.filter(p => (isAdminControlMode(ui) ? true : !p.isRestrictionOnUser));
  const activePermissions = managerPermissions.filter(p => p.selected);
  const youAreBeingManaged = !youAreTheManager;
  const canEdit = !isCurrentlyManaging(manager);

  if (!userObject) return null;

  // Actions Setup
  const permissions = managerPermissions.map(data => {
    const { selected, display } = data;
    return {
      display,
      selected,
      toggle: () => props.updateUserManager(userManager, { [data.variable]: !selected })
    };
  });

  // UI Setup
  const MAX_STATUSES_TO_DISPLAY = 3;
  const MAX_STATUSES_TO_DISPLAY_MOBILE = 2;
  let statusDisplays = [];
  userManagerPayoutConfigs.forEach(config => {
    const value = userManager[config.variable];
    if (value) statusDisplays.push(config.formatValueForDisplay(value));
  });
  activePermissions.forEach(p => statusDisplays.push(p.display));
  const activePermissionsDisplay =
    statusDisplays.slice(0, MAX_STATUSES_TO_DISPLAY).join(', ') +
    (statusDisplays.length > MAX_STATUSES_TO_DISPLAY ? ` and ${statusDisplays.length - MAX_STATUSES_TO_DISPLAY} more...` : '');
  const activePermissionsDisplayMobile =
    statusDisplays.slice(0, MAX_STATUSES_TO_DISPLAY_MOBILE).join(', ') +
    (statusDisplays.length > MAX_STATUSES_TO_DISPLAY ? ` and ${statusDisplays.length - MAX_STATUSES_TO_DISPLAY} more...` : '');

  const toggleEdit = () => (youAreTheManager || !canEdit ? null : setIsEditing(!isEditing));
  const remove = () => {
    confirmAlert({
      title: 'Just Confirming',
      message: youAreTheManager
        ? `Are you sure you want to stop managing ${userObject.name}?`
        : `Are you sure you want to remove ${userObject.name} as a manager?`,
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        { label: 'Yes', onClick: () => props.deleteUserManager(userManager) }
      ]
    });
  };

  return (
    <div className={cn('row', { editing: isEditing })}>
      <div className='manager-data'>
        <div className='main'>
          {userObject.image ? <img src={userObject.image} alt={userObject.name} /> : <div className='initials'>{getInitialsForUser(userObject)}</div>}
          <div className='meta'>
            <div className='name'>{userObject.name}</div>
            <div onClick={toggleEdit} className='display'>
              <span className='desktop-only'>{activePermissionsDisplay || 'No Active Permissions'}</span>
              <span className='mobile-only'>{activePermissionsDisplayMobile || 'No Active Permissions'}</span>
            </div>
          </div>
        </div>
        {canEdit && (
          <div className='actions'>
            {isEditing ? (
              <div onClick={toggleEdit} className='action primary'>
                Done
              </div>
            ) : (
              <>
                {youAreBeingManaged ? (
                  <>
                    <div onClick={remove} className='action primary'>
                      Remove
                    </div>
                    <div onClick={toggleEdit} className='action primary'>
                      Edit
                    </div>
                  </>
                ) : (
                  <>
                    <div onClick={remove} className='action'>
                      Stop Managing
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {isEditing && (
        <div className='sections'>
          <div className='section'>
            <div className='section-label'>Permissions</div>
            <div className='permissions'>
              {_.chunk(permissions, 3).map((permissions, idx) => {
                return (
                  <div key={idx} className='permissions-block'>
                    {permissions.map((vals, idx) => {
                      const { display, selected, toggle } = vals;
                      return (
                        <div className={cn('permission', { selected })} onClick={toggle} key={idx}>
                          <div className='checkbox'>
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                          {display}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className='section'>
            <div className='section-label'>Payout Configurations</div>
            <div className='payout-configs'>
              {allPayoutConfigs.map(config => (
                <UserManagerPanelPayoutConfig
                  key={config.display}
                  userManager={userManager}
                  updateUserManager={props.updateUserManager}
                  config={config}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const UserManagerPanelPayoutConfig = props => {
  const { userManager, updateUserManager, config } = props;
  const { display, variable, placeholder, formatValueForServer, formatValueForInput } = config;

  const saveDebounce = React.useRef(null);
  const [value, setValue] = useState(config.value || '');
  const updateValue = newValue => {
    setValue(newValue);
    const newPermissions = { [variable]: formatValueForServer(newValue || null) };
    clearTimeout(saveDebounce.current);
    saveDebounce.current = setTimeout(() => updateUserManager(userManager, newPermissions), 500);
  };
  return (
    <div key={variable} className='payout-config'>
      <div className='label'>{display}</div>
      <input
        type='text'
        placeholder={placeholder}
        value={formatValueForInput ? formatValueForInput(value) : value}
        onChange={e => updateValue(e.target.value)}
      />
    </div>
  );
};

UserManagersPanel.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired,
  updateUserManager: PropTypes.func.isRequired,
  deleteUserManager: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui, manager } = state;
  return { user, ui, manager };
};

export default connect(mapStateToProps, {
  updateUserManager,
  deleteUserManager,
  syncCurrentUser
})(UserManagersPanel);
