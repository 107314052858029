import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Discover.scss';

import { isBrand } from '../../Helpers/user_helpers';
import { getUIKeyValueDiscover } from '../../Helpers/ui_helpers';
import { setUIKeyValue } from '../../Actions/UIActions';
import { getAnnouncementByType } from '../../Helpers/announcement_helpers';
import { blockOnRequiringSubscription } from '../../Helpers/subscription_helpers';
import { insertMetaTags } from '../../Helpers/seo_helpers';

import DiscoverSidebar from '../../Components/Discover/DiscoverSidebar';
import DiscoverControls from '../../Components/Discover/DiscoverControls';
import DiscoverResults from '../../Components/Discover/DiscoverResults';
import DiscoverFeatures from '../../Components/Discover/DiscoverFeatures';
import DiscoverLists from '../../Components/Discover/DiscoverLists';
import DiscoverData from '../../Components/Discover/DiscoverData';

import RequiresBrandLoginPanel from '../../Components/General/RequiresBrandLoginPanel';
import AnnouncementModal from '../../Components/Announcements/AnnouncementModal';

const Discover = props => {
  const { user, ui } = props;

  // Modes
  const [inSearchMode, setInSearchMode] = useState(false);

  // Redux level Stats - stored so we persist across reloads
  const [isEditing, setIsEditing] = [getUIKeyValueDiscover(ui, 'isEditing', false), val => props.setUIKeyValue('isEditing', val, 'discover')];
  const [onListsTab, setOnListsTab] = [getUIKeyValueDiscover(ui, 'onListsTab', true), val => props.setUIKeyValue('onListsTab', val, 'discover')];

  // Values
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState('score');
  const [curSearchVal, setCurSearchVal] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [hideMyPromoters, setHideMyPromoters] = useState(false);
  const [onlyMyPromoters, setOnlyMyPromoters] = useState(false);
  const [hideAlreadyGifted, setHideAlreadyGifted] = useState(false);
  const [results, setResults] = useState([]);
  const [lists, setLists] = useState([]);

  // UI
  const [isSearchingUsers, setIsSearchingUsers] = useState(false);
  const [isSearchingTags, setIsSearchingTags] = useState(false);

  // Allow for a force reload
  const [isForcingReload, setIsForcingReload] = useState(false);
  const forceReload = () => setIsForcingReload(!isForcingReload);

  if (!isBrand(user)) return <RequiresBrandLoginPanel />;

  // Ensuring Package Level
  const checkSubscriptionLevelThenRunFn = fn => {
    if (blockOnRequiringSubscription(user, 'DISCOVER_2.0', { ui })) return null;
    fn();
  };

  // Announcement
  const announcement = getAnnouncementByType(user, 'DISCOVER_2.0');

  return (
    <div className='discover-outer-container'>
      {insertMetaTags({
        title: 'Discover Talent',
        description: '',
        image: ''
      })}
      {announcement && <AnnouncementModal announcement={announcement} extra={{}} />}
      <DiscoverData
        page={page}
        results={results}
        selectedTags={selectedTags}
        curSearchVal={curSearchVal}
        setResults={setResults}
        setLists={setLists}
        setIsSearchingUsers={setIsSearchingUsers}
        isSearchingTags={isSearchingTags}
        isSearchingUsers={isSearchingUsers}
        setIsSearchingTags={setIsSearchingTags}
        hideMyPromoters={hideMyPromoters}
        onlyMyPromoters={onlyMyPromoters}
        hideAlreadyGifted={hideAlreadyGifted}
        isForcingReload={isForcingReload}
        sortBy={sortBy}
        checkSubscriptionLevelThenRunFn={checkSubscriptionLevelThenRunFn}
      />
      <div className='discover-inner-container'>
        <div className='discover-sidebar-container'>
          <DiscoverSidebar
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            inSearchMode={inSearchMode}
            setInSearchMode={setInSearchMode}
            isSearchingUsers={isSearchingUsers}
            curSearchVal={curSearchVal}
            setCurSearchVal={setCurSearchVal}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            isSearchingTags={isSearchingTags}
            setPage={setPage}
            onListsTab={onListsTab}
            setOnListsTab={setOnListsTab}
            results={results}
            checkSubscriptionLevelThenRunFn={checkSubscriptionLevelThenRunFn}
          />
        </div>
        <div className='discover-main-container'>
          {inSearchMode ? (
            <>
              <DiscoverControls
                user={user}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                hideMyPromoters={hideMyPromoters}
                setHideMyPromoters={setHideMyPromoters}
                onlyMyPromoters={onlyMyPromoters}
                setOnlyMyPromoters={setOnlyMyPromoters}
                hideAlreadyGifted={hideAlreadyGifted}
                setHideAlreadyGifted={setHideAlreadyGifted}
                setPage={setPage}
                sortBy={sortBy}
                setSortBy={setSortBy}
                checkSubscriptionLevelThenRunFn={checkSubscriptionLevelThenRunFn}
              />
              <DiscoverResults
                results={results}
                lists={lists}
                page={page}
                setPage={setPage}
                isSearchingUsers={isSearchingUsers}
                syncData={forceReload}
                checkSubscriptionLevelThenRunFn={checkSubscriptionLevelThenRunFn}
              />
            </>
          ) : onListsTab ? (
            <DiscoverLists checkSubscriptionLevelThenRunFn={checkSubscriptionLevelThenRunFn} />
          ) : (
            <DiscoverFeatures
              isEditing={isEditing}
              setPage={setPage}
              setIsEditing={setIsEditing}
              checkSubscriptionLevelThenRunFn={checkSubscriptionLevelThenRunFn}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Discover.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  setUIKeyValue: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  setUIKeyValue
})(Discover);
