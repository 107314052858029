import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import _ from 'lodash';
import icons from '../../static/icons';
import './Footer.scss';

import { isPublicPage, isEmbedPage, isHomePage, getRootSMSUrl, isPromotePage, isReferralPage, isOAuthPage } from '../../Helpers/helpers';
import { CREATOR_HOME_STEPS, BRAND_HOME_STEPS } from '../../Helpers/home_helpers';

import EmailPanel from '../HomeOld/EmailPanel';

class Footer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    setJoinedMailingList: PropTypes.func.isRequired,
    clickApply: PropTypes.func
  };

  getCurPath = () => _.get(this.props.history, ['location', 'pathname']);

  scrollTop = () => {
    window.scrollTo(0, 0);
  };

  generateLink = (name, destination) => {
    const { to, href, isNew } = destination || {};
    const newIcon = <div className='new-badge'>NEW</div>;
    return to ? (
      <li key={name}>
        <Link onClick={this.scrollTop} className='footer-panel-link' to={to[0] === '/' ? to : `/${to}`}>
          {name}
        </Link>
      </li>
    ) : (
      <li key={name}>
        <a onClick={this.upsellToShopmy} className='footer-panel-link' rel='noopener nofollow' href={href}>
          {name}
          {isNew && newIcon}
        </a>
      </li>
    );
  };

  clickSMS = () => {
    window.__ADD_EVENT__('Footer - Click To Navigate to ShopMy');
  };

  upsellToShopmy = () => {
    window.__ADD_EVENT__('Footer - Upsell to ShopMy');
  };

  clickFAQ = () => {
    this.props.history.push(`/faq`);
  };

  clickTerms = () => {
    if (window.__IS_PRO__) {
      window.location.href = 'https://static.shopmy.us/Shoplist_TOS.pdf';
    }
  };

  clickPrivacy = () => {
    if (window.__IS_PRO__) {
      window.location.href = 'https://static.shopmy.us/Privacy_Policy.pdf';
    }
  };

  render() {
    if (isEmbedPage() || isOAuthPage()) return null;
    const isHome = isHomePage();
    const isPromote = isPromotePage();
    const isReferral = isReferralPage();
    return (
      <div
        className={classnames('footer-outer-shell', {
          promote: isPromote,
          home: isHome,
          referral: isReferral
        })}
      >
        {isPublicPage() ? (
          <div className='footer-outer-container public'>
            <a itemProp='url' className='footer-home-link powered-by' rel='noopener noreferrer' target='_blank' href={`${getRootSMSUrl()}/home`}>
              <div className='footer-public-logo-header'>powered by</div>
              <img className='logo' alt='ShopMy Logo' src='https://static.shopmy.us/Logo/shop_my_sm.png' />
            </a>
          </div>
        ) : (
          <div className='footer-outer-container'>
            <div itemScope className='footer-inner-container'>
              <div className='footer-main-body wide'>
                <div className='footer-panel left'>
                  <div className='footer-panel-header-main'>Connect with us</div>
                  <div className='footer-panel-subheader-main'>Stay up to date on all of our updates with our monthly newsletter.</div>
                  <EmailPanel ui={this.props.ui} setJoinedMailingList={this.props.setJoinedMailingList} inFooter />
                  <div className='footer-panel-social-links'>
                    <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/shopmy'>
                      <div className='follow-label'>Follow us on social</div>
                      <img alt='Instagram Icon' src={icons.social.instagram.white} />
                    </a>
                  </div>
                </div>
                <div className='footer-panel with-subpanels right'>
                  <div className='footer-subpanel'>
                    <div className='footer-panel-header'>For Creators</div>
                    <ul>
                      {this.generateLink('ShopMy For Creators', { to: 'home/creators' })}
                      {CREATOR_HOME_STEPS.map((step, idx) => this.generateLink(step.header, { to: step.to }))}
                    </ul>
                  </div>
                  <div className='footer-subpanel'>
                    <div className='footer-panel-header'>For Brands</div>
                    <ul>
                      {this.generateLink('ShopMy For Brands', { to: 'home/brands' })}
                      {BRAND_HOME_STEPS.map((step, idx) => this.generateLink(step.header, { to: step.to }))}
                    </ul>
                  </div>
                  <div className='footer-subpanel'>
                    <div className='footer-panel-header'>Explore</div>
                    <ul>
                      {this.generateLink('Shop Top Products', { to: 'shop' })}
                      {this.generateLink('About Us', { to: 'about' })}
                      {this.generateLink('Careers', { to: 'careers' })}
                      {this.generateLink('Blog', { to: 'blog' })}
                      {this.generateLink('Make a Shop', { href: `${getRootSMSUrl()}/home?auth=apply`, isNew: true })}
                    </ul>
                  </div>
                  <div className='footer-subpanel'>
                    <div className='footer-panel-header'>Support + Legal</div>
                    <ul>
                      {this.generateLink('Creator How-To Guide', { href: `https://guide.shopmy.us` })}
                      {this.generateLink('Brand Guide', { href: `https://brands.shopmy.us` })}
                      {this.generateLink('Contact Us', { to: 'contact' })}
                      {this.generateLink('Terms of Service', { to: 'terms' })}
                      {this.generateLink('Privacy', { to: 'terms' })}
                    </ul>
                  </div>
                </div>
              </div>
              <Link onClick={this.scrollTop} to='/home' replace={this.getCurPath().includes('/home')} className='footer-brand-container'>
                <div className='footer-logo'>shop my</div>
                <div className='footer-copyright'>© Copyright {moment().format('YYYY')} Shop My Shelf, Inc. All Rights Reserved.</div>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Footer);
