import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Subscriptions.scss';

import { getSubscriptionOffers } from '../../APIClient/subscriptions';
import { getContractInvoices, getCreatorBonuses } from '../../APIClient/brands';
import { openSubscriptionOfferModal } from '../../Actions/UIActions';
import {
  getBrandSubscription,
  getBrandSubscriptionInvoices,
  getBrandAffiliateInvoices,
  getBrandBudgetInvoices,
  getUnpaidBrandSubscriptionInvoices,
  getUnpaidBrandAffiliateInvoices,
  getUnpaidBrandBudgetInvoices,
  getUnpaidBrandSubscriptionInvoicesPastDue,
  getUnpaidBrandAffiliateInvoicesPastDue,
  getUnpaidBrandBudgetInvoicesPastDue,
  getBrandIntegrations
} from '../../Helpers/user_helpers';

import BrandInvoices from '../../Components/Subscriptions/BrandInvoices';
import SubscriptionTabs from '../../Components/Subscriptions/SubscriptionTabs';
import SubscriptionOfferTile from '../../Components/Subscriptions/SubscriptionOfferTile';
import YourSubscriptionOffers from '../../Components/Subscriptions/YourSubscriptionOffers';
import ManageBudgetPanel from '../../Components/Subscriptions/ManageBudgetPanel';
import BudgetChangesPanel from '../../Components/Subscriptions/BudgetChangesPanel';
import RequiresBrandLoginPanel from '../../Components/General/RequiresBrandLoginPanel';

const Subscriptions = props => {
  const { user } = props;
  const subscription = getBrandSubscription(user);
  const params = useParams();

  // Get all data to power the page
  const [allOffers, setAllOffers] = useState([]);
  const [contractInvoices, setContractInvoices] = useState([]);
  const [creatorBonuses, setCreatorBonuses] = useState([]);
  const [opportunityBonuses, setOpportunityBonuses] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(true);
  useEffect(() => {
    const subscriptionOffers = getSubscriptionOffers().then(os => {
      setAllOffers(os?.filter(offer => !offer.isDeprecated) || []);
    });
    const contractInvoices = getContractInvoices(user).then(invoices => {
      setContractInvoices(invoices);
    });
    const creatorBonuses = getCreatorBonuses(user).then(resp => {
      setCreatorBonuses(resp.bonuses);
      setOpportunityBonuses(resp.opportunity_bonuses);
    });
    Promise.all([subscriptionOffers, contractInvoices, creatorBonuses]).then(() => {
      setIsFetchingData(false);
    });
  }, []);

  // Subscription Invoices
  const subscriptionInvoices = getBrandSubscriptionInvoices(user);
  const subscriptionInvoices_unpaid = getUnpaidBrandSubscriptionInvoices(user);
  const subscriptionInvoices_pastdue = getUnpaidBrandSubscriptionInvoicesPastDue(user);
  const subscriptionInvoicesToShow = subscriptionInvoices;

  // Affiliate Invoices
  const affiliateInvoices = getBrandAffiliateInvoices(user);
  const affiliateInvoices_unpaid = getUnpaidBrandAffiliateInvoices(user);
  const affiliateInvoices_pastdue = getUnpaidBrandAffiliateInvoicesPastDue(user);
  const affiliateInvoicesToShow = affiliateInvoices;

  // Budget Invoices
  const budgetInvoices = getBrandBudgetInvoices(user);
  const budgetInvoices_unpaid = getUnpaidBrandBudgetInvoices(user);
  const budgetInvoices_pastdue = getUnpaidBrandBudgetInvoicesPastDue(user);
  const budgetInvoicesToShow = budgetInvoices;

  const hasInvoices = subscriptionInvoices.length || affiliateInvoices.length || budgetInvoices.length;
  const numOverdueInvoices = subscriptionInvoices_pastdue.length + affiliateInvoices_pastdue.length + budgetInvoices_pastdue.length;
  const numUnpaidInvoices = subscriptionInvoices_unpaid.length + affiliateInvoices_unpaid.length + budgetInvoices_unpaid.length;

  // Tab management
  const [activeTab, setActiveTab] = useState(params.tab || (numOverdueInvoices ? 'invoices' : 'subscriptions')); // subscriptions, invoices, budget
  const isSubscriptionsTab = activeTab === 'subscriptions';
  const isInvoicesTab = activeTab === 'invoices';
  const isBudgetTab = activeTab === 'budget';
  const tabs = [
    {
      title: 'View Your Subscription',
      variable: 'subscriptions'
    },
    ...(hasInvoices
      ? [
          {
            title: numOverdueInvoices
              ? `${numOverdueInvoices} Overdue Invoice${numOverdueInvoices === 1 ? '' : 's'}`
              : numUnpaidInvoices
              ? `${numUnpaidInvoices} Unpaid Invoice${numUnpaidInvoices === 1 ? '' : 's'}`
              : 'View Invoices',
            variable: 'invoices',
            hasAlert: !!numOverdueInvoices,
            hasWarning: numUnpaidInvoices && !numOverdueInvoices
          }
        ]
      : []),
    {
      title: 'Manage Budget',
      variable: 'budget',
      isActive: activeTab === 'budget'
    }
  ].map(tab => ({
    ...tab,
    isActive: activeTab === tab.variable
  }));
  const selectTab = tab => {
    tab.variable === 'subscriptions' ? props.history.push(`/subscriptions`) : props.history.push(`/subscriptions/${tab.variable}`);
    setActiveTab(tab.variable);
  };

  if (!subscription) return <RequiresBrandLoginPanel />;

  // Offer Groups
  const currentSubscriptionOffers = subscription.items.filter(i => !i.isCancelled).map(i => i.offer);
  const inactiveSubscriptionOffers = allOffers.filter(
    o => !currentSubscriptionOffers.find(co => co.id === o.id) && !o.VariationOfSubscriptionOffer_id
  ); // Hide Subvariations
  const visibleSubscriptionOffers = inactiveSubscriptionOffers.filter(o => {
    // Hide variations that already have an active subscription
    const hasVariationSelected = currentSubscriptionOffers.find(
      co => co.VariationOfSubscriptionOffer_id === o.id || o.VariationOfSubscriptionOffer_id === co.id
    );
    if (hasVariationSelected) return false;

    // Hide hidden offers if not in admin mode
    if (o.isHidden && !window.__ADMIN_CONTROL_MODE__) return false;

    // Hide Shopify offers if the user is a Shopify user
    const isShopifyIntegrated = getBrandIntegrations(user).some(i => i.type === 'shopify');
    if (isShopifyIntegrated && o.title.includes('Shopify')) return false;

    return true;
  });
  const subscriptionSections = [
    {
      title: 'Level Up Your Program',
      offers: visibleSubscriptionOffers
    }
  ];

  const invoiceSections = [
    {
      title: 'Subscription Invoices',
      invoices: subscriptionInvoicesToShow
    },
    {
      title: 'Affiliate Invoices',
      invoices: affiliateInvoicesToShow
    },
    {
      title: 'Budget Invoices',
      invoices: budgetInvoicesToShow
    }
  ];

  return (
    <div className='subscriptions-outer-container'>
      <div className='subscriptions-inner-container'>
        <div className='tabs-container capped-width'>
          <SubscriptionTabs tabs={tabs} selectedTab={tabs.find(t => t.isActive)} selectTab={selectTab} />
        </div>
        <div className='sections'>
          {isSubscriptionsTab && currentSubscriptionOffers.length > 0 && (
            <div className='section'>
              <YourSubscriptionOffers user={user} />
            </div>
          )}

          {isInvoicesTab &&
            invoiceSections.map(section => {
              const { title, invoices } = section;
              if (!invoices.length) return null;
              return (
                <div key={title} className='section'>
                  <div className='section-header-container'>
                    <div className='header main'>{title}</div>
                  </div>
                  <BrandInvoices invoices={invoices} />
                </div>
              );
            })}
          {isSubscriptionsTab &&
            subscriptionSections.map(section => {
              const { title, offers, badge } = section;
              if (!offers.length) return null;
              return (
                <div key={title} className='section'>
                  <div className='section-header-container'>
                    {badge && <div className='badge'>{badge}</div>}
                    <div className='header main'>{title}</div>
                  </div>
                  <div className='tiles'>
                    {offers.map(offer => (
                      <SubscriptionOfferTile key={offer.id} offer={offer} />
                    ))}
                  </div>
                </div>
              );
            })}
          {isBudgetTab && (
            <>
              <div className='section'>
                <div className='section-header-container'>
                  <div className='header main'>Manage Budget</div>
                </div>
                <ManageBudgetPanel />
              </div>
              <div className='section'>
                <div className='section-header-container'>
                  <div className='header main'>Budget Changes</div>
                </div>
                <BudgetChangesPanel
                  isFetchingData={isFetchingData}
                  user={user}
                  contractInvoices={contractInvoices}
                  creatorBonuses={creatorBonuses}
                  opportunityBonuses={opportunityBonuses}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Subscriptions.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openSubscriptionOfferModal
})(Subscriptions);
