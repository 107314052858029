import React from 'react';
import PropTypes from 'prop-types';
import './PartnerPortalCodes.scss';
import PartnerPortalCode from './PartnerPortalCode';

const PartnerPortalCodes = props => {
  const { discountCodes, curSearchVal } = props;

  const sortByCodeRevenue = (a, b) => {
    if (a.orderVolumeDriven > b.orderVolumeDriven) return -1;
    if (a.orderVolumeDriven < b.orderVolumeDriven) return 1;
    return 0;
  };

  const filterBySearchValue = code => {
    if (!curSearchVal) return true;
    const { brand, displayText } = code;
    const { name, domain } = brand;

    return (
      name?.toLowerCase().includes(curSearchVal?.toLowerCase()) ||
      domain?.toLowerCase().includes(curSearchVal?.toLowerCase()) ||
      displayText?.toLowerCase().includes(curSearchVal?.toLowerCase())
    );
  };

  return (
    <div className='partner-portal-codes-outer'>
      <div className='partner-portal-codes-inner'>
        {discountCodes
          ?.filter(filterBySearchValue)
          ?.sort(sortByCodeRevenue)
          ?.map(code => {
            return <PartnerPortalCode key={code.id} code={code} />;
          })}
      </div>
    </div>
  );
};

PartnerPortalCodes.propTypes = {
  discountCodes: PropTypes.array.isRequired,
  curSearchVal: PropTypes.string
};

export default PartnerPortalCodes;
