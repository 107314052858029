import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import './OnboardingPanelTiers.scss';

import { getUserTier } from '../../../Helpers/user_helpers';
import { getBio, getSocialLinks, getImage } from '../../../Helpers/user_helpers';
import { getTierMeterData, getUserTiers } from '../../../Helpers/tier_helpers';

const OnboardingPanelTiers = props => {
  const { user, syncUserTier } = props;
  const userTiers = getUserTiers();
  const userTier = getUserTier(user);

  useEffect(() => {
    syncUserTier(userTier);
  }, [syncUserTier]);

  const getMeter = () => {
    /* See tier_helpers.js formatting descriptions. */
    const { score, values, pointsInt, pointsLabel } = getTierMeterData(user);
    return (
      <div className='meter'>
        <div className='spine' />
        <div className={cn('spine active')} style={{ width: `calc(${score}% + 24px)` }} />
        {values.map((val, idx) => (
          <div
            key={val.value}
            className={cn('mark-container', { complete: score >= val.value })}
            style={{ left: `calc(${_.min([val.value, 100])}% - 12px)` }}
          >
            <div className='mark'>{val.valueLabel}</div>
            {val.label && <div className='mark-label desktop-only'>{val.label}</div>}
            {val.shortLabel && <div className='mark-label mobile-only'>{val.shortLabel}</div>}
          </div>
        ))}
        <div className='mark-container' style={{ left: `calc(${score}% - 12px)` }}>
          <div className='mark current'>{pointsInt}</div>
          {pointsLabel && <div className='mark-label-pointer' />}
          {pointsLabel && <div className='mark-label current'>{pointsLabel}</div>}
        </div>
      </div>
    );
  };

  const getExplainers = () => {
    return (
      <>
        {userTiers.map((ut, idx) => {
          const nextTier = userTiers[idx + 1];
          const isActive = ut.tier === userTier.tier;
          return (
            <div key={ut.label} className={cn('section', { active: isActive })} style={{ width: `${(nextTier?.minScore || 100) - ut.minScore}%` }}>
              <div className={cn('label', { 'desktop-only': !isActive })}>{ut.label}</div>
              {isActive && <div className={cn('sublabel', { 'desktop-only': !isActive })}>{ut.explainer}</div>}
            </div>
          );
        })}
      </>
    );
  };

  const initialActions = [
    { display: 'Bio Added', complete: !!getBio(user) },
    { display: 'Social Media Linked', complete: !!getSocialLinks(user) },
    { display: 'Photo Uploaded', complete: !!getImage(user) }
  ];

  const nextActions = [
    { display: 'Generate first product link', complete: false },
    { display: 'Create first shelf, closet or home collection', complete: false },
    { display: 'Drive clicks to your shop', complete: false },
    { display: 'Drive orders to your shop', complete: false },
    { display: 'Accept product gifting from brands', complete: false },
    { display: 'Complete first paid collaboration', complete: false }
  ];

  return (
    <div className='onboarding-panel-tiers-container'>
      <div className='onboarding-header'>Unlock more features</div>
      <div className='onboarding-subheader'>
        Level up your creator tier by completing tasks and reaching milestones. With every tier, you will unlock advanced features to help you grow.
      </div>
      <div className='tier-container'>
        <div className='meter-container'>{getMeter()}</div>
        <div className='tier-sections'>{getExplainers()}</div>
      </div>
      <div className='improvement-sections'>
        <div className='improvement-section'>
          <div className='improvement-header'>First Steps:</div>
          {initialActions.map(action => {
            const { complete, display } = action;
            return (
              <div key={display} className={cn('improvement', { complete })}>
                <div className={cn('checkbox', { complete })}>{complete && <FontAwesomeIcon icon={faCheck} />}</div>
                {display}
              </div>
            );
          })}
        </div>
        <div className='improvement-section'>
          <div className='improvement-header'>Tips for how to upgrade your tier:</div>
          {nextActions.map(action => {
            const { complete, display } = action;
            return (
              <div key={display} className={cn('improvement', { complete })}>
                <div className={cn('checkbox', { complete })}>{complete && <FontAwesomeIcon icon={faCheck} />}</div>
                {display}
              </div>
            );
          })}
        </div>
      </div>
      <div className='perks-sections'>
        {userTiers.map(({ label, explainer, unlocked, tier }) => {
          const current = tier === userTier.tier;
          return (
            <div key={label} className='perks-section'>
              <div className={cn('perks-section-inner', { current })}>
                <div className='perks-header'>{label}</div>
                <div className='perks-subheader'>{explainer}</div>
                <div className='perks'>
                  {unlocked.map((perk, idx) => (
                    <div className='perk' key={idx}>
                      <span className='bullet'>•</span>
                      {perk}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

OnboardingPanelTiers.propTypes = {
  user: PropTypes.object.isRequired,
  syncUserTier: PropTypes.func.isRequired
};

export default OnboardingPanelTiers;
