import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './BrandLookbookSettingsModal.scss';
import Modal from '../../General/Modal';
import Select from 'react-select';
import SelectOption from '../../General/SelectOption';
import cogoToast from 'cogo-toast';
import { createTestShopifyOrder } from '../../../APIClient/lookbooks';
import Loader from '../../Loader/Loader';

import { isAdminControlMode } from '../../../Helpers/ui_helpers';
import { isSubscribedToFeature } from '../../../Helpers/subscription_helpers';
import * as lookbookHelpers from '../../../Helpers/lookbook_helpers';
import { getShopifyIntegration } from '../../../Helpers/user_helpers';
import ReactSwitch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { countryToRegionMapping } from '../../../Helpers/geo_helpers';

const BrandLookbookSettingsModal = props => {
  const { user, ui, lookbook, setViewSettingsModal, updateLookbook } = props;
  const { price_limit, item_limit, disableLinkGeneration } = lookbook;

  const brandCanHaveShopMyFulfillOrders = isSubscribedToFeature(user, 'LOOKBOOK_SHOPMY_ORDER_HANDLING');

  const orderHandledData = lookbookHelpers.getOrderHandledByData(user, lookbook);
  const { isConnectedToShopify, reason, forDomain } = lookbookHelpers.isLookbookConnectedToShopify(user, lookbook);
  const allowedCountryCodesArr = lookbook.allowedCountryCodes ? lookbook.allowedCountryCodes.split(',') : [];

  const close = () => setViewSettingsModal(false);

  // Debouncers
  const shippingDiscountUpdateDebouncer = useRef(null);
  const priceLimitUpdateDebouncer = useRef(null);
  const itemLimitUpdateDebouncer = useRef(null);

  /************************************************************************************************* */
  // Shipping Options
  /************************************************************************************************* */
  let lookbookShippingOptions = [
    {
      value: 'default',
      label: 'Default',
      sublabel: 'Place orders through an integration if available, otherwise manually.'
    },
    {
      value: 'brand',
      label: 'Your Team',
      sublabel: 'You will have to place the order manually and then mark it as sent in the gifting portal.'
    },
    {
      value: null,
      label: 'Shopify',
      isDisabled: !isConnectedToShopify,
      sublabel: isConnectedToShopify
        ? 'Shopify will automatically place the order for you. You will be able to view the order on your Shopify dashboard.'
        : reason
    },
    {
      value: 'shopmy',
      label: 'ShopMy',
      isDisabled: !brandCanHaveShopMyFulfillOrders || isAdminControlMode(ui),
      sublabel: brandCanHaveShopMyFulfillOrders
        ? 'Each Lookbook order will be fulfilled by a ShopMy brand associate team member.'
        : 'The package you are on does not support order handling from the ShopMy team.'
    }
  ];

  if (isConnectedToShopify) {
    // remove default option if connected to shopify, can use an integration
    lookbookShippingOptions = lookbookShippingOptions.filter(option => option.value !== 'default');
  }

  const [selectedShippingOptionOverride, setSelectedShippingOptionOverride] = useState(null);
  const selectedShippingOption =
    selectedShippingOptionOverride ||
    lookbookShippingOptions.find(
      option => option.value === orderHandledData.handledBy || (!option.value && orderHandledData.handledBy === 'shopify' && isConnectedToShopify)
    ) ||
    lookbookShippingOptions[0];
  const [testingShopifyOrder, setTestingShopifyOrder] = useState(false);

  // shopify option states
  const [storeDefaultCurrencyCode, setStoreDefaultCurrencyCode] = useState(null);
  const [selectedShippingRate, setSelectedShippingRate] = useState(null);
  const [shippingRateOptions, setShippingRateOptions] = useState([]);
  const [selectedOrderCurrencyCode, setSelectedOrderCurrencyCode] = useState(null);
  const [orderCurrencyCodeOptions, setOrderCurrencyCodeOptions] = useState([]);

  const [isDiscountOrderShippingChecked, setIsDiscountOrderShippingChecked] = useState(!!lookbook.shouldDiscountOrderShipping);

  // country restrictions
  const [showCountrySearch, setShowCountrySearch] = useState(
    allowedCountryCodesArr.length > 1 || (allowedCountryCodesArr.length === 1 && allowedCountryCodesArr[0] !== 'US')
  );
  const toggleCountrySearch = () => setShowCountrySearch(!showCountrySearch);

  const initShippingRateOptions = shopifyIntegration => {
    const getItemSublabel = item => {
      const { price, currencyCode, source } = item;
      const priceString = price ? `${parseFloat(price).toFixed(2)} ${currencyCode}` : '';
      const sourceString = source ? `${source} - ` : '';
      return `${sourceString} ${priceString}`;
    };

    const rateOptions =
      shopifyIntegration.shipping_lines?.map(sl => ({
        value: sl.id,
        label: sl.title,
        sublabel: getItemSublabel(sl)
      })) || [];

    setShippingRateOptions(rateOptions);

    if (lookbook?.ShippingLine_id) {
      const selectedRate = rateOptions.find(ro => ro.value === lookbook.ShippingLine_id);
      setSelectedShippingRate(selectedRate || null);
    }
  };

  const initOrderCurrencyCodeOptions = shopifyIntegration => {
    // init order currency code options
    if (shopifyIntegration.allowShopifyDraftOrderCompletion) {
      // Shopify draft order flow does not support currency code overrides
      return;
    }

    const currencyCodeStr = shopifyIntegration.shopify_store_config?.enabledPresentmentCurrencies;

    if (currencyCodeStr?.length) {
      const currencyCodeOptions = currencyCodeStr.split(',').map(cc => ({ value: cc, label: cc }));
      setOrderCurrencyCodeOptions(currencyCodeOptions);

      if (lookbook?.orderOverrideCurrencyCode) {
        const selectedCurrencyCode = currencyCodeOptions.find(cco => cco.value === lookbook.orderOverrideCurrencyCode);
        setSelectedOrderCurrencyCode(selectedCurrencyCode || null);
      }
    }

    setStoreDefaultCurrencyCode(shopifyIntegration.shopify_store_config?.defaultCurrency || null);
  };

  useEffect(() => {
    const shopifyIntegration = isConnectedToShopify && forDomain ? getShopifyIntegration(user, forDomain) : null;

    if (shopifyIntegration && !_.isEmpty(shopifyIntegration)) {
      initShippingRateOptions(shopifyIntegration);
      initOrderCurrencyCodeOptions(shopifyIntegration);
    }
  }, []);

  useEffect(() => {
    // handle update lookbook failure
    if (!!lookbook.shouldDiscountOrderShipping !== isDiscountOrderShippingChecked) {
      setIsDiscountOrderShippingChecked(!!lookbook.shouldDiscountOrderShipping);
    }
  }, [lookbook]);

  const handleSelectShippingRate = async option => {
    setSelectedShippingRate(option);
    await updateLookbook({ ShippingLine_id: option?.value || null });
  };

  const handleSelectOrderCurrencyCode = async option => {
    setSelectedOrderCurrencyCode(option);
    await updateLookbook({ orderOverrideCurrencyCode: option?.value || null });
  };

  const shouldShowShippingRateSelect = shippingRateOptions.length > 0;
  const shouldShowOrderCurrencyCodeSelect = orderCurrencyCodeOptions.length > 0;
  const shouldShowShopifySettings = (shouldShowShippingRateSelect || shouldShowOrderCurrencyCodeSelect) && selectedShippingOption.label === 'Shopify';

  /************************************************************************************************* */
  // Update Lookbook Functions
  /************************************************************************************************* */

  const updatePriceLimit = e => {
    const price = parseInt(e.target.value);

    if (priceLimitUpdateDebouncer.current) {
      clearTimeout(priceLimitUpdateDebouncer.current);
    }

    priceLimitUpdateDebouncer.current = setTimeout(() => {
      updateLookbook({ price_limit: isNaN(price) || price < 0 ? null : price });
    }, 1000);
  };

  const updateItemLimit = e => {
    const limit = parseInt(e.target.value);

    if (itemLimitUpdateDebouncer.current) {
      clearTimeout(itemLimitUpdateDebouncer.current);
    }

    itemLimitUpdateDebouncer.current = setTimeout(() => {
      updateLookbook({ item_limit: isNaN(limit) || limit < 0 ? null : limit });
    }, 1000);
  };

  const updateShouldDiscountOrderShipping = isChecked => {
    setIsDiscountOrderShippingChecked(isChecked);

    if (shippingDiscountUpdateDebouncer.current) {
      clearTimeout(shippingDiscountUpdateDebouncer.current);
    }

    shippingDiscountUpdateDebouncer.current = setTimeout(() => {
      updateLookbook({ shouldDiscountOrderShipping: isChecked });
    }, 1000);
  };

  const updateShippingOption = option => {
    setSelectedShippingOptionOverride(option);
    updateLookbook({ orderHandledBy: option.value === 'default' ? null : option.value }); // default/shopify handled as null on the backend
  };

  const testShopifyOrder = () => {
    if (testingShopifyOrder) return;

    setTestingShopifyOrder(true);
    createTestShopifyOrder(lookbook.Brand_id)
      .then(() => cogoToast.success('Test order successful! You can view it on your Shopify dashboard.'))
      .catch(() => cogoToast.error('Error creating test order. Check your integration scopes and try again.'))
      .finally(() => setTestingShopifyOrder(false));
  };

  const updateAllowedCountryCodes = countryCodes => {
    let countryCodesStr = '';

    if (countryCodes) {
      countryCodesStr = countryCodes.map(c => c.value).join(',');
    }

    if (!countryCodesStr) {
      setShowCountrySearch(false);
    }

    updateLookbook({ allowedCountryCodes: countryCodesStr || null });
  };

  /************************************************************************************************* */
  // Styling Options
  /************************************************************************************************* */
  const [imageStyle, setImageStyle] = useState(lookbook.imageStyle || 'cover');
  const imageStyleOptions = [
    { value: 'cover', label: 'Square + Stretch', sublabel: 'Image will be stretched to fill a square image container.' },
    { value: 'contain', label: 'Square + Contain', sublabel: 'Image will be contained within the square image container.' },
    { value: 'original', label: 'Native Dimensions', sublabel: 'No sizing needed, simply use the image dimensions.' }
  ];
  const currentImageOption = imageStyleOptions.find(option => option.value === imageStyle);
  const updateImageStyle = option => {
    setImageStyle(option.value);
    updateLookbook({ imageStyle: option.value });
  };

  const countryCodeSelectOptions = showCountrySearch ? countryToRegionMapping.map(option => ({ label: option.label, value: option.key })) : [];

  return (
    <Modal
      noPadding
      visible={true}
      close={close}
      innerClassName='brand-lookbook-settings-modal-outer'
      contentClassName='brand-lookbook-settings-modal-inner'
      showClose={true}
    >
      <div className='content'>
        <div className='settings-section'>
          <div className='section-header'>Set Creator Limits</div>
          <div className='section-sub-heading'>Set maximum values for the users when selecting from your lookbook.</div>

          <div className='sub-section'>
            <div className='sub-section-header'>Price Limit</div>
            <input type='number' className='limit-input' placeholder='1000' defaultValue={price_limit} onChange={updatePriceLimit} />
          </div>

          <div className='sub-section'>
            <div className='sub-section-header'>Item Limit</div>
            <input type='number' className='limit-input' placeholder='3' defaultValue={item_limit} onChange={updateItemLimit} />
          </div>
        </div>
        <div className='settings-section'>
          <div className='section-header'>Configure Styles</div>
          <div className='section-sub-heading'>Customize the look of your lookbook</div>

          <div className='sub-section'>
            <div className='sub-section-header'>Image Style</div>
            <div className='image-style-container'>
              <Select
                unstyled
                onChange={updateImageStyle}
                options={imageStyleOptions}
                value={currentImageOption}
                components={{ Option: SelectOption }}
              />
            </div>
          </div>
        </div>

        <div className='settings-section'>
          <div className='section-header'>Shipping Settings</div>
          <div className='section-sub-heading'>Set how orders will be placed for your lookbook</div>

          <div className='sub-section'>
            <div className='sub-section-header'>Responsible for placing order</div>
            <div className='shipping-option-container'>
              <Select
                unstyled
                placeholder='Shipping Option'
                classNamePrefix='shipping-option-select'
                onChange={updateShippingOption}
                className='shipping-option-select'
                options={lookbookShippingOptions}
                value={selectedShippingOption}
                components={{ Option: SelectOption }}
              />
            </div>
          </div>
          <div className='sub-section'>
            <div className='sub-section-header'>Country Restrictions</div>
            <div className='sub-section-subheader'>Only allow creators in certain countries to select from this lookbook</div>

            <div className='toggle-container beginning'>
              <ReactSwitch
                checked={allowedCountryCodesArr.includes('US')}
                onChange={() => {
                  if (allowedCountryCodesArr.includes('US')) {
                    updateAllowedCountryCodes([]);
                  } else {
                    updateAllowedCountryCodes([{ value: 'US' }]);
                  }
                }}
                checkedIcon={<FontAwesomeIcon icon={faCheck} />}
                uncheckedIcon={false}
                onColor='#11835a'
                height={24}
                width={43}
                handleDiameter={21}
                disabled={showCountrySearch}
              />
              <div className='country-label'>United States</div>
              {!showCountrySearch && (
                <div className='search-more-label' onClick={toggleCountrySearch}>
                  Add Other Countries
                </div>
              )}
            </div>
            {showCountrySearch && (
              <div className='country-search-container'>
                <Select
                  isMulti={true}
                  classNamePrefix='country-select'
                  className='country-select'
                  options={countryCodeSelectOptions}
                  onChange={updateAllowedCountryCodes}
                  placeholder='Country'
                  components={{ Option: SelectOption }}
                  value={countryCodeSelectOptions.filter(option => allowedCountryCodesArr.includes(option.value))}
                />
              </div>
            )}
          </div>
        </div>

        {shouldShowShopifySettings && (
          <div className='settings-section'>
            <div className='section-header'>Shopify Order Settings</div>
            <div className='section-sub-heading'>
              {`Your lookbook is connected to Shopify for ${forDomain}. Optionally configure the following settings to customize Shopify order attributes to be applied to all
              orders placed from this lookbook`}
            </div>
            {shouldShowShippingRateSelect && (
              <>
                <div className='sub-section'>
                  <div className='sub-section-header'>Shipping Rate</div>
                  <div className='section-sub-heading short'>Select a shipping rate (optional)</div>
                  <div className='shipping-rate-container'>
                    <Select
                      isClearable={true}
                      placeholder='Shipping Rate'
                      classNamePrefix='shipping-rate-select'
                      className='shipping-rate-select'
                      onChange={handleSelectShippingRate}
                      value={selectedShippingRate}
                      options={shippingRateOptions}
                      components={{ Option: SelectOption }}
                      styles={{
                        menuList: provided => ({
                          ...provided,
                          maxHeight: '175px'
                        })
                      }}
                    />
                  </div>
                </div>
                {!!selectedShippingRate && (
                  <div className={'sub-section nested'}>
                    <div className='sub-section-header'>Discount Order Shipping</div>
                    <div className='section-sub-heading short'>Apply a full discount for the shipping rate specified above</div>
                    <div className='toggle-container beginning'>
                      <ReactSwitch
                        checked={isDiscountOrderShippingChecked}
                        onChange={checked => updateShouldDiscountOrderShipping(checked)}
                        checkedIcon={<FontAwesomeIcon icon={faCheck} />}
                        uncheckedIcon={false}
                        onColor='#11835a'
                        height={24}
                        width={43}
                        handleDiameter={21}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            {shouldShowOrderCurrencyCodeSelect && (
              <div className='sub-section'>
                <div className='sub-section-header'>Order Currency Code</div>
                <div className='section-sub-heading short'>
                  {`Select a currency code from your Shopify store's available presentment currencies to use for orders placed from this lookbook. By
                  default, we will use your store's default currency${storeDefaultCurrencyCode ? ` (${storeDefaultCurrencyCode})` : ''}.`}
                </div>
                <div className='order-currency-code-container'>
                  <Select
                    isClearable={true}
                    placeholder={storeDefaultCurrencyCode || 'Currency Code'}
                    classNamePrefix='order-currency-code-select'
                    className='order-currency-code-select'
                    onChange={handleSelectOrderCurrencyCode}
                    value={selectedOrderCurrencyCode}
                    options={orderCurrencyCodeOptions}
                    components={{ Option: SelectOption }}
                    styles={{
                      menuList: provided => ({
                        ...provided,
                        maxHeight: '175px'
                      })
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div className='settings-section'>
          <div className='section-header'>General Settings</div>

          <div className='sub-section'>
            <div className='sub-section-header'>Enable Commissionable Links</div>
            <div className='sub-section-subheader'>Allow users to easily generate commissionable links from the gifted products</div>
            <div className='toggle-container beginning'>
              <ReactSwitch
                checked={!disableLinkGeneration}
                onChange={() => updateLookbook({ disableLinkGeneration: !disableLinkGeneration })}
                className='follow-up-message-toggle'
                checkedIcon={<FontAwesomeIcon icon={faCheck} />}
                uncheckedIcon={false}
                onColor='#11835a'
                height={24}
                width={43}
                handleDiameter={21}
              />
            </div>
          </div>
        </div>

        {isConnectedToShopify && isAdminControlMode(props.ui) && (
          <div className='settings-section'>
            <div className='section-header'>Configure Shopify Settings</div>
            <div className='section-sub-heading'>
              Having a shopify integration lets you fully integrate Lookbooks with users. Check your integration works by sending a test order. (You
              must first have some items from your Shopify store in your Lookbook.)
            </div>

            {/* <div className='sub-section'>
              <div className='sub-section-header'>Disable Automatic Order Creation</div>
              <div className='toggle-container beginning'>
                <ReactSwitch
                  checked={!!shopifyNoAutomatedOrders}
                  onChange={updateShopifyNoAutomatedOrders}
                  className='follow-up-message-toggle'
                  checkedIcon={<FontAwesomeIcon icon={faCheck} />}
                  uncheckedIcon={false}
                  onColor='#11835a'
                  height={24}
                  width={43}
                  handleDiameter={21}
                />
              </div>
            </div> */}

            {/* <div className='sub-section'>
              <div className='sub-section-header'>Allow Out of Stock Items</div>
              <div className='toggle-container beginning'>
                <ReactSwitch
                  checked={!!shopifyOverrideUnavailableItems}
                  onChange={updateShopifyOverrideUnavailableItems}
                  className='follow-up-message-toggle'
                  checkedIcon={<FontAwesomeIcon icon={faCheck} />}
                  uncheckedIcon={false}
                  onColor='#11835a'
                  height={24}
                  width={43}
                  handleDiameter={21}
                />
              </div>
            </div> */}

            <div className='sub-section'>
              <div className='sub-section-header'>Test Ordering</div>
              <div className='test-shopify-button' onClick={testShopifyOrder}>
                {testingShopifyOrder ? <Loader size={25} /> : 'Create Test Order'}
              </div>
            </div>
          </div>
        )}

        {/* <div className='settings-section'>
          <div className='section-header'>Automated Follow Up Messages</div>
          <div className='section-sub-heading'>We will send messages automatically via chat 7 days after creators receive the product(s).</div>

          <div className='sub-section'>
            <div className='sub-section-header'>
              <div>Creators who promoted your gifting</div>
              <Select
                unstyled
                placeholder='Add Variable'
                classNamePrefix='variable-select'
                onChange={value => insertVariableIntoText(value, 'promotedGifting')}
                className='variable-select'
                options={variableOptions}
                value={null}
                components={{ Option: SelectOption }}
              />
            </div>
            <textarea
              className='email'
              placeholder="Hey {{FIRST_NAME}}, thanks for the mention! We'd love to chat further about future collaborations."
              onChange={updatePromotedGiftingMessage}
              rows={6}
              ref={ref => {
                matchScrollHeight(ref);
                promotedGiftingRef.current = ref;
              }}
              value={automatedPromotingMessage || ''}
            />
            <div className='toggle-container'>
              Is Enabled
              <ReactSwitch
                checked={!!automatedPromotingMessaging}
                onChange={updateAutomatedPromotingMessaging}
                className='follow-up-message-toggle'
                checkedIcon={<FontAwesomeIcon icon={faCheck} />}
                uncheckedIcon={false}
                onColor='#11835a'
                height={24}
                width={43}
                handleDiameter={21}
              />
            </div>
          </div>

          <div className='sub-section'>
            <div className='sub-section-header'>
              <div>Creators who have yet to promote your gifting</div>
              <Select
                unstyled
                placeholder='Add Variable'
                classNamePrefix='variable-select'
                onChange={value => insertVariableIntoText(value, 'notYetPromotedGifting')}
                className='variable-select'
                options={variableOptions}
                value={null}
                components={{ Option: SelectOption }}
              />
            </div>
            <textarea
              className='email'
              placeholder="Hey {{FIRST_NAME}}, we hope you received your products! We can't wait to see how you like them."
              onChange={updateNotYetPromotedGiftingMessage}
              value={automatedNotYetPromotedMessage || ''}
              rows={6}
              ref={ref => {
                matchScrollHeight(ref);
                notYetPromotedGiftingRef.current = ref;
              }}
            />
            <div className='toggle-container'>
              Is Enabled
              <ReactSwitch
                checked={!!automatedNotYetPromotedMessaging}
                onChange={updateAutomatedNotYetPromotedMessaging}
                className='follow-up-message-toggle'
                checkedIcon={<FontAwesomeIcon icon={faCheck} />}
                uncheckedIcon={false}
                onColor='#11835a'
                height={24}
                width={43}
                handleDiameter={21}
              />
            </div>
          </div>
        </div> */}
      </div>
      <div className='footer'>
        <div className='done-button' onClick={close}>
          Done
        </div>
      </div>
    </Modal>
  );
};

BrandLookbookSettingsModal.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  setViewSettingsModal: PropTypes.func.isRequired,
  updateLookbook: PropTypes.func.isRequired
};

export default BrandLookbookSettingsModal;
