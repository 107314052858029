import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import './Header.scss';

import hamburgerMenu from '../../static/images/nav/nav_bar.png';

import HeaderLogo from './HeaderLogo';
import MoreOptionsMenu from './MoreOptions/MoreOptionsMenu';
import InviteTalentModal from '../../Components/Modals/InviteTalentModal';
import ManageUsersMenu from '../../Components/Managers/ManageUsersMenu';
import HeaderSubpagePanel from './Elements/HeaderSubpagePanel';

import {
  isPublicPage,
  isEmbedPage,
  isOAuthPage,
  isWelcomePage,
  isHomePage,
  isPromotePage,
  isReferralPage,
  getRootSMSUrl
} from '../../Helpers/helpers';
import {
  getUsername,
  isBrand,
  isManager,
  managesUsers,
  getManages,
  getImage,
  getNotificationCount,
  isShopifyUserRequiredForAppAcceptance,
  getCodes,
  getRequests,
  getOpportunityRequests,
  getBrand,
  getUserTier,
  getProfileFromObject
} from '../../Helpers/user_helpers';
import { isCurrentlyManaging, getCurrentManager, hasPermission, getNotificationCountForManagesUser } from '../../Helpers/manager_helpers';
import { getAnnouncementByType } from '../../Helpers/announcement_helpers';
import { CREATOR_HOME_NAV, BRAND_HOME_NAV } from '../../Helpers/home_helpers';
import { getInitialsForUser } from '../../Helpers/formatting';
import { addEvent } from '../../APIClient/events';
import { getUserTierDisplay } from '../../Helpers/tier_helpers';
import { getBrandTierData } from '../../Helpers/brand_tier_helpers';
import ProfilePhoto from '../Profile/ProfilePhoto';
import { isAdminControlMode } from '../../Helpers/ui_helpers';

const SCROLL_BREAK = 40;

class Header extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    manager: PropTypes.object.isRequired,
    setShopMyLabel: PropTypes.func.isRequired,
    openAuthModal: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    changeSearchVal: PropTypes.func.isRequired,
    toggleBodyScrollLock: PropTypes.func.isRequired,
    selectUserToManage: PropTypes.func.isRequired,
    requireAuth: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      moreOptionsMenuOpen: false,
      manageUsersMenuOpen: false,

      // UI State
      isScrolled: window.pageYOffset > SCROLL_BREAK,
      isBackgroundElementBehindDivDark: this.getBackgroundColorBehindHeader(),

      // Modals
      showingInviteTalentModal: false
    };

    // Throttle the background color check
    this.throttledSetBackgroundColor = _.throttle(this.setBackgroundColorBehindHeader, 100);
    this.throttledScroll = _.throttle(this.setOffset, 50);
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.throttledScroll);
    window.addEventListener('scroll', this.throttledSetBackgroundColor);
    this.setOffset();
    this.setBackgroundColorBehindHeader();
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setOffset);
    window.removeEventListener('scroll', this.throttledSetBackgroundColor);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setBackgroundColorBehindHeader();
    }
  }
  setOffset = () => {
    const { isScrolled } = this.state;
    if ((isScrolled && window.pageYOffset < SCROLL_BREAK) || (!isScrolled && window.pageYOffset > SCROLL_BREAK)) {
      this.setState({ isScrolled: !isScrolled });
    }
  };

  setBackgroundColorBehindHeader = () => {
    const isBackgroundElementBehindDivDark = this.getBackgroundColorBehindHeader();
    if (isBackgroundElementBehindDivDark !== this.state.isBackgroundElementBehindDivDark) {
      this.setState({ isBackgroundElementBehindDivDark });
    }
  };

  getBackgroundColorBehindHeader = () => {
    /*
      Returns whether the background color behind the header is dark.

      This is used on pages where the header is transparent, and we want to change the color of the elements in the header
    */

    const hasTransparentBackground = this.hasTransparentBackground();
    if (!hasTransparentBackground) return false;

    const getBackgroundFromDiv = div => parseBackgroundColor(window.getComputedStyle(div)?.backgroundColor);
    const parseBackgroundColor = background => {
      const parsed = background.replace(/[^\d,]/g, '');
      const [r, g, b, a] = parsed.split(',');
      return {
        r: r ? parseInt(r) : null,
        g: g ? parseInt(g) : null,
        b: b ? parseInt(b) : null,
        a: a ? parseInt(a) : 1
      };
    };

    // Find the top element on the page that has a background
    const divs = document.querySelectorAll('.main-container-children div,span');
    const viableDivs = Array.from(divs).filter(div => {
      const rect = div.getBoundingClientRect();
      const straddlesNav = rect.top <= 0 && rect.bottom > 80; // Height of nav
      const isWideEnough = rect.width > window.innerWidth / 1.5;
      if (straddlesNav && isWideEnough) {
        const data = getBackgroundFromDiv(div);
        const hasBackground = data.a > 0;
        return hasBackground;
      }
      return false;
    });

    const divRightUnderHeader = _.orderBy(
      viableDivs,
      div => {
        let [el, level] = [div, 0];
        while (el) {
          el = el.parentElement;
          level++;
        }
        return level;
      },
      'desc'
    )[0];

    if (divRightUnderHeader) {
      const background = window.getComputedStyle(divRightUnderHeader)?.backgroundColor;
      const { r, g, b } = parseBackgroundColor(background);
      const average = (r + g + b) / 3;
      const isDark = average < 200;
      return isDark;
    }
    return false;
  };

  toggleMoreOptionsMenu = () => {
    const isOpening = !this.state.moreOptionsMenuOpen;
    this.setState({ moreOptionsMenuOpen: isOpening });
    isOpening && addEvent('Navigation - Open More Options Menu');
    isMobile && this.props.toggleBodyScrollLock(isOpening);
  };

  toggleManageUsersMenu = () => {
    const isOpening = !this.state.manageUsersMenuOpen;
    this.setState({ manageUsersMenuOpen: isOpening });
    isOpening && addEvent('Navigation - Open Manage Users Menu');
    isMobile && this.props.toggleBodyScrollLock(isOpening);
  };

  openInviteTalentModal = () => this.setState({ showingInviteTalentModal: true });
  closeInviteTalentModal = () => this.setState({ showingInviteTalentModal: false });

  clickLogo = () => {
    const scrollToTop = () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    if (isMobile && window.pageYOffset) {
      scrollToTop();
    } else {
      addEvent('Header - Navigate To Home Page');
      if (window.__IS_SMS__) {
        this.props.history.push('/home');
      } else {
        window.location.href = `${getRootSMSUrl()}/home`;
      }
    }
  };

  getMarketingTab = (label, to, extraClasses, subpages) => this.getTab(label, to, extraClasses, { useSMSRoot: true, subpages });
  getTab = (label, to, extraClasses = {}, extraOptions = {}) => {
    const { useSMSRoot, subpages, isNew, otherActiveEndpoints } = extraOptions || {};
    const toParts = _.filter(to.split('/'));
    const pathToConsider = window.location.pathname.split('/').slice(0, toParts.length + 1).join('/'); // prettier-ignore
    const active = pathToConsider === to || otherActiveEndpoints?.includes(pathToConsider);
    const click = e => {
      e.preventDefault();
      useSMSRoot && !window.__IS_SMS__ ? (window.location.href = `${getRootSMSUrl()}${to}`) : this.props.history.push(to);
      window.scrollTo(0, 0);
      addEvent('Navigation - Switch Tab', { tab: label, user: this.props.user });
    };
    return (
      <div className={cn('nav-tab', { active }, extraClasses)}>
        <Link to={to} onClick={click}>
          {label}
          {isNew && !active && <span className='new-badge'>NEW</span>}
        </Link>
        {!!subpages?.length && <FontAwesomeIcon icon={faChevronDown} className='chevron' />}
        {subpages?.length && <HeaderSubpagePanel subpages={subpages} />}
      </div>
    );
  };

  getButton = (label, onClick, extraClasses = {}) => {
    return (
      <button className={cn('nav-tab btn', extraClasses)} onClick={onClick}>
        {label}
      </button>
    );
  };

  getHeaderClasses = () => {};
  hasTransparentBackground = () => {
    const isHome = isHomePage();
    const isWelcome = isWelcomePage();
    const isPromote = isPromotePage();
    const isReferral = isReferralPage();
    return isHome || isWelcome || isPromote || isReferral;
  };

  render() {
    const { user, manager, isLoggedIn, ui } = this.props;
    const { isScrolled, moreOptionsMenuOpen, manageUsersMenuOpen, isBackgroundElementBehindDivDark } = this.state;
    if (isPublicPage() || isEmbedPage() || isOAuthPage() || window.__IS_APP__) return null;
    const isHome = isHomePage();
    const isWelcome = isWelcomePage();
    const isPromote = isPromotePage();
    const isReferral = isReferralPage();
    const hasCodes = !isBrand(user) && getCodes(user).length > 0;
    const hasGifting = !isBrand(user) && getRequests(user).length > 0;
    const hasOpportunityRequests = !isBrand(user) && getOpportunityRequests(user).length > 0;
    const hasCodesOrGifting = hasCodes || hasGifting;
    const hasTransparentBackground = isHome || isWelcome || isPromote || isReferral;
    const additionalClasses = {
      sms: window.__IS_SMS__,
      pro: window.__IS_PRO__,
      scrolled: isScrolled,

      // Specific to certain pages
      home: isHome,
      welcome: isWelcome,
      promote: isPromote,
      referral: isReferral,

      // General Coloring
      transparent: hasTransparentBackground,
      'white-elements': !hasTransparentBackground || isBackgroundElementBehindDivDark,
      'dark-elements': hasTransparentBackground && !isBackgroundElementBehindDivDark
    };
    const notificationCount = getNotificationCount(user, manager);
    const getHamburgerMenu = (extraClasses = {}) => {
      return (
        <button onClick={this.toggleMoreOptionsMenu} className={cn('nav-hamburger-wrapper', extraClasses, additionalClasses)}>
          <img alt='Menu Icon' className={cn('nav-hamburger', additionalClasses, { close: moreOptionsMenuOpen })} src={hamburgerMenu} />
          {!!notificationCount && (
            <div className={cn('notification-count', additionalClasses)}>
              <div className='count'>{notificationCount}</div>
            </div>
          )}
        </button>
      );
    };

    const showManageUsersMenu = managesUsers(user) || isCurrentlyManaging(manager);

    const getUserTierData = () => {
      const { user } = this.props;
      const brand = getBrand(user);
      const tierObj = brand ? brand.tier : getUserTier(user);

      if (!tierObj?.tier) return null;

      const tierLabel = brand ? getBrandTierData(tierObj.tier).label : getUserTierDisplay(tierObj.tier);

      return {
        ...tierObj,
        label: tierLabel
      };
    };

    const userTierData = getUserTierData();

    const getUserTierSummary = () => {
      const profile = getBrand(user) || getProfileFromObject(user);

      const handleNav = e => {
        e.preventDefault();

        if (isBrand(user)) {
          this.props.history.push('/report');
        } else {
          this.props.history.push('/settings');
        }
        window.scrollTo(0, 0);
      };

      if (isAdminControlMode(ui)) {
        return null;
      }

      if (!userTierData) return null;

      return (
        <div className='user-score-header' onClick={handleNav}>
          {!showManageUsersMenu && (
            <div className={cn('profile-img-wrapper', { brand: isBrand(user) })}>
              <ProfilePhoto ui={this.props.ui} profile={profile} basic={true} />
            </div>
          )}
          <div className='score-container'>
            <div className='label'>{userTierData.label}</div>
            <span>{Math.floor(userTierData.score)}/100</span>
          </div>
        </div>
      );
    };

    const getManageUsersMenu = (extraClasses = {}) => {
      if (!showManageUsersMenu) return null;
      const image = getImage(user);

      // Show Notification Count if manager account
      const managesUsers = getManages(getCurrentManager(manager) || user).map(m => m.user);
      const notificationCount = _.sum(_.map(managesUsers, u => getNotificationCountForManagesUser(u, manager)));
      return (
        <button onClick={this.toggleManageUsersMenu} className={cn('manage-user-menu-wrapper', extraClasses, additionalClasses)}>
          {image ? (
            <img alt='Current User' className={cn('profile-image', additionalClasses)} src={image} />
          ) : (
            <div className='initials'>{getInitialsForUser(user)}</div>
          )}
          {!!notificationCount && <div className='notification-count'>{notificationCount}</div>}
        </button>
      );
    };

    return (
      <div className={cn('nav-top', { ...additionalClasses })}>
        <div className={cn('nav-top-outer-container', additionalClasses)}>
          <div className={cn('before', additionalClasses)} />
          <div className={cn('background', additionalClasses)} />
          <div className={cn('nav-top-inner-container', additionalClasses)}>
            <div className='header-main-container'>
              <HeaderLogo
                clickLogo={this.clickLogo}
                ui={this.props.ui}
                setShopMyLabel={this.props.setShopMyLabel}
                additionalClasses={additionalClasses}
                isScrolled={isScrolled}
              />
            </div>
            <div className={cn('header-secondary-container', additionalClasses)}>
              <nav className='nav-tabs'>
                {!isLoggedIn ? (
                  isPromote ? (
                    <>
                      <div className={cn('nav-tab-group shrink-on-scroll', additionalClasses)}>
                        {this.getButton('Login', () => this.props.openAuthModal('login'), 'login show-on-large')}
                        {getHamburgerMenu()}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={cn('nav-tab-group main  shrink-on-scroll', additionalClasses)}>
                        {this.getMarketingTab('For Creators', `/home/creators`, 'show-on-large', CREATOR_HOME_NAV)}
                        {this.getMarketingTab('For Brands', `/home/brands`, 'show-on-large', BRAND_HOME_NAV)}
                      </div>
                      <div className={cn('nav-tab-group shrink-on-scroll', additionalClasses)}>
                        {this.getButton('Login', () => this.props.openAuthModal('login'), 'login show-on-large')}
                        {this.getButton(
                          window.location.href.includes('/brand') ? 'Request Demo' : 'Apply',
                          () => this.props.openAuthModal('apply-select-type'),
                          'apply'
                        )}
                        {getHamburgerMenu()}
                      </div>
                    </>
                  )
                ) : isShopifyUserRequiredForAppAcceptance(user) ? (
                  <div className={cn('nav-tab-group shrink-on-scroll', additionalClasses)}>
                    {this.getTab('Codes', `/codes`)}
                    {getHamburgerMenu()}
                  </div>
                ) : isBrand(user) ? (
                  <>
                    <div className={cn('nav-tab-group shrink-on-scroll', additionalClasses)}>
                      {this.getTab('Talent', `/talent`, 'show-on-large')}
                      {this.getTab('Discover', `/discover`, {}, { isNew: !!getAnnouncementByType(user, 'DISCOVER_2.0') })}
                      {this.getTab('Gifting', `/lookbooks`, 'show-on-large', {
                        isNew: !!getAnnouncementByType(user, 'LOOKBOOKS'),
                        otherActiveEndpoints: ['/requests', '/recommendations']
                      })}
                      {this.getTab('Opportunities', `/opportunities`, 'show-on-large', {
                        isNew: !!getAnnouncementByType(user, 'OPPORTUNITIES')
                      })}
                      {this.getTab('Analytics', `/links`, 'show-on-large')}
                      {this.getTab('Chat', `/chat`)}
                      {this.getTab('Mentions', `/mentions`, 'show-on-large', { isNew: !!getAnnouncementByType(user, 'SOCIAL_MENTIONS_FEED') })}
                      {/* {this.getTab('Collaborations', `/collaborations`, 'show-on-large')} */}
                      {/* {this.getButton('Invite', this.openInviteTalentModal, 'show-on-large invite')} */}
                      {getUserTierSummary()}
                      {getHamburgerMenu()}
                    </div>
                  </>
                ) : isManager(user) ? (
                  <>
                    <div className={cn('nav-tab-group shrink-on-scroll', additionalClasses)}>
                      {this.getTab('Invite Talent', `/invite/${getUsername(user)}`)}
                      {this.getTab('Account', `/settings`)}
                      {getManageUsersMenu()}
                      {getHamburgerMenu()}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={cn('nav-tab-group shrink-on-scroll', additionalClasses)}>
                      {hasPermission(user, manager, 'canEditShop') && this.getTab('My Shop', `/${getUsername(user)}`)}
                      {hasPermission(user, manager, 'canCreateLinks') && this.getTab('Links', `/links`)}
                      {hasPermission(user, manager, 'canAcceptGifting') &&
                        hasCodesOrGifting &&
                        this.getTab(
                          hasGifting && hasCodes ? 'Gifting & Codes' : hasGifting ? 'Gifting' : 'Codes',
                          `/partners/gifting`,
                          'show-on-large'
                        )}
                      {hasPermission(user, manager, 'canAnswerOpportunities') &&
                        hasOpportunityRequests &&
                        this.getTab('Opportunities', `/partners/opportunities`, 'show-on-large', {
                          disabledNonExactMatches: true
                        })}
                      {hasPermission(user, manager, 'canViewEarnings') && this.getTab('Earnings', `/payouts`, 'show-on-large')}
                      {this.getTab('Latest', `/latest`, 'show-on-large')}
                      {getManageUsersMenu()}
                      {getUserTierSummary()}
                      {getHamburgerMenu()}
                    </div>
                  </>
                )}
              </nav>
            </div>
            {showManageUsersMenu && (
              <ManageUsersMenu
                user={user}
                manager={manager}
                isScrolled={isScrolled}
                active={manageUsersMenuOpen}
                close={this.toggleManageUsersMenu}
                selectUserToManage={this.props.selectUserToManage}
              />
            )}
            <MoreOptionsMenu
              isLoggedIn={isLoggedIn}
              user={user}
              ui={this.props.ui}
              manager={manager}
              isScrolled={isScrolled}
              active={moreOptionsMenuOpen}
              openAuthModal={this.props.openAuthModal}
              logoutUser={this.props.logoutUser}
              close={this.toggleMoreOptionsMenu}
              openInviteTalentModal={this.openInviteTalentModal}
            />
            {this.state.showingInviteTalentModal && <InviteTalentModal closeModal={this.closeInviteTalentModal} />}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
