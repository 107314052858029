import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './PartnerPortal.scss';

import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { updateRequest } from '../../Actions/UserActions';
import { openAddressModal } from '../../Actions/UIActions';

import { getCodes, getRequests, isBrand } from '../../Helpers/user_helpers';
import { insertMetaTags } from '../../Helpers/seo_helpers';
import { hasRequestExpired } from '../../Helpers/gifting_helpers';
import PartnerPortalControls from '../../Components/PartnerPortal/PartnerPortalControls';
import PartnerPortalCodes from '../../Components/PartnerPortal/PartnerPortalCodes';
import PartnerPortalRequests from '../../Components/PartnerPortal/PartnerPortalRequests';
import PartnerPortalOpportunities from '../../Components/PartnerPortal/PartnerPortalOpportunities';
import PartnerPortalRequestModal from '../../Components/PartnerPortal/PartnerPortalRequestModal';

const PartnerPortal = props => {
  const { user, updateRequest, openAddressModal } = props;

  const history = useHistory();
  const params = useParams();
  const curTab = params.tab || 'gifting';
  const changeTab = tab => history.push(`/partners/${tab}`);

  const giftingRequests = getRequests(user);
  const discountCodes = getCodes(user);

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [curSearchVal, setCurSearchVal] = useState('');
  const resetSearchAndFilters = () => {
    setSelectedFilter(null);
    setCurSearchVal('');
  };

  const filterOptions = [
    { filter: () => true, label: 'Show All', count: giftingRequests.length },
    { filter: r => r.Lookbook_id, label: 'With Lookbooks', count: giftingRequests.filter(r => r.Lookbook_id).length },
    {
      filter: r => !r.userRejected && !r.userAccepted && r.brandAccepted,
      label: 'Awaiting Response',
      count: giftingRequests.filter(r => !r.userRejected && !r.userAccepted && r.brandAccepted).length
    },
    { filter: r => r.userRejected, label: 'Declined', count: giftingRequests.filter(r => r.userRejected).length },
    {
      filter: r => !r.isComplete && r.userAccepted && r.brandAccepted,
      label: 'Accepted, Pending Send',
      count: giftingRequests.filter(r => !r.isComplete && r.userAccepted && r.brandAccepted).length
    },
    {
      filter: r => hasRequestExpired(r),
      label: 'Expired',
      count: giftingRequests.filter(r => hasRequestExpired(r)).length
    },
    { filter: r => r.isComplete, label: 'Sent', count: giftingRequests.filter(r => r.isComplete).length }
  ];

  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const openRequestModal = request => setSelectedRequestId(request.id);
  const closeRequestModal = () => setSelectedRequestId(null);

  const getHeaderTitle = () => {
    switch (curTab) {
      case 'gifting':
        return 'My Gifting Requests';
      case 'codes':
        return 'My Codes';
      case 'opportunities':
        return 'My Opportunities';
      case 'samples':
        return 'Free Samples';
      case 'lookbooks':
        return 'My Lookbooks';
      default:
        return 'Partner Portal';
    }
  };

  if (isBrand(user)) history.push('/requests');
  return (
    <div className='partner-portal-outer'>
      {insertMetaTags({
        title: getHeaderTitle(),
        description: '',
        image: ''
      })}
      <div className='partner-portal-inner'>
        <div className='partner-portal-header'>{getHeaderTitle()}</div>
        {selectedRequestId && (
          <PartnerPortalRequestModal
            request={giftingRequests.find(r => r.id === selectedRequestId)}
            close={closeRequestModal}
            openAddressModal={openAddressModal}
            updateRequest={updateRequest}
            user={user}
          />
        )}
        <PartnerPortalControls
          user={user}
          tab={curTab}
          changeTab={changeTab}
          curSearchVal={curSearchVal}
          setCurSearchVal={setCurSearchVal}
          setSelectedFilter={setSelectedFilter}
          selectedFilter={selectedFilter}
          giftingRequests={giftingRequests}
          discountCodes={discountCodes}
          filterOptions={filterOptions}
        />
        {curTab === 'gifting' && (
          <PartnerPortalRequests
            giftingRequests={giftingRequests}
            selectedFilter={selectedFilter}
            curSearchVal={curSearchVal}
            resetSearchAndFilters={resetSearchAndFilters}
            openRequestModal={openRequestModal}
            updateRequest={updateRequest}
          />
        )}
        {curTab === 'codes' && <PartnerPortalCodes discountCodes={discountCodes} curSearchVal={curSearchVal} />}
        {curTab === 'opportunities' && <PartnerPortalOpportunities />}
      </div>
    </div>
  );
};

PartnerPortal.propTypes = {
  user: PropTypes.object.isRequired,
  updateRequest: PropTypes.func.isRequired,
  openAddressModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  updateRequest,
  openAddressModal
})(PartnerPortal);
