import React from 'react';
import commaNumber from 'comma-number';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTimes } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import './OpportunityPlanHeader.scss';

import { getBrand } from '../../../Helpers/user_helpers';

import ConfirmPrompt from '../../General/ConfirmPrompt';

const OpportunityPlanHeader = props => {
  const { user, opportunity, plans, activePlan } = props;
  const { users } = activePlan;

  const [showingFullSummary, setShowingFullSummary] = React.useState(false);
  const toggleFullSummary = () => setShowingFullSummary(!showingFullSummary);

  let askDisplays = [];
  if (activePlan.linksExpected) askDisplays.push(`${activePlan.linksExpected} links`);
  if (activePlan.linkingDaysExpected) askDisplays.push(`${activePlan.linkingDaysExpected} days linking`);
  if (activePlan.mentionsExpected) askDisplays.push(`${activePlan.mentionsExpected} mentions`);
  if (activePlan.mentionDaysExpected) askDisplays.push(`${activePlan.mentionDaysExpected} days mentioning`);

  let timeDisplay;
  const trackingStart = opportunity.trackingStartsAt && moment(opportunity.trackingStartsAt).format('MMM D');
  const trackingEnd = opportunity.trackingEndsAt && moment(opportunity.trackingEndsAt).format('MMM D');
  if (trackingStart === trackingEnd) timeDisplay = trackingStart;
  else timeDisplay = `${trackingStart} - ${trackingEnd}`;

  // Estimate ROI
  const primaryUsers = users.filter(u => !u.isBackup);
  const budget = _.sumBy(primaryUsers, 'recommendedFixedFee');
  const volExpected = _.sumBy(primaryUsers, u => u.vol_per_link * activePlan.linksExpected);

  let projectedReturn;
  if (volExpected) {
    projectedReturn = `$${commaNumber(volExpected)}`;
  }

  const editPlanTitle = plan => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Title This Plan.'
          onCancel={onClose}
          customInputFields={[{ preloaded: plan.title, placeholder: 'Plan Title', value: 'title', isSingleLine: true }]}
          onSubmit={resp => {
            props.updateOpportunityPlan(plan, { title: resp.title });
          }}
        />
      )
    });
  };

  const startNewCustomPlan = async () => {
    await props.createOpportunityPlan({
      Opportunity_id: opportunity.id,
      title: 'New Plan',
      linksExpected: opportunity.linksExpected || 0,
      linkingDaysExpected: opportunity.linkingDaysExpected || 0,
      mentionDaysExpected: opportunity.mentionDaysExpected || 0,
      mentionsExpected: opportunity.mentionsExpected || 0
    });
  };

  const editExpectations = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Set Expectations'
          onCancel={onClose}
          customInputFields={[
            {
              display: 'Links',
              placeholder: '# Links Expected',
              value: 'linksExpected',
              isSingleLine: true,
              preloaded: activePlan.linksExpected || ''
            },
            {
              display: 'Mentions',
              placeholder: '# Mentions Expected',
              value: 'mentionsExpected',
              isSingleLine: true,
              preloaded: activePlan.mentionsExpected || ''
            }
          ]}
          onSubmit={responseValues => {
            props.updateOpportunityPlan(activePlan, {
              linksExpected: +responseValues.linksExpected || 0,
              mentionsExpected: +responseValues.mentionsExpected || 0,
              linkingDaysExpected: 0, // Don't allow for these on plans for now
              mentionDaysExpected: 0 // Don't allow for these on plans for now
            });
          }}
        />
      )
    });
  };

  return (
    <div className='opportunity-plan-header'>
      <div className='active-plan'>
        <div className='main'>
          <div className='summary-container'>
            <div className='summary-title'>Summary</div>
            <div onClick={toggleFullSummary} className={cn('summary', { 'showing-full-summary': showingFullSummary })}>
              See below for the full plan.
            </div>
          </div>
          <div className='stat-containers'>
            <div className='stat-container'>
              <div className='stat-label'>Budget</div>
              <div className='stat-value'>${commaNumber(budget)}</div>
            </div>
            <div className='stat-container'>
              <div className='stat-label'>Creators</div>
              <div className='stat-value'>{_.filter(users, u => !u.isBackup).length}</div>
            </div>
            <div onClick={editExpectations} className='stat-container'>
              <div className='stat-label clickable'>
                Expectations
                <FontAwesomeIcon icon={faPencil} />
              </div>
              <div className='stat-value small'>{askDisplays.join(', ')}</div>
            </div>
            {projectedReturn ? (
              <div className='stat-container'>
                <div className='stat-label'>Projected Return</div>
                <div className='stat-value small'>{projectedReturn}</div>
              </div>
            ) : (
              <div className='stat-container'>
                <div className='stat-label'>The Timing</div>
                <div className='stat-value small'>{timeDisplay}</div>
              </div>
            )}
          </div>
        </div>
        <div className='plan-tabs'>
          {plans.map((plan, idx) => {
            const isActive = plan.id === activePlan.id;
            const select = () => (isActive ? editPlanTitle(plan) : props.setActivePlan(plan));
            const remove = e => {
              e.stopPropagation();
              props.removePlan(plan);
            };
            return (
              <div onClick={select} key={plan.id} className={cn('plan-tab', { active: isActive })}>
                <span className='plan-label'>
                  {`Plan ${idx + 1}`}
                  {plan.title ? ':' : ''}
                </span>
                {plan.title && <span className='title'>{plan.title}</span>}
                {isActive && (
                  <div className='actions'>
                    <FontAwesomeIcon icon={faPencil} />
                  </div>
                )}
                <div className='remove-icon' onClick={remove}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            );
          })}
          <div className='plan-tab add-plan' onClick={startNewCustomPlan}>
            <span className='plan-label'>New Custom Plan</span>
          </div>
          <div className='plan-tab add-plan' onClick={props.openNewPlanModal}>
            <span className='plan-label'>New Smart Plan</span>
          </div>
        </div>
        <div className='background-fade'>
          <img className='background-cover-img' src={getBrand(user)?.mobileCoverImage} alt='Brand' />
          <div className='background-gradient' />
        </div>
      </div>
    </div>
  );
};

OpportunityPlanHeader.propTypes = {
  user: PropTypes.object.isRequired,
  plans: PropTypes.array.isRequired,
  activePlan: PropTypes.object.isRequired,
  setActivePlan: PropTypes.func.isRequired,
  openNewPlanModal: PropTypes.func.isRequired,
  updateOpportunityPlan: PropTypes.func.isRequired,
  removePlan: PropTypes.func.isRequired
};

export default OpportunityPlanHeader;
