import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import './BulkTalentModalSidebar.scss';

import BulkTalentModalTalent from './Elements/BulkTalentModalTalent';
import SelectOption from '../General/SelectOption';

import { getBrandLists } from '../../Helpers/user_helpers';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import InviteTalentOffPlatformModal from './InviteTalentOffPlatformModal';

const BulkTalentModalSidebar = props => {
  const { user, allTalent, selectedTalent, talentStatuses, outreachType, addNewTalentOffPlatform } = props;

  const [performanceImprovementForLongSelect, setPerformanceImprovementForLongSelect] = useState(false);
  const [inviteTalentOffPlatformModalOpen, setInviteTalentOffPlatformModalOpen] = useState(false);
  const openInviteTalentOffPlatformModal = () => setInviteTalentOffPlatformModalOpen(true);
  const closeInviteTalentOffPlatformModal = () => setInviteTalentOffPlatformModalOpen(false);

  const inviteOffTalentCallback = allTalentToAdd => {
    addNewTalentOffPlatform(allTalentToAdd);
    closeInviteTalentOffPlatformModal();
  };

  const selectNewTalentToAdd = data => props.selectNewTalent(data.value);
  const selectListToAdd = data => props.selectListToAdd(data.value);
  const brandCanInviteTalentOffPlatform = outreachType.allowOffPlatformInvites;

  const selectedTalentIds = new Set(selectedTalent.map(t => t.id));
  const talentOptions = _.orderBy(allTalent, 'name').map(user => ({
    value: user,
    label: user.name,
    disabled: selectedTalentIds.has(user.id)
  }));

  const lists = getBrandLists(user);
  const listOptions = _.orderBy(lists, 'createdAt', 'desc').map(list => ({
    value: list,
    label: list.title,
    sublabels: [`${list.users.length} member${list.users.length === 1 ? '' : 's'}`, `Created ${moment(list.createdAt).format('MMM Do, YY')}`],
    disabled: false
  }));

  const hasActions = !!selectedTalent.length;
  const numTalentWithExistingChats = selectedTalent.filter(t => t.has_chatted).length;
  const numTalentWithRequests = selectedTalent.filter(t => t.has_request).length;
  return (
    <div className='bulk-talent-modal-sidebar'>
      <div className='header-controls'>
        <Select
          unstyled
          placeholder='Search Talent'
          classNamePrefix='search-select'
          onChange={selectNewTalentToAdd}
          className='search-select'
          isOptionDisabled={option => option.disabled}
          options={talentOptions}
          value={null}
          onInputChange={val => setPerformanceImprovementForLongSelect(val.length)}
          {...(talentOptions.length > 150 ? { menuIsOpen: performanceImprovementForLongSelect >= 2 } : {})}
          components={{ Option: SelectOption }}
        />
        <Select
          unstyled
          placeholder='From List'
          classNamePrefix='list-select'
          onChange={selectListToAdd}
          className='list-select'
          isOptionDisabled={option => option.disabled}
          options={listOptions}
          value={null}
          components={{ Option: SelectOption }}
        />
      </div>
      {hasActions && (
        <div className='additional-actions-container'>
          <div className='clear-talent-hover'>
            Clear Talent <FontAwesomeIcon icon={faChevronDown} />
          </div>
          <div className='additional-actions-overlay'>
            <div className='additional-actions'>
              <div onClick={props.removeAllTalent} className={cn('additional-action', { disabled: !selectedTalent.length })}>
                Clear All Talent ({selectedTalent.length})
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div onClick={props.removeAllTalentWithChats} className={cn('additional-action', { disabled: !numTalentWithExistingChats })}>
                Clear Talent With Existing Chats ({numTalentWithExistingChats})
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div onClick={props.removeAllTalentWithRequests} className={cn('additional-action', { disabled: !numTalentWithRequests })}>
                Clear Talent With Gifting Requests ({numTalentWithRequests})
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='talent-results'>
        {!selectedTalent.length ? (
          <div className='empty-results'>
            {props.isFetchingTalent ? (
              <div className='message'>Getting Talent Details...</div>
            ) : (
              <div className='message'>Please search talent above or add from a list</div>
            )}
          </div>
        ) : (
          selectedTalent?.map(talent => {
            const status = talentStatuses[talent.id || talent.email];
            return (
              <BulkTalentModalTalent
                key={talent?.id || talent?.email}
                user={user}
                talent={talent}
                analytics={props.analytics}
                status={status}
                removeTalentById={props.removeTalentById}
                removeTalentByOffPlatformEmail={props.removeTalentByOffPlatformEmail}
                outreachType={outreachType}
                openArtistModal={props.openArtistModal}
                openChatOverlay={props.openChatOverlay}
              />
            );
          })
        )}
        {brandCanInviteTalentOffPlatform && (
          <div className='invite-off-platform-talent' onClick={openInviteTalentOffPlatformModal}>
            <span className='message'>
              <FontAwesomeIcon icon={faPlus} />
              Invite Talent Not On Shopmy
            </span>
          </div>
        )}
      </div>
      {inviteTalentOffPlatformModalOpen && (
        <InviteTalentOffPlatformModal close={closeInviteTalentOffPlatformModal} saveCallback={inviteOffTalentCallback} />
      )}
    </div>
  );
};

BulkTalentModalSidebar.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  allTalent: PropTypes.array.isRequired,
  isFetchingTalent: PropTypes.bool.isRequired,
  selectedTalent: PropTypes.array.isRequired,
  removeTalentById: PropTypes.func.isRequired,
  removeTalentByOffPlatformEmail: PropTypes.func.isRequired,
  selectNewTalent: PropTypes.func.isRequired,
  selectListToAdd: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  outreachType: PropTypes.object.isRequired,
  addNewTalentOffPlatform: PropTypes.func.isRequired
};

export default BulkTalentModalSidebar;
