import _ from 'lodash';

import {
  getInvoicesSummary,
  getContractInvoices,
  getBudgetInvoices,
  updateBrand as updateBrandAPI,
  updateBrandSettings as updateBrandSettingsAPI,
  refreshBrandTier as refreshBrandTierAPI
} from '../APIClient/brands';
import { getBrandBudget as getBrandBudgetAPI } from '../APIClient/budgets';
import {
  getBrandLists as getBrandListsAPI,
  addBrandList as addBrandListAPI,
  updateBrandList as updateBrandListAPI,
  deleteBrandList as deleteBrandListAPI,
  addBrandListUser as addBrandListUserAPI,
  updateBrandListUser as updateBrandListUserAPI,
  deleteBrandListUser as deleteBrandListUserAPI
} from '../APIClient/lists';
import { syncShopifyScopesForBrandId as syncShopifyScopesForBrandIdAPI } from '../APIClient/shopify';
import { saveShopifyIntegration as saveShopifyIntegrationAPI, updateBrandIntegration as updateBrandIntegrationAPI } from '../APIClient/integrations';
import { addBlacklistedCode as addBlacklistedCodeAPI, deleteBlacklistedCode as deleteBlacklistedCodeAPI } from '../APIClient/blacklisted_codes';
import { updateInventoryLocations as updateInventoryLocationsAPI } from '../APIClient/inventory_locations';
import {
  updateCompetitorBrand as updateCompetitorBrandAPI,
  addCompetitorBrand as addCompetitorBrandAPI,
  deleteCompetitorBrand as deleteCompetitorBrandAPI
} from '../APIClient/competitor_brands';

import { getBrandId, getBrandSettings } from '../Helpers/user_helpers';

export const BRAND_ACTION_DISMISS = 'BRAND_ACTION_DISMISS';

export const GET_INVOICES_SUMMARY_SUCCESS = 'GET_INVOICES_SUMMARY_SUCCESS';
export const GET_INVOICES_SUMMARY_ERROR = 'GET_INVOICES_SUMMARY_ERROR';

export const GET_ONBOARDING_DATA_SUCCESS = 'GET_ONBOARDING_DATA_SUCCESS';
export const GET_ONBOARDING_DATA_ERROR = 'GET_ONBOARDING_DATA_ERROR';

export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_ERROR = 'UPDATE_BRAND_ERROR';

export const UPDATE_BRAND_SETTINGS_SUCCESS = 'UPDATE_BRAND_SETTINGS_SUCCESS';
export const UPDATE_BRAND_SETTINGS_ERROR = 'UPDATE_BRAND_SETTINGS_ERROR';

export const AUGMENT_BRAND_LISTS_SUCCESS = 'AUGMENT_BRAND_LISTS_SUCCESS';
export const AUGMENT_BRAND_LISTS_ERROR = 'AUGMENT_BRAND_LISTS_ERROR';

export const ADD_BRAND_LIST_SUCCESS = 'ADD_BRAND_LIST_SUCCESS';
export const ADD_BRAND_LIST_ERROR = 'ADD_BRAND_LIST_ERROR';

export const DELETE_BRAND_LIST_REQUEST = 'DELETE_BRAND_LIST_REQUEST';
export const DELETE_BRAND_LIST_SUCCESS = 'DELETE_BRAND_LIST_SUCCESS';
export const DELETE_BRAND_LIST_ERROR = 'DELETE_BRAND_LIST_ERROR';

export const UPDATE_BRAND_LIST_REQUEST = 'UPDATE_BRAND_LIST_REQUEST';
export const UPDATE_BRAND_LIST_SUCCESS = 'UPDATE_BRAND_LIST_SUCCESS';
export const UPDATE_BRAND_LIST_ERROR = 'UPDATE_BRAND_LIST_ERROR';

export const UPDATE_BRAND_LIST_USER_REQUEST = 'UPDATE_BRAND_LIST_USER_REQUEST';
export const UPDATE_BRAND_LIST_USER_SUCCESS = 'UPDATE_BRAND_LIST_USER_SUCCESS';
export const UPDATE_BRAND_LIST_USER_ERROR = 'UPDATE_BRAND_LIST_USER_ERROR';

export const ADD_BRAND_LIST_USER_SUCCESS = 'ADD_BRAND_LIST_USER_SUCCESS';
export const ADD_BRAND_LIST_USER_ERROR = 'ADD_BRAND_LIST_USER_ERROR';

export const DELETE_BRAND_LIST_USER_REQUEST = 'DELETE_BRAND_LIST_USER_REQUEST';
export const DELETE_BRAND_LIST_USER_SUCCESS = 'DELETE_BRAND_LIST_USER_SUCCESS';
export const DELETE_BRAND_LIST_USER_ERROR = 'DELETE_BRAND_LIST_USER_ERROR';

export const ADD_BLACKLISTED_CODE_SUCCESS = 'ADD_BLACKLISTED_CODE_SUCCESS';
export const ADD_BLACKLISTED_CODE_FAILURE = 'ADD_BLACKLISTED_CODE_FAILURE';
export const DELETE_BLACKLISTED_CODE = 'DELETE_BLACKLISTED_CODE';
export const DELETE_BLACKLISTED_CODE_SUCCESS = 'DELETE_BLACKLISTED_CODE_SUCCESS';
export const DELETE_BLACKLISTED_CODE_FAILURE = 'DELETE_BLACKLISTED_CODE_FAILURE';

export const SAVE_SHOPIFY_INTEGRATION_SUCCESS = 'SAVE_SHOPIFY_INTEGRATION_SUCCESS';
export const UPDATE_SHOPIFY_INTEGRATION_SUCCESS = 'UPDATE_SHOPIFY_INTEGRATION_SUCCESS';
export const UPDATE_SHOPIFY_INTEGRATION_ERROR = 'SAVE_SHOPIFY_INTEGRATION_ERROR';

export const SYNC_SHOPIFY_SCOPES_SUCCESS = 'SYNC_SHOPIFY_SCOPES_SUCCESS';
export const SYNC_SHOPIFY_SCOPES_ERROR = 'SYNC_SHOPIFY_SCOPES_ERROR';

export const UPDATE_SHOPIFY_INVENTORY_LOCATIONS_SUCCESS = 'UPDATE_SHOPIFY_INVENTORY_LOCATIONS_SUCCESS';
export const UPDATE_SHOPIFY_INVENTORY_LOCATIONS_ERROR = 'UPDATE_SHOPIFY_INVENTORY_LOCATIONS_ERROR';

export const REFRESH_BRAND_TIER_SUCCESS = 'REFRESH_BRAND_TIER_SUCCESS';
export const REFRESH_BRAND_TIER_ERROR = 'REFRESH_BRAND_TIER_ERROR';

export const SYNC_BRAND_BUDGET_SUCCESS = 'SYNC_BRAND_BUDGET_SUCCESS';
export const SYNC_BRAND_BUDGET_ERROR = 'SYNC_BRAND_BUDGET_ERROR';

export const ADD_COMPETITOR_BRAND_SUCCESS = 'ADD_COMPETITOR_BRAND_SUCCESS';
export const ADD_COMPETITOR_BRAND_ERROR = 'ADD_COMPETITOR_BRAND_ERROR';

export const UPDATE_COMPETITOR_BRAND_REQUEST = 'UPDATE_COMPETITOR_BRAND_REQUEST';
export const UPDATE_COMPETITOR_BRAND_SUCCESS = 'UPDATE_COMPETITOR_BRAND_SUCCESS';
export const UPDATE_COMPETITOR_BRAND_ERROR = 'UPDATE_COMPETITOR_BRAND_ERROR';

export const DELETE_COMPETITOR_BRAND_REQUEST = 'DELETE_COMPETITOR_BRAND_REQUEST';
export const DELETE_COMPETITOR_BRAND_SUCCESS = 'DELETE_COMPETITOR_BRAND_SUCCESS';
export const DELETE_COMPETITOR_BRAND_ERROR = 'DELETE_COMPETITOR_BRAND_ERROR';

export const getBrandInvoices = user => async (dispatch, getState) => {
  const resp = await getInvoicesSummary(user);
  const contractInvoices = await getContractInvoices(user);
  const budgetInvoices = await getBudgetInvoices(user);
  if (resp.invoices) {
    return dispatch({
      type: GET_INVOICES_SUMMARY_SUCCESS,
      invoices: _.get(resp, 'invoices', []),
      contractInvoices,
      budgetInvoices,
      summary: _.get(resp, 'summary', {})
    });
  } else {
    return dispatch({
      type: GET_INVOICES_SUMMARY_ERROR,
      error: resp.error
    });
  }
};

export const updateBrand = (brand, updates) => async (dispatch, getState) => {
  try {
    const resp = await updateBrandAPI(brand, updates);
    return dispatch({
      type: UPDATE_BRAND_SUCCESS,
      brand: resp
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_BRAND_ERROR,
      error: error
    });
  }
};

export const updateBrandSettings = updates => async (dispatch, getState) => {
  try {
    const settings = getBrandSettings(getState().user);
    const resp = await updateBrandSettingsAPI(settings, updates);
    return dispatch({
      type: UPDATE_BRAND_SETTINGS_SUCCESS,
      settings: resp
    });
  } catch (error) {
    console.error(error);
    return dispatch({
      type: UPDATE_BRAND_SETTINGS_ERROR,
      error: error
    });
  }
};

export const addBlacklistedCode = (brand, code) => async (dispatch, getState) => {
  try {
    const data = { Brand_id: brand.id, code };
    const newCode = await addBlacklistedCodeAPI(data);
    dispatch({ type: ADD_BLACKLISTED_CODE_SUCCESS, code: newCode });
  } catch (error) {
    dispatch({ type: ADD_BLACKLISTED_CODE_FAILURE, rawCode: code, error });
  }
};

export const deleteBlacklistedCode = code => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_BLACKLISTED_CODE, code });
    await deleteBlacklistedCodeAPI(code.id);
    dispatch({ type: DELETE_BLACKLISTED_CODE_SUCCESS, code });
  } catch (error) {
    dispatch({ type: DELETE_BLACKLISTED_CODE_FAILURE, code, error });
  }
};

export const saveShopifyIntegration = (brand, config) => async (dispatch, getState) => {
  const data = { Brand_id: brand.id, ...config };
  const resp = await saveShopifyIntegrationAPI(data);

  return dispatch({
    type: SAVE_SHOPIFY_INTEGRATION_SUCCESS,
    shopifyIntegration: resp.shopifyIntegration
  });
};

export const updateBrandIntegration = (integrationId, updates) => async (dispatch, getState) => {
  try {
    const updated_integration = await updateBrandIntegrationAPI(integrationId, updates);
    window.ALERT.success('Integration updated');
    return dispatch({
      type: UPDATE_SHOPIFY_INTEGRATION_SUCCESS,
      integration: updated_integration
    });
  } catch (error) {
    if (_.isString(error) && error.toLowerCase().includes('missing scopes')) {
      return window.ALERT.error(
        'Please add the following Shopify permission scopes to enable this option: ' + error.replace('Missing scopes: ', ''),
        { hideAfter: 15 }
      );
    }

    return window.ALERT.error('Error updating integration');
  }
};

export const syncShopifyScopesForBrandId = Brand_id => async (dispatch, getState) => {
  try {
    const resp = await syncShopifyScopesForBrandIdAPI(Brand_id);
    return dispatch({
      type: SYNC_SHOPIFY_SCOPES_SUCCESS,
      integrations: resp.integrations
    });
  } catch (error) {
    return dispatch({
      type: SYNC_SHOPIFY_SCOPES_ERROR,
      error: error
    });
  }
};

export const updateBrandInventoryLocations = (integrationId, locationIds) => async (dispatch, getState) => {
  try {
    const data = {
      BrandIntegration_id: integrationId,
      locationIds
    };
    const resp = await updateInventoryLocationsAPI(data);
    window.ALERT.success('Inventory locations updated');

    return dispatch({
      type: UPDATE_SHOPIFY_INVENTORY_LOCATIONS_SUCCESS,
      inventoryLocationData: resp
    });
  } catch (error) {
    console.error('Error updating brand inventory locations: ', error);

    return dispatch({
      error,
      type: UPDATE_SHOPIFY_INVENTORY_LOCATIONS_ERROR
    });
  }
};

export const augmentBrandLists = () => async (dispatch, getState) => {
  /*
    Used by the brand lists overlay to get more metadata such as the last added user image.
  */
  try {
    const resp = await getBrandListsAPI(getBrandId(getState().user));
    return dispatch({
      type: AUGMENT_BRAND_LISTS_SUCCESS,
      lists: resp.lists,
      discoverListPreviews: resp.discoverListPreviews
    });
  } catch (error) {
    return dispatch({
      type: AUGMENT_BRAND_LISTS_ERROR,
      error: error
    });
  }
};

export const addBrandList = title => async (dispatch, getState) => {
  try {
    const resp = await addBrandListAPI({
      title,
      Brand_id: getBrandId(getState().user),
      isVisibleOnTalentTab: true
    });
    return dispatch({
      type: ADD_BRAND_LIST_SUCCESS,
      list: resp
    });
  } catch (error) {
    return dispatch({
      type: ADD_BRAND_LIST_ERROR,
      error: error
    });
  }
};

export const updateBrandList = (list, updates) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_BRAND_LIST_REQUEST,
      list,
      updates
    });
    const resp = await updateBrandListAPI(list, updates);
    return dispatch({
      type: UPDATE_BRAND_LIST_SUCCESS,
      list: resp
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_BRAND_LIST_ERROR,
      list,
      error: error
    });
  }
};

export const deleteBrandList = list => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_BRAND_LIST_REQUEST,
      list
    });
    await deleteBrandListAPI(list);
    return dispatch({
      type: DELETE_BRAND_LIST_SUCCESS,
      list
    });
  } catch (error) {
    return dispatch({
      type: DELETE_BRAND_LIST_ERROR,
      error: error
    });
  }
};

export const addBrandListUser = (list, User_id) => async (dispatch, getState) => {
  try {
    const listUser = await addBrandListUserAPI({ BrandList_id: list.id, User_id });
    return dispatch({
      type: ADD_BRAND_LIST_USER_SUCCESS,
      listUser
    });
  } catch (error) {
    return dispatch({
      type: ADD_BRAND_LIST_USER_ERROR,
      error: error
    });
  }
};

export const updateBrandListUser = (listUser, updates) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_BRAND_LIST_USER_REQUEST,
      listUser,
      updates
    });
    const newListUser = await updateBrandListUserAPI(listUser, updates);
    return dispatch({
      type: UPDATE_BRAND_LIST_USER_SUCCESS,
      listUser: newListUser
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_BRAND_LIST_USER_ERROR,
      listUser,
      error: error
    });
  }
};

export const deleteBrandListUser = listUser => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_BRAND_LIST_USER_REQUEST,
      listUser
    });
    await deleteBrandListUserAPI(listUser);
    return dispatch({
      type: DELETE_BRAND_LIST_USER_SUCCESS,
      listUser
    });
  } catch (error) {
    return dispatch({
      type: DELETE_BRAND_LIST_USER_ERROR,
      error: error,
      listUser
    });
  }
};

export const refreshBrandTier = () => async (dispatch, getState) => {
  try {
    const Brand_id = getBrandId(getState().user);
    const resp = await refreshBrandTierAPI(Brand_id);
    return dispatch({
      type: REFRESH_BRAND_TIER_SUCCESS,
      tier: resp.tier
    });
  } catch (error) {
    return dispatch({
      type: REFRESH_BRAND_TIER_ERROR,
      error: error
    });
  }
};

export const syncBrandBudget = () => async (dispatch, getState) => {
  try {
    const Brand_id = getBrandId(getState().user);
    const resp = await getBrandBudgetAPI(Brand_id);
    const invoices = await getBudgetInvoices(getState().user);

    return dispatch({
      type: SYNC_BRAND_BUDGET_SUCCESS,
      budget: resp.budget,
      invoices
    });
  } catch (error) {
    return dispatch({
      type: SYNC_BRAND_BUDGET_ERROR,
      error: error
    });
  }
};

export const addCompetitorBrand = competitorBrand => async dispatch => {
  try {
    const resp = await addCompetitorBrandAPI(competitorBrand);
    return dispatch({
      type: ADD_COMPETITOR_BRAND_SUCCESS,
      competitorBrand: resp
    });
  } catch (error) {
    return dispatch({
      type: ADD_COMPETITOR_BRAND_ERROR,
      error: error
    });
  }
};

export const updateCompetitorBrand = (competitorBrand, updates) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_COMPETITOR_BRAND_REQUEST,
      competitorBrand,
      updates
    });

    const updatedCompetitorBrand = await updateCompetitorBrandAPI(competitorBrand, updates);
    return dispatch({
      type: UPDATE_COMPETITOR_BRAND_SUCCESS,
      competitorBrand: updatedCompetitorBrand
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_COMPETITOR_BRAND_ERROR,
      error: error,
      competitorBrand
    });
  }
};

export const deleteCompetitorBrand = competitorBrand => async dispatch => {
  try {
    dispatch({
      type: DELETE_COMPETITOR_BRAND_REQUEST,
      competitorBrand
    });

    await deleteCompetitorBrandAPI(competitorBrand);
    return dispatch({
      type: DELETE_COMPETITOR_BRAND_SUCCESS,
      competitorBrand
    });
  } catch (error) {
    return dispatch({
      type: DELETE_COMPETITOR_BRAND_ERROR,
      error: error,
      competitorBrand
    });
  }
};
