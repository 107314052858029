import React from 'react';
import PropTypes from 'prop-types';
import { copyToClipboard } from '../../../Helpers/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import { orderTrackingCodeGeneral } from '../../../Helpers/brand_setup_helpers';

const BrandSetupBigCommerce = props => {
  const { brand, updateBrand, clickTrackingCode } = props;
  const bigCommerceOrderCancellationEndpoint = `https://api.shopmy.us/api/AffiliateWebhooks/big_commerce/order/statusUpdate/${brand?.id}`;

  const { integrationStatus } = brand || {};
  const isIntegrationStatusPending = integrationStatus === 'PENDING';
  const isIntegrationStatusComplete = integrationStatus === 'COMPLETE';

  return (
    <>
      <div className='section'>
        <h2 className='section-header'>Step One: Integrate Click Tracking</h2>
        <div>
          <div className='section-step'>
            <div className='step'>
              Add the one-line script below to the main site template just before the closing {`</body>`} tag. This script must be on every page, as
              it takes the tracking URL and turns it into a first-party cookie.
            </div>
            <div onClick={() => copyToClipboard(clickTrackingCode, true)} className='code'>
              {clickTrackingCode}
              <div className='copy-overlay'>
                <div>Click to Copy</div>
                <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='section'>
        <h2 className='section-header'>Step Two: Integrate Order Tracking</h2>
        <div className='section-step'>
          <div className='step'>
            Add the following to the order confirmation page. You will need to update the bold items below with the correct values.
          </div>
          <div onClick={() => copyToClipboard(orderTrackingCodeGeneral, true)} className='code'>
            {orderTrackingCodeGeneral.split('\t').map((t, idx) =>
              t.split('***').map((p, idx) => (
                <span key={idx} className={cn(t ? 'text' : 'space', { 'needs-fill': idx === 1 })}>
                  {p}
                </span>
              ))
            )}
            <div className='copy-overlay'>
              <div>Click to Copy</div>
              <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
            </div>
          </div>
        </div>
      </div>

      <div className='section'>
        <h2 className='section-header'>Step Three: Handle Order Cancellations</h2>
        <div>
          If you are using Big Commerce, you will need to set up a webhook to notify us when an order is cancelled. This is so we can cancel the
          commission for the creator. Please follow the steps below to set up the webhook.
        </div>
        <ol className='section-step'>
          <li className='step'>On the left sidebar, go to "Settings" then "Advanced Settings"</li>
          <li className='step'>Click Webhooks</li>
          <li className='step'>Click Create Webhook</li>
          <li className='step'>Set the following values:</li>
          <ul>
            <li className='step'>Name: ShopMy Order Cancellation</li>
            <li className='step'>Copy the url below into the "URL" field</li>
            {brand?.id ? (
              <div className='step code' onClick={() => copyToClipboard(bigCommerceOrderCancellationEndpoint, true)}>
                {bigCommerceOrderCancellationEndpoint}
                <div className='copy-overlay'>
                  <div>Click to Copy</div>
                  <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                </div>
              </div>
            ) : (
              <div className='step code'>Please login to a brand account to see the url</div>
            )}
            <li className='step'>Method: POST</li>
            <li className='step'>Select the "/store/order/statusUpdated" event</li>
          </ul>
        </ol>
      </div>
      <div className='section'>
        <h2 className='section-header'>Step Four: Request Testing</h2>
        <div>
          Please make a discount code <b>SHOPMYSHELF_TEST</b> for 100% off then {brand ? 'click the button below' : 'email us'} and we will test the
          integration end-to-end and let you know the order ID so we can cancel it afterwards.
        </div>
        {brand && (
          <div
            className={cn('integration-btn', { complete: isIntegrationStatusComplete, pending: isIntegrationStatusPending })}
            onClick={async () => {
              if (isIntegrationStatusPending) {
                window.ALERT.info(
                  'We are currently in the process of reviewing your integration. If you have any further questions please reach us at team@shopmy.us'
                );
              } else {
                confirmAlert({
                  title: 'Just confirming',
                  message:
                    'Did you make a discount code SHOPMYSHELF_TEST for 100% off? We will use this to test the integration and then cancel the order upon completion of the test.',
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: async () => {
                        await updateBrand(brand, { integrationStatus: 'PENDING' });
                        window.ALERT.success('We will review your integration and reach out shortly!');
                      }
                    },
                    { label: 'No', onClick: () => {} }
                  ]
                });
              }
            }}
          >
            {isIntegrationStatusPending ? 'In Review' : isIntegrationStatusComplete ? 'Integration Complete!' : 'Request Integration Review'}
          </div>
        )}
      </div>
    </>
  );
};

BrandSetupBigCommerce.propTypes = {
  brand: PropTypes.object.isRequired,
  updateBrand: PropTypes.func.isRequired,
  clickTrackingCode: PropTypes.string.isRequired
};

export default BrandSetupBigCommerce;
