import React from 'react';
import PropTypes from 'prop-types';
import './UserLookbookBody.scss';
import cn from 'classnames';

import { getRequestForLookbook } from '../../../Helpers/user_helpers';

import UserLookbookProducts from './UserLookbookProducts';

const UserLookbookBody = props => {
  const { user, lookbook, lookbooks } = props;
  const { brandNote, brand } = lookbook;
  const request = getRequestForLookbook(user, lookbook);
  const note = request?.brandMessage || brandNote || '';
  return (
    <div className='user-lookbook-body-outer'>
      <div className='user-lookbook-body-inner'>
        {!!note && (
          <div className='header'>
            <div id='note-from-brand'>
              <div className='image-container'>
                <img src={brand.logo} alt='brand-logo' />
              </div>
              <div className={cn('text')}>
                <div className='intro'>A note from {brand.name}</div>
                <div className='brand-note'>{note}</div>
              </div>
            </div>
          </div>
        )}
        <UserLookbookProducts user={user} lookbook={lookbook} lookbooks={lookbooks} updateLookbookCart={props.updateLookbookCart} />
      </div>
    </div>
  );
};

UserLookbookBody.propTypes = {
  user: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  lookbooks: PropTypes.object.isRequired,
  updateLookbookCart: PropTypes.func.isRequired
};

export default UserLookbookBody;
