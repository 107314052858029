import { getNewsletter } from '../APIClient/newsletter';

import { getUserId } from '../Helpers/user_helpers';

export const GET_LATEST_SUCCESS = 'GET_LATEST_SUCCESS';
export const GET_LATEST_ERROR = 'GET_LATEST_ERROR';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export const syncLatest = (options = {}) => async (dispatch, getState) => {
  try {
    const resp = await getNewsletter({ User_id: getUserId(getState().user), ...options });
    return dispatch({ type: GET_LATEST_SUCCESS, newsletter: resp.newsletter });
  } catch (error) {
    console.error(error);
    return dispatch({ type: GET_LATEST_ERROR, error });
  }
};

export const setActiveTab = tab => async (dispatch, getState) => {
  return dispatch({ type: SET_ACTIVE_TAB, tab });
};
