import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faGift } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment';
import cn from 'classnames';
import './OpportunityListingExplanation.scss';

import { matchScrollHeight } from '../../../Helpers/helpers';
import { enhanceHTML } from '../../../Helpers/formatting';
import { getLookbooks } from '../../../Helpers/user_helpers';
import { getOpportunityRequestsForOpportunity } from '../../../Helpers/brand_helpers';

import Tooltip from '../../General/Tooltip';
import Select from '../../General/Select';

const OpportunityListingExplanation = props => {
  const {
    analytics,
    user,
    opportunity,
    opportunityRequest,
    isEditing,
    description,
    guidelines,
    additionalPerks,
    AutoSend_Lookbook_id,
    updateField,
    hasLoadedFullOpportunity
  } = props;
  const { auto_send_lookbook } = opportunity || {};

  // Lookbooks
  const brandLookbooks = getLookbooks(user) || [];
  const existingRequests = getOpportunityRequestsForOpportunity(analytics, opportunity);
  const acceptedRequests = existingRequests.filter(request => request.userAccepted);
  const isEditingAutoSendLookbook = (auto_send_lookbook?.id || null) !== AutoSend_Lookbook_id;

  const formatBullets = text => {
    const lines = text?.split('\n') || [];
    return (
      <ul>
        {lines.map((line, index) => {
          const formattedLine = line.trim().startsWith('-') ? line.replace('- ', '') : line.trim().startsWith('•') ? line.replace('• ', '') : line;
          return formattedLine && <li key={index}>{enhanceHTML(formattedLine.trim())}</li>;
        })}
      </ul>
    );
  };

  const additionalClasses = { editing: isEditing };

  const showAboutSection = isEditing || !!description;
  const showGuidelinesSection = isEditing || !!guidelines;
  const showPerksSection = isEditing || !!additionalPerks || !!AutoSend_Lookbook_id;

  return (
    <>
      {showAboutSection && (
        <div className='opportunity-listing-section opportunity-listing-explanation-section'>
          <div className='opportunity-listing-section-title-container'>
            <div className='opportunity-listing-section-title'>About This Opportunity</div>
          </div>
          <div className={cn('opportunity-listing-content', additionalClasses)}>
            {isEditing ? (
              <textarea
                rows={3}
                className='content'
                placeholder='Please explain this opportunity.'
                value={description || ''}
                onChange={e => updateField('description', e.target.value)}
                ref={ref => matchScrollHeight(ref)}
              />
            ) : hasLoadedFullOpportunity ? (
              description ? (
                <div className='content'>{enhanceHTML(description)}</div>
              ) : (
                <div className='content empty'>There is no description for this opportunity.</div>
              )
            ) : (
              <div className='loading-content'>Loading Description...</div>
            )}
          </div>
        </div>
      )}
      {showGuidelinesSection && (
        <div className='opportunity-listing-section opportunity-listing-explanation-section'>
          <div className='opportunity-listing-section-title-container'>
            <div className='opportunity-listing-section-title'>Guidelines</div>
            {isEditing && (
              <div className='opportunity-listing-section-subtitle'>
                Please type individual guidelines on separate lines. Please note that guidelines are not requirements, they are guidance for creators.
                They will not be enforced in the automated check for completion. If you would like a more formal agreement, please use the contracts
                feature.
              </div>
            )}
          </div>
          <div className={cn('opportunity-listing-content', additionalClasses)}>
            {isEditing ? (
              <textarea
                rows={3}
                className='content'
                placeholder='What should creators know about what you are looking for?'
                value={guidelines || ''}
                onChange={e => updateField('guidelines', e.target.value)}
                ref={ref => matchScrollHeight(ref)}
              />
            ) : hasLoadedFullOpportunity ? (
              guidelines ? (
                <div className='content'>{formatBullets(guidelines)}</div>
              ) : (
                <div className='content empty'>There are no guidelines for this opportunity.</div>
              )
            ) : (
              <div className='loading-content'>Loading Guidelines...</div>
            )}
          </div>
        </div>
      )}
      {showPerksSection && (
        <div className='opportunity-listing-section opportunity-listing-explanation-section'>
          <div className='opportunity-listing-section-title-container'>
            <div className='opportunity-listing-section-title'>What Else You Get</div>
          </div>
          {(isEditing || additionalPerks) && (
            <div className={cn('opportunity-listing-content', additionalClasses)}>
              {isEditing ? (
                <textarea
                  rows={3}
                  className='content'
                  placeholder='Are there any additional perks beyond the payment tiers below? This is optional.'
                  value={additionalPerks || ''}
                  onChange={e => updateField('additionalPerks', e.target.value)}
                  ref={ref => matchScrollHeight(ref)}
                />
              ) : (
                <div className='content'>{formatBullets(additionalPerks)}</div>
              )}
            </div>
          )}
          {isEditing && (
            <div className='additional-perk-actions'>
              <div className={cn('action-select', { 'is-active': !!AutoSend_Lookbook_id })}>
                <div className='action-label'>
                  Auto-Send Lookbook
                  <Tooltip
                    message={`Use this if you want to automatically send a lookbook when a creator accepts the opportunity.${
                      acceptedRequests.length > 0
                        ? ` There ${acceptedRequests.length === 1 ? 'is' : 'are'} currently ${acceptedRequests.length} accepted request${
                            acceptedRequests.length === 1 ? '' : 's'
                          }, these will not receive the lookbook as this only applies to future requests.`
                        : ''
                    }`}
                  >
                    {AutoSend_Lookbook_id ? <FontAwesomeIcon className='check' icon={faCheck} /> : <FontAwesomeIcon icon={faInfoCircle} />}
                  </Tooltip>
                </div>
                <Select
                  isDark
                  options={[
                    {
                      value: null,
                      label: 'None',
                      sublabel: 'No lookbook will be sent automatically upon acceptance.'
                    },
                    ..._.orderBy(brandLookbooks, 'createdAt', 'desc').map(lookbook => ({
                      value: lookbook.id,
                      label: lookbook.title,
                      sublabel: `Created on ${moment(lookbook.createdAt).format('MMM Do, YYYY')}`
                    }))
                  ]}
                  value={AutoSend_Lookbook_id}
                  placeholder='Attach a Lookbook'
                  onChangeValue={newValue => updateField('AutoSend_Lookbook_id', newValue, { debounceDuration: 0 })}
                  isSearching={false}
                  isDisabled={false}
                  isLoading={false}
                />
              </div>
            </div>
          )}
          {auto_send_lookbook && (
            <div
              className={cn('opportunity-listing-auto-send-lookbook', {
                editing: isEditingAutoSendLookbook
              })}
            >
              <FontAwesomeIcon icon={faGift} />
              {opportunityRequest?.userAccepted ? (
                <div>
                  Lookbook <Link to={`/lookbooks/${auto_send_lookbook.id}`}>{auto_send_lookbook.title}</Link> will be sent automatically upon
                  acceptance.
                </div>
              ) : (
                `Lookbook "${auto_send_lookbook.title}" will be sent automatically upon acceptance.`
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

OpportunityListingExplanation.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  opportunityRequest: PropTypes.object,
  canEdit: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  description: PropTypes.string,
  guidelines: PropTypes.string,
  additionalPerks: PropTypes.string,
  updateField: PropTypes.func,
  hasLoadedFullOpportunity: PropTypes.bool
};

export default OpportunityListingExplanation;
