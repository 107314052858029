import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import './RequestResults.scss';

import RequestResult from './RequestResult';

import { doesRequestRequireFulfillment, hasRequestExpired, isRequestDismissed, isRequestOutstanding } from '../../../Helpers/gifting_helpers';
import {
  isOpportunityRequestExpired,
  isOpportunityRequestDismissed,
  isOpportunityRequestOutstanding,
  isOpportunityRequestAccepted
} from '../../../Helpers/opportunity_helpers';
import { getBrandRequests, getOpportunityRequests } from '../../../Helpers/brand_helpers';
import { getOpportunities } from '../../../Helpers/user_helpers';

const RequestResults = props => {
  const {
    talent,
    user,
    analytics,
    openArtistModal,
    openChatOverlay,
    downloadGiftingRequests,
    curSearchVal,
    selectedFilter,
    isDownloading,
    resetSearch,
    lookbooks,
    ui
  } = props;

  const opportunities = getOpportunities(user);
  const requests = getBrandRequests(analytics);
  const opportunityRequests = getOpportunityRequests(analytics);

  const orderByCreatedAt = (a, b) => new Date(b.createdAt) - new Date(a.createdAt);
  const allGiftingRequests = requests;

  const isSearchingOrFiltering = curSearchVal || (selectedFilter?.value && selectedFilter?.value !== 'all');
  const requestFiltering = r => (selectedFilter ? selectedFilter.filter(r) : true);
  const requestsSearchFilter = r => {
    return r.user?.name?.toLowerCase().includes(curSearchVal.toLowerCase());
  };
  const filteredGiftingRequests = allGiftingRequests
    .filter(requestFiltering)
    .filter(requestsSearchFilter)
    .sort(orderByCreatedAt);

  const filteredOpportunityRequests = opportunityRequests
    .filter(requestFiltering)
    .filter(requestsSearchFilter)
    .sort(orderByCreatedAt);

  // panel will be split up into 4 regions:
  // 1. requests requiring fulfillment, 2. oustanding requests, 3. fulfilled requests, 4. dismissed requests
  const sections = [
    {
      header: 'Gifting Awaiting Fulfillment',
      requests: filteredGiftingRequests.filter(doesRequestRequireFulfillment),
      onClick: r => props.openFulfillmentModal({ params: { Request_id: r.id } }),
      largeFormat: true,
      featureRequired: 'GIFTING'
    },
    {
      header: 'Outstanding Gifting Requests',
      requests: filteredGiftingRequests.filter(isRequestOutstanding),
      featureRequired: 'GIFTING'
    },
    {
      header: 'Outstanding Opportunity Requests',
      requests: filteredOpportunityRequests.filter(isOpportunityRequestOutstanding),
      featureRequired: 'OPPORTUNITIES'
    },
    {
      header: 'Fulfilled Gifting Requests',
      requests: filteredGiftingRequests.filter(r => r.isComplete),
      featureRequired: 'GIFTING'
    },
    {
      header: 'Accepted Opportunity Requests',
      requests: filteredOpportunityRequests.filter(isOpportunityRequestAccepted),
      featureRequired: 'OPPORTUNITIES'
    },
    {
      header: 'Expired Gifting Requests',
      requests: filteredGiftingRequests.filter(hasRequestExpired),
      featureRequired: 'GIFTING'
    },
    {
      header: 'Expired Opportunity Requests',
      requests: filteredOpportunityRequests.filter(isOpportunityRequestExpired),
      featureRequired: 'OPPORTUNITIES'
    },
    {
      header: 'Dismissed Gifting Requests',
      requests: filteredGiftingRequests.filter(isRequestDismissed),
      featureRequired: 'GIFTING'
    },
    {
      header: 'Dismissed Opportunity Requests',
      requests: filteredOpportunityRequests.filter(isOpportunityRequestDismissed),
      featureRequired: 'OPPORTUNITIES'
    }
  ];

  const noGiftingRequests = allGiftingRequests.length === 0;
  const noOpportunityRequests = opportunityRequests.length === 0;
  const noRequests = noGiftingRequests && noOpportunityRequests;
  const noResultsAfterFilterOrSearch = !filteredGiftingRequests.length && !filteredOpportunityRequests.length && isSearchingOrFiltering;

  return (
    <div className='request-results-outer'>
      <div className='request-results-inner'>
        {noRequests && (
          <div className='empty-results-message'>
            <div className='text'>
              <div>No gifting or opportunities have been sent yet.</div>
            </div>
          </div>
        )}
        {noResultsAfterFilterOrSearch && (
          <>
            <div className='empty-results-message'>
              <div className='text'>
                <div>No results found for search term "{curSearchVal}".</div>
                <button className='clear-filters-button' onClick={resetSearch}>
                  Clear Search
                </button>
              </div>
            </div>
          </>
        )}
        {!noRequests &&
          !noResultsAfterFilterOrSearch &&
          sections.map((section, index) => {
            const { header, requests, onClick, showChatButton, largeFormat } = section;

            if (requests.length === 0) return null;
            return (
              <div className='results-section' key={header}>
                <div className='results-section-header'>
                  <div className='text'>
                    <div>{header}</div>
                    <span className='request-count'>{requests.length}</span>
                  </div>
                  <div className='spacer'></div>
                  <div className='download-button' onClick={() => downloadGiftingRequests(requests, index)}>
                    <FontAwesomeIcon icon={faDownload} />
                    <button className='btn btn-primary'>{isDownloading === index ? 'Downloading...' : 'Download'}</button>
                  </div>
                </div>
                <div className='results-container'>
                  {requests.map(request => {
                    const lookbook = lookbooks.find(l => l.id === request.Lookbook_id);
                    const opportunity = opportunities.find(o => o.id === request.Opportunity_id);

                    return (
                      <RequestResult
                        ui={ui}
                        user={user}
                        talent={talent}
                        featureRequired={section.featureRequired}
                        openArtistModal={openArtistModal}
                        openChatOverlay={openChatOverlay}
                        removeSamplesRequest={props.removeSamplesRequest}
                        removeOpportunityRequest={props.removeOpportunityRequest}
                        updateRequest={props.updateRequest}
                        updateOpportunityRequest={props.updateOpportunityRequest}
                        openRequestModal={props.openRequestModal}
                        request={request}
                        key={`all-requests-${request.id}`}
                        markSent={onClick || (() => {})}
                        lookbook={lookbook}
                        opportunity={opportunity}
                        showChatButton={showChatButton}
                        largeFormat={largeFormat}
                        signedInUser={user}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

RequestResults.propTypes = {
  talent: PropTypes.array,
  ui: PropTypes.object,
  user: PropTypes.object.isRequired,
  lookbooks: PropTypes.array.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  openFulfillmentModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  downloadGiftingRequests: PropTypes.func.isRequired,
  removeSamplesRequest: PropTypes.func.isRequired,
  removeOpportunityRequest: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
  updateOpportunityRequest: PropTypes.func.isRequired,
  isDownloading: PropTypes.number.isRequired,

  curSearchVal: PropTypes.string,
  selectedFilter: PropTypes.object,
  resetSearch: PropTypes.func.isRequired
};

export default RequestResults;
