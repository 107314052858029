import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './OpportunitySetupGuideStep.scss';

import Tooltip from '../../General/Tooltip';
import OpportunitySetupGuideSearch from './OpportunitySetupGuideSearch';
import OpportunitySetupGuideTags from './OpportunitySetupGuideTags';

import { matchScrollHeight } from '../../../Helpers/helpers';

import OpportunitySetupGuideBetaDisclaimer from './OpportunitySetupGuideBetaDisclaimer';

const OpportunitySetupGuideStep = props => {
  const { user, step } = props;

  const [isOptionalAIMessageHidden, setIsOptionalAIMessageHidden] = React.useState(false);
  const hideOptionalAIMessage = () => setIsOptionalAIMessageHidden(true);

  return (
    <div className='opportunity-setup-guide-step'>
      {step.showOptionalAIMessage && !isOptionalAIMessageHidden && (
        <OpportunitySetupGuideBetaDisclaimer closeToOverview={props.closeToOverview} close={hideOptionalAIMessage} />
      )}
      <div className='step-title'>{step.title}</div>
      <div className='questions'>
        {step.questions?.map((question, idx) => {
          const { type, display, subdisplay, options, fields, details, search_options, tag_options } = question;
          const additionalClasses = { small: type === 'CHECKBOXES_SMALL' };
          return (
            <div className='question' key={idx}>
              <div className={cn('question-display-container', additionalClasses)}>
                <div className={cn('question-display', additionalClasses)}>{display}</div>
                {subdisplay && <div className={cn('question-subdisplay', additionalClasses)}>{subdisplay}</div>}
              </div>
              {(type === 'CHECKBOXES' || type === 'CHECKBOXES_SMALL') && (
                <div className={cn('options checkboxes', additionalClasses)}>
                  {options?.map((option, idx) => {
                    const { display, isSelected, onChangeValue } = option; // Required keys
                    const { isRecommended, description, isDisabled, isDisabledMessage } = option; // Optional
                    const missingKeys = ['display', 'isSelected', 'onChangeValue'].filter(key => !(key in option));
                    if (missingKeys.length) return <div key={idx}>Mission Required Option Key: {missingKeys.join(', ')}</div>;
                    const additionalOptionClasses = { ...additionalClasses, selected: isSelected };
                    const wrapInTooltipIfNeeded = content => (isDisabled ? <Tooltip message={isDisabledMessage}>{content}</Tooltip> : content);
                    const select = () => (isDisabled ? window.ALERT.warn(isDisabledMessage) : onChangeValue());
                    return (
                      <div key={idx + display} className={cn('multi-select option', additionalOptionClasses)} onClick={select}>
                        <div className={cn('checkbox-container', additionalOptionClasses)}>
                          {wrapInTooltipIfNeeded(
                            <div className={cn('checkbox', additionalOptionClasses)}>
                              <div className={cn('checkbox-inner', additionalOptionClasses)} />
                            </div>
                          )}
                        </div>
                        <div className={cn('option-container', additionalOptionClasses)}>
                          <div className={cn('display-container', additionalOptionClasses)}>
                            {wrapInTooltipIfNeeded(<div className={cn('display', additionalOptionClasses)}>{display}</div>)}
                            {isRecommended && <div className={cn('recommended-badge', additionalOptionClasses)}>Recommended</div>}
                          </div>
                          <div className={cn('description', additionalOptionClasses)}>{description}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {type === 'DATES' && (
                <div onClick={details.onClick} className='date-select'>
                  {details.currentDateDisplay ? (
                    <div className='date-display'>{details.currentDateDisplay}</div>
                  ) : (
                    <div className='date-select-btn'>Select a date</div>
                  )}
                </div>
              )}
              {type === 'TEXTAREAS' && (
                <div className='options textareas'>
                  {fields?.map((option, idx) => {
                    const { label, sublabel, value, placeholder, rows, onChangeValue } = option; // Required keys
                    return (
                      <div key={label} className={cn('textarea-container option')}>
                        <div className='label-container'>
                          <div className='label'>{label}</div>
                          {sublabel && <div className='sublabel'>{sublabel}</div>}
                        </div>
                        <textarea
                          className='textarea'
                          value={value}
                          rows={rows || 3}
                          placeholder={placeholder}
                          ref={ref => matchScrollHeight(ref)}
                          onChange={e => onChangeValue(e.target.value)}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {type === 'MULTI_SEARCH' && <OpportunitySetupGuideSearch user={user} {...search_options} />}
              {type === 'MULTI_TAGS' && <OpportunitySetupGuideTags results={tag_options.results} setResults={tag_options.setResults} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

OpportunitySetupGuideStep.propTypes = {
  user: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  closeToOverview: PropTypes.func.isRequired
};

export default OpportunitySetupGuideStep;
