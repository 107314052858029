import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './BrandLookbook.scss';

import BrandLookbookHeader from './BrandLookbookHeader';
import BrandLookbookBody from './BrandLookbookBody';
import BrandLookbookSettingsModal from './BrandLookbookSettingsModal';
import LookbookProductModal from '../LookbookModals/LookbookProductModal/LookbookProductModal';

import { updateLookbookCart } from '../../../Actions/LookbookActions';
import { openRequestModal } from '../../../Actions/UIActions';

import { getBrand } from '../../../Helpers/user_helpers';
import { insertMetaTags } from '../../../Helpers/seo_helpers';

const BrandLookbook = props => {
  const { user, ui, analytics, lookbooks, lookbook, saving } = props;
  const { deleteLookbook, updateLookbook, deleteItemFromLookbook } = props;
  const { addLookbookItemSibling, updateLookbookItemSibling, deleteLookbookItemSibling } = props;
  const brand = getBrand(user);
  const history = useHistory();
  const location = useLocation();

  const [inPublicViewMode, setInPublicViewMode] = useState(false);
  // const [inAnalyticsMode, setInAnalyticsMode] = useState(false);
  const [viewSettingsModal, setViewSettingsModal] = useState(false);
  const [viewAddProdutsModal, setViewAddProductsModal] = useState(false);
  const [viewReplaceProductsModal, setViewReplaceProductsModal] = useState(false);
  const [itemIdBeingEdited, setItemIdBeingEdited] = useState(null);
  const [itemBeingReplaced, setItemBeingReplaced] = useState(null);

  // we want to show analytics only if the url has .../lookbooks/:id/analytics
  // so we will just simulate react state here
  const inAnalyticsMode = location.pathname.includes('/analytics');
  const setInAnalyticsMode = shouldBeOn => {
    if (shouldBeOn) history.push(`/lookbooks/${lookbook.id}/analytics`);
    else history.push(`/lookbooks/${lookbook.id}`);
  };

  /******************************************************************************************* */
  // MODAL HANDLERS
  /******************************************************************************************* */

  const openSettingsModal = () => setViewSettingsModal(true);
  const openAddProductsModal = () => setViewAddProductsModal(true);
  const openReplaceProductsModal = itemToReplace => {
    setItemBeingReplaced(itemToReplace);
    setViewReplaceProductsModal(true);
  };

  const closeAllModals = () => {
    setItemIdBeingEdited(null);
    setViewSettingsModal(false);
    setViewAddProductsModal(false);
    setViewReplaceProductsModal(false);
    setItemBeingReplaced(null);
    setInAnalyticsMode(false);
    props.syncLookbook();
  };

  const openEditModal = item => {
    setItemIdBeingEdited(item.id);
    openAddProductsModal();
  };

  const goBackToCatalog = () => setItemIdBeingEdited(null);

  /******************************************************************************************* */
  // LOOKBOOK ITEM OPERATIONS
  /******************************************************************************************* */

  const addLookbookItem = async (data, { shouldClose }) => {
    const newItem = await props.addLookbookItem(data);
    if (newItem && shouldClose) closeAllModals();
    return newItem;
  };

  const handleLookbookItemReplace = async data => {
    await props.replaceLookbookItem(itemBeingReplaced, data);
    closeAllModals();
  };

  const updateLookbookItem = async (item, updates) => {
    const updatedItem = await props.updateLookbookItem(item, updates);
    closeAllModals();
    return updatedItem;
  };

  return (
    <div className='brand-lookbook-outer'>
      {insertMetaTags({
        title: lookbook?.title || `${brand.name} Lookbook`,
        description: lookbook?.brandNote || '',
        image: lookbook?.coverImage || ''
      })}
      <div className='brand-lookbook-inner'>
        <BrandLookbookHeader
          ui={ui}
          user={user}
          analytics={analytics}
          lookbook={lookbook}
          updateLookbook={updateLookbook}
          deleteLookbook={deleteLookbook}
          openSettingsModal={openSettingsModal}
          openAddProductsModal={openAddProductsModal}
          openRequestModal={props.openRequestModal}
          inPublicViewMode={inPublicViewMode}
          setInPublicViewMode={setInPublicViewMode}
          inAnalyticsMode={inAnalyticsMode}
          setInAnalyticsMode={setInAnalyticsMode}
        />
        <BrandLookbookBody
          ui={ui}
          user={user}
          brand={brand}
          analytics={analytics}
          lookbook={lookbook}
          lookbooks={lookbooks}
          updateLookbook={updateLookbook}
          openSettingsModal={openSettingsModal}
          deleteItemFromLookbook={deleteItemFromLookbook}
          openEditModal={openEditModal}
          openAddProductsModal={openAddProductsModal}
          openReplaceProductsModal={openReplaceProductsModal}
          updateLookbookItem={updateLookbookItem}
          updateLookbookCart={props.updateLookbookCart}
          saving={saving}
          inPublicViewMode={inPublicViewMode}
          inAnalyticsMode={inAnalyticsMode}
        />

        {viewSettingsModal && (
          <BrandLookbookSettingsModal
            ui={props.ui}
            lookbook={lookbook}
            updateLookbook={updateLookbook}
            setViewSettingsModal={setViewSettingsModal}
            user={user}
          />
        )}

        {viewAddProdutsModal && (
          <LookbookProductModal
            ui={ui}
            user={user}
            brand={brand}
            lookbook={lookbook}
            itemIdBeingEdited={itemIdBeingEdited}
            setViewAddProductsModal={setViewAddProductsModal}
            addLookbookItem={addLookbookItem}
            updateLookbookItem={updateLookbookItem}
            removeLookbookItem={deleteItemFromLookbook}
            addLookbookItemSibling={addLookbookItemSibling}
            updateLookbookItemSibling={updateLookbookItemSibling}
            deleteLookbookItemSibling={deleteLookbookItemSibling}
            openEditModal={openEditModal}
            goBackToCatalog={goBackToCatalog}
            close={closeAllModals}
            syncLookbook={props.syncLookbook}
            saving={saving}
          />
        )}

        {viewReplaceProductsModal && (
          <LookbookProductModal
            ui={ui}
            user={user}
            brand={brand}
            lookbook={lookbook}
            addLookbookItem={handleLookbookItemReplace}
            close={closeAllModals}
            syncLookbook={props.syncLookbook}
            saving={saving}
            isReplaceMode={true}
            itemBeingReplaced={itemBeingReplaced}
          />
        )}
      </div>
    </div>
  );
};

BrandLookbook.propTypes = {
  // From Outside
  lookbook: PropTypes.object.isRequired,
  deleteLookbook: PropTypes.func.isRequired,
  updateLookbook: PropTypes.func.isRequired,
  addLookbookItem: PropTypes.func.isRequired,
  updateLookbookItem: PropTypes.func.isRequired,
  deleteItemFromLookbook: PropTypes.func.isRequired,
  addLookbookItemSibling: PropTypes.func.isRequired,
  updateLookbookItemSibling: PropTypes.func.isRequired,
  deleteLookbookItemSibling: PropTypes.func.isRequired,
  replaceLookbookItem: PropTypes.func.isRequired,
  syncLookbook: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,

  // From Inside
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  lookbooks: PropTypes.object.isRequired,
  updateLookbookCart: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui, lookbooks, analytics } = state;
  return { user, ui, lookbooks, analytics };
};

export default connect(mapStateToProps, {
  openRequestModal,
  updateLookbookCart
})(BrandLookbook);
