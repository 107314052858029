import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import cn from 'classnames';
import _ from 'lodash';

import CollectionMedia from '../../Components/Collection/Elements/CollectionMedia';
import CollectionSocialLinks from '../../Components/Collection/Elements/CollectionSocialLinks';
import UploadImage from '../../Components/General/UploadImage';

import { isPublicPage, matchScrollHeight, getCollectionsTitle, isQuickPage } from '../../Helpers/helpers';
import { uploadVideoContentsToS3, getThumbnailImageFromVideoUrl, isVideoString } from '../../Helpers/media_helpers';
import { getTabUrlSlugForCollection } from '../../Helpers/store_helpers';
import { saveCollection as saveCollectionAPI } from '../../APIClient/collections';
import { getUserId, getNameWithS } from '../../Helpers/user_helpers';

import './CollectionHeader.scss';

class CollectionHeader extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    collection: PropTypes.object.isRequired,
    syncCollection: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.editCollectionDebouncer = AwesomeDebouncePromise(async value => {
      await saveCollectionAPI(this.props.match.params.id, {
        name: _.isNil(this.state.collectionNameOverride) ? _.get(this.props.collection, 'name', '') : this.state.collectionNameOverride,
        description: _.isNil(this.state.collectionDescriptionOverride)
          ? _.get(this.props.collection, 'description', '')
          : this.state.collectionDescriptionOverride
      });
      this.props.syncCollection(); // Until we add this to the redux store
    }, 600);
    if (this.editNameInput && this.editDescriptionInput) {
      this.editNameInput.addEventListener('keyup', e => {
        e.key === 'Enter' && this.editNameInput.blur();
      });
      this.editDescriptionInput.addEventListener('keyup', e => {
        e.key === 'Enter' && this.editDescriptionInput.blur();
      });
    }
  }

  state = {
    collectionNameOverride: null,
    collectionDescriptionOverride: null
  };

  editCollectionName = name => {
    this.setState({ collectionNameOverride: name || '' });
    this.editCollectionDebouncer();
  };

  editCollectionDescription = description => {
    this.setState({ collectionDescriptionOverride: description || '' });
    this.editCollectionDebouncer();
  };

  isFromLatest = () => this.props.lastLocation?.pathname?.includes('/latest');

  headBack = () => {
    const { history, collection } = this.props;
    const storeUser = this.getStoreUser();
    if (storeUser.username) {
      const lastLocation = _.get(this.props.lastLocation, 'pathname');
      const isFromShop = lastLocation?.includes(`/${storeUser.username}`);
      const isFromLatest = this.isFromLatest();
      if (isFromShop || isFromLatest) {
        history.goBack();
      } else {
        const urlSlug = getTabUrlSlugForCollection(collection);
        history.push(`/${isPublicPage() ? 'public/' : ''}${storeUser.username}${urlSlug ? `/${urlSlug}` : ''}`);
        window.scrollTo(0, 0);
      }
    } else {
      history.goBack();
    }
  };

  removeImage = async () => {
    const { syncCollection, collection } = this.props;
    await saveCollectionAPI(collection.id, { image: null });
    await syncCollection(); // Until we add this to the redux store
  };

  completeImageUpload = async newImage => {
    const { syncCollection, collection } = this.props;
    await saveCollectionAPI(collection.id, { image: newImage });
    await syncCollection(); // Until we add this to the redux store
    if (isVideoString(newImage)) {
      setTimeout(() => {
        const video = document.querySelector('video');
        if (video) video.onloadeddata = () => setTimeout(() => uploadVideoContentsToS3(video, getThumbnailImageFromVideoUrl(newImage).fileOnly), 500); // delay to ensure paint on canvas works
      }, 100);
    }
  };

  getStoreUser = () => _.get(this.props.collection, 'user', {});

  render() {
    const { user, collection, canEdit, history, lastLocation, syncCollection } = this.props;
    const { collectionNameOverride, collectionDescriptionOverride } = this.state;
    // Labeling Header
    const storeUser = this.getStoreUser();
    const storeUserFirstName = _.first(_.split(_.get(storeUser, 'name'), ' '));
    const pluralizedName = getNameWithS(storeUser);

    const you = collection && getUserId(user) === collection.User_id;
    const numPins = _.get(collection, ['pins', 'length'], 0);
    const fromHome = lastLocation && lastLocation.pathname === '/home';
    const fromColab = lastLocation && lastLocation.pathname.includes('/collaboration');
    const fromBlog = lastLocation && lastLocation.pathname && lastLocation.pathname.includes('/blog');

    const isGiftGuide = collection.forceType === 'gift-guide';
    const isLongForm = collection.skinType === 'longform';

    const blankHeader = !collection.name && !collection.social_links && !collection.image;
    if (isQuickPage() && blankHeader) {
      return null;
    }

    return (
      <div className='collection-outer-header-container'>
        <div className={cn('collection-header', collection.skinType, collection.skinType, { mobile: isMobile })}>
          {fromHome || fromBlog || fromColab ? (
            <div className='top-row'>
              <span onClick={() => history.goBack()} className='back-link btn'>
                <FontAwesomeIcon icon={faArrowLeft} className='back' />
                BACK {fromHome ? 'HOME' : fromBlog ? 'TO POST' : fromColab ? 'TO COLLABORATION' : ''}
              </span>
            </div>
          ) : (
            <div className='top-row'>
              {collection.isQuick && you ? (
                isQuickPage() ? (
                  <Link to={`/collections/${collection.id}`} className='back-link'>
                    <FontAwesomeIcon icon={faArrowLeft} className='back' />
                    Edit {getCollectionsTitle({ uppercase: true })}
                  </Link>
                ) : (
                  <Link to={`/collections/quick/${collection.id}`} className='back-link'>
                    <FontAwesomeIcon icon={faArrowLeft} className='back' />
                    Back to Quick {getCollectionsTitle({ uppercase: true })}
                  </Link>
                )
              ) : (
                <span onClick={this.headBack} className='back-link'>
                  <FontAwesomeIcon icon={faChevronLeft} className='back' />
                  {this.isFromLatest() ? 'Back' : you ? 'Your shop' : storeUserFirstName ? `${pluralizedName.full} shop` : 'shop'}
                </span>
              )}
            </div>
          )}
          <div itemScope className='title-container'>
            {canEdit ? (
              <>
                <textarea
                  rows={1}
                  value={_.isNil(collectionNameOverride) ? collection.name || '' : collectionNameOverride}
                  className='name-input-container'
                  ref={ref => {
                    matchScrollHeight(ref);
                    this.editNameInput = ref;
                  }}
                  placeholder={isGiftGuide ? `Title your guide` : `Name your ${window.__IS_SMS__ ? 'collection' : 'list'}`}
                  onChange={e => this.editCollectionName(e.target.value)}
                />
                <textarea
                  rows={1}
                  value={_.isNil(collectionDescriptionOverride) ? collection.description || '' : collectionDescriptionOverride}
                  className='description-input-container'
                  ref={ref => {
                    matchScrollHeight(ref);
                    this.editDescriptionInput = ref;
                  }}
                  placeholder={isGiftGuide ? `Describe your guide` : `Describe your ${window.__IS_SMS__ ? 'collection' : 'list'}`}
                  onChange={e => this.editCollectionDescription(e.target.value)}
                />
              </>
            ) : (
              <>
                <h1 itemProp='name' className='collection-name'>
                  {collection.name || '    '}
                </h1>
                {collection.description && (
                  <h2 itemProp='description' className='collection-description'>
                    {collection.description}
                  </h2>
                )}
              </>
            )}
          </div>
          <div className='meta-data-container'>
            {!isLongForm && (
              <Link onClick={() => window.scrollTo(0, 0)} to={`/${isPublicPage() ? 'public/' : ''}${storeUser.username}`} className='section owner'>
                {storeUser.image && <img alt='Shop Owner' src={storeUser.image} />}
                <div>
                  <u>{storeUser.name}</u>
                </div>
              </Link>
            )}
            {!isLongForm && (
              <div className='section num-pins'>
                <div>{`${numPins} PRODUCT${numPins === 1 ? '' : 'S'}`}</div>
              </div>
            )}
            {canEdit && (
              <div className='section upload-image'>
                <UploadImage
                  basicUploader
                  basicTextExplainer={collection.image ? 'Update Media For Post' : 'Upload Media For Post'}
                  postUploadMessageWhileCompleting='Finalizing Upload...'
                  completeUpload={this.completeImageUpload}
                />
              </div>
            )}
            {(canEdit || collection.social_links) && (
              <div className='section'>
                <CollectionSocialLinks user={user} collection={collection} syncCollection={syncCollection} canEdit={canEdit} />
              </div>
            )}
            {!canEdit && !isLongForm && <div className='section disclaimer'>The products featured here may contain affiliate links.</div>}
          </div>
          {(collection.image || collection.social_links) && (
            <div className='collection-media'>
              <CollectionMedia collection={collection} removeImage={this.removeImage} syncCollection={syncCollection} canEdit={canEdit} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(withLastLocation(CollectionHeader));
