import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import './OpportunityLeaderboard.scss';

import { openArtistModal, openChatOverlay, openBulkTalentModal, openRequestModal } from '../../Actions/UIActions';
import { removeOpportunityRequest, updateOpportunityRequest } from '../../Actions/AnalyticsActions';
import { createOpportunityPaymentTier, updateOpportunityPaymentTier, deleteOpportunityPaymentTier } from '../../Actions/OpportunityActions';

import { getOpportunityRequestsForOpportunity } from '../../Helpers/brand_helpers';
import {
  getTimingOnOpportunity,
  isOpportunityRequestAccepted,
  isOpportunityRequestOutstanding,
  isOpportunityRequestExpired,
  isOpportunityRequestDismissed,
  getSortOptionsForOpportunity
} from '../../Helpers/opportunity_helpers';
import { getPrettyTimeAgoFromNow } from '../../Helpers/formatting';

import OpportunityLeaderboardCard from './Elements/OpportunityLeaderboardCard';
import OpportunityLeaderboardCardInactive from './Elements/OpportunityLeaderboardCardInactive';
import OpportunityLeaderboardControls from './Elements/OpportunityLeaderboardControls';

const OpportunityLeaderboard = props => {
  const { user, opportunity, analytics } = props;

  // The Opportunities endpoint returns augmented requests, update those here
  const requests = getOpportunityRequestsForOpportunity(analytics, opportunity);
  opportunity.results?.forEach(result => {
    const request = requests.find(request => request.id === result.OpportunityRequest_id);
    if (request) request.result = result;
  });

  // Sorting Temporary
  const sortFnOptions = getSortOptionsForOpportunity(opportunity, requests);
  const [sortFnValue, setSortFnValue] = React.useState(_.flatten(_.map(sortFnOptions, 'options')).find(o => o.isDefault)?.value);
  const sortFn = _.flatten(sortFnOptions.map(o => o.options)).find(option => option.value === sortFnValue)?.sortFn;

  // Allow Searching
  const [curSearchVal, setCurSearchVal] = React.useState('');

  // Get timeline
  const timing = getTimingOnOpportunity(opportunity);

  // Group them by performance and section
  const validRequests = requests.filter(request => !isOpportunityRequestExpired(request));
  const orderedRequests = _.orderBy(
    validRequests,
    [sortFn.getValue, ...sortFn.backupGetValues],
    [sortFn.sortDirection, ...sortFn.backupGetValuesDirection]
  );
  const expiredRequests = orderedRequests.filter(isOpportunityRequestExpired);
  const dismissedRequests = orderedRequests.filter(isOpportunityRequestDismissed);
  const acceptedRequests = orderedRequests.filter(isOpportunityRequestAccepted);
  const outstandingRequests = orderedRequests.filter(isOpportunityRequestOutstanding);
  const sections = [
    {
      title: 'Performance',
      requests: acceptedRequests,
      showTimeline: true,
      showActions: true,
      showLastUpdated: !!opportunity.statsLastUpdatedAt,
      getCard: (request, idx) => (
        <OpportunityLeaderboardCard
          key={request.id}
          rank={idx + 1}
          request={request}
          sortFn={sortFn}
          opportunity={opportunity}
          openArtistModal={props.openArtistModal}
          openChatOverlay={props.openChatOverlay}
        />
      )
    },
    {
      title: 'Awaiting Response',
      requests: _.orderBy(outstandingRequests, 'createdAt', 'desc'),
      getCard: request => (
        <OpportunityLeaderboardCardInactive
          key={request.id}
          request={request}
          user={user}
          analytics={analytics}
          opportunity={opportunity}
          createOpportunityPaymentTier={props.createOpportunityPaymentTier}
          updateOpportunityPaymentTier={props.updateOpportunityPaymentTier}
          deleteOpportunityPaymentTier={props.deleteOpportunityPaymentTier}
          openArtistModal={props.openArtistModal}
          openChatOverlay={props.openChatOverlay}
          removeOpportunityRequest={props.removeOpportunityRequest}
          updateOpportunityRequest={props.updateOpportunityRequest}
          openRequestModal={props.openRequestModal}
        />
      )
    },
    {
      title: 'Expired Requests',
      requests: _.orderBy(expiredRequests, 'createdAt', 'desc'),
      getCard: request => (
        <OpportunityLeaderboardCardInactive
          key={request.id}
          request={request}
          user={user}
          analytics={analytics}
          opportunity={opportunity}
          createOpportunityPaymentTier={props.createOpportunityPaymentTier}
          updateOpportunityPaymentTier={props.updateOpportunityPaymentTier}
          deleteOpportunityPaymentTier={props.deleteOpportunityPaymentTier}
          openArtistModal={props.openArtistModal}
          openChatOverlay={props.openChatOverlay}
          removeOpportunityRequest={props.removeOpportunityRequest}
          updateOpportunityRequest={props.updateOpportunityRequest}
          openRequestModal={props.openRequestModal}
        />
      )
    },
    {
      title: 'Dismissed Requests',
      requests: _.orderBy(dismissedRequests, 'createdAt', 'desc'),
      getCard: request => (
        <OpportunityLeaderboardCardInactive
          key={request.id}
          request={request}
          user={user}
          analytics={analytics}
          opportunity={opportunity}
          createOpportunityPaymentTier={props.createOpportunityPaymentTier}
          updateOpportunityPaymentTier={props.updateOpportunityPaymentTier}
          deleteOpportunityPaymentTier={props.deleteOpportunityPaymentTier}
          openArtistModal={props.openArtistModal}
          openChatOverlay={props.openChatOverlay}
          removeOpportunityRequest={props.removeOpportunityRequest}
          updateOpportunityRequest={props.updateOpportunityRequest}
          openRequestModal={props.openRequestModal}
        />
      )
    }
  ].filter(section => section.requests.length > 0);

  return (
    <div className='opportunity-leaderboard-container'>
      <div className='leaderboard-sections'>
        {sections.map(section => {
          const { title, requests, showTimeline, showLastUpdated, showActions } = section;

          // Filter by search
          const visibleRequests = requests.filter(request => {
            const { user } = request;
            const { username, name } = user;
            const searchStr = `${username} ${name}`.toLowerCase();
            return searchStr.includes(curSearchVal.toLowerCase());
          });

          return (
            <div className='leaderboard-section' key={title}>
              <div className='leaderboard-section-header-container'>
                <div className='title-container'>
                  <div className='title'>{title}</div>
                  {showLastUpdated && (
                    <div className='subtitle'>Stats updated {getPrettyTimeAgoFromNow(opportunity.statsLastUpdatedAt, { longForm: true })}</div>
                  )}
                </div>
                {showActions && (
                  <OpportunityLeaderboardControls
                    analytics={analytics}
                    opportunity={opportunity}
                    curSearchVal={curSearchVal}
                    setCurSearchVal={setCurSearchVal}
                    sortFnOptions={sortFnOptions}
                    sortFnValue={sortFnValue}
                    setSortFnValue={setSortFnValue}
                    openBulkTalentModal={props.openBulkTalentModal}
                  />
                )}
              </div>
              {showTimeline && (
                // {showTimeline && !timing.hasEnded && ( PUTTHISBACK!!
                <div className='leaderboard-section-timeline-container'>
                  {timing.isActive && (
                    <div className='status-badge-container'>
                      <div className='badge-outer'>
                        <div className='badge-inner' />
                      </div>
                      <div className='status'>Currently Live</div>
                    </div>
                  )}
                  <div className='timeline-spine-container'>
                    <div className={cn('timeline-spine pending', timing.additionalClasses)} />
                    <div className={cn('timeline-spine')} style={{ width: `${timing.percentComplete}%` }} />
                    {timing.hasStarted && <div className={cn('timeline-spine-current-marker')} style={{ left: `${timing.percentComplete}%` }} />}
                  </div>
                  <div className={cn('timeline-label', timing.additionalClasses)}>{timing.timelineLabel}</div>
                </div>
              )}
              {visibleRequests.length ? (
                <div className='results-container'>{visibleRequests.map(section.getCard)}</div>
              ) : (
                <div className='no-results-container'>No results found for search term {curSearchVal}.</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

OpportunityLeaderboard.propTypes = {
  // From inner
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  removeOpportunityRequest: PropTypes.func.isRequired,
  updateOpportunityRequest: PropTypes.func.isRequired,
  createOpportunityPaymentTier: PropTypes.func.isRequired,
  updateOpportunityPaymentTier: PropTypes.func.isRequired,
  deleteOpportunityPaymentTier: PropTypes.func.isRequired,
  openBulkTalentModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,

  // From outer
  opportunity: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, analytics } = state;
  return { user, analytics };
};

export default connect(mapStateToProps, {
  openArtistModal,
  openChatOverlay,
  openBulkTalentModal,
  openRequestModal,
  removeOpportunityRequest,
  updateOpportunityRequest,
  createOpportunityPaymentTier,
  updateOpportunityPaymentTier,
  deleteOpportunityPaymentTier
})(OpportunityLeaderboard);
