import _ from 'lodash';
import * as ActionTypes from '../Actions/OpportunityActions';
import * as UserActionTypes from '../Actions/UserActions';

export const opportunities = (
  state = {
    activeOpportunity: null,
    activeOpportunityRequest: null
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_OPPORTUNITY:
      return {
        ...state,
        activeOpportunity: action.opportunity
      };

    case ActionTypes.SET_ACTIVE_OPPORTUNITY_REQUEST:
      return {
        ...state,
        activeOpportunityRequest: action.request
      };

    case ActionTypes.SYNC_ACTIVE_OPPORTUNITY_FULL_DATA_SUCCESS:
      return {
        ...state,
        activeOpportunity: {
          ...(state.activeOpportunity || {}),
          ...action.opportunity
        }
      };

    case ActionTypes.SYNC_ACTIVE_OPPORTUNITY_RESULT_FULL_DATA_SUCCESS:
      return {
        ...state,
        activeOpportunityRequest: {
          ...(state.activeOpportunityRequest || {}),
          result: {
            ...(state.activeOpportunityRequest?.result || {}),
            ...action.result
          }
        }
      };

    case ActionTypes.CREATE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        activeOpportunity: action.opportunity
      };

    case ActionTypes.DUPLICATE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        activeOpportunity: action.opportunity
      };

    case ActionTypes.UPDATE_OPPORTUNITY_REQUEST:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          ...action.updates
        }
      };

    case ActionTypes.UPDATE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        activeOpportunity: action.opportunity
      };

    case ActionTypes.UPDATE_OPPORTUNITY_FAILURE:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          ...action.opportunity
        }
      };

    case ActionTypes.CREATE_OPPORTUNITY_UPLOAD_SUCCESS:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          uploads: state.activeOpportunity.uploads.concat(action.upload)
        }
      };

    case ActionTypes.CREATE_OPPORTUNITY_UPLOAD_FAILURE:
      window.ALERT.error(action.error || 'There was an issue creating this upload, please try again.');
      return {
        ...state
      };

    case ActionTypes.UPDATE_OPPORTUNITY_UPLOAD_REQUEST:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          uploads: state.activeOpportunity.uploads.map(upload => (upload.id === action.upload.id ? { ...upload, ...action.updates } : upload))
        }
      };

    case ActionTypes.UPDATE_OPPORTUNITY_UPLOAD_FAILURE:
      window.ALERT.error(action.error || 'There was an issue updating this upload, please try again.');
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          uploads: state.activeOpportunity.uploads.map(upload => (upload.id === action.upload.id ? action.upload : upload))
        }
      };

    case ActionTypes.UPDATE_OPPORTUNITY_EXPECTATION_SUCCESS:
      return {
        ...state,
        activeOpportunity: state.activeOpportunity && {
          ...state.activeOpportunity,
          results: state.activeOpportunity.results?.map(result => ({
            ...result,
            expectations: _.filter(
              result.expectations.map(expectation => (expectation.id === action.expectation.id ? action.expectation : expectation)),
              e => !e.hasBeenMarkedInvalid // Need this to filter out invalid expectations immediately vs waiting for reload of entire page
            )
          }))
        },
        activeOpportunityRequest:
          state.activeOpportunityRequest?.result?.id !== action.expectation.OpportunityResult_id
            ? null
            : {
                ...state.activeOpportunityRequest,
                result: {
                  ...state.activeOpportunityRequest.result,
                  expectations: _.filter(
                    state.activeOpportunityRequest.result.expectations.map(expectation =>
                      expectation.id === action.expectation.id ? action.expectation : expectation
                    ),
                    e => !e.hasBeenMarkedInvalid // Need this to filter out invalid expectations immediately vs waiting for reload of entire page
                  )
                }
              }
      };

    case ActionTypes.UPDATE_OPPORTUNITY_RESULT_REQUEST:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          results: state.activeOpportunity.results.map(result => (result.id === action.result.id ? { ...result, ...action.updates } : result))
        },
        activeOpportunityRequest:
          action.result.OpportunityRequest_id === state.activeOpportunityRequest?.id
            ? {
                ...state.activeOpportunityRequest,
                result: {
                  ...state.activeOpportunityRequest.result,
                  ...action.updates
                }
              }
            : null
      };

    case ActionTypes.UPDATE_OPPORTUNITY_RESULT_FAILURE:
      return {
        ...state,
        activeOpportunityRequest:
          action.result.OpportunityRequest_id === state.activeOpportunityRequest?.id
            ? {
                ...state.activeOpportunityRequest,
                result: action.result
              }
            : null
      };

    case ActionTypes.DELETE_OPPORTUNITY_UPLOAD_REQUEST:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          uploads: state.activeOpportunity.uploads.filter(upload => upload.id !== action.upload.id)
        }
      };

    case ActionTypes.DELETE_OPPORTUNITY_UPLOAD_FAILURE:
      window.ALERT.error(action.error || 'There was an issue deleting this upload, please try again.');
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          uploads: state.activeOpportunity.uploads.concat(action.upload)
        }
      };

    case ActionTypes.CREATE_OPPORTUNITY_PAYMENT_TIER_SUCCESS:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          payment_tiers: state.activeOpportunity.payment_tiers.concat(action.payment_tier)
        }
      };

    case ActionTypes.CREATE_OPPORTUNITY_PAYMENT_TIER_FAILURE:
      window.ALERT.error(action.error || 'There was an issue creating this payment tier, please try again.');
      return {
        ...state
      };

    case ActionTypes.UPDATE_OPPORTUNITY_PAYMENT_TIER_SUCCESS:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          payment_tiers: state.activeOpportunity.payment_tiers.map(pt => (pt.id === action.payment_tier.id ? { ...pt, ...action.payment_tier } : pt))
        }
      };

    case ActionTypes.UPDATE_OPPORTUNITY_PAYMENT_TIER_FAILURE:
      window.ALERT.error(action.error || 'There was an issue updating this payment tier, please try again.');
      return {
        ...state
      };

    case ActionTypes.DELETE_OPPORTUNITY_PAYMENT_TIER_REQUEST:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          payment_tiers: state.activeOpportunity.payment_tiers.filter(pt => pt.id !== action.payment_tier.id)
        }
      };

    case ActionTypes.DELETE_OPPORTUNITY_PAYMENT_TIER_FAILURE:
      window.ALERT.error(action.error || 'There was an issue deleting this payment tier, please try again.');
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          payment_tiers: state.activeOpportunity.payment_tiers.concat(action.payment_tier)
        }
      };

    case UserActionTypes.UPDATE_USER_OPPORTUNITY_REQUEST_SUCCESS:
      return {
        ...state,
        activeOpportunityRequest: state.activeOpportunityRequest && {
          ...state.activeOpportunityRequest,
          ...action.request
        }
      };

    // Plans
    case ActionTypes.CREATE_OPPORTUNITY_PLAN_SUCCESS:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          plans: state.activeOpportunity.plans.concat(action.plan)
        }
      };

    case ActionTypes.UPDATE_OPPORTUNITY_PLAN_SUCCESS:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          plans: state.activeOpportunity.plans.map(plan => (plan.id === action.plan.id ? { ...plan, ...action.plan } : plan))
        }
      };

    case ActionTypes.UPDATE_OPPORTUNITY_PLAN_FAILURE:
      window.ALERT.error(action.error || 'There was an issue updating this plan, please try again.');
      return {
        ...state
      };

    case ActionTypes.DELETE_OPPORTUNITY_PLAN_REQUEST:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          plans: state.activeOpportunity.plans.filter(plan => plan.id !== action.plan.id)
        }
      };

    case ActionTypes.DELETE_OPPORTUNITY_PLAN_FAILURE:
      window.ALERT.error(action.error || 'There was an issue deleting this plan, please try again.');
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          plans: state.activeOpportunity.plans.concat(action.plan)
        }
      };

    // Plan Users
    case ActionTypes.CREATE_OPPORTUNITY_PLAN_USER_SUCCESS:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          plans: state.activeOpportunity.plans.map(plan =>
            plan.id === action.user.OpportunityPlan_id
              ? {
                  ...plan,
                  users: plan.users.concat(action.user)
                }
              : plan
          )
        }
      };

    case ActionTypes.CREATE_OPPORTUNITY_PLAN_USERS_FAILURE:
      window.ALERT.error(action.error || 'There was an issue creating this plan user, please try again.');
      return {
        ...state
      };

    case ActionTypes.UPDATE_OPPORTUNITY_PLAN_USER_REQUEST:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          plans: state.activeOpportunity.plans.map(plan =>
            plan.id === action.user.OpportunityPlan_id
              ? {
                  ...plan,
                  users: plan.users.map(u => (u.id === action.user.id ? { ...u, ...action.updates } : u))
                }
              : plan
          )
        }
      };

    case ActionTypes.UPDATE_OPPORTUNITY_PLAN_USER_FAILURE:
      window.ALERT.error(action.error || 'There was an issue updating this plan user, please try again.');
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          plans: state.activeOpportunity.plans.map(plan =>
            plan.id === action.user.OpportunityPlan_id
              ? {
                  ...plan,
                  users: plan.users.map(u => (u.id === action.user.id ? action.user : u))
                }
              : plan
          )
        }
      };

    case ActionTypes.DELETE_OPPORTUNITY_PLAN_USER_REQUEST:
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          plans: state.activeOpportunity.plans.map(plan =>
            plan.id === action.user.OpportunityPlan_id
              ? {
                  ...plan,
                  users: plan.users.filter(u => u.id !== action.user.id)
                }
              : plan
          )
        }
      };

    case ActionTypes.DELETE_OPPORTUNITY_PLAN_USER_FAILURE:
      window.ALERT.error(action.error || 'There was an issue deleting this plan user, please try again.');
      return {
        ...state,
        activeOpportunity: {
          ...state.activeOpportunity,
          plans: state.activeOpportunity.plans.map(plan =>
            plan.id === action.user.OpportunityPlan_id
              ? {
                  ...plan,
                  users: plan.users.concat(action.user)
                }
              : plan
          )
        }
      };

    default:
      return state;
  }
};
