import _ from 'lodash';
import {
  createCollection as addCollectionAPI,
  getCollection as getCollectionAPI,
  saveCollection as saveCollectionAPI,
  deleteCollection as deleteCollectionAPI
} from '../APIClient/collections';
import { getShopExamples as getShopExamplesAPI } from '../APIClient/featured';
import {
  getConsult as getConsultAPI,
  getConsultResult as getConsultResultAPI,
  addConsult as addConsultAPI,
  addConsultResult as addConsultResultAPI,
  editConsult as editConsultAPI,
  editConsultResult as editConsultResultAPI,
  deleteConsult as deleteConsultAPI
} from '../APIClient/consults';
import { getMerchants as getMerchantsAPI, deletePin as deletePinAPI, editPin as editPinAPI } from '../APIClient/pins';
import { fetchHighRateMerchants, fetchFeaturedMerchants } from '../APIClient/merchants';
import { isProUser, canEditCollection } from '../Helpers/user_helpers';
import { fetchUserThroughUsername } from '../APIClient/users';
import { fetchCustomRates } from '../APIClient/brands';

import { setBrandTheme } from './UIActions';

export const GET_VISIBLE_COLLECTION_REQUEST = 'GET_VISIBLE_COLLECTION_REQUEST';
export const GET_VISIBLE_COLLECTION_SUCCESS = 'GET_VISIBLE_COLLECTION_SUCCESS';
export const GET_VISIBLE_COLLECTION_FAILURE = 'GET_VISIBLE_COLLECTION_FAILURE';
export const GET_VISIBLE_SHOP_REQUEST = 'GET_VISIBLE_SHOP_REQUEST';
export const GET_VISIBLE_SHOP_SUCCESS = 'GET_VISIBLE_SHOP_SUCCESS';
export const GET_VISIBLE_SHOP_FAILURE = 'GET_VISIBLE_SHOP_FAILURE';
export const GET_VISIBLE_CONSULT_REQUEST = 'GET_VISIBLE_CONSULT_REQUEST';
export const GET_VISIBLE_CONSULT_SUCCESS = 'GET_VISIBLE_CONSULT_SUCCESS';
export const GET_VISIBLE_CONSULT_FAILURE = 'GET_VISIBLE_CONSULT_FAILURE';
export const GET_VISIBLE_CONSULT_RESULT_REQUEST = 'GET_VISIBLE_CONSULT_RESULT_REQUEST';
export const GET_VISIBLE_CONSULT_RESULT_SUCCESS = 'GET_VISIBLE_CONSULT_RESULT_SUCCESS';
export const GET_VISIBLE_CONSULT_RESULT_FAILURE = 'GET_VISIBLE_CONSULT_RESULT_FAILURE';
export const ADD_COLLECTION_REQUEST = 'ADD_COLLECTION_REQUEST';
export const ADD_COLLECTION_SUCCESS = 'ADD_COLLECTION_SUCCESS';
export const ADD_COLLECTION_FAILURE = 'ADD_COLLECTION_FAILURE';
export const DELETE_COLLECTION_REQUEST = 'DELETE_COLLECTION_REQUEST';
export const DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS';
export const DELETE_COLLECTION_FAILURE = 'DELETE_COLLECTION_FAILURE';
export const DELETE_CONSULT_REQUEST = 'DELETE_CONSULT_REQUEST';
export const DELETE_CONSULT_SUCCESS = 'DELETE_CONSULT_SUCCESS';
export const DELETE_CONSULT_FAILURE = 'DELETE_CONSULT_FAILURE';
export const EDIT_COLLECTION_REQUEST = 'EDIT_COLLECTION_REQUEST';
export const EDIT_COLLECTION_SUCCESS = 'EDIT_COLLECTION_SUCCESS';
export const EDIT_COLLECTION_FAILURE = 'EDIT_COLLECTION_FAILURE';
export const EDIT_CONSULT_REQUEST = 'EDIT_CONSULT_REQUEST';
export const EDIT_CONSULT_SUCCESS = 'EDIT_CONSULT_SUCCESS';
export const EDIT_CONSULT_FAILURE = 'EDIT_CONSULT_FAILURE';
export const EDIT_CONSULT_RESULT_REQUEST = 'EDIT_CONSULT_RESULT_REQUEST';
export const EDIT_CONSULT_RESULT_SUCCESS = 'EDIT_CONSULT_RESULT_SUCCESS';
export const EDIT_CONSULT_RESULT_FAILURE = 'EDIT_CONSULT_RESULT_FAILURE';
export const DELETE_PIN_REQUEST = 'DELETE_PIN_REQUEST';
export const DELETE_PIN_SUCCESS = 'DELETE_PIN_SUCCESS';
export const DELETE_PIN_FAILURE = 'DELETE_PIN_FAILURE';
export const EDIT_PIN_REQUEST = 'EDIT_PIN_REQUEST';
export const EDIT_PIN_SUCCESS = 'EDIT_PIN_SUCCESS';
export const EDIT_PIN_FAILURE = 'EDIT_PIN_FAILURE';
export const ADD_CONSULT_SUCCESS = 'ADD_CONSULT_SUCCESS';
export const ADD_CONSULT_FAILURE = 'ADD_CONSULT_FAILURE';
export const ADD_CONSULT_RESULT_SUCCESS = 'ADD_CONSULT_RESULT_SUCCESS';
export const ADD_CONSULT_RESULT_FAILURE = 'ADD_CONSULT_RESULT_FAILURE';
export const SAVE_CONSULT_RESPONSE = 'SAVE_CONSULT_RESPONSE';
export const GET_CUSTOM_RATES_SUCCESS = 'GET_CUSTOM_RATES_SUCCESS';
export const SET_ATTRIBUTION_URL = 'SET_ATTRIBUTION_URL';
export const SET_VISIBLE_PRODUCT = 'SET_VISIBLE_PRODUCT';
export const SET_VISIBLE_SHOP = 'SET_VISIBLE_SHOP';
export const SET_VISIBLE_COLLECTION = 'SET_VISIBLE_COLLECTION';
export const SET_VISIBLE_CONSULT = 'SET_VISIBLE_CONSULT';
export const GET_EXAMPLE_DATA_SUCCESS = 'GET_EXAMPLE_DATA_SUCCESS';
export const UPDATE_MERCHANTS_HIGH_RATES = 'UPDATE_MERCHANTS_HIGH_RATES';
export const UPDATE_MERCHANT_DATA = 'UPDATE_MERCHANT_DATA';
export const UPDATE_MERCHANTS_FEATURED = 'UPDATE_MERCHANTS_FEATURED';

export const getVisibleCollection = (id, options = {}) => async (dispatch, getState) => {
  const { showLoader } = options;

  showLoader && dispatch({ type: GET_VISIBLE_COLLECTION_REQUEST });
  try {
    const visibleCollection = await getCollectionAPI(id);
    dispatch(setBrandTheme(visibleCollection?.user?.referring_brand));
    const canEdit = canEditCollection(visibleCollection, getState()?.user);
    return dispatch({
      type: GET_VISIBLE_COLLECTION_SUCCESS,
      visibleCollection,
      canEdit
    });
  } catch (error) {
    return dispatch({
      type: GET_VISIBLE_COLLECTION_FAILURE
    });
  }
};

export const getVisibleConsult = id => async (dispatch, getState) => {
  const visibleConsult = await getConsultAPI(id);
  dispatch(setBrandTheme(visibleConsult?.user?.referring_brand));
  if (visibleConsult) {
    return dispatch({
      type: GET_VISIBLE_CONSULT_SUCCESS,
      visibleConsult
    });
  } else {
    return dispatch({
      type: GET_VISIBLE_CONSULT_FAILURE
    });
  }
};

export const getVisibleConsultResult = stub => async (dispatch, getState) => {
  try {
    const resp = await getConsultResultAPI(stub);
    return dispatch({
      type: GET_VISIBLE_CONSULT_RESULT_SUCCESS,
      result: resp.result
    });
  } catch (error) {
    return dispatch({
      type: GET_VISIBLE_CONSULT_RESULT_FAILURE,
      error
    });
  }
};

export const getVisibleShop = (username, params = {}) => async (dispatch, getState) => {
  const { visibleShop } = getState().store || {};
  const showLoader = _.get(visibleShop, 'username') !== username;
  showLoader && dispatch({ type: GET_VISIBLE_SHOP_REQUEST });
  const resp = await fetchUserThroughUsername(username, params);
  const userProfile = _.get(resp, 'user') || {};
  dispatch(setBrandTheme(userProfile?.referring_brand));

  // Non Pro Users cannot use Pro
  if (!isProUser(userProfile) && window.__IS_PRO__) {
    return dispatch({
      type: GET_VISIBLE_SHOP_SUCCESS,
      visibleShop: {}
    });
  }

  return dispatch({
    type: GET_VISIBLE_SHOP_SUCCESS,
    visibleShop: userProfile
  });
};

export const editPin = (pin, updates) => async (dispatch, getState) => {
  try {
    dispatch({ type: EDIT_PIN_REQUEST, pin, updates });
    const newPin = await editPinAPI(pin.id, updates);
    return newPin
      ? dispatch({
          type: EDIT_PIN_SUCCESS,
          success: true,
          pin,
          updates
        })
      : dispatch({
          type: EDIT_PIN_FAILURE,
          success: false,
          error: {
            message: 'There was an issue saving this product.'
          },
          pin,
          updates
        });
  } catch (error) {
    return dispatch({
      type: EDIT_PIN_FAILURE,
      success: false,
      error: error,
      pin,
      updates
    });
  }
};

export const deletePin = pin => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_PIN_REQUEST, pin });
    const newPin = await deletePinAPI(pin.id);
    return newPin
      ? dispatch({
          type: DELETE_PIN_SUCCESS,
          success: true,
          pin
        })
      : dispatch({
          type: DELETE_PIN_FAILURE,
          error: {
            message: 'There was an issue deleting this product.'
          },
          pin
        });
  } catch (error) {
    return dispatch({
      type: DELETE_PIN_FAILURE,
      error: error,
      pin
    });
  }
};

export const addCollection = data => async (dispatch, getState) => {
  try {
    const collection = await addCollectionAPI(data);
    return dispatch({
      type: ADD_COLLECTION_SUCCESS,
      collection
    });
  } catch (error) {
    return dispatch({
      type: ADD_COLLECTION_FAILURE,
      data
    });
  }
};

export const editCollection = (id, edits) => async (dispatch, getState) => {
  const newCollection = await saveCollectionAPI(id, edits);
  return newCollection
    ? dispatch({
        type: EDIT_COLLECTION_SUCCESS,
        collection: newCollection
      })
    : dispatch({
        type: EDIT_COLLECTION_FAILURE,
        error: true
      });
};

export const deleteCollection = collection => async (dispatch, getState) => {
  dispatch({ type: DELETE_COLLECTION_REQUEST, collection });
  try {
    await deleteCollectionAPI(collection.id);
    dispatch({
      type: DELETE_COLLECTION_SUCCESS,
      collection
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: DELETE_COLLECTION_FAILURE,
      error,
      collection
    });
  }
};

export const addConsult = data => async (dispatch, getState) => {
  try {
    const element = await addConsultAPI(data);
    return dispatch({
      type: ADD_CONSULT_SUCCESS,
      element
    });
  } catch (error) {
    return dispatch({
      type: ADD_CONSULT_FAILURE,
      data
    });
  }
};

export const addConsultResult = data => async (dispatch, getState) => {
  try {
    const result = await addConsultResultAPI(data);
    return dispatch({
      type: ADD_CONSULT_RESULT_SUCCESS,
      result
    });
  } catch (error) {
    return dispatch({
      type: ADD_CONSULT_RESULT_FAILURE,
      data
    });
  }
};

// Avoid race conditions
let [lastCall, isQuickCalling] = [null, false];
export const editConsult = (id, updates) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_CONSULT_REQUEST,
    id,
    updates
  });
  try {
    lastCall = Date.now();
    const thisCall = lastCall;
    let newConsult = await editConsultAPI(id, updates);

    if (lastCall === thisCall) {
      // Fire off once more in the case that we are rapid firing on a slow connection
      newConsult = isQuickCalling ? await editConsultAPI(id, updates) : newConsult;
      isQuickCalling = false;
      return dispatch({
        type: EDIT_CONSULT_SUCCESS,
        consult: newConsult
      });
    } else {
      isQuickCalling = true;
      return dispatch({
        type: 'EDIT_CONSULT_SUCCESS_INTERMEDIATE_RESULT'
      });
    }
  } catch (error) {
    console.error(error);
    return dispatch({
      type: EDIT_CONSULT_FAILURE,
      error
    });
  }
};

export const editResult = (id, updates) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_CONSULT_RESULT_REQUEST,
    id,
    updates
  });
  try {
    const newConsultResult = await editConsultResultAPI(id, updates);
    return dispatch({
      type: EDIT_CONSULT_RESULT_SUCCESS,
      consultResult: newConsultResult
    });
  } catch (error) {
    return dispatch({
      type: EDIT_CONSULT_RESULT_FAILURE,
      error
    });
  }
};

export const deleteConsult = consult => async (dispatch, getState) => {
  dispatch({ type: DELETE_CONSULT_REQUEST, consult });
  try {
    await deleteConsultAPI(consult);
    dispatch({
      type: DELETE_CONSULT_SUCCESS,
      consult
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: DELETE_CONSULT_FAILURE,
      error,
      consult
    });
  }
};

export const setVisibleCollection = visibleCollection => async (dispatch, getState) => {
  const canEdit = canEditCollection(visibleCollection, getState()?.user);
  return dispatch({
    type: SET_VISIBLE_COLLECTION,
    visibleCollection,
    canEdit
  });
};

export const setVisibleConsult = visibleConsult => async (dispatch, getState) => {
  return dispatch({
    type: SET_VISIBLE_CONSULT,
    visibleConsult
  });
};

export const setVisibleShop = user => async (dispatch, getState) => {
  return dispatch({
    type: SET_VISIBLE_SHOP,
    visibleShop: user
  });
};

export const setVisibleProduct = visibleProduct => async (dispatch, getState) => {
  return dispatch({
    type: SET_VISIBLE_PRODUCT,
    visibleProduct
  });
};

export const saveConsultResponse = (consult, content) => async (dispatch, getState) => {
  return dispatch({
    type: SAVE_CONSULT_RESPONSE,
    consult,
    content
  });
};

export const getCustomRates = id => async (dispatch, getState) => {
  const resp = await fetchCustomRates(id);
  return dispatch({
    type: GET_CUSTOM_RATES_SUCCESS,
    rates: _.get(resp, 'rates')
  });
};

export const getHighRateMerchants = () => async (dispatch, getState) => {
  const resp = await fetchHighRateMerchants();
  const merchants = _.get(resp, 'merchants');
  return dispatch({
    type: UPDATE_MERCHANTS_HIGH_RATES,
    merchants
  });
};

export const getShopExamples = () => async (dispatch, getState) => {
  // TODO: use legitimate HTTP caching and not this style
  const prevExamples = getState()?.store?.examples || {};
  const needRefresh = !prevExamples.lastFetched || prevExamples.lastFetched + 1000 * 30 < Date.now(); // 30 second cache
  const examples = needRefresh ? (await getShopExamplesAPI()).examples : prevExamples;
  return dispatch({
    type: GET_EXAMPLE_DATA_SUCCESS,
    examples: examples || {},
    resetCache: needRefresh
  });
};

export const getFeaturedMerchants = () => async (dispatch, getState) => {
  const resp = await fetchFeaturedMerchants();
  const featured = _.get(resp, 'featured');
  const domains = _.map(featured, 'domain') || [];
  dispatch(updateMerchantData(domains));
  return dispatch({
    type: UPDATE_MERCHANTS_FEATURED,
    featured: featured || []
  });
};

export const updateMerchantData = (merchants = []) => async (dispatch, getState) => {
  const { merchantData } = getState().store || {};
  const needRefresh = _.filter(merchants, merchant => _.get(merchantData, [merchant, 'cacheExpires'], 0) < Date.now());
  if (!needRefresh.length) return;
  const { data } = (await getMerchantsAPI(_.uniq(needRefresh))) || {};
  return dispatch({
    type: UPDATE_MERCHANT_DATA,
    data: data || {}
  });
};
