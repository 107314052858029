import { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { syncTags, syncDiscoverGroups, syncActiveDiscoverGroup } from '../../Actions/DiscoverActions';

import { discoverUsers } from '../../APIClient/discover';
import { getBrandId } from '../../Helpers/user_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { getDiscoverResultsLimit } from '../../Helpers/discover_helpers';

let debounce;
const DiscoverData = props => {
  const {
    discover,
    user,
    ui,
    sortBy,
    curSearchVal,
    setIsSearchingUsers,
    page,
    selectedTags,
    hideMyPromoters,
    onlyMyPromoters,
    hideAlreadyGifted,
    isForcingReload
  } = props;

  const numTags = selectedTags.length;
  const adminControlMode = isAdminControlMode(ui);

  // Initial Fetch
  useEffect(() => {
    props.syncDiscoverGroups();
    discover.activeGroup && props.syncActiveDiscoverGroup(discover.activeGroup);
  }, []);

  // Handle the end of the results
  const [noMoreResults, setNoMoreResults] = useState(false);
  const fetchData = (query, tags) => {
    const LIMIT = getDiscoverResultsLimit();
    if (noMoreResults && page > 0) return setIsSearchingUsers(false); // Don't search if we already have all the results
    if (page === 0) setNoMoreResults(false); // Reset noMoreResults if we're on page 0

    discoverUsers({
      Brand_id: getBrandId(user),
      tagids: tags.map(t => t.id),
      ...(curSearchVal ? { query } : {}),
      ...(hideMyPromoters ? { hideMyPromoters: true } : {}),
      ...(onlyMyPromoters ? { onlyMyPromoters: true } : {}),
      ...(hideAlreadyGifted ? { hideAlreadyGifted: true } : {}),
      sortBy: sortBy,
      page: page,
      limit: LIMIT
    })
      .then(resp => {
        const newResults = [...props.results.slice(0, page * LIMIT), ...resp.results];
        props.setResults(newResults);
        props.setLists(resp.lists);
        if (!resp.results.length) {
          setNoMoreResults(true);
        }
      })
      .catch(err => {
        window.ALERT.error('Error fetching discover results, please check your connection.');
        setNoMoreResults(true);
      })
      .finally(() => {
        setIsSearchingUsers(false);
      });
  };

  // Fetch Data Initially
  const initialMountRef = useRef();
  const isInitialMount = !initialMountRef.current;
  useEffect(() => {
    fetchData(curSearchVal, selectedTags);
    initialMountRef.current = true;
  }, []);

  // Search Version 1 - Changed Input Field
  useEffect(() => {
    if (isInitialMount) return;
    setIsSearchingUsers(true);
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      fetchData(curSearchVal, selectedTags);
    }, 750);
  }, [curSearchVal]);

  // Search Version 2 - Toggled a Tag
  useEffect(() => {
    if (isInitialMount) return;
    setIsSearchingUsers(true);
    fetchData(curSearchVal, selectedTags);
  }, [numTags]);

  // Search Version 3 - Toggled Configuration Button or changed sort
  useEffect(() => {
    if (isInitialMount) return;
    setIsSearchingUsers(true);
    fetchData(curSearchVal, selectedTags);
  }, [hideMyPromoters, onlyMyPromoters, hideAlreadyGifted, sortBy]);

  // Search Version 4 - New Page
  useEffect(() => {
    if (isInitialMount) return;
    setIsSearchingUsers(true);
    const runFetch = () => fetchData(curSearchVal, selectedTags);
    page > 0 ? props.checkSubscriptionLevelThenRunFn(runFetch) : runFetch();
  }, [page]);

  // Force Reload
  useEffect(() => {
    if (isInitialMount) return;
    // setIsSearchingUsers(true); -- Don't show loading state, this is called when someone is removing a user from Discover
    fetchData(curSearchVal, selectedTags);
  }, [isForcingReload]);

  // Tag Counts
  useEffect(() => {
    props.setIsSearchingTags(true);
    props
      .syncTags({
        tagids: props.selectedTags.map(t => t.id),
        ...(adminControlMode ? { showHidden: true } : {}),
        ...(hideMyPromoters ? { hideMyPromoters: true } : {}),
        ...(onlyMyPromoters ? { onlyMyPromoters: true } : {}),
        ...(hideAlreadyGifted ? { hideAlreadyGifted: true } : {})
      })
      .then(resp => {
        props.setIsSearchingTags(false);
      });
  }, [numTags, adminControlMode, hideMyPromoters, onlyMyPromoters, hideAlreadyGifted]);

  return null;
};

DiscoverData.propTypes = {
  // Outside
  page: PropTypes.number.isRequired,
  results: PropTypes.array.isRequired,
  setResults: PropTypes.func.isRequired,
  setLists: PropTypes.func.isRequired,
  curSearchVal: PropTypes.string.isRequired,
  setIsSearchingUsers: PropTypes.func.isRequired,
  selectedTags: PropTypes.array.isRequired,
  isSearchingTags: PropTypes.bool.isRequired,
  setIsSearchingTags: PropTypes.func.isRequired,
  hideMyPromoters: PropTypes.bool.isRequired,
  onlyMyPromoters: PropTypes.bool.isRequired,
  hideAlreadyGifted: PropTypes.bool.isRequired,
  isForcingReload: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  checkSubscriptionLevelThenRunFn: PropTypes.func.isRequired,

  // Redux
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  discover: PropTypes.object.isRequired,
  syncTags: PropTypes.func.isRequired,
  syncDiscoverGroups: PropTypes.func.isRequired,
  syncActiveDiscoverGroup: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { discover, user, ui } = state;
  return { discover, user, ui };
};

export default connect(mapStateToProps, {
  syncTags,
  syncDiscoverGroups,
  syncActiveDiscoverGroup
})(DiscoverData);
