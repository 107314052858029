import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import SettingsProfile from './Profile/SettingsProfile';
import Payouts from '../../Components/Payouts/Payouts';

import RequiresLoginPanel from '../../Components/General/RequiresLoginPanel';
import { fetchBrandDeveloperKey } from '../../APIClient/brands';

import {
  updateCurrentUser,
  addTagsToUser,
  deleteTagsForUser,
  connectInstagram,
  disconnectSocialAccount,
  connectStripeAccount,
  setAddress,
  logoutUser,
  syncUserTier,
  updateUserSettings
} from '../../Actions/UserActions';
import {
  getBrandInvoices,
  updateBrand,
  updateBrandSettings,
  saveShopifyIntegration,
  updateBrandIntegration,
  syncShopifyScopesForBrandId
} from '../../Actions/BrandActions';
import { getUserPayouts, getUserPayments, linkPaypalAccount, linkInvoiceEmailAccount, unlinkPaymentAccount } from '../../Actions/PayoutActions';
import { openAddressModal, openBrandBudgetModal, openConnectInstagramModal } from '../../Actions/UIActions';

import { insertMetaTags } from '../../Helpers/seo_helpers';
import { getBrandId, isBrand, isLoggedIn } from '../../Helpers/user_helpers';
import { isSimulatingUser } from '../../Helpers/user_helpers';

import './Settings.scss';

class Settings extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    updateCurrentUser: PropTypes.func.isRequired,
    addTagsToUser: PropTypes.func.isRequired,
    deleteTagsForUser: PropTypes.func.isRequired,
    connectInstagram: PropTypes.func.isRequired,
    disconnectSocialAccount: PropTypes.func.isRequired,
    connectStripeAccount: PropTypes.func.isRequired,
    saveShopifyIntegration: PropTypes.func.isRequired,
    updateBrandIntegration: PropTypes.func.isRequired,
    syncShopifyScopesForBrandId: PropTypes.func.isRequired,
    getUserPayouts: PropTypes.func.isRequired,
    getUserPayments: PropTypes.func.isRequired,
    getBrandInvoices: PropTypes.func.isRequired,
    linkPaypalAccount: PropTypes.func.isRequired,
    unlinkPaymentAccount: PropTypes.func.isRequired,
    setAddress: PropTypes.func.isRequired,
    updateBrand: PropTypes.func.isRequired,
    updateBrandSettings: PropTypes.func.isRequired,
    openBrandBudgetModal: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    syncUserTier: PropTypes.func.isRequired,
    isPayouts: PropTypes.bool,
    openAddressModal: PropTypes.func.isRequired,
    openConnectInstagramModal: PropTypes.func.isRequired,
    updateUserSettings: PropTypes.func.isRequired
  };

  state = {
    selectedTab: 'profile',
    developerKey: ''
  };

  componentDidMount() {
    if (isBrand(this.props.user)) {
      const Brand_id = getBrandId(this.props.user);
      if (Brand_id) {
        fetchBrandDeveloperKey(Brand_id)
          .then(res => this.setState({ developerKey: res.developerKey }))
          .catch(() => window.ALERT.error('Error fetching brand developer key'));
      }
    }
  }

  render() {
    const { user, ui, manager, isPayouts } = this.props;
    if (!isLoggedIn(user)) return <RequiresLoginPanel />;
    return (
      <div className='settings-outer-container'>
        {insertMetaTags({
          title: isPayouts ? (isBrand(user) ? 'Invoices & Subscriptions' : 'Earnings') : 'Account Settings',
          description: '',
          image: ''
        })}

        <div className='settings-body-inner-container'>
          {isPayouts ? (
            <Payouts
              user={user}
              ui={ui}
              linkPaypalAccount={this.props.linkPaypalAccount}
              linkInvoiceEmailAccount={this.props.linkInvoiceEmailAccount}
              unlinkPaymentAccount={this.props.unlinkPaymentAccount}
              getUserPayouts={this.props.getUserPayouts}
              getUserPayments={this.props.getUserPayments}
              getBrandInvoices={this.props.getBrandInvoices}
              updateCurrentUser={this.props.updateCurrentUser}
              openBrandBudgetModal={this.props.openBrandBudgetModal}
              updateBrandSettings={this.props.updateBrandSettings}
            />
          ) : (
            <SettingsProfile
              user={user.profile}
              ui={ui}
              manager={manager}
              isSimulatingUser={isSimulatingUser(user)}
              connectInstagram={this.props.connectInstagram}
              disconnectSocialAccount={this.props.disconnectSocialAccount}
              connectStripeAccount={this.props.connectStripeAccount}
              saveShopifyIntegration={this.props.saveShopifyIntegration}
              updateBrandIntegration={this.props.updateBrandIntegration}
              syncShopifyScopesForBrandId={this.props.syncShopifyScopesForBrandId}
              syncUserTier={this.props.syncUserTier}
              setAddress={this.props.setAddress}
              logoutUser={this.props.logoutUser}
              updateCurrentUser={this.props.updateCurrentUser}
              addTagsToUser={this.props.addTagsToUser}
              deleteTagsForUser={this.props.deleteTagsForUser}
              updateBrand={this.props.updateBrand}
              updateBrandSettings={this.props.updateBrandSettings}
              openAddressModal={this.props.openAddressModal}
              openConnectInstagramModal={this.props.openConnectInstagramModal}
              developerKey={this.state.developerKey}
              updateUserSettings={this.props.updateUserSettings}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user, manager, ui } = state;
  return { user, manager, ui };
};

export default connect(mapStateToProps, {
  updateCurrentUser,
  updateBrand,
  updateBrandSettings,
  addTagsToUser,
  deleteTagsForUser,
  setAddress,
  getUserPayouts,
  getUserPayments,
  getBrandInvoices,
  connectInstagram,
  connectStripeAccount,
  saveShopifyIntegration,
  updateBrandIntegration,
  syncShopifyScopesForBrandId,
  disconnectSocialAccount,
  linkPaypalAccount,
  linkInvoiceEmailAccount,
  unlinkPaymentAccount,
  logoutUser,
  syncUserTier,
  openAddressModal,
  openBrandBudgetModal,
  openConnectInstagramModal,
  updateUserSettings
})(withRouter(Settings));
