import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './List.scss';

import { deleteBrandListUser, updateBrandListUser } from '../../Actions/BrandActions';
import { openArtistModal, openChatOverlay, openAuthModal, openBulkTalentModal, openCodesModal, openRequestModal } from '../../Actions/UIActions';

import { getDiscoverList, getDiscoverListMentions } from '../../APIClient/discover';
import { getBrandList } from '../../APIClient/lists';
import { getBrandId, isLoggedIn } from '../../Helpers/user_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { blockOnRequiringSubscription } from '../../Helpers/subscription_helpers';

import Loader from '../../Components/Loader/Loader';
import ListHeader from '../../Components/Lists/ListHeader';
import ListSettings from '../../Components/Lists/ListSettings';
import ListUsers from '../../Components/Lists/ListUsers';
import ListMentions from '../../Components/Lists/ListMentions';
import ListSimilarLists from '../../Components/Lists/ListSimilarLists';
import ScrollToTop from '../../Components/General/ScrollToTop';

const List = props => {
  const { isBrandList, talent, user, ui } = props;

  // Allow non-logged in users to see the page, but not take actions
  const ensureValidPackageThenCall = cb => {
    if (blockOnRequiringSubscription(user, 'DISCOVER_2.0', { ui })) return null;
    cb();
  };
  const ensureLoggedInThenCall = cb => {
    if (isLoggedIn(user)) return cb();
    props.openAuthModal('apply-brands');
  };

  // UI State Management
  const [isEditing, setIsEditing] = useState(false);

  // Data Collection
  const Brand_id = getBrandId(user);
  const [list, setList] = useState(null);
  const [mentions, setMentions] = useState([]);
  const [isSyncingList, setIsSyncingList] = useState(false);
  const syncList = async () => {
    const listId = props.match.params.id;
    setIsSyncingList(true);
    if (isBrandList) {
      getBrandList(listId).then(resp => {
        setList(resp.list);
      });
    } else {
      // Fetch core Discover List object
      let list;
      try {
        const resp = await getDiscoverList(listId, Brand_id ? { Brand_id } : {});
        list = resp.list;
        setList(list);
        setIsSyncingList(false);
        if (isAdminControlMode(ui) && !resp.list.title) setIsEditing(true); // Default to make it easier to create new lists
      } catch (error) {
        window.ALERT.error(error?.message || 'Could not find this list, please try again.');
      }

      // We fetch mentions asynchronously from the main call in order to increase load performance
      try {
        if (!list?.users?.length) return setMentions([]);

        const mentions = await getDiscoverListMentions(list.id, {
          ...(Brand_id ? { Brand_id } : {}),
          User_ids: list.users.map(u => u.User_id)
        });
        setMentions(mentions);
      } catch (error) {}
    }
  };
  useEffect(() => {
    syncList();
  }, []);

  // Filtering & Sorting
  const [hideCurrentPromoters, setHideCurrentPromoters] = useState(false);
  const [sortBy, setSortBy] = useState(props.sortBy);
  const [sortDirection, setSortDirection] = useState(null);

  const showSimilarLists = !isBrandList && isLoggedIn(user);
  const showMentions = isBrandList || !!list?.showMentions;

  return (
    <div className='list-outer-container'>
      <ScrollToTop />;
      <div className='list-inner-container'>
        {!list ? (
          <div className='loader-container'>
            <Loader size={100} />
          </div>
        ) : (
          <div className='sections'>
            <ListHeader
              ui={ui}
              user={user}
              list={list}
              isBrandList={isBrandList}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              syncList={syncList}
              openBulkTalentModal={props.openBulkTalentModal}
              openRequestModal={props.openRequestModal}
              hideCurrentPromoters={hideCurrentPromoters}
              setHideCurrentPromoters={setHideCurrentPromoters}
              ensureLoggedInThenCall={ensureLoggedInThenCall}
              ensureValidPackageThenCall={ensureValidPackageThenCall}
            />
            {isEditing && !isBrandList && <ListSettings user={user} list={list} syncList={syncList} />}
            <ListUsers
              talent={talent}
              user={user}
              list={list}
              sortBy={sortBy}
              sortDirection={sortDirection}
              analytics={props.analytics}
              isBrandList={!!isBrandList}
              isEditing={isEditing}
              syncList={syncList}
              isSyncingList={isSyncingList}
              openArtistModal={props.openArtistModal}
              openChatOverlay={props.openChatOverlay}
              hideCurrentPromoters={hideCurrentPromoters}
              ensureLoggedInThenCall={ensureLoggedInThenCall}
              deleteBrandListUser={props.deleteBrandListUser}
              updateBrandListUser={props.updateBrandListUser}
              openCodesModal={props.openCodesModal}
              openRequestModal={props.openRequestModal}
            />
            {showSimilarLists && <ListSimilarLists user={user} list={list} isEditing={isEditing} syncList={syncList} />}
            {showMentions && (
              <ListMentions
                ui={ui}
                user={user}
                list={list}
                mentions={mentions}
                syncList={syncList}
                isEditing={isEditing}
                isBrandList={isBrandList}
                openArtistModal={props.openArtistModal}
                openChatOverlay={props.openChatOverlay}
                openAuthModal={props.openAuthModal}
                ensureLoggedInThenCall={ensureLoggedInThenCall}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

List.propTypes = {
  talent: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  openAuthModal: PropTypes.func.isRequired,
  openBulkTalentModal: PropTypes.func.isRequired,
  openCodesModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  deleteBrandListUser: PropTypes.func.isRequired,
  updateBrandListUser: PropTypes.func.isRequired,

  // From outside
  isBrandList: PropTypes.bool
};

const mapStateToProps = state => {
  const { ui, user, analytics, talent } = state;
  return { ui, user, analytics, talent };
};

export default connect(mapStateToProps, {
  openArtistModal,
  openChatOverlay,
  openAuthModal,
  openBulkTalentModal,
  deleteBrandListUser,
  updateBrandListUser,
  openCodesModal,
  openRequestModal
})(withRouter(List));
