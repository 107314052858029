import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import PropTypes from 'prop-types';
import _ from 'lodash';

class ScrollToTop extends Component {
  static propTypes = {
    from: PropTypes.array
  };

  componentDidMount() {
    const { from, lastLocation } = this.props;
    const needsScroll = !from || !!_.find(from, loc => lastLocation && lastLocation.pathname.includes(loc));
    needsScroll && setTimeout(() => window.scrollTo(0, 0), 0);
  }

  render() {
    return <React.Fragment />;
  }
}

export default withLastLocation(withRouter(ScrollToTop));
