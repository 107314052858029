import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import './BrandLookbookBody.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import BrandLookbookProducts from './BrandLookbookProducts';
import cn from 'classnames';
import { matchScrollHeight } from '../../../Helpers/helpers';
import LookbookReport from './LookbookReport/LookbookReport';

let debouncer;

const BrandLookbookBody = props => {
  const {
    lookbook,
    openSettingsModal,
    deleteItemFromLookbook,
    updateLookbook,
    openAddProductsModal,
    openReplaceProductsModal,
    updateLookbookItem,
    saving
  } = props;
  const { inPublicViewMode, inAnalyticsMode } = props;

  const { price_limit, item_limit, brandNote, brand } = lookbook;
  const brandMessageRef = useRef(null);

  const updateLookbookBrandNote = e => {
    const newBrandNote = e.target.value;
    clearTimeout(debouncer);
    debouncer = setTimeout(async () => {
      if (newBrandNote === brandNote || newBrandNote === '') return;
      await updateLookbook({ brandNote: newBrandNote });
    }, 1000);
  };

  const showPriceLimitBlock = !inPublicViewMode || !!price_limit;
  const showItemLimitBlock = !inPublicViewMode || !!item_limit;

  return (
    <div className='brand-lookbook-body-outer'>
      <div className='brand-lookbook-body-inner'>
        {inAnalyticsMode ? (
          <LookbookReport lookbook={lookbook} />
        ) : (
          <>
            <div className='header'>
              <div id='note-from-brand'>
                <div className='image-container'>
                  <img src={brand.logo} alt='brand-logo' className='icon' />
                </div>
                <div className='text'>
                  <div className='intro'>A note from {inPublicViewMode ? brand.name : 'you'}</div>
                  <textarea
                    placeholder='Write a description here for your talent to see'
                    rows={1}
                    className='brand-note'
                    onChange={updateLookbookBrandNote}
                    defaultValue={brandNote}
                    spellCheck={false}
                    ref={ref => {
                      matchScrollHeight(ref);
                      brandMessageRef.current = ref;
                    }}
                  />
                </div>
              </div>
              <div id='price-and-item-limit'>
                {showPriceLimitBlock && (
                  <div className='price-limit' onClick={openSettingsModal}>
                    <div className={cn('limit-content', { inactive: !price_limit })}>
                      {!inPublicViewMode && !!price_limit && <FontAwesomeIcon icon={faEdit} />}
                      <span>{price_limit ? `$${commaNumber(price_limit)}` : '-'}</span>
                    </div>
                    <div className='limit-label'>{price_limit ? 'Price Limit' : 'Set Price Limit'}</div>
                  </div>
                )}
                {showItemLimitBlock && (
                  <div className='item-limit' onClick={openSettingsModal}>
                    <div className={cn('limit-content', { inactive: !item_limit })}>
                      {!inPublicViewMode && !!item_limit && <FontAwesomeIcon icon={faEdit} />}
                      {item_limit ? (
                        <>
                          {item_limit} {item_limit === 1 ? 'Item' : 'Items'}
                        </>
                      ) : (
                        '-'
                      )}
                    </div>
                    <div className='limit-label'>{item_limit ? 'Item Limit' : 'Set Item Limit'}</div>
                  </div>
                )}
              </div>
            </div>
            <BrandLookbookProducts
              ui={props.ui}
              user={props.user}
              analytics={props.analytics}
              lookbook={lookbook}
              lookbooks={props.lookbooks}
              updateLookbookCart={props.updateLookbookCart}
              deleteItemFromLookbook={deleteItemFromLookbook}
              openEditModal={props.openEditModal}
              inPublicViewMode={inPublicViewMode}
              openAddProductsModal={openAddProductsModal}
              openReplaceProductsModal={openReplaceProductsModal}
              updateLookbookItem={updateLookbookItem}
              saving={saving}
            />
          </>
        )}
      </div>
    </div>
  );
};

BrandLookbookBody.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  lookbooks: PropTypes.object.isRequired,
  updateLookbook: PropTypes.func.isRequired,
  deleteItemFromLookbook: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  openSettingsModal: PropTypes.func.isRequired,
  openAddProductsModal: PropTypes.func.isRequired,
  openReplaceProductsModal: PropTypes.func.isRequired,
  updateLookbookItem: PropTypes.func.isRequired,
  updateLookbookCart: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,

  inPublicViewMode: PropTypes.bool.isRequired,
  inAnalyticsMode: PropTypes.bool.isRequired
};

export default BrandLookbookBody;
