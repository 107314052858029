import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { getUserId, getUserPaymentAccounts, isBrand, isBanned, getBrand, getStripeAccountId } from '../../Helpers/user_helpers';
import { isValidEmail } from '../../Helpers/formatting';

import './PayoutsAccounts.scss';

class PayoutsAccounts extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    linkPaypalAccount: PropTypes.func.isRequired,
    linkInvoiceEmailAccount: PropTypes.func.isRequired,
    unlinkPaymentAccount: PropTypes.func.isRequired,
    updateCurrentUser: PropTypes.func.isRequired
  };

  loadPaypal = (idx = 0) => {
    const script = document.createElement('script');
    script.src = 'https://www.paypalobjects.com/payouts/js/payouts_aac.js';
    script.setAttribute('data-namespace', `paupal_sdk${idx || ''}`);

    script.onload = () => {
      window.PayoutsAAC = window.paypal?.PayoutsAAC || window.PayoutsAAC; // We've seen issues of double loading so need to store it here
      const scriptSubscription = document.createElement('script');
      scriptSubscription.src = window.__IS_PROD__
        ? 'https://www.paypal.com/sdk/js?client-id=AcyzI0djoatYzkD8QnBVErRCeGfsdzuW1nHgbAWYcl0VYet1MHmd_N3ZX-QZPQob7r5Qx13fu__p9CJv&vault=true&intent=subscription'
        : 'https://www.paypal.com/sdk/js?client-id=AV8LJVWzjxYCtQ1tVwyaPL3XJFnaMdYro-_Fdc7San0uwh6MZARYkhYnvYKWZ0laBFDhdo3-go1ST0fH&vault=true&intent=subscription';
      scriptSubscription.setAttribute('data-sdk-integration-source', 'button');

      window.paypal = null;
      scriptSubscription.onload = async () => {
        const PayoutsAAC = window.paypal.PayoutsAAC || window.PayoutsAAC;
        const AACComponent = PayoutsAAC?.driver('react', { React, ReactDOM });

        if (!AACComponent) {
          cogoToast.warn('Issue loading!!');
          return null;
        }

        this.setState({
          paypalLinkComponent: (
            <AACComponent
              clientId={{
                production: 'AcyzI0djoatYzkD8QnBVErRCeGfsdzuW1nHgbAWYcl0VYet1MHmd_N3ZX-QZPQob7r5Qx13fu__p9CJv',
                sandbox: 'AV8LJVWzjxYCtQ1tVwyaPL3XJFnaMdYro-_Fdc7San0uwh6MZARYkhYnvYKWZ0laBFDhdo3-go1ST0fH'
              }}
              style={{ layout: 'vertical', color: 'blue', shape: 'rect', label: 'paypal' }}
              merchantId='BZ2U36U3HHSL6'
              env={window.__IS_PROD__ ? 'production' : 'sandbox'}
              pageType='login'
              onLogin={this.linkPayPal}
            />
          )
        });
      };
      document.body.appendChild(scriptSubscription);
    };
    document.body.appendChild(script);
  };

  componentDidMount() {
    if (!window.paypal) {
      this.loadPaypal();
    }
  }

  state = {
    unlinkingAccount: false,
    linkingPaypal: false,
    linkingEmail: false,
    loadedPaypalScript: false,
    isAdminControlMode: false,
    paypalLinkComponent: null
  };

  unlinkAccount = account => {
    if (this.state.unlinkingAccount) return;
    this.setState({ unlinkingAccount: true });
    this.props.unlinkPaymentAccount(account).then(() => {
      this.setState({ unlinkingAccount: false });
    });
  };

  linkPayPal = response => {
    const { user } = this.props;
    const code = _.get(response, 'body.code');
    if (isBanned(user)) {
      cogoToast.warn(`Your account is currently under review and cannot link a PayPal account.`);
    } else if (response.err || !code) {
      cogoToast.error(
        'PayPal is current experiencing outages on their account linking. For now, please email the PayPal email from your account to us at team@shopmy.us and we will manually send you payments. We are working with PayPal to resolve the issue and apologize for the extra steps.',
        { hideAfter: 10 }
      );
    } else {
      this.setState({ linkingPaypal: true });
      this.props.linkPaypalAccount(user, code).then(resp => {
        this.setState({ linkingPaypal: false });
      });
    }
  };

  requestEmail = () => {
    const { user } = this.props;
    const email = prompt('Please enter an email to receive invoices.');
    if (isValidEmail(email)) {
      this.setState({ linkingEmail: true });
      this.props.linkInvoiceEmailAccount(user, email).then(resp => {
        this.setState({ linkingEmail: false });
      });
    } else {
      cogoToast.error('This email is invalid, please try again.');
    }
  };

  getDisplaySource = source =>
    ({
      INVOICE_EMAIL: 'Email To Send Invoices For Commissions',
      PAYPAL_SUBSCRIPTION: 'Basic Subscription PayPal',
      PAYPAL_SUBSCRIPTION_PREMIUM: 'Premium Subscription PayPal'
    }[source] || source);

  tryLinking = () => {
    cogoToast.warn(
      `If no popup appears when clicking this button, please send us a chat message with your PayPal email and we will connect it manually.`
    );
  };

  unlinkStripe = () => {
    const { user } = this.props;
    confirmAlert({
      title: 'Just confirming',
      message: 'Are you sure you want to unlink your Stripe Account?',
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        {
          label: 'Yes',
          onClick: () => {
            this.props.updateCurrentUser({ stripeAccountId: null, stripeOnboardingComplete: false }, getUserId(user));
          }
        }
      ]
    });
  };

  render() {
    const { user } = this.props;
    const { linkingPaypal, linkingEmail, paypalLinkComponent } = this.state;
    const brand = getBrand(user);
    const payment_accounts = getUserPaymentAccounts(user);
    const requiresInvoiceAccount = brand && !_.find(payment_accounts, acct => !acct.source.includes('SUBSCRIPTION'));
    const stripeAccountId = getStripeAccountId(user);
    return (
      <div className='payout-accounts-outer-container'>
        <div className='column-row-container'>
          <div className='column-row header'>
            <div className='column-cell'>TYPE</div>
            <div className='column-cell'>ACCOUNT</div>
            <div className='column-cell'>ACTION</div>
          </div>
          {stripeAccountId && (
            <div className='column-row'>
              <div className='column-cell'>STRIPE</div>
              <div className='column-cell'>{stripeAccountId}</div>
              <div onClick={this.unlinkStripe} className='column-cell email-btn, btn'>
                REMOVE
              </div>
            </div>
          )}
          {_.map(payment_accounts, account => {
            const { paypalEmail, email, address, paypalSubscriptionId, source } = account;
            const isSubscription = _.includes(source, 'SUBSCRIPTION');
            return (
              <div key={account.id} className='column-row'>
                <div className='column-cell'>{this.getDisplaySource(source)}</div>
                <div className='column-cell'>{paypalEmail || email || address || paypalSubscriptionId}</div>
                <div
                  onClick={() =>
                    isSubscription && !window.__IS_DEV__
                      ? cogoToast.warn('Please reach out to us at team@shopmy.us to cancel your subscription.')
                      : this.unlinkAccount(account)
                  }
                  className='column-cell btn'
                >
                  REMOVE
                </div>
              </div>
            );
          })}
          {!isBrand(user) && !_.get(payment_accounts, 'length') && (
            <div className='column-row'>
              <div className='column-cell'>{isBrand(user) ? 'Commission Invoices - PayPal' : 'PAYPAL'}</div>
              <div className='column-cell'>-</div>
              <div
                className={cn('column-cell', 'paypal-btn', {
                  btn: !linkingPaypal,
                  loaded: !!paypalLinkComponent
                })}
                onClick={() => {
                  if (!paypalLinkComponent) {
                    cogoToast.warn(
                      `Please refresh the page to see if the red background goes away! If the background of the button remains red after refreshing, please contact a member of our team by chat or by email at team@shopmy.us to provide your PayPal email address so we can pay you on the first of each month.`,
                      { hideAfter: 12 }
                    );
                    // logToSlack({
                    //   message: `Issue loading PayPal component for ${getUsername(user)}, please investigate. We asked them to refresh.`,
                    //   channel: 'critical'
                    // });
                  }
                }}
              >
                {linkingPaypal ? (
                  <span>LINKING...</span>
                ) : (
                  <>
                    <span onClick={this.tryLinking}>LINK ACCOUNT</span>
                    <div className='paypal-link-btn'>{paypalLinkComponent}</div>
                  </>
                )}
              </div>
            </div>
          )}
          {requiresInvoiceAccount && (
            <div className='column-row'>
              <div className='column-cell'>{this.getDisplaySource('INVOICE_EMAIL')}</div>
              <div className='column-cell'>-</div>
              <div onClick={this.requestEmail} className='column-cell email-btn, btn'>
                {linkingEmail ? 'LINKING...' : 'ADD EMAIL ADDRESS'}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PayoutsAccounts;
