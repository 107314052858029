import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import cn from 'classnames';
import _ from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './Basic.scss';
import './BrandSetup.scss';

import ScrollToTop from '../../Components/General/ScrollToTop';

import { fetchBrandDeveloperKey } from '../../APIClient/brands';
import { updateBrand } from '../../Actions/BrandActions';

import { getBrand } from '../../Helpers/user_helpers';

import BrandSetupGeneral from './BrandSetupPages/BrandSetupGeneral';
import BrandSetupBigCommerce from './BrandSetupPages/BrandSetupBigCommerce';
import BrandSetupShopify from './BrandSetupPages/BrandSetupShopify';
import BrandSetupShopifyPlus from './BrandSetupPages/BrandSetupShopifyPlus';
import BrandSetupWooCommerce from './BrandSetupPages/BrandSetupWooCommerce';
import BrandSetupSalesforce from './BrandSetupPages/BrandSetupSalesforce';
import BrandSetupMiva from './BrandSetupPages/BrandSetupMiva';

const BrandSetup = props => {
  const { updateBrand, user, match } = props;
  const setupTypeFromUrl = _.get(match, ['params', 'type']);
  const brand = getBrand(user);
  const [setupType, setSetupType] = useState(setupTypeFromUrl || 'general');
  const [developerKey, setDeveloperKey] = useState();
  const generalCancellationEndpoint = `https://api.shopmy.us/api/Affiliates/cancel`;
  const generalUpdateEndpoint = `https://api.shopmy.us/api/Affiliates/update`;

  useEffect(() => {
    if (!brand?.id) return;

    fetchBrandDeveloperKey(brand.id)
      .then(res => setDeveloperKey(res.developerKey))
      .catch(() => window.ALERT.error('Error fetching brand developer key'));
  }, [brand?.id]);

  const isGeneralSetup = setupType === 'general';
  const isShopifySetup = setupType === 'shopify';
  const isShopifyPlusSetup = setupType === 'shopify_plus';
  const isWoocommerceSetup = setupType === 'woocommerce';
  const isBigCommerceSetup = setupType === 'bigcommerce';
  const isSalesforceSetup = setupType === 'salesforce';
  const isMivaSetup = setupType === 'miva';
  const clickTrackingCode = `<script defer src="https://static.shopmy.us/Affiliates/sms_aff_clicktrack.js"></script>`;

  return (
    <div className='brand-setup-outer-container basic-outer-container'>
      <ScrollToTop from={['/talent', '/settings']} />
      <MetaTags>
        <title>Affiliate Network Setup | ShopMy</title>
        <meta property='description' content='To set up with the ShopMy affiliate network, please complete the following steps.' />
      </MetaTags>
      <div className='brand-setup-body-container'>
        <div className='main-header-container'>
          <div className='main-header'>Integration Guide</div>
          <div className='main-subheader'>
            Complete the following steps to integrate with the ShopMy Affiliate Network. This integration will enable you to offer a commission rate
            to all of the creators on the platform as well as more advanced features, such as offering custom commission rates and discount codes for
            particular creators.
          </div>
        </div>
        <div className='type-selection-container'>
          <div onClick={() => setSetupType('general')} className={cn('type-selection', { active: isGeneralSetup })}>
            General Setup
          </div>
          <div onClick={() => setSetupType('shopify')} className={cn('type-selection', { active: isShopifySetup })}>
            Non Shopify Integration Setup
          </div>
          <div onClick={() => setSetupType('shopify_plus')} className={cn('type-selection', { active: isShopifyPlusSetup })}>
            Shopify Integration Setup
          </div>
          <div onClick={() => setSetupType('woocommerce')} className={cn('type-selection', { active: isWoocommerceSetup })}>
            WooCommerce Setup
          </div>
          <div onClick={() => setSetupType('bigcommerce')} className={cn('type-selection', { active: isBigCommerceSetup })}>
            BigCommerce Setup
          </div>
          <div onClick={() => setSetupType('salesforce')} className={cn('type-selection', { active: isSalesforceSetup })}>
            Salesforce Setup
          </div>
          <div onClick={() => setSetupType('miva')} className={cn('type-selection', { active: isMivaSetup })}>
            MIVA Setup
          </div>
        </div>

        {/* Render the setup page based on the setup type */}
        {isGeneralSetup && (
          <BrandSetupGeneral
            brand={brand}
            updateBrand={updateBrand}
            clickTrackingCode={clickTrackingCode}
            developerKey={developerKey}
            generalCancellationEndpoint={generalCancellationEndpoint}
            generalUpdateEndpoint={generalUpdateEndpoint}
          />
        )}
        {isShopifySetup && (
          <BrandSetupShopify
            brand={brand}
            updateBrand={updateBrand}
            clickTrackingCode={clickTrackingCode}
            developerKey={developerKey}
            generalCancellationEndpoint={generalCancellationEndpoint}
            generalUpdateEndpoint={generalUpdateEndpoint}
          />
        )}
        {isShopifyPlusSetup && <BrandSetupShopifyPlus brand={brand} updateBrand={updateBrand} clickTrackingCode={clickTrackingCode} />}
        {isWoocommerceSetup && (
          <BrandSetupWooCommerce
            brand={brand}
            updateBrand={updateBrand}
            clickTrackingCode={clickTrackingCode}
            developerKey={developerKey}
            generalCancellationEndpoint={generalCancellationEndpoint}
            generalUpdateEndpoint={generalUpdateEndpoint}
          />
        )}
        {isBigCommerceSetup && <BrandSetupBigCommerce brand={brand} updateBrand={updateBrand} clickTrackingCode={clickTrackingCode} />}
        {isSalesforceSetup && (
          <BrandSetupSalesforce
            brand={brand}
            updateBrand={updateBrand}
            clickTrackingCode={clickTrackingCode}
            developerKey={developerKey}
            generalCancellationEndpoint={generalCancellationEndpoint}
            generalUpdateEndpoint={generalUpdateEndpoint}
          />
        )}
        {isMivaSetup && <BrandSetupMiva brand={brand} updateBrand={updateBrand} clickTrackingCode={clickTrackingCode} developerKey={developerKey} />}

        <div className='footer-container'>
          By adding our code to your site, you are agreeing to the terms of the ShopMy affiliate network. Please see our terms{' '}
          <a target='_blank' rel='noopener noreferrer' href='https://static.shopmy.us/Files/ShopMy+Merchant+Terms.pdf'>
            here
          </a>
          .
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  updateBrand
})(withRouter(BrandSetup));

/*
  Wix Setup:

  1) Go to Edit Site (editor.wix.com)
  2) Turn on Dev Mode at the top
  3) in "Page Code" open the masterPage.js file (at the bottom)
  4) Replace the code with the following:

    import wixLocation from 'wix-location';
    import { local } from 'wix-storage';

    $w.onReady(function () {
      // ShopMy Click Tracking
      const urlParams = wixLocation.query
      const { smsclickid } = urlParams || {}
      const getExpireDate = () => {
        const expireTime = (new Date()).getTime() + 1000 * 60 * 60 * 24 * 30; // 30 day standard
        const expires = new Date();
        expires.setTime(expireTime);
        return expires.toUTCString();
      };
      if (smsclickid) {
        local.setItem('smsclickid', smsclickid);
        local.setItem('smsclickid_expires', getExpireDate());
        console.info(`ShopMy: Registered click ${smsclickid}.`);
      }
    });

  5) Go to "Thank You Page"
  6) Replace the code with the following:

    import { local } from 'wix-storage';
    import { fetch } from 'wix-fetch';
    import wixLocation from 'wix-location';

    $w.onReady(function () {
      // ShopMy Order Tracking
      let smsclickid = local.getItem('smsclickid');
      const expiration = local.getItem('smsclickid_expires');
      if (expiration && new Date(expiration) < new Date()) {
        local.removeItem('smsclickid');
        local.removeItem('smsclickid_expires');
        smsclickid = null
      }
      const completeOrder = order => {
        const code = order.discount && order.discount.appliedCoupon && order.discount.appliedCoupon.name;
        const total = order.totals ? order.totals.total - order.totals.tax - order.totals.shipping : 0;
        if (smsclickid || code) {
          let orderToSubmit = {
            orderAmount: total || 0,
            orderId: order.number,
            currency: order.currency,
            page_url: wixLocation.url,
            clickId: smsclickid,
            code
          }
          fetch(`https://api.shopmy.us/api/order_confirmation`, { 
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(orderToSubmit)
          }).then(r => r.json())
            .then(result => {
              local.removeItem('smsclickid');
              local.removeItem('smsclickid_expires');
            }); 
        }
      }

      $w('#thankYouPage1').getOrder()
          .then(completeOrder)
          .catch(console.error);
    });

*/
