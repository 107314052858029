import React from 'react';
import './ShopifyIntegration.scss';

const ShopifyIntegration = () => {
  return (
    <div className='shopify-integration-outer-container'>
      <div className='shopify-integration-inner-container'>
        <div className='explanation-section' id='video'>
          <video
            src={`https://askemma-static-public.s3.us-east-2.amazonaws.com/explainers/shopify/shopify_integration_setup.mov`}
            className='explanation-video'
            controls
          ></video>
        </div>

        <div className='explanation-section left' id='navigate to apps'>
          <div className='image-container'>
            <img
              src={`https://askemma-static-public.s3.us-east-2.amazonaws.com/explainers/shopify/shopify_dashboard_screenshot.png`}
              alt='shopify_dashboard'
            />
          </div>
          <div className='text-container'>
            <div>
              <div className='text-container-header'>Navigate to Apps Panel</div>
              <div className='text-container-body'>
                <div className='bullets'>
                  <div className='bullet'>
                    <div className='number'>1</div>
                    <div className='task'>Click "Apps" button on left side panel</div>
                  </div>
                  <div className='bullet'>
                    <div className='number'>2</div>
                    <div className='task'>Click "Apps and sales channel settings" optionn on the dropdown</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='explanation-section right'>
          <div className='text-container'>
            <div>
              <div className='text-container-header'>Develop New App</div>
              <div className='text-container-body'>
                <div className='bullets'>
                  <div className='bullet'>
                    <div className='number'>1</div>
                    <div className='task'>Click "Develop Apps" button in top right corner</div>
                  </div>
                  <div className='bullet'>
                    <div className='number'>2</div>
                    <div className='task'>Create a new app</div>
                  </div>
                  <div className='bullet'>
                    <div className='number'>3</div>
                    <div className='task'>Grant access for custom apps to be developed</div>
                  </div>
                  <div className='bullet'>
                    <div className='number'>4</div>
                    <div className='task'>Enter "Shopmy Integration" for your app name and click "Create App"</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='image-container'>
            <img
              src={`https://askemma-static-public.s3.us-east-2.amazonaws.com/explainers/shopify/develop_app_screenshot.png`}
              alt='develop_app_button'
            />
          </div>
        </div>

        <div className='explanation-section left' id='navigate to apps'>
          <div className='image-container'>
            <img
              src={`https://askemma-static-public.s3.us-east-2.amazonaws.com/explainers/shopify/configure_scopes_screenshot.png`}
              alt='shopify_dashboard'
            />
          </div>
          <div className='text-container'>
            <div>
              <div className='text-container-header'>Configure App's Scopes</div>
              <div className='text-container-body'>
                <div className='bullets'>
                  <div className='bullet'>
                    <div className='number'>1</div>
                    <div className='task'>Click "Configure Admin API scopes" under the "Overview" tab</div>
                  </div>

                  <div className='bullet'>
                    <div className='number'>2</div>
                    <div className='task'>
                      Allow the required scopes (8 total):
                      <div className='spacer'></div>
                      write_discounts :: read_discounts
                      <br />
                      write_price_rules :: read_price_rules
                      <br />
                      write_orders :: read_orders
                      <br />
                      read_products
                      <br />
                      read_inventory
                      <div className='spacer'></div>
                    </div>
                  </div>

                  <div className='bullet'>
                    <div className='number'>3</div>
                    <div className='task'>
                      Allow optional scopes (if necessary):
                      <div className='spacer'></div>
                      read_customers - Add this scope if you are looking to do new vs returning cutsomer commission rates. Please note we do not
                      recommend this setting new vs returning rates as a strategy.
                      <div className='spacer'></div>
                      write_draft_orders :: read_draft_orders :: write_customers - Add these scopes to enable us to create and complete Shopify draft
                      orders for gifting to be compatible with Shopify Flow workflows you may have in place.
                      <div className='spacer'></div>
                    </div>
                  </div>

                  <div className='bullet'>
                    <div className='number'>4</div>
                    <div className='task'>Save the Changes</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='explanation-section right'>
          <div className='text-container'>
            <div>
              <div className='text-container-header'>Get Your Tokens</div>
              <div className='text-container-body'>
                <div className='bullets'>
                  <div className='bullet'>
                    <div className='number'>1</div>
                    <div className='task'>Click "API credentials" tab header</div>
                  </div>
                  <div className='bullet'>
                    <div className='number'>2</div>
                    <div className='task'>
                      Locate your shop name. This will be in the form of (shopname).myshopify.com. Only the shopname is needed.
                    </div>
                  </div>
                  <div className='bullet'>
                    <div className='number'>3</div>
                    <div className='task'>Locate the API secret key.</div>
                  </div>
                  <div className='bullet'>
                    <div className='number'>4</div>
                    <div className='task'>Click "Install App" and install this. Then click "Reveal token once"</div>
                  </div>
                  <div className='bullet'>
                    <div className='number'>5</div>
                    <div className='task'>Locate the new Admin API access token (if you lose this you will need to restart this process)</div>
                  </div>
                  <div className='bullet'>
                    <div className='number'>6</div>
                    <div className='task'>Store these values and send to the ShopMy team.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='image-container'>
            <img src={`https://askemma-static-public.s3.us-east-2.amazonaws.com/explainers/shopify/tokens_screenshot.png`} alt='develop_app_button' />
          </div>
        </div>

        <div className='explanation-section left' id='navigate to apps'>
          <div className='image-container'>
            <img
              src={`https://askemma-static-public.s3.us-east-2.amazonaws.com/explainers/shopify/shopmy_completed_integration_screenshot.png`}
              alt='shopify_dashboard'
            />
          </div>
          <div className='text-container'>
            <div>
              <div className='text-container-header'>Next Steps</div>
              <div className='text-container-body'>
                <div className='bullets'>
                  <div className='bullet'>
                    <div className='number'>1</div>
                    <div className='task'>The onboarding team will set up the app and save it to your account.</div>
                  </div>
                  <div className='bullet'>
                    <div className='number'>2</div>
                    <div className='task'>Testing the integration</div>
                  </div>
                  <div className='bullet'>
                    <div className='number'>3</div>
                    <div className='task'>You will receive an email from the onboarding team in case of any issues.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='explanation-section left'>
          <div className='image-container'></div>
          <div className='text-container'></div>
        </div>

        <div className='explanation-section right'>
          <div className='image-container'></div>
          <div className='text-container'></div>
        </div>

        <div className='explanation-section left'>
          <div className='image-container'></div>
          <div className='text-container'></div>
        </div>
      </div>
    </div>
  );
};

ShopifyIntegration.propTypes = {};

export default ShopifyIntegration;
