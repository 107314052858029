import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import './RequestsPortal.scss';

import RequiresBrandLoginPanel from '../../Components/General/RequiresBrandLoginPanel';
import RequestsPortalData from '../../Components/Requests/RequestsPortalData';
import RequestsControlBar from '../../Components/Requests/RequestsControlBar';
import OpportunityResults from '../../Components/Opportunities/OpportunityResults';
import RequestResults from '../../Components/Gifting/Requests/RequestResults';
import RecommendationResults from '../../Components/Gifting/Recommendations/RecommendationResults';
import LookbookPreviews from '../../Components/Gifting/Lookbooks/LookbookPreviews';
import AnnouncementModal from '../../Components/Announcements/AnnouncementModal';
import { Loader } from '../../Components';

import { createLookbook as createLookbookAPI, duplicateLookbook as duplicateLookbookAPI } from '../../APIClient/lookbooks';
import { getGiftingRecommendations, removeGiftingRecommendation } from '../../APIClient/recommendations';
import { deleteLookbook } from '../../Actions/LookbookActions';
import { createOpportunity } from '../../Actions/OpportunityActions';
import { openArtistModal, openChatOverlay, openFulfillmentModal, openRequestModal } from '../../Actions/UIActions';
import { removeSamplesRequest, removeOpportunityRequest, updateRequest, updateOpportunityRequest } from '../../Actions/AnalyticsActions';
import { getLookbookOrderStatusDisplay } from '../../Helpers/lookbook_helpers';

import { downloadCSVFromArray } from '../../Helpers/helpers';
import { getAnnouncementByType } from '../../Helpers/announcement_helpers';
import { blockOnRequiringSubscriptionToFeature } from '../../Helpers/subscription_helpers';
import {
  isOpportunityRequestOutstanding,
  isOpportunityRequestExpired,
  isOpportunityRequestAccepted,
  isOpportunityRequestDismissed
} from '../../Helpers/opportunity_helpers';
import { getBrand, getBrandId, getLookbooks, isBrand, getRequestStatus, getOpportunities } from '../../Helpers/user_helpers';
import { insertMetaTags } from '../../Helpers/seo_helpers';
import { hasRequestExpired } from '../../Helpers/gifting_helpers';
import { blockOnRequiringSubscription } from '../../Helpers/subscription_helpers';
import { split_db_social_links_into_individual_links } from '../../Helpers/social_helpers';

const RequestsPortal = props => {
  const { user, analytics, deleteLookbook, ui } = props;
  const brand = getBrand(user);
  const talent = props.talent.talent;
  const Brand_id = getBrandId(user);

  // high level state (dictates which tab and global results)
  const history = useHistory();
  let tab = useParams()?.tab || props.tab;
  if (!tab)
    tab = window.__ADMIN_CONTROL_MODE__
      ? 'requests' // Since admins can't view announcements
      : getAnnouncementByType(user, 'LOOKBOOKS')
      ? 'lookbooks'
      : getAnnouncementByType(user, 'OPPORTUNITIES')
      ? 'opportunities'
      : 'requests';
  const [isLoading, setIsLoading] = useState(false);

  const requests = analytics?.brandAnalytics?.requests || [];
  const opportunityRequests = analytics?.brandAnalytics?.opportunity_requests || [];
  const lookbooks = getLookbooks(user);

  // recommendations state
  const [recommendations, setRecommendations] = useState([]);
  const [isLoadingRecommendations, setIsLoadingRecommendations] = useState(false);
  const [loadedRecommendations, setLoadedRecommendations] = useState(false);

  // gifting requests state
  const [curSearchVal, setCurSearchVal] = useState('');
  const [isDownloading, setIsDownloading] = useState(-1);

  const [selectedFilterValue, setSelectedFilterValue] = useState('all');
  const filterOptionGroups = [
    {
      label: 'All',
      options: [{ value: 'all', filter: () => true, label: 'Show All Requests' }]
    },
    {
      label: 'Gifting',
      options: [
        { value: 'gifting_awaitingResponse', filter: r => !r.userRejected && !r.userAccepted && r.brandAccepted, label: 'Awaiting Response' },
        { value: 'gifting_acceptedPendingSend', filter: r => !r.isComplete && r.userAccepted && r.brandAccepted, label: 'Accepted, Pending Send' },
        { value: 'gifting_fulfilled', filter: r => r.isComplete, label: 'Fulfilled' },
        { value: 'gifting_expired', filter: r => hasRequestExpired(r), label: 'Expired' }
      ]
    },
    {
      label: 'Opportunities',
      options: [
        { value: 'opportunity_awaitingResponse', filter: r => r.Opportunity_id && isOpportunityRequestOutstanding(r), label: 'Awaiting Response' },
        { value: 'opportunity_dismissed', filter: r => r.Opportunity_id && isOpportunityRequestDismissed(r), label: 'Dismissed Responses' },
        { value: 'opportunity_accepted', filter: r => r.Opportunity_id && isOpportunityRequestAccepted(r), label: 'Accepted Opportunities' },
        { value: 'opportunity_expired', filter: r => r.Opportunity_id && isOpportunityRequestExpired(r), label: 'Expired Requests' }
      ]
    }
  ].map(group => ({
    ...group,
    options: group.options.map(option => {
      const count = requests.filter(option.filter).length + opportunityRequests.filter(option.filter).length;
      return {
        ...option,
        sublabel: count ? `${count} requests` : 'No requests',
        count: count,
        isDisabled: count === 0
      };
    })
  }));
  const selectedFilter =
    _.find(filterOptionGroups, { value: selectedFilterValue }) ||
    _.find(_.flatten(_.map(filterOptionGroups, 'options')), { value: selectedFilterValue });

  const handleTabChange = route => {
    history.push(route);
    changeSearchVal('');
  };

  const resetSearch = () => {
    setCurSearchVal('');
    setSelectedFilterValue('all');
  };

  const changeSearchVal = newVal => {
    setCurSearchVal(newVal);
    setSelectedFilterValue('all');
  };

  /******************************************************************************************* */
  // Gifting Requests
  /******************************************************************************************* */

  const downloadGiftingRequests = async (requests, downloadButton) => {
    if (isDownloading !== -1) return;
    setIsDownloading(downloadButton);
    const isGiftingRequest = 'Lookbook_id' in requests[0];
    const isOpportunityRequest = 'Opportunity_id' in requests[0];

    // transform requests into information for CSV
    const opportunities = getOpportunities(user);
    const augmentedRequests = requests.map(r => {
      if (isOpportunityRequest) {
        const opportunity = _.find(opportunities, { id: r.Opportunity_id });

        return {
          'Created At': moment(r.createdAt).format('MM/DD/YYYY'),
          Name: r.user.name,
          Status: r.userAccepted ? 'Accepted' : r.userRejected ? 'Rejected' : isOpportunityRequestExpired(r) ? 'Expired' : 'Outstanding',
          'Accepted At': r.userAccepted ? moment(r.userAccepted).format('MMMM Do, YYYY') : '-',
          Opportunity: opportunity.title
        };
      } else if (isGiftingRequest) {
        const { user, lookbook_order, createdAt } = r;
        const talentObject = _.find(talent, { id: r.User_id }) || {};
        const lookbook = _.find(lookbooks, { id: r.Lookbook_id });
        const { address, rawAddress } = talentObject;
        const { items } = lookbook_order || {};
        const { instagram_url, tiktok_url, youtube_url } = split_db_social_links_into_individual_links(talentObject.social_links || '');
        const jsonAddress = rawAddress ? JSON.parse(rawAddress) : null;
        const { address1 = '-', address2 = '-', city = '-', state = '-', zip = '-', country = '-' } = jsonAddress || {};
        const fulfillmentType =
          !r.isComplete && !lookbook_order?.id
            ? '-'
            : lookbook_order?.shopifyOrderId
            ? 'Shopify'
            : lookbook_order?.id && !lookbook_order?.isManuallyShipped
            ? 'ShopMy'
            : 'Your Team';

        return {
          'Created At': moment(createdAt).format('MM/DD/YYYY'),
          Name: user.name,
          Lookbook: lookbook ? lookbook.title : '-',
          Status: !_.isEmpty(lookbook_order)
            ? getLookbookOrderStatusDisplay(lookbook_order).display
            : getRequestStatus(r) === 'Sent'
            ? 'Waiting For Talent'
            : getRequestStatus(r),
          'Fulfillment Type': fulfillmentType,
          'Total Price': parseInt(lookbook_order?.price) ? `$${lookbook_order?.price}` : '-',
          'Order Items': items ? items.map(i => `${i.title} (${i.quantity}x${i.price ? ` - $${i.price}` : ''})`).join(', ') : '-',
          'Tracking Number': lookbook_order?.trackingNumber || r?.trackingNumber || '-',
          'Tracking URL': lookbook_order?.trackingUrl || r?.trackingUrl || '-',
          'Tracking Source': lookbook_order?.trackingSource || r?.trackingSource || '-',
          Address: address,
          Address1: address1,
          Address2: address2,
          City: city,
          State: state,
          Zip: zip,
          Country: country,
          'Instagram URL': instagram_url || '-',
          'TikTok URL': tiktok_url || '-',
          'YouTube URL': youtube_url || '-'
        };
      } else {
        return {};
      }
    });

    // download CSV
    downloadCSVFromArray(augmentedRequests, isGiftingRequest ? `Gifting Requests.csv` : `Opportunity Requests.csv`);
    setIsDownloading(-1);
  };

  /******************************************************************************************* */
  // Gifting Recommendations
  /******************************************************************************************* */

  const loadGiftingRecommendations = async (shouldExcludeBrandSellers = false) => {
    try {
      setIsLoadingRecommendations(true);
      const response = await getGiftingRecommendations({
        Brand_id,
        excludeBrandSellers: shouldExcludeBrandSellers,
        recommendationType: 'gifting',
        runShuffle: true
      });

      setRecommendations(response.recommendations);
      setLoadedRecommendations(true);
    } catch (error) {
      cogoToast.error(`Could not fetch recommendations, please reload and try again.`);
    } finally {
      setIsLoadingRecommendations(false);
    }
  };

  const removeUserFromRecommendationsList = User_id => {
    setRecommendations(recommendations.filter(r => r.User_id !== User_id));
  };

  const onRemoveRecommendation = async User_id => {
    try {
      await removeGiftingRecommendation({ User_id, Brand_id });
      removeUserFromRecommendationsList(User_id);
      cogoToast.success('Recommendation removed!');
    } catch (error) {
      cogoToast.error(`Could not remove recommendation, please try again.`);
    }
  };

  /******************************************************************************************* */
  // Lookbooks
  /******************************************************************************************* */

  const createNewEmptyLookbook = async () => {
    try {
      if (blockOnRequiringSubscription(user, 'LOOKBOOKS')) return null;

      // need to create a new lookbook and then redirect to that page
      const lookbook = await createLookbookAPI({ Brand_id });

      history.push(`/lookbooks/${lookbook.id}`);
    } catch (e) {
      cogoToast.error('Could not create lookbook, please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const duplicateLookbook = async lookbook => {
    setIsLoading(true);

    try {
      const response = await duplicateLookbookAPI(lookbook);
      const { lookbook: newLookbook } = response;

      history.push(`/lookbooks/${newLookbook.id}`);
    } catch (e) {
      cogoToast.error('Could not duplicate lookbook, please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  /******************************************************************************************* */
  // Opportunities
  /******************************************************************************************* */

  const createNewOpportunity = async () => {
    if (blockOnRequiringSubscriptionToFeature('OPPORTUNITIES')) return null;
    const resp = await props.createOpportunity();
    if (resp.opportunity) history.push(`/opportunity/${resp.opportunity.id}`);
  };

  /******************************************************************************************* */
  // HTML
  /******************************************************************************************* */

  const getHeaderTitle = () => {
    switch (tab) {
      case 'recommendations':
        return 'Recommendations';
      case 'lookbooks':
        return 'My Lookbooks';
      case 'opportunities':
        return 'Creator Opportunities';
      default:
        return 'Your Requests Portal';
    }
  };

  // Tabs
  const isOpportunityTab = tab === 'opportunities';

  // UI
  const lookbooksAnnouncement = getAnnouncementByType(user, 'LOOKBOOKS');
  const opportunitiesAnnouncement = getAnnouncementByType(user, 'OPPORTUNITIES');
  const showLoader = !isOpportunityTab && (_.isNil(requests) || _.isNil(talent));

  if (!isBrand(user)) return <RequiresBrandLoginPanel />;
  return (
    <div className='requests-portal-outer'>
      {insertMetaTags({
        title: getHeaderTitle(),
        description: '',
        image: ''
      })}
      <RequestsPortalData
        Brand_id={Brand_id}
        tab={tab}
        loadGiftingRecommendations={loadGiftingRecommendations}
        loadedRecommendations={loadedRecommendations}
      />
      {tab === 'lookbooks' && lookbooksAnnouncement && <AnnouncementModal announcement={lookbooksAnnouncement} extra={{}} />}
      {tab === 'opportunities' && opportunitiesAnnouncement && <AnnouncementModal announcement={opportunitiesAnnouncement} extra={{}} />}
      <div className='requests-portal-inner'>
        <div className='requests-portal-header'>{getHeaderTitle()}</div>
        <RequestsControlBar
          tab={tab}
          handleTabChange={handleTabChange}
          user={user}
          createNewEmptyLookbook={createNewEmptyLookbook}
          createNewOpportunity={createNewOpportunity}
          selectedFilter={selectedFilter}
          loadGiftingRecommendations={loadGiftingRecommendations}
          isLoadingRecommendations={isLoadingRecommendations}
          curSearchVal={curSearchVal}
          changeSearchVal={changeSearchVal}
          isLoading={isLoading}
          filterOptionGroups={filterOptionGroups}
          setSelectedFilterValue={setSelectedFilterValue}
        />

        {showLoader ? (
          <Loader />
        ) : (
          <div className={cn('results-container', { isLoading })}>
            {tab === 'requests' && (
              <RequestResults
                talent={talent}
                user={user}
                analytics={analytics}
                openArtistModal={props.openArtistModal}
                openChatOverlay={props.openChatOverlay}
                openRequestModal={props.openRequestModal}
                openFulfillmentModal={props.openFulfillmentModal}
                removeSamplesRequest={props.removeSamplesRequest}
                removeOpportunityRequest={props.removeOpportunityRequest}
                updateRequest={props.updateRequest}
                updateOpportunityRequest={props.updateOpportunityRequest}
                downloadGiftingRequests={downloadGiftingRequests}
                lookbooks={lookbooks}
                curSearchVal={curSearchVal}
                selectedFilter={selectedFilter}
                isDownloading={isDownloading}
                resetSearch={resetSearch}
                ui={ui}
              />
            )}
            {tab === 'recommendations' && (
              <RecommendationResults
                recommendations={recommendations}
                onSendGifting={User_id => removeUserFromRecommendationsList(User_id)}
                onRemoveRecommendation={onRemoveRecommendation}
                isLoadingRecommendations={isLoadingRecommendations}
              />
            )}
            {tab === 'opportunities' && <OpportunityResults curSearchVal={curSearchVal} createNewOpportunity={createNewOpportunity} />}
            {tab === 'lookbooks' && (
              <LookbookPreviews
                analytics={analytics}
                lookbooks={lookbooks}
                brand={brand}
                deleteLookbook={deleteLookbook}
                duplicateLookbook={duplicateLookbook}
                createNewEmptyLookbook={createNewEmptyLookbook}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

RequestsPortal.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openFulfillmentModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  removeSamplesRequest: PropTypes.func.isRequired,
  removeOpportunityRequest: PropTypes.func.isRequired,
  createOpportunity: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
  updateOpportunityRequest: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, analytics, talent, ui } = state;
  return { user, analytics, talent, ui };
};

export default connect(mapStateToProps, {
  openArtistModal,
  openChatOverlay,
  openFulfillmentModal,
  openRequestModal,
  deleteLookbook,
  removeSamplesRequest,
  removeOpportunityRequest,
  createOpportunity,
  updateRequest,
  updateOpportunityRequest
})(RequestsPortal);
