import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import './CommissionControls.scss';
import commaNumber from 'comma-number';

import { getAffiliateCommissionsForBrandId } from '../../APIClient/commissions';
import { getBrandId } from '../../Helpers/user_helpers';
import { downloadCsvFromUrl } from '../../Helpers/helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSearch } from '@fortawesome/pro-solid-svg-icons';
import cogoToast from 'cogo-toast';

import Select from 'react-select';
import SelectOption from '../../Components/General/SelectOption';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import Loader from '../../Components/Loader/Loader';

const CommissionControls = props => {
  const { user, changeSearchVal, selectedInvoice, setSelectedInvoice, allInvoices } = props;
  const { startDate, endDate, curSearchVal, updateDates } = props;
  const Brand_id = getBrandId(user);

  const [isDownloading, setIsDownloading] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);

  const momentFormat = 'MMM DD, YYYY';
  const invoiceOptions = _.orderBy(
    allInvoices.map(invoice => {
      const invoiceTotal = parseFloat(invoice.amount + invoice.network_fee).toFixed(2);

      return {
        label: `Sent on ${moment(invoice.createdAt).format(momentFormat)}`,
        sublabel: `Total: $${commaNumber(invoiceTotal)}  •   Commissions: $${commaNumber(invoice.amount)}  •   $${commaNumber(
          invoice.network_fee
        )} network fee  •   ${moment(invoice.minCommissionDate).format(momentFormat)} - ${moment(invoice.maxCommissionDate).format(momentFormat)}`,
        value: invoice.id,
        invoice
      };
    }),
    ['invoice.minCommissionDate'],
    ['desc']
  );

  const downloadResults = async () => {
    if (isDownloading) return;
    setIsDownloading(true);
    try {
      const timezoneOffset = new Date().getTimezoneOffset();
      const Invoice_id = _.get(selectedInvoice, 'value', null);
      const queryData = Invoice_id
        ? { Invoice_id, limit: 5000, downloadAllToCsv: true, timezoneOffset }
        : { limit: 2500, query: curSearchVal, startDate, endDate, downloadAllToCsv: true, timezoneOffset };

      const resp = await getAffiliateCommissionsForBrandId(Brand_id, _.omitBy(queryData, _.isNil));
      if (!resp.downloaded_url) throw new Error('Failure downloading');
      downloadCsvFromUrl(resp.downloaded_url);
    } catch (error) {
      cogoToast.error(`Could not download results, plase try again.`);
    }
    setIsDownloading(false);
  };

  return (
    <div className='commission-controls-outer'>
      <div className='commission-controls-inner'>
        <div className='main-inputs'>
          <div className='search-container'>
            <FontAwesomeIcon icon={faSearch} />
            <input onChange={changeSearchVal} id='search-input' placeholder='Search by name, order ID, amount or code' />
          </div>
        </div>
        <div className='secondary-inputs'>
          <div className='input-container'>
            <Select
              className='invoice-select'
              classNamePrefix='invoice-select'
              placeholder='Filter by invoice'
              value={selectedInvoice}
              onChange={setSelectedInvoice}
              components={{ Option: SelectOption }}
              options={invoiceOptions}
            />
          </div>
          <div className='input-container date-picker'>
            <DateRangePicker
              startDate={startDate} // momentPropTypes.momentObj or null,
              startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
              endDate={endDate} // momentPropTypes.momentObj or null,
              endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
              displayFormat={startDate && moment().year() !== startDate.year() ? "MMM Do 'YY" : 'MMM Do'}
              onDatesChange={({ startDate, endDate }) => updateDates(startDate, endDate)}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => setFocusedInput(focusedInput)}
              isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
              noBorder
              minimumNights={0}
              showClearDates
            />
          </div>
          <div className='action' onClick={downloadResults}>
            {isDownloading ? (
              <Loader size={20} />
            ) : (
              <>
                Download
                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CommissionControls.propTypes = {
  user: PropTypes.object.isRequired,
  changeSearchVal: PropTypes.func.isRequired,
  updateDates: PropTypes.func.isRequired,
  selectedInvoice: PropTypes.object,
  setSelectedInvoice: PropTypes.func.isRequired,
  allInvoices: PropTypes.array.isRequired,

  startDate: PropTypes.object,
  endDate: PropTypes.object,
  curSearchVal: PropTypes.string.isRequired
};

export default CommissionControls;
