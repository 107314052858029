import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './ManageBudgetPanel.scss';

import { openBrandBudgetModal } from '../../Actions/UIActions';
import { getBrandBudgetRemaining, getBrandBudgetPending } from '../../Helpers/user_helpers';
import { getBrandBudgetPendingOpportunityCompletion } from '../../Helpers/brand_helpers';
import { getPrettyNumber } from '../../Helpers/formatting';

import Tooltip from '../General/Tooltip';

const ManageBudgetPanel = props => {
  const { user, analytics } = props;
  const budgetRemaining = getBrandBudgetRemaining(user);
  const budgetPending = getBrandBudgetPending(user);
  const budgetPendingOpportunityCompletion = getBrandBudgetPendingOpportunityCompletion(analytics, user);

  const openManagementPanel = () => {
    props.openBrandBudgetModal();
  };
  return (
    <div className='manage-budget-panel-container'>
      <div className='main'>
        <div className='data-point'>
          <div className='badge'>Current Budget</div>
          <div className='value'>${getPrettyNumber(budgetRemaining) || 0}</div>
        </div>
        {!!budgetPending && (
          <Tooltip message='You have a pending budget increase that will be added to your account once it is paid. Please switch to the Invoices tab to get links to the invoices.'>
            <div className='data-point'>
              <div className='badge'>Pending Invoice Completion</div>
              <div className='value'>${getPrettyNumber(budgetPending)}</div>
            </div>
          </Tooltip>
        )}
        {!!budgetPendingOpportunityCompletion && (
          <Tooltip message='This budget is owed to creators for opportunities that have not yet been completed.'>
            <div className='data-point'>
              <div className='badge'>Awaiting Opportunities</div>
              <div className='value'>-${getPrettyNumber(budgetPendingOpportunityCompletion)}</div>
            </div>
          </Tooltip>
        )}
      </div>
      <div className='add-btn' onClick={openManagementPanel}>
        Add to Budget
      </div>
    </div>
  );
};

ManageBudgetPanel.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  openBrandBudgetModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, analytics } = state;
  return { user, analytics };
};

export default connect(mapStateToProps, {
  openBrandBudgetModal
})(ManageBudgetPanel);
