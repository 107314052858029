import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCalendar, faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import { orderBy } from 'lodash';
import './LatestSection_Sales.scss';

import { getRateDisplay, getDateStringForSale, hasSaleEnded } from '../../../Helpers/formatting';
import { copyToClipboard } from '../../../Helpers/helpers';
import { getAdjPayoutRate } from '../../../Helpers/user_helpers';

const LatestSection_Sales = props => {
  const { section } = props;
  const { items } = section;
  const itemsSortedByAvailability = orderBy(items, item => hasSaleEnded(item), 'asc');

  return (
    <div className='latest-section sales'>
      {itemsSortedByAvailability.map(item => (
        <Sale key={item.id} item={item} {...props} />
      ))}
    </div>
  );
};

const Sale = props => {
  const { item, user } = props;
  const { merchant, domain, brand, isFeatured, badge } = item;

  const splitSubtitle = item.subtitle?.split(' | ') || [];
  const headlineText = splitSubtitle.length > 1 ? splitSubtitle[0] : item.subtitle;
  const subtitle = splitSubtitle.length > 1 ? splitSubtitle.pop() : null;

  const copyBadge = e => {
    e.preventDefault();
    e.stopPropagation();
    copyToClipboard(badge, true, `Copied ${badge}`);
  };

  // Get Date String
  const dateString = getDateStringForSale(item);
  const rate = getAdjPayoutRate(user, merchant);
  const rateDisplay = getRateDisplay(rate, merchant);
  const hasEnded = hasSaleEnded(item);
  const image = item.image || brand?.mobileCoverImage;
  const url = `https://${domain}`;

  if (!merchant?.name || !item.subtitle) return null;
  return (
    <a href={url} rel='noopener noreferrer' target='_blank' className={cn('sale', { ended: hasEnded }, isFeatured ? 'isFeatured' : 'notFeatured')}>
      {/* FEATURED DESIGN */}

      {isFeatured ? (
        <>
          <div className='brand-cover-image'>{image && <img src={image} alt={merchant.name} />}</div>

          <div className='data-container'>
            <div className='merchant-details'>
              <div className='merchant'>{item.title || merchant.name}</div>
              <div>•</div>
              <span>{rateDisplay} commission</span>
            </div>
            <div className='sale-details'>
              <div className='sale-text'>{headlineText}</div>
              {!!subtitle && <div className='sale-subtext'>{subtitle}</div>}
            </div>
            <div className='sale-date-details'>
              {!!badge && !hasEnded && (
                <div className='sale-extra'>
                  <div className={cn('action-btn', { disabled: hasEnded })} onClick={copyBadge}>
                    {badge}
                    <FontAwesomeIcon className='icon' icon={faCopy} />
                  </div>
                </div>
              )}

              {!!dateString && (
                <div className='sale-date'>
                  <FontAwesomeIcon icon={faCalendar} />
                  {dateString}
                </div>
              )}
            </div>
          </div>
          {props.getItemIndicatorBadges(item, 'primary')}
        </>
      ) : (
        <>
          {/* NOT FEATURED DESIGN*/}

          <div className='percentage'>{image && <img src={image} alt={merchant.name} />}</div>
          <div className='data-container'>
            <div className='main'>
              <div className='title'>{item.title || merchant.name}</div>
              <div className='merchant'>
                {!hasEnded && rateDisplay && <span className='rate'>Earn {rateDisplay} at </span>}
                <span className='domain'>{domain?.toLowerCase()}</span>
                <FontAwesomeIcon icon={faExternalLinkAlt} className='icon' />
              </div>
              {subtitle && <div className={cn('description')}>{subtitle}</div>}
            </div>
            <div className='secondary-details'>
              {!hasEnded && badge && (
                <div className={cn('action-btn', { disabled: hasEnded })} onClick={copyBadge}>
                  {badge}
                  <FontAwesomeIcon className='icon' icon={faCopy} />
                </div>
              )}

              <div className='date'>
                <FontAwesomeIcon className='icon' icon={faCalendar} />
                {dateString}
              </div>
            </div>
          </div>
          {props.getItemIndicatorBadges(item, 'primary')}
        </>
      )}
    </a>
  );
};

LatestSection_Sales.propTypes = {
  section: PropTypes.object.isRequired
};

export default LatestSection_Sales;
