import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import _ from 'lodash';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faHeart, faRedo, faFilter, faSortAlt, faChevronDown, faEnvelope, faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faHeartSolid, faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import './ChatSearch.scss';

import ProfilePhoto from '../../Components/Profile/ProfilePhoto';
import BrandListsOverlay from '../General/BrandListsOverlay';
import Tooltip from '../General/Tooltip';
import ChatFilters from './ChatFilters';
import ChatSort from './ChatSort';

import { logToSlack } from '../../APIClient/alerts';
import { getPrettyTimeAgoFromNow } from '../../Helpers/formatting';
import { getUserId, getName, isBrand, getBrandId } from '../../Helpers/user_helpers';
import { getAllBrandListsFoundIn } from '../../Helpers/brand_helpers';
import { getAllChats } from '../../Helpers/chat_helpers';
import { isSubscribedToFeatureOrAdmin, blockOnRequiringSubscriptionToFeature } from '../../Helpers/subscription_helpers';
import { getUrlParam, removeUrlParam } from '../../Helpers/helpers';
import { ActionsOverlay } from '../Modals/ActionsOverlay';
import { isAdminControlMode } from '../../Helpers/ui_helpers';

const RESULTS_PAGE_SIZE = 150;

class ChatSearch extends Component {
  static propTypes = {
    chats: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    activeChat: PropTypes.object,
    selectChat: PropTypes.func.isRequired,
    selectInitialChat: PropTypes.func.isRequired,
    isSearchExpandedOnMobile: PropTypes.bool.isRequired,
    expandSearchOnMobile: PropTypes.func.isRequired,
    activePotentialPartner: PropTypes.object,
    selectActivePotentialPartner: PropTypes.func.isRequired,
    selectInitialActivePotentialPartner: PropTypes.func.isRequired,
    updateBrandList: PropTypes.func.isRequired,
    deleteBrandList: PropTypes.func.isRequired,
    getChats: PropTypes.func.isRequired,
    updateChat: PropTypes.func.isRequired,
    deleteChat: PropTypes.func.isRequired
  };

  componentDidMount = () => {
    this.fetchChats(true);
    this.performSearch = AwesomeDebouncePromise(this.fetchChats, 400);
    getUrlParam('query') && removeUrlParam('query');
  };

  state = {
    // Searching
    isSearchingChats: true,
    curChatSearchQuery: getUrlParam('query') || '',

    // Filtering
    isShowingFilterPanel: false,
    showingNewOnly: false,

    activeFilterObjects: [], // [{ display: "List 1", key: 12, count: 13, filterFn: chat => chat.isNew }] // Clear in the ChatFilters.js file

    // Sorting
    activeSortFnObject: null, // { display: 'Last Message', sortFn: c => c.lastMessageTime, sortFnDirection: 'desc },

    // Experience clearing out only new messages
    visibleChatIdsWhileShowingNewOnly: null,

    // List Editing
    selectingBrandListUser: null,

    // For performance
    numVisible: RESULTS_PAGE_SIZE
  };

  // Paging for performance
  showNextPage = () => this.setState({ numVisible: this.state.numVisible + RESULTS_PAGE_SIZE });
  hidePrevPage = () => this.setState({ numVisible: this.state.numVisible >= 1e6 ? RESULTS_PAGE_SIZE : this.state.numVisible - RESULTS_PAGE_SIZE });
  resetPaging = () => this.setState({ numVisible: RESULTS_PAGE_SIZE });
  showAllPages = () => this.setState({ numVisible: 1e6 });

  fetchChats = isFirstLoad => {
    const { user, getChats } = this.props;
    const { curChatSearchQuery, curSelectedList } = this.state;
    this.setState({ isSearchingChats: true });
    getChats({
      ...(curSelectedList ? { listids: [curSelectedList.id] } : {}),
      ...(curChatSearchQuery ? { query: curChatSearchQuery } : {}),
      ...(isBrand(user) ? {} : { User_id: getUserId(user) }),
      ...(isBrand(user) ? { Brand_id: getBrandId(user) } : {})
    }).then(resp => {
      this.setState({ isSearchingChats: false });
      if (isFirstLoad) {
        if (resp?.chats?.length) {
          this.props.selectInitialChat(resp.chats[0]);
        } else if (resp?.potentialPartners?.length) {
          this.props.selectInitialActivePotentialPartner(resp.potentialPartners[0]);
        }
      }
    });
  };

  updateSearch = newVal => {
    this.setState({ curChatSearchQuery: newVal, isSearchingChats: true });
    this.performSearch();
    this.resetPaging();
  };

  isSelectingBrandList = artist => this.state.selectingBrandListUser?.id === artist?.id;

  getBrandListIcon = artist => {
    const inBrandLists = getAllBrandListsFoundIn(this.props.user, artist);
    const isInList = !!inBrandLists.length;
    const isActive = this.isSelectingBrandList(artist);
    const open = e => {
      e.stopPropagation();
      this.setState({ selectingBrandListUser: artist });
    };
    const close = () => this.setState({ selectingBrandListUser: null });
    return (
      <BrandListsOverlay closeOverlay={close} selectedUser_id={artist.id} isActive={isActive}>
        <div onClick={open} className={cn('select-list-btn', { active: isInList })}>
          {isInList ? <FontAwesomeIcon icon={faHeartSolid} /> : <FontAwesomeIcon icon={faHeart} />}
        </div>
      </BrandListsOverlay>
    );
  };

  // Sorting Actions
  toggleSortPanel = () => {
    if (blockOnRequiringSubscriptionToFeature('CORE')) return null;
    this.setState({ isShowingSortPanel: !this.state.isShowingSortPanel });
  };
  setSortFnObject = newSortFnObject => this.setState({ activeSortFnObject: newSortFnObject });

  // Filter Actions
  toggleFiltersPanel = () => {
    if (blockOnRequiringSubscriptionToFeature('CORE')) return null;
    this.setState({ isShowingFilterPanel: !this.state.isShowingFilterPanel });
  };
  turnOnNewOnlyFilter = () => this.toggleNewOnly(true);
  turnOffNewOnlyFilter = () => this.toggleNewOnly(false);
  toggleNewOnly = (forceNew = false) => {
    if (blockOnRequiringSubscriptionToFeature('CORE')) return null;

    const { chats } = this.props;
    const { showingNewOnly } = this.state;
    const isTogglingOn = forceNew || !showingNewOnly;
    const allChats = getAllChats(chats);

    // Only show new chats
    const visibleChatsWhileShowingNewOnly = isTogglingOn ? allChats.filter(this.isNewAndNotDismissed) : null;

    // Store the current chats we are showing so we don't hide those the instant they are marked as read
    this.setState({ showingNewOnly: isTogglingOn, visibleChatIdsWhileShowingNewOnly: _.map(visibleChatsWhileShowingNewOnly, 'id') });

    // Focus on first result
    if (isTogglingOn) {
      visibleChatsWhileShowingNewOnly.length && this.props.selectInitialChat(_.first(visibleChatsWhileShowingNewOnly));
    } else {
      allChats.length && this.props.selectInitialChat(_.first(allChats));
    }
  };

  // Filtering Helpers
  isSelectedChat = chat => this.props.activeChat?.id === chat.id;
  isNewChat = chat => (isBrand(this.props.user) ? chat.hasNewMessagesForBrand : chat.hasNewMessagesForUser);
  isDismissed = chat => (isBrand(this.props.user) ? chat.isDismissedByBrand : chat.isDismissedByUser);
  dismissChat = chat => this.props.updateChat(chat, { [isBrand(this.props.user) ? 'isDismissedByBrand' : 'isDismissedByUser']: true });
  undismissChat = chat => this.props.updateChat(chat, { [isBrand(this.props.user) ? 'isDismissedByBrand' : 'isDismissedByUser']: false });
  isNewAndNotDismissed = chat => this.isNewChat(chat) && !this.isDismissed(chat);
  isFilterActive = filter => this.state.activeFilterObjects.some(f => f.key === filter.key);
  setFilters = filters => this.setState({ activeFilterObjects: filters });
  filterChatsBasedOnSelectedFilters = chats => {
    const { showingNewOnly, visibleChatIdsWhileShowingNewOnly, activeFilterObjects } = this.state;
    return chats.filter(c => {
      // Hide non-new chats, keeping those who we only recently marked as new
      if (showingNewOnly && !this.isNewAndNotDismissed(c) && !visibleChatIdsWhileShowingNewOnly.includes(c.id)) return false;

      for (const filter of activeFilterObjects) {
        if (!filter.filter(c)) return false;
      }

      return true;
    });
  };

  render = () => {
    const { user, ui, chats, activeChat, activePotentialPartner, isSearchExpandedOnMobile, selectActivePotentialPartner, selectChat } = this.props;
    const {
      curChatSearchQuery,
      isSearchingChats,
      curSelectedList,
      isShowingFilterPanel,
      isShowingSortPanel,
      activeFilterObjects,
      activeSortFnObject,
      showingNewOnly
    } = this.state;
    const allChats = getAllChats(chats);
    const noResults = !allChats.length && !chats?.potentialPartners?.length;

    // Filtering
    const visibleChats = this.filterChatsBasedOnSelectedFilters(allChats);
    const newVisibleChats = visibleChats.filter(this.isNewAndNotDismissed);
    const sortedChats = _.orderBy(
      visibleChats,
      [
        c => {
          const pushToBottom = c[isBrand(user) ? 'isDismissedByBrand' : 'isDismissedByUser'];
          return pushToBottom;
        },
        activeSortFnObject?.sortFn || 'lastMessageTime'
      ],
      ['asc', activeSortFnObject?.sortFnDirection || 'desc']
    );

    // UI
    const showPotentialPartners =
      this.state.numVisible >= sortedChats.length && // Not hidden behind paging
      !showingNewOnly && // Not showing unread only
      !!chats?.potentialPartners?.length && // have potential partners
      (!activeFilterObjects.length || !!curChatSearchQuery) && // Not filtering
      (!activeSortFnObject || !!curChatSearchQuery);
    const showCount = showingNewOnly || !!curSelectedList || !!curChatSearchQuery || !!activeFilterObjects.length;

    const allResults = _.concat(sortedChats, chats?.potentialPartners || []);
    const hasMore = allResults.length > this.state.numVisible;
    const hasLess = this.state.numVisible > RESULTS_PAGE_SIZE;
    const showPagingBtns = (hasMore || hasLess) && !showingNewOnly && !curSelectedList;
    const isShowingFilterOrSortPanel = isShowingFilterPanel || isShowingSortPanel;
    const isUnsubscribedBrand = isBrand(user) && !isSubscribedToFeatureOrAdmin(user, 'CORE');
    return (
      <div
        className={cn('chat-search-container container', {
          searching: isSearchingChats,
          'expanded-on-mobile': isSearchExpandedOnMobile,
          'collapsed-on-mobile': !isSearchExpandedOnMobile,
          'is-showing-filters-or-sort-panel': isShowingFilterOrSortPanel
        })}
      >
        <div
          onClick={isShowingFilterPanel ? this.toggleFiltersPanel : this.toggleSortPanel}
          className={cn('is-filtering-or-sorting-overlay', { active: isShowingFilterOrSortPanel })}
        />
        <div className='sections'>
          <div className='search-section section'>
            <div className='search-result-input-container'>
              <input
                disabled={isUnsubscribedBrand}
                className='search-result-input'
                placeholder={isMobile && !isSearchExpandedOnMobile ? 'Search' : isBrand(user) ? 'Search for talent' : 'Search for a brand'}
                onChange={({ target }) => this.updateSearch(target.value)}
                onFocus={this.props.expandSearchOnMobile}
                value={curChatSearchQuery}
              />
              {!!curChatSearchQuery.length && (
                <div className='clear-search'>
                  <FontAwesomeIcon onClick={() => this.updateSearch('')} icon={faTimes}></FontAwesomeIcon>
                </div>
              )}
            </div>
            {isBrand(user) && !curChatSearchQuery && (
              <>
                <div onClick={this.toggleSortPanel} className='sort-control'>
                  <div className='label'>Sort</div>
                  <div className='filter-icon'>
                    <FontAwesomeIcon icon={faSortAlt} />
                  </div>
                </div>
                <div onClick={this.toggleFiltersPanel} className='filters-control'>
                  <div className='label'>Filters</div>
                  <div className='filter-icon'>
                    <FontAwesomeIcon icon={faFilter} />
                  </div>
                </div>
              </>
            )}
            {isShowingFilterPanel && (
              <div className='filters-panel'>
                <ChatFilters
                  allChats={allChats}
                  isFilterActive={this.isFilterActive}
                  setFilters={this.setFilters}
                  turnOnNewOnlyFilter={this.turnOnNewOnlyFilter}
                  turnOffNewOnlyFilter={this.turnOffNewOnlyFilter}
                  activeFilterObjects={activeFilterObjects}
                  closePanel={this.toggleFiltersPanel}
                />
              </div>
            )}
            {isShowingSortPanel && (
              <div className='sort-panel'>
                <ChatSort activeSortFnObject={activeSortFnObject} setSortFnObject={this.setSortFnObject} closePanel={this.toggleSortPanel} />
              </div>
            )}
          </div>
          {isBrand(user) && activeFilterObjects.length > 0 && (
            <div className='filters-section section'>
              <div className='active-filters'>
                {activeFilterObjects.map(filter => {
                  const remove = () => this.setFilters(activeFilterObjects.filter(f => f.key !== filter.key));
                  return (
                    <div onClick={remove} key={filter.key} className='active-filter'>
                      <div className='title'>{filter.display}</div>
                      <div className='remove-icn'>
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className='results-section section'>
            <div className='section-header-container'>
              <div className='section-header'>
                <div className='main'>
                  <div className='header'>Chats</div>
                  {showCount && <div className='count'>{visibleChats.length}</div>}
                </div>
                {activeSortFnObject && (
                  <div onClick={this.toggleSortPanel} className='sort-by-label'>
                    Sorted By {activeSortFnObject.display}
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                )}
              </div>
              <div className='section-actions'>
                <div className='section-action switch-container'>
                  {newVisibleChats.length ? (
                    <div className='switch-label'>Unread Only ({newVisibleChats.length})</div>
                  ) : (
                    <div className='switch-label'>Unread Only</div>
                  )}
                  <Switch
                    onChange={() => this.toggleNewOnly()}
                    checked={showingNewOnly}
                    height={14}
                    width={24}
                    onColor='#333'
                    offColor='#ccc'
                    className='switch'
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>
            {sortedChats.slice(0, this.state.numVisible).map(chat => {
              const relevantUser = isBrand(user) ? chat?.user : chat?.brand;
              const score = chat?.user?.tier?.score;
              const isActive = activeChat?.id === chat.id;
              const isNew = this.isNewChat(chat);
              const isSelectingList = this.isSelectingBrandList(relevantUser);
              const hasBeenDismissed = isBrand(user) ? !!chat?.isDismissedByBrand : !!chat?.isDismissedByUser;
              const isRead = isBrand(user) ? !chat?.hasNewMessagesForBrand : !chat?.hasNewMessagesForUser;
              return (
                <div
                  className={cn('chat-cell', {
                    active: activeChat?.id === chat.id,
                    brand: !isBrand(user),
                    dismissed: hasBeenDismissed,
                    new: isNew,
                    'unsubscribed-brand': isUnsubscribedBrand
                  })}
                  onClick={() => !isActive && selectChat(chat)}
                  key={chat.id}
                >
                  {!!score && !!window.__ADMIN_CONTROL_MODE__ && <div className='score'>{score.toFixed(0)}</div>}
                  <ProfilePhoto ui={ui} profile={relevantUser} basic />
                  {!isNew && (
                    <div className='action-buttons'>
                      <ActionsOverlay
                        containerClassName='action-button'
                        options={[
                          {
                            display: 'Mark As Unread',
                            icon: 'faEnvelopeOpen',
                            onClick: () => this.props.updateChat(chat, { [isBrand(user) ? 'hasNewMessagesForBrand' : 'hasNewMessagesForUser']: true })
                          },
                          hasBeenDismissed
                            ? {
                                display: 'Undismiss Chat',
                                icon: 'faPlus',
                                onClick: () => this.props.updateChat(chat, { [isBrand(user) ? 'isDismissedByBrand' : 'isDismissedByUser']: false })
                              }
                            : {
                                display: 'Dismiss Chat',
                                icon: 'faTimes',
                                onClick: () => this.props.updateChat(chat, { [isBrand(user) ? 'isDismissedByBrand' : 'isDismissedByUser']: true })
                              },
                          ...(window.__ADMIN_CONTROL_MODE__
                            ? [
                                {
                                  display: 'Delete Chat',
                                  icon: 'faTrash',
                                  onClick: () => {
                                    const confirm = window.confirm('Are you sure you want to delete this chat?');
                                    confirm && this.props.deleteChat(chat);
                                  }
                                }
                              ]
                            : [])
                        ]}
                      >
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </ActionsOverlay>
                    </div>
                  )}
                  <div className='chat-cell-data'>
                    <div className='name'>{relevantUser?.name}</div>
                    <div className='last-message'>{chat?.lastMessage}</div>
                  </div>
                  <div className='secondary-background-cover show-on-hover' />
                  {isBrand(user) ? (
                    <>
                      {!isSelectingList && <div className='chat-secondary hide-on-hover'>{getPrettyTimeAgoFromNow(chat.lastMessageTime)}</div>}
                      <div className={cn('chat-secondary', { 'show-on-hover': !isSelectingList })}>
                        <div className='secondary-actions'>
                          <Tooltip message={hasBeenDismissed ? 'Undismiss Chat' : `Dismiss Chat and Remove ${chat.user?.name} from Inbox`}>
                            <FontAwesomeIcon
                              icon={hasBeenDismissed ? faRedo : faTimes}
                              className='action'
                              onClick={() => (hasBeenDismissed ? this.undismissChat(chat) : this.dismissChat(chat))}
                            />
                          </Tooltip>
                          <Tooltip message={isRead ? 'Mark Unread' : 'Mark Read'}>
                            <FontAwesomeIcon
                              icon={isRead ? faEnvelopeOpen : faEnvelope}
                              className='action'
                              onClick={e => {
                                this.props.updateChat(chat, { [isBrand(user) ? 'hasNewMessagesForBrand' : 'hasNewMessagesForUser']: isRead });
                                e.stopPropagation();
                              }}
                            />
                          </Tooltip>
                        </div>
                        {this.getBrandListIcon(relevantUser)}
                      </div>
                    </>
                  ) : (
                    <div className='chat-secondary'>
                      {!!isAdminControlMode(ui) && !!isNew && (
                        <FontAwesomeIcon
                          className='show-on-hover'
                          icon={hasBeenDismissed ? faRedo : faTimes}
                          onClick={() => (hasBeenDismissed ? this.undismissChat(chat) : this.dismissChat(chat))}
                        />
                      )}
                      {getPrettyTimeAgoFromNow(chat.lastMessageTime)}
                    </div>
                  )}

                  {!!isNew && !isActive && <div className='new-badge'>NEW</div>}
                </div>
              );
            })}
            {showingNewOnly && (
              <div onClick={this.turnOffNewOnlyFilter} className='show-all-chats-while-filtered-to-new-container'>
                <div className='toggle-button'>
                  Remove Unread Only Filter
                  <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                </div>
              </div>
            )}

            {showPotentialPartners && (
              <div className='potential-partners-separator-container'>
                <div className='potential-partners-separator'>Potential Partners</div>
              </div>
            )}
            {showPotentialPartners &&
              _.map(chats.potentialPartners.slice(0, this.state.numVisible - sortedChats.length), partner => {
                const isSelectingList = this.isSelectingBrandList(partner);
                return (
                  <div
                    className={cn('chat-cell potential', { active: activePotentialPartner?.id === partner.id, brand: !isBrand(user) })}
                    onClick={() => selectActivePotentialPartner(partner)}
                    key={partner.id}
                  >
                    <ProfilePhoto ui={ui} profile={partner} basic />
                    <div className='chat-cell-data'>
                      <div className='name'>{partner?.name}</div>
                      <div className='partner-message'>{partner?.domain || partner?.description || ''}</div>
                    </div>
                    {isBrand(user) ? (
                      curSelectedList ? (
                        <div className='chat-secondary'>{this.getBrandListIcon(partner)}</div>
                      ) : (
                        <div className={cn('chat-secondary', { 'show-on-hover': !isSelectingList })}>{this.getBrandListIcon(partner)}</div>
                      )
                    ) : null}
                  </div>
                );
              })}
            {!!curChatSearchQuery.length && noResults && !isSearchingChats && (
              <div className='empty-results-message'>
                {isBrand(user) ? (
                  <div className='empty-message'>
                    We do not have any users that match the search <i>{curChatSearchQuery}</i>. Please message us to let us know you have someone you
                    would like on the platform and we will reach out to them!
                  </div>
                ) : (
                  <div className='empty-message'>
                    We do not have any brands or retailers that match the search <i>{curChatSearchQuery}.</i>&nbsp;Please message us to request that
                    we reach out and get them on the platform. We also offer a $1,000 referral bonus if you are able to directly connect us with
                    someone at the brand and the brand ends up joining one of our monthly subscription packages.
                    <br />
                    <br />
                    You can read more about how this referral program works here: &nbsp;
                    <a href='https://guide.shopmy.us/can-i-refer-a-brand-to-shopmy' target='_blank' rel='noopener noreferrer'>
                      https://guide.shopmy.us/can-i-refer-a-brand-to-shopmy.
                    </a>
                  </div>
                )}
                <div
                  className='empty-results-send-message'
                  onClick={() => {
                    logToSlack({ message: `${getName(user)} would like us to onboard ${curChatSearchQuery}`, channel: 'brands' }).then(resp => {
                      cogoToast.success(`Thank you for the recommendation for ${curChatSearchQuery}, we will work on it!`);
                      this.updateSearch('');
                    });
                  }}
                >
                  Request Addition
                </div>
              </div>
            )}
            {showPagingBtns ? (
              <div className='paging-btns'>
                {hasMore && (
                  <>
                    <div onClick={this.showNextPage} className='paging-btn more'>
                      SHOW MORE
                    </div>

                    <div onClick={this.showAllPages} className='paging-btn all'>
                      SHOW ALL
                    </div>
                  </>
                )}
                {hasLess && (
                  <div onClick={this.hidePrevPage} className='paging-btn less'>
                    SHOW LESS
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };
}

export default ChatSearch;
