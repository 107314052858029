import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import cn from 'classnames';
import commaNumber from 'comma-number';
import './LookbookReportTalent.scss';

import { connect } from 'react-redux';
import { openChatOverlay, openArtistModal, openBulkTalentModal } from '../../../../Actions/UIActions';
import { updateRequest } from '../../../../Actions/AnalyticsActions';

import { getLookbookOrderStatusDisplay, getLookbookRequests } from '../../../../Helpers/lookbook_helpers';
import { lookbookReportItemFilter } from '../../../../Helpers/lookbook_helpers';
import { getCodesForUser } from '../../../../Helpers/brand_helpers';
import { hasRequestExpired, openEditRequestExpirationOverlay } from '../../../../Helpers/gifting_helpers';
import { blockOnRequiringSubscription, isSubscribedToFeature } from '../../../../Helpers/subscription_helpers';
import { downloadCSVFromArray } from '../../../../Helpers/helpers';
import { isAdminControlMode } from '../../../../Helpers/ui_helpers';
import * as userHelpers from '../../../../Helpers/user_helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/pro-regular-svg-icons';
import { faArrowUp, faArrowDown } from '@fortawesome/pro-solid-svg-icons';

import Tooltip from '../../../General/Tooltip';
import Loader from '../../../Loader/Loader';
import Image from '../../../General/Image';
import OutsideClickHandler from 'react-outside-click-handler';

const TALENT_TO_SHOW_PAGE_SIZE = 8;

const LookbookReportTalent = props => {
  let { lookbook, startDate, endDate, isFetchingMentions, isFetchingPins, analytics } = props;

  const lookbookRequests = getLookbookRequests(analytics, lookbook);
  const requests = lookbookRequests.filter(r => lookbookReportItemFilter(r, 'request', startDate, endDate));
  const pins = props.pins.filter(p => requests.some(r => r.User_id === p.User_id));
  const mentions = props.mentionUserStats.filter(m => requests.some(r => r.User_id === m.User_id));

  const [showAllResults, setShowAllResults] = useState(false);
  const isFetchingResults = isFetchingMentions || isFetchingPins;
  const talent = props.talent?.talent;
  const loaderSize = 35;

  const [sortMetric, setSortMetric] = useState('volume');
  const [sortDirection, setSortDirection] = useState('desc');
  const [searchValue, setSearchValue] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);

  const aggregatedStats = _.orderBy(
    requests
      .map(request => {
        const { User_id, userAcceptedAt } = request;
        const pinStatsForUser = pins.find(pinStat => pinStat.User_id === User_id);
        const mentionStatsForUser = mentions.find(mentionStat => mentionStat.User_id === User_id);
        const user = request.user;
        const lookbook_order = request.lookbook_order;

        return {
          request,
          user,
          name: request.user?.name,
          lookbook_order,
          clicks: _.get(pinStatsForUser, 'views') || 0,
          userAcceptedAt,
          volume: _.get(pinStatsForUser, 'orderVolumeTotal') || 0,
          views: _.get(mentionStatsForUser, 'views') || 0,
          estimatedMediaValue: _.get(pinStatsForUser, 'estimatedMediaValue') || 0,
          orderItems: lookbook_order?.items,
          pinCreationDate: _.get(pinStatsForUser, 'createdAt', null)
        };
      })
      .filter(talent => {
        const { name, user } = talent;
        const { username } = user || {};

        const nameMatch = name?.toLowerCase().includes(searchValue.toLowerCase());
        const usernameMatch = username?.toLowerCase().includes(searchValue.toLowerCase());

        return nameMatch || usernameMatch;
      }),
    [sortMetric, 'clicks', 'volume', 'estimatedMediaValue'],
    [sortDirection, 'desc', 'desc', 'desc']
  );

  const downloadLookbookReport = async () => {
    // Here we basically just have to push as much information as we can into a csv about each reqest
    setIsDownloading(true);

    const csvRows = aggregatedStats.map(stat => {
      const { user, lookbook_order, request } = stat;
      const { clicks, volume, views, estimatedMediaValue } = stat; // Calculated values
      const { address } = lookbook_order || {};
      const parsedAddress = address ? JSON.parse(address) : {};
      const { address1, address2, city, state, zip, country, stringAddress } = parsedAddress;
      const userTalentObject = talent?.find(talent => talent.id === user?.id);
      const discountCodesForUser = getCodesForUser(analytics, userTalentObject);
      const social_links = userTalentObject?.social_links;

      const { instagram_url } = userHelpers.splitDbSocialLinksIntoIndividualLinks(social_links);

      return {
        Name: user?.name,
        Username: user?.username,
        Instagram: instagram_url || '-',
        'Lookbook Name': lookbook.title.replace(/\n/g, ' '),
        'Promotion Clicks': commaNumber(clicks),
        'Promotion Volume': commaNumber(volume.toFixed(2)),
        'Promotion Views': commaNumber(views),
        'Promotion Estimated EMV': commaNumber(estimatedMediaValue),
        'Request ID': request.id,
        'Request Sent Date': moment(request.createdAt).format('MM/DD/YYYY'),
        'Request Response Date': request.userAcceptedAt ? moment(request.userAcceptedAt).format('MM/DD/YYYY') : '-',
        'Request Fulfillment Date': request.isCompleteAt ? moment(request.isCompleteAt).format('MM/DD/YYYY') : '-',
        'Fullfillment Status': lookbook_order?.status || '-',
        'Tracking Source': lookbook_order?.trackingSource || '-',
        'Tracking Url': lookbook_order?.trackingUrl || '-',
        'Discount Code': discountCodesForUser?.length ? discountCodesForUser[0].displayText : '-',
        Promoted: request.userPromoted ? 'Yes' : '-',

        // address
        Address: stringAddress || '-',
        'Address 1': address1 || '-',
        'Address 2': address2 || '-',
        City: city || '-',
        State: state || '-',
        Zip: zip || '-',
        Country: country || '-'
      };
    });

    downloadCSVFromArray(csvRows, `${lookbook.title} Report.csv`);
    setIsDownloading(false);
  };

  const applySort = metric => {
    if (metric === sortMetric) {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    } else {
      setSortDirection('desc');
      setSortMetric(metric);
    }
  };

  const requireFeatureSubscriptionThenCallback = (feature, callback) => {
    if (blockOnRequiringSubscription(props.user, feature)) return null;
    callback();
  };

  const [showMessageOptions, setShowMessageOptions] = useState(false);
  const messageOptions = [
    { label: 'All Talent', filter: () => true, sublabel: 'Everyone that was invited regardless of status.' },
    {
      label: 'Not Yet Ordered',
      filter: talent => !talent.lookbook_order?.id && !talent.request.userRejectedAt,
      sublabel: 'Only talent that has yet to order from this Lookbook.'
    },
    { label: 'Ordered', filter: talent => !!talent.lookbook_order?.id, sublabel: 'Any talent that picked items from this Lookbook' },
    { label: 'Promoting', filter: talent => !!talent.request?.userPromoted, sublabel: 'All talent that have linked or posted about this content' },
    {
      label: 'Not Promoting',
      filter: talent => !talent.request?.userPromoted && talent.request?.userAcceptedAt,
      sublabel: 'Talent that has not linked or posted about this content since accepting the request.'
    }
  ];

  return (
    <div className='lookbook-report-talent-outer'>
      <div className='lookbook-report-talent-inner'>
        <div className='lookbook-report-talent-search-container'>
          <div className='lookbook-report-download-button' onClick={downloadLookbookReport}>
            {isDownloading ? 'Downloading...' : 'Download'}
          </div>

          <OutsideClickHandler onOutsideClick={() => setShowMessageOptions(false)}>
            <div className='bulk-message-talent-container'>
              <div className='displayed-text-container' onClick={() => setShowMessageOptions(true)}>
                Bulk Message
              </div>
              {showMessageOptions && (
                <div className='talent-select-dropdown-container'>
                  {messageOptions.map(option => {
                    const { label, sublabel, filter } = option;
                    const filteredTalent = aggregatedStats.filter(filter).map(talent => talent.user);
                    const filteredTalentCount = filteredTalent.length;
                    const onClick = e => {
                      e.stopPropagation();
                      props.openBulkTalentModal({ talent: filteredTalent, outreachType: 'general' });
                    };

                    return (
                      <div className='talent-select-dropdown-option' key={label} onClick={onClick}>
                        <div className='talent-select-dropdown-option-label'>
                          {label} <span>{filteredTalentCount}</span>
                        </div>
                        <div className='talent-select-dropdown-option-sublabel'>{sublabel}</div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </OutsideClickHandler>

          <input
            className='lookbook-report-talent-search'
            type='text'
            placeholder='Search Talent'
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
        </div>

        <div className='lookbook-report-talent-table'>
          <div className='lookbook-report-talent-table-row header'>
            <div className='lookbook-report-talent-table-cell header' onClick={() => applySort('name')}>
              Creator
              <FontAwesomeIcon
                className={cn('sort-direction-icon', { active: sortMetric === 'name' })}
                icon={sortDirection === 'desc' || sortMetric !== 'name' ? faArrowDown : faArrowUp}
              />
            </div>
            <div className='lookbook-report-talent-table-cell header less-important'>Selected</div>
            <div className='lookbook-report-talent-table-cell header'>Status</div>
            <div className='lookbook-report-talent-table-cell header' onClick={() => applySort('clicks')}>
              Clicks
              <FontAwesomeIcon
                className={cn('sort-direction-icon', { active: sortMetric === 'clicks' })}
                icon={sortDirection === 'desc' || sortMetric !== 'clicks' ? faArrowDown : faArrowUp}
              />
            </div>
            <div className='lookbook-report-talent-table-cell header' onClick={() => applySort('volume')}>
              Volume
              <FontAwesomeIcon
                className={cn('sort-direction-icon', { active: sortMetric === 'volume' })}
                icon={sortDirection === 'desc' || sortMetric !== 'volume' ? faArrowDown : faArrowUp}
              />
            </div>
            <div
              className='lookbook-report-talent-table-cell header'
              onClick={() => requireFeatureSubscriptionThenCallback('SOCIAL_MENTIONS_FEED', () => applySort('estimatedMediaValue'))}
            >
              EMV
              <FontAwesomeIcon
                className={cn('sort-direction-icon', { active: sortMetric === 'estimatedMediaValue' })}
                icon={sortDirection === 'desc' || sortMetric !== 'estimatedMediaValue' ? faArrowDown : faArrowUp}
              />
            </div>
          </div>

          {aggregatedStats.map((stat, i) => {
            const { user, request, lookbook_order } = stat;
            const { id, name, image } = user;
            const { userPromoted, createdAt, userAcceptedAt, userRejectedAt, expiredOn } = request;

            const isPromoting = !!userPromoted;
            const orderStatus = lookbook_order?.status;
            const hasBeenDeclined = !!request.userRejectedAt;
            const hasExpired = hasRequestExpired(request);
            const openEditExpirationDateModal = () => openEditRequestExpirationOverlay(request, props.updateRequest);

            const openTalentCard = () => props.openArtistModal({ id });
            const openChat = () => props.openChatOverlay({ id, name, image });
            const logRequest = () => window.__ADMIN_CONTROL_MODE__ && console.info(request);

            if (!showAllResults && i > TALENT_TO_SHOW_PAGE_SIZE) return null;

            const isSubscribedToEMV = isAdminControlMode(props.ui) || isSubscribedToFeature(props.user, 'SOCIAL_MENTIONS_FEED');
            const openEMVUpsellModalIfNeeded = () => !isSubscribedToEMV && blockOnRequiringSubscription(props.user, 'SOCIAL_MENTIONS_FEED');

            /*
              Sometimes we can have talent who have stats but are not considered "Promoting", this is because: 

              the stats are coming from a Pin generated prior to the gifting being sent OR after a subsequent gifting request 
              OR the talent JUST posted and the nightly gifting_roi.js script hasn't run yet.

              In this case, we simply show a tooltip.
            */
            const hasStats = !!stat.clicks || !!stat.volume || !!stat.estimatedMediaValue;
            const pinCreatedToday = moment(stat.pinCreationDate).isSameOrAfter(moment(), 'day');
            const tooltipForStatus =
              !isPromoting && hasStats
                ? `Even though ${name} is driving ${
                    stat.clicks ? `clicks` : stat.volume ? 'volume' : stat.estimatedMediaValue ? 'EMV' : 'activity'
                  }, we do not consider them as promoting because ${
                    pinCreatedToday
                      ? 'we wait one full day after link creation to consider their stats.'
                      : 'the link was generated prior to accepting this lookbook.'
                  }`
                : '';

            return (
              <div className='lookbook-report-talent-table-row' key={i}>
                <div className='lookbook-report-talent-table-cell profile-cell'>
                  <div className='profile-image'>
                    {isFetchingResults ? (
                      <div className='loading-image placeholder' />
                    ) : image ? (
                      <img src={image} alt='profile' />
                    ) : (
                      <div className='profile-initials'>{name[0]}</div>
                    )}
                  </div>
                  <div className='profile-text'>
                    {!isFetchingResults ? (
                      <>
                        <div onClick={openTalentCard} className='profile-name'>
                          {name}
                        </div>
                        <div className='request-date-last-action'>
                          {userAcceptedAt
                            ? `Accepted on ${moment(userAcceptedAt).format('MMM Do, YYYY')}`
                            : userRejectedAt
                            ? `Dismissed on ${moment(userRejectedAt).format('MMM Do, YYYY')}`
                            : expiredOn
                            ? `Expired on ${moment(expiredOn).format('MMM Do, YYYY')}`
                            : `Sent on ${moment(createdAt).format('MMM Do, YYYY')}`}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='profile-name loading placeholder'>...</div>
                        <div className='request-date-last-action loading placeholder'>...</div>
                      </>
                    )}
                  </div>
                </div>
                <div className='lookbook-report-talent-table-cell selections-cell less-important'>
                  {isFetchingResults ? (
                    <Loader size={loaderSize} />
                  ) : lookbook_order?.items ? (
                    <div className='selections'>
                      {lookbook_order?.items.map((item, i) => {
                        const { id, title, image } = item;

                        return (
                          <Tooltip message={item.title} key={i}>
                            <div key={id} className='selection'>
                              <Image src={image} alt={title} className={cn('selection-image', lookbook.imageStyle)} />
                            </div>
                          </Tooltip>
                        );
                      })}
                    </div>
                  ) : (
                    '-'
                  )}
                </div>
                <div onClick={logRequest} className='lookbook-report-talent-table-cell'>
                  {isFetchingResults ? (
                    <Loader size={loaderSize} />
                  ) : isPromoting ? (
                    <div className='lookbook-report-promoting-badge'>Promoting</div>
                  ) : orderStatus ? (
                    tooltipForStatus ? (
                      <Tooltip
                        message={tooltipForStatus}
                        getIconDiv={() => (
                          <div className={cn('lookbook-report-order-status-badge', orderStatus)}>
                            {getLookbookOrderStatusDisplay(lookbook_order).display}
                          </div>
                        )}
                      />
                    ) : (
                      <div className={cn('lookbook-report-order-status-badge', orderStatus)}>
                        {getLookbookOrderStatusDisplay(lookbook_order).display}
                      </div>
                    )
                  ) : userAcceptedAt ? (
                    <div className={cn('lookbook-report-order-status-badge', 'accepted')}>Accepted</div>
                  ) : hasBeenDeclined ? (
                    <div className='lookbook-report-declined-badge'>
                      <div className='badge-text'>Not Interested</div>
                      <div className='chat-button'>
                        View Chat <FontAwesomeIcon icon={faChevronCircleRight} />
                      </div>
                    </div>
                  ) : hasExpired ? (
                    <Tooltip message='This request has expired, click to edit expiration date'>
                      <div onClick={openEditExpirationDateModal} className='lookbook-report-expired-badge'>
                        Expired
                      </div>
                    </Tooltip>
                  ) : (
                    <div className='lookbook-report-pending-badge'>
                      <div className='badge-text'>Request Sent</div>
                      <div className='chat-button' onClick={openChat}>
                        View Chat <FontAwesomeIcon icon={faChevronCircleRight} />
                      </div>
                    </div>
                  )}
                </div>
                <div className='lookbook-report-talent-table-cell'>
                  {isFetchingResults ? <Loader size={loaderSize} /> : stat.clicks ? commaNumber(stat.clicks) : '-'}
                </div>
                <div className='lookbook-report-talent-table-cell'>
                  {isFetchingResults ? <Loader size={loaderSize} /> : stat.volume ? `$${commaNumber(stat.volume.toFixed(2))}` : '-'}
                </div>
                <div onClick={openEMVUpsellModalIfNeeded} className='lookbook-report-talent-table-cell'>
                  {isFetchingResults ? (
                    <Loader size={loaderSize} />
                  ) : !isSubscribedToEMV ? (
                    'VIEW'
                  ) : stat.estimatedMediaValue ? (
                    `$${commaNumber(stat.estimatedMediaValue.toFixed(2))}`
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            );
          })}

          {requests.length > TALENT_TO_SHOW_PAGE_SIZE && !searchValue && (
            <div className='lookbook-report-talent-table-row ' onClick={() => setShowAllResults(!showAllResults)}>
              <div className='lookbook-report-talent-table-cell show-all-button'>{showAllResults ? 'Show Less' : 'View All'}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

LookbookReportTalent.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  mentionUserStats: PropTypes.array.isRequired,
  pins: PropTypes.array.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,

  isFetchingMentions: PropTypes.bool.isRequired,
  isFetchingPins: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const { user, ui, talent, analytics } = state;
  return { user, ui, talent, analytics };
};

export default connect(mapStateToProps, {
  openChatOverlay,
  openArtistModal,
  updateRequest,
  openBulkTalentModal
})(LookbookReportTalent);
