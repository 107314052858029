import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faExclamationTriangle, faCommentSmile } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import AddPinModalGeoLink from './Elements/AddPinModalGeoLink';
import AddPinModalFinalTile from './Elements/AddPinModalFinalTile';
import AddPinModalImageSelection from './Elements/AddPinModalImageSelection';
import AddPinModalMerchantPayoutTile from './Elements/AddPinModalMerchantPayoutTile';
import Loader from '../Loader/Loader';

import { getProductFromUrl } from '../../APIClient/pins';
import { getCollectionsTitle } from '../../Helpers/helpers';

import './AddPinModalFinal.scss';

class AddPinModalFinal extends Component {
  static propTypes = {
    finalProduct: PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      image: PropTypes.string,
      retailer: PropTypes.string,
      description: PropTypes.string,
      Product_id: PropTypes.number,
      error: PropTypes.object,
      redirect_url: PropTypes.string,
      disable_monetization: PropTypes.bool,
      allow_optimization: PropTypes.bool,
      allow_displaying_codes: PropTypes.bool,
      allow_deep_link_to_app: PropTypes.bool,
      geolinks: PropTypes.array,
      all_images: PropTypes.arrayOf(PropTypes.string),
      all_images_augmented: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    isAddingOrEditing: PropTypes.bool.isRequired,
    inEditMode: PropTypes.bool.isRequired,
    goBack: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    submitProduct: PropTypes.func.isRequired,
    updateGlobalModalState: PropTypes.func.isRequired,
    updateMerchantData: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
    collaborations: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    Editing_Collection_id: PropTypes.number,
    Editing_ConsultResult_id: PropTypes.number,
    Editing_Contract_id: PropTypes.number,
    pinBeingEdited: PropTypes.object,
    lockedCatalogBrand: PropTypes.object
  };

  componentDidMount() {
    this.fetchLinkDataDebounced = AwesomeDebouncePromise(() => this.fetchLinkData({ noOverrides: true }), 400);
    this.fetchLinkData();
  }

  componentWillUnmount() {
    /*
      Since we may still have in flight API calls, we use this boolean to ensure
      we do not make external updates after the component unmounts.
    */
    this.isUnmounted = true;
  }

  componentDidUpdate(prevProps) {
    if (_.get(this.props, 'finalProduct.link') !== _.get(prevProps, 'finalProduct.link')) {
      this.fetchLinkDataDebounced();
    }
  }

  state = {
    mobileAdvancedSelectionOverlay: null, // 'images', 'links'

    // Similar Results
    productMatch: null,
    variantMatch: null,
    variantMatches: null,
    geolinkMatches: null,
    merchantMatches: null,
    pinMatches: null
  };

  updateFields = updates => {
    // Ensure this call isn't returning after we already unmounted
    if (this.isUnmounted) return;

    this.props.updateGlobalModalState({
      finalProduct: {
        ...this.props.finalProduct,
        ...updates
      }
    });
  };
  updateField = (key, val) => {
    // Ensure this call isn't returning after we already unmounted
    if (this.isUnmounted) return;

    this.props.updateGlobalModalState({
      finalProduct: {
        ...this.props.finalProduct,
        [key]: val
      }
    });
  };

  canGoBack = () => {
    /*
      1) In Adding Mode.
      2) In Edit mode on mobile with panel selected
      3) In Edit mode on Desktop with links selected
    */
    const { inEditMode } = this.props;
    const { mobileAdvancedSelectionOverlay } = this.state;
    if (!inEditMode) return true;
    if (isMobile && mobileAdvancedSelectionOverlay) return true;
    if (!isMobile && mobileAdvancedSelectionOverlay === 'links') return true;
    return false;
  };

  clickBackBtn = () => {
    const { inEditMode } = this.props;
    const { mobileAdvancedSelectionOverlay } = this.state;
    const isMobileWithOverlay = isMobile && mobileAdvancedSelectionOverlay;
    const isDesktopWithLinksSelected = !isMobile && mobileAdvancedSelectionOverlay === 'links';
    if (isMobileWithOverlay || isDesktopWithLinksSelected) {
      this.setState({ mobileAdvancedSelectionOverlay: null });
    } else if (!inEditMode) {
      this.props.goBack();
    } else {
      console.error('We should not hit this use case');
    }
  };

  fetchLinkData = async ({ noOverrides = false } = {}) => {
    /*
      When we see a new URL, try to look up the url in the database to pull
      additional metadata from any variant or product matches that we can use
      to automatically update the title or automatically match the product.
    */
    const url = _.get(this.props, 'finalProduct.link');
    const productResp = await getProductFromUrl(url);
    const { product, variant, variants, merchants, pins, geolinks } = productResp;

    if (!this.props.inEditMode && (product || variant)) {
      product && this.updateField('Product_id', product.id);
      const brand = _.get(product, 'brand') || _.get(variant, 'rawBrand') || '';
      const title = _.get(product, 'title') || _.get(variant, 'rawTitle') || '';
      !noOverrides && this.updateField('title', `${brand.toUpperCase()} | ${title}`);
    }

    this.setState({
      productMatch: product,
      variantMatch: variant,
      variantMatches: variants,
      pinMatches: pins,
      geolinkMatches: geolinks,
      merchantMatches: merchants
    });
  };

  getWarningMsg = () => {
    const { finalProduct, inEditMode, store, collaborations } = this.props;
    const url = _.get(finalProduct, 'link');

    if (!url) return null;

    if (url.includes('amazon') && url.includes('tag=')) {
      return 'The current URL has an Amazon attribution tag, check to make sure it is yours otherwise you may not be paid out correctly.';
    }
    if (url.includes('googleadservices')) {
      return 'The current URL is attributed to Google and therefore you will not earn on the link. Please use the URL from the final product page.';
    }
    if (url.includes('gclid')) {
      return 'The current URL has a "gclid" parameter meaning it will not accurately pay you on purchases. Please remove anything after the ? from the URL before adding when you find the URL via Google Search.';
    }

    const isConsultResult = !!this.props.Editing_ConsultResult_id;
    const isContract = !!this.props.Editing_Contract_id;
    const alreadyAddedProducts = isConsultResult
      ? store?.visibleConsultResult?.pins || []
      : isContract
      ? collaborations?.visibleContract?.pins || []
      : store?.visibleCollection?.pins || [];
    if (
      !inEditMode &&
      (alreadyAddedProducts.find(pin => pin.link === finalProduct.link) ||
        alreadyAddedProducts.find(pin => pin.Product_id === finalProduct.Product_id))
    ) {
      return `This product is already in this ${isConsultResult ? 'Consult' : isContract ? 'Collaboration' : getCollectionsTitle()}`;
    }

    if (typeof finalProduct?.error === 'string') {
      return finalProduct?.error;
    }
    return null;
  };

  getGeneralMsg = () => {
    const url = _.get(this.props, 'finalProduct.link');
    if (!url) return null;

    if (url.includes('rstyle.me')) {
      return 'Please let us know if you are getting a better rate with rewardStyle and we will try to negotiate a higher rate on your behalf!';
    }
    return null;
  };

  render() {
    const { finalProduct, inEditMode, submitProduct, isAddingOrEditing, closeModal, user, store } = this.props;
    const { mobileAdvancedSelectionOverlay } = this.state;
    const { redirect_url, link } = finalProduct || {};
    const warningMsg = this.getWarningMsg();
    const generalMsg = this.getGeneralMsg();
    return (
      <div className='add-pin-modal-final-outer-container'>
        <div className='header-container'>
          {!this.canGoBack() ? (
            <div></div>
          ) : (
            <div onClick={this.clickBackBtn} className='icn back-icn'>
              <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
            </div>
          )}
          {inEditMode ? <div>EDIT YOUR PRODUCT</div> : <div>FINALIZE AND ADD</div>}
          <div onClick={closeModal} className='icn close-icn'>
            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
          </div>
        </div>
        <div className='final-product-tile-container panel'>
          <AddPinModalMerchantPayoutTile
            user={user}
            store={store}
            initial_link={redirect_url}
            link={link}
            updateFields={this.updateFields}
            variantMatches={this.state.variantMatches}
            merchantMatches={this.state.merchantMatches}
            lockedCatalogBrand={this.props.lockedCatalogBrand}
          />
          <AddPinModalFinalTile
            ui={this.props.ui}
            user={user}
            finalProduct={finalProduct}
            updateField={this.updateField}
            advancedSelectionOverlay={mobileAdvancedSelectionOverlay}
            geolinkMatches={this.state.geolinkMatches}
            pinBeingEdited={this.props.pinBeingEdited}
            toggleAdvancedPanel={newPanel =>
              this.setState({
                mobileAdvancedSelectionOverlay: newPanel
              })
            }
          />
        </div>
        <div className={classnames('panel', 'final-product-advanced-selection-container', { visible: mobileAdvancedSelectionOverlay })}>
          {mobileAdvancedSelectionOverlay === 'links' ? (
            <AddPinModalGeoLink
              product={finalProduct}
              productMatch={this.state.productMatch}
              variantMatch={this.state.variantMatch}
              variantMatches={this.state.variantMatches}
              geolinkMatches={this.state.geolinkMatches}
              updateMerchantData={this.props.updateMerchantData}
              updateGeolinks={newlinks => this.updateFields({ geolinks: newlinks })}
              store={this.props.store}
              user={user}
            />
          ) : (
            <AddPinModalImageSelection
              user={user}
              product={finalProduct}
              inEditMode={inEditMode}
              productMatch={this.state.productMatch}
              variantMatch={this.state.variantMatch}
              variantMatches={this.state.variantMatches}
              pinMatches={this.state.pinMatches}
              updateData={({ image, link, title }) => {
                if (image && window.innerWidth <= 576) {
                  this.setState({ mobileAdvancedSelectionOverlay: null });
                }
                this.updateFields({
                  ...(image ? { image } : {}),
                  ...(link ? { link } : {}),
                  ...(title ? { title } : {})
                });
              }}
            />
          )}
        </div>
        <div className='footer-container'>
          <div className='footer-warning'>
            {(warningMsg || generalMsg) && (
              <>
                <FontAwesomeIcon icon={warningMsg ? faExclamationTriangle : faCommentSmile}></FontAwesomeIcon>
                <div>{warningMsg || generalMsg}</div>
              </>
            )}
          </div>
          <div onClick={submitProduct} className={classnames('submit-btn', { adding: isAddingOrEditing })}>
            {inEditMode ? 'SAVE' : 'ADD PRODUCT'}
            {isAddingOrEditing && <Loader size={60} />}
          </div>
        </div>
      </div>
    );
  }
}

export default AddPinModalFinal;
