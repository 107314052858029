import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import Mousetrap from 'mousetrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Header, HeaderOld } from '../../Components';
import { withRouter } from 'react-router-dom';
import '../../../node_modules/intersection-observer/intersection-observer.js';

import AuthModal from '../../Components/Auth/AuthModal';
import Footer from '../../Components/Footer/Footer';
import FooterOld from '../../Components/Footer/FooterOld';
import MovePinModal from '../../Components/Modals/MovePinModal';
import CodesModal from '../../Components/Modals/CodesModal';
import FulfillmentModal from '../../Components/Modals/FulfillmentModal';
import BrandBudgetModal from '../../Components/Modals/BrandBudgetModal';
import SubscriptionOfferModal from '../../Components/Modals/SubscriptionOfferModal';
import UnpaidInvoicesModal from '../../Components/Modals/UnpaidInvoicesModal';
import ConnectInstagramModal from '../../Components/Modals/ConnectInstagramModal';
import BulkTalentModal from '../../Components/BulkTalentModal/BulkTalentModal';
import BulkRequestModal from '../../Components/BulkRequestModal/BulkRequestModal';
import BrandModal from '../../Components/BrandModal/BrandModal';
import OnboardingModal from '../../Components/Onboarding/OnboardingModal';
import OpenSpotlightOptionsOverlay from '../../Components/Spotlight/OpenSpotlightOptionsOverlay';
import SimulatedUserControl from '../../Components/Admin/SimulatedUserControl';
import ChatOverlay from '../../Components/Chat/ChatOverlay';
import ArtistModal from '../../Components/ArtistModal/ArtistModal';
import AddressModal from '../../Components/Modals/AddressModal';
import BonusModal from '../../Components/Modals/BonusModal';
import CobrowseSDK from '../../Components/General/CobrowseSDK';

import { loginUser, registerUser, logoutUser, syncCurrentUser, applyToWaitlist, updateCurrentUser } from '../../Actions/UserActions';
import { storeAttributionFromUrl } from '../../Actions/AttributionActions';
import { updateBrand } from '../../Actions/BrandActions';
import {
  openAuthModal,
  hideUserLogin,
  requireAuth,
  changeSearchVal,
  setJoinedMailingList,
  toggleBodyScrollLock,
  clearPinsToMove,
  setShopMyLabel,
  toggleAdminControlMode,
  closeArtistModal,
  closeChatOverlay,
  openAddressModal,
  closeAddressModal,
  closeCodesModal,
  closeRequestModal,
  closeBonusModal,
  closeBrandModal,
  closeConnectInstagramModal,
  closeBulkTalentModal,
  closeBrandBudgetModal,
  closeFulfillmentModal,
  setTermsAndConditionsVisible,
  closeSubscriptionOfferModal,
  closeUnpaidInvoicesModal
} from '../../Actions/UIActions';
import { selectUserToManage, getUserManagesNotificationCounts } from '../../Actions/ManagerActions';
import { getBrandAnalytics } from '../../Actions/AnalyticsActions';
import { getTalent as syncTalent } from '../../Actions/TalentActions';
import { isPublicPage, isEmbedPage, getAndRemoveUrlParam } from '../../Helpers/helpers';
import { isCurrentlyManaging } from '../../Helpers/manager_helpers';
import {
  isLoggedIn,
  hasCompletedOnboarding,
  isSimulatingUser,
  isBrand,
  isManager,
  getBrand,
  getLastLoggedIn,
  getUserId,
  hasAgreedToPrivacyPolicy,
  hasAgreedToTermsAndConditions
} from '../../Helpers/user_helpers';
import { getBrandLastLoggedIn } from '../../Helpers/brand_helpers';
import {
  isMovingPins,
  isBrandModalVisible,
  isBulkTalentModalVisible,
  isCodesModalVisible,
  isFulfillmentModalVisible,
  isBonusModalVisible,
  isBulkRequestModalVisible,
  isTermsAndConditionsModalVisible,
  isSubscriptionOfferModalVisible,
  isUnpaidInvoicesModalVisible,
  isBrandBudgetModalVisible
} from '../../Helpers/ui_helpers';
import { showingSpotlightTasksModal, getSelectedSpotlightKey } from '../../Helpers/spotlight_helpers';
import { intitializeIntercom } from '../../Helpers/chat_helpers';

import moment from 'moment';

import './MainContainer.scss';
import { ONBOARDING_MODAL_TABS } from '../../Helpers/constants.js';

class MainContainer extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    manager: PropTypes.object.isRequired,
    requireAuth: PropTypes.func.isRequired,
    syncCurrentUser: PropTypes.func.isRequired,
    selectUserToManage: PropTypes.func.isRequired,
    getUserManagesNotificationCounts: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    syncTalent: PropTypes.func.isRequired,
    getBrandAnalytics: PropTypes.func.isRequired,
    changeSearchVal: PropTypes.func.isRequired,
    clearPinsToMove: PropTypes.func.isRequired,
    closeChatOverlay: PropTypes.func.isRequired,
    closeBrandModal: PropTypes.func.isRequired,
    theme: PropTypes.string
  };

  initCurrentUser = async () => {
    // Sync user object with server
    await this.props.syncCurrentUser();

    // after sync user, check if Terms and Conditions need review
    if (this.shouldShowTermsAndConditions()) {
      this.props.setTermsAndConditionsVisible(true);
    } else {
      this.props.setTermsAndConditionsVisible(false); // ensure it's closed (eg. switching between users)
    }

    // Sync talent object with server
    isBrand(this.props.user) && this.props.syncTalent({ isFetchingInitial: true });

    // Sync brand data with the server
    isBrand(this.props.user) && this.props.getBrandAnalytics();

    // Sync all Notifications
    this.props.getUserManagesNotificationCounts();

    // Store attribution
    this.props.storeAttributionFromUrl();
  };

  constructor(props) {
    super(props);

    this.initCurrentUser();
  }

  componentDidMount() {
    const { user, ui } = this.props;

    // Intercom Initialization
    isLoggedIn(user) && !this.shouldShowOnboarding() && !isEmbedPage() && !isMobile && this.intitializeIntercom();

    // Update now to be the last time the user / brand logged in
    if (isLoggedIn(user) && !isSimulatingUser(user)) {
      const isOutOfSync = lastLoggedIn => !lastLoggedIn || moment(lastLoggedIn).isBefore(moment().subtract(12, 'hours'));
      const updates = { lastLoggedIn: moment().format('YYYY-MM-DD HH:mm:ss') };
      !isBrand(user) && isOutOfSync(getLastLoggedIn(user)) && this.props.updateCurrentUser(updates, getUserId(user));
      isBrand(user) && isOutOfSync(getBrandLastLoggedIn(user)) && this.props.updateBrand(getBrand(user), updates);
    }

    // Clear out data just in case
    isMovingPins(ui) && this.props.clearPinsToMove();

    // Admin Configuration
    Mousetrap.bind('ctrl+s+m', () => this.props.toggleAdminControlMode());

    // Handle URL decoding
    this.handleAuthUrlParams();

    // Close overlays
    this.props.closeChatOverlay();
    this.props.closeBulkTalentModal();
    this.props.closeFulfillmentModal();
    this.props.closeCodesModal();
    this.props.closeRequestModal();
    this.props.closeAddressModal();
    this.props.closeBonusModal();
    this.props.closeBrandModal();
    this.props.closeBrandBudgetModal();
    this.props.closeSubscriptionOfferModal();
    this.props.closeUnpaidInvoicesModal();
    this.props.closeConnectInstagramModal();
    // this.props.closeArtistModal(); // This isn't working, needs delay potentially?
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    // re-hydrate data for switching/simulating users
    if (user?.profile?.id && prevProps.user?.profile?.id !== user.profile.id) {
      this.initCurrentUser();

      // Intercom Initialization
      isLoggedIn(user) && !this.shouldShowOnboarding() && !isEmbedPage() && !isMobile && this.intitializeIntercom();
    }
  }

  componentWillUnmount() {
    Mousetrap.unbind('ctrl+s+m');
  }

  onChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleAuthUrlParams = () => {
    const authPanel = getAndRemoveUrlParam('auth');
    if (['login', 'register', 'apply', 'invite', 'forgot-password', 'apply-brands'].includes(authPanel)) {
      this.props.openAuthModal(authPanel);
    } else {
      setTimeout(this.props.hideUserLogin, 100);
    }
  };

  intitializeIntercom = () => {
    const { user } = this.props;

    // Ensure we want to open intercom
    // if (isSimulatingUser(user)) return;

    intitializeIntercom(user);
  };

  canShowIntroModal = () => {
    // base conditions for intro modal (full onboarding, terms/conditions)
    const { user, ui, manager } = this.props;

    // Ensure we want to open intro modal
    if (!isLoggedIn(user)) return false;
    if (getSelectedSpotlightKey(ui)) return false; // Ensure we don't show it after closing in the moment we store the onboarding status
    if (isSimulatingUser(user)) return false;
    if (isBrand(user)) return false;
    if (isManager(user)) return false;
    if (isCurrentlyManaging(manager)) return false;
    if (window.location.href.includes('/oauth')) return false; // Hide on oauth landing page for newly registered user

    return true;
  };

  shouldShowOnboarding = () => {
    /* Whether to show Onboarding Modal */
    const { user } = this.props;
    const location = window.location.href;
    const onLookbookPage = location.includes('/lookbooks');
    const onLookbookOrderPage = location.includes('/lookbooks/order');

    // Ensure we want to open onboarding
    if (!this.canShowIntroModal()) return false;
    if (hasCompletedOnboarding(user)) return false;
    if (onLookbookPage && !onLookbookOrderPage) return false; // Hide on lookbook page for lookbook invites (except order page)

    return true;
  };

  shouldShowTermsAndConditions = () => {
    /* Whether to show Terms and Conditions
      Terms and Conditions and Privacy Policy are shown in onboarding modal, but also can be shown separately on updates to either policy
    */
    const { user } = this.props;

    // Ensure we want to open terms and conditions
    if (!this.canShowIntroModal()) return false;
    if (this.shouldShowOnboarding()) return false; // already showing onboarding
    if (hasAgreedToTermsAndConditions(user) && hasAgreedToPrivacyPolicy(user)) return false; // already agreed to both
    if (window.location.href.includes('/lookbook')) return false; // Hide on lookbooks page for lookbook invites

    return true;
  };

  shouldShowOnboardingOverlay = () => {
    /* Whether to show Question Mark that opens spotlight modal */
    const { user, ui } = this.props;
    if (window.__IS_APP__) return false;
    if (isBrand(user)) return false;
    if (isManager(user)) return false;
    if (this.shouldShowOnboarding()) return false;
    if (showingSpotlightTasksModal(ui)) return false;
    if (isTermsAndConditionsModalVisible(ui)) return false;
    return true;
  };

  shouldShowChatOverlay = () => {
    const { user } = this.props;
    if (window.__IS_APP__) return false;
    if (!isBrand(user)) return false;
    return true;
  };

  render() {
    const { user, openAuthModal, ui, manager, requireAuth, theme = 'light' } = this.props;
    const { modalVisible, showAuthPanel, activeArtistModalTalent } = ui;
    const isRebrandedHeader = window.__IS_SMS__;
    return (
      <div
        className={classnames('root', {
          'modal-visible': modalVisible,
          sms: window.__IS_SMS__,
          app: window.__IS_APP__,
          pro: window.__IS_PRO__,
          home: window.location.pathname.includes('/home')
        })}
      >
        <MetaTags>
          <title>ShopMy | The Growth Platform for Elite Creators and Brands</title>
          <meta property='og:title' content='ShopMy | The Growth Platform for Elite Creators and Brands' />
          <meta
            name='description'
            content="Connecting the world's leading content creators with the top brands in beauty, fashion, lifestyle and more."
          />
          <meta
            property='og:description'
            content="Connecting the world's leading content creators with the top brands in beauty, fashion, lifestyle and more."
          />
          <meta
            name='theme-color'
            content={isPublicPage() ? '#fff' : ui?.brandTheme?.allow_advanced_theming ? ui?.brandTheme?.primaryColor : '#1f1f1f'}
          />
        </MetaTags>
        {showAuthPanel && (
          <AuthModal
            user={this.props.user}
            starterPanel={showAuthPanel}
            closePanel={this.props.hideUserLogin}
            loginUser={this.props.loginUser}
            registerUser={this.props.registerUser}
            applyToWaitlist={this.props.applyToWaitlist}
          />
        )}
        {isRebrandedHeader ? (
          <Header
            user={user}
            ui={ui}
            manager={manager}
            openAuthModal={openAuthModal}
            isLoggedIn={!!user.isLoggedIn}
            requireAuth={requireAuth}
            logoutUser={this.props.logoutUser}
            changeSearchVal={this.props.changeSearchVal}
            setShopMyLabel={this.props.setShopMyLabel}
            toggleBodyScrollLock={this.props.toggleBodyScrollLock}
            selectUserToManage={this.props.selectUserToManage}
          />
        ) : (
          <HeaderOld
            user={user}
            ui={ui}
            openAuthModal={openAuthModal}
            isLoggedIn={!!user.isLoggedIn}
            requireAuth={requireAuth}
            logoutUser={this.props.logoutUser}
            changeSearchVal={this.props.changeSearchVal}
          />
        )}
        <div className={classnames('content-container', { dark: theme === 'dark' })}>
          <div
            className={classnames('main-container-children', {
              public: isPublicPage(),
              embed: isEmbedPage()
            })}
          >
            {this.props.children}
          </div>
        </div>
        {window.__IS_APP__ ? null : window.__IS_SMS__ ? (
          <Footer
            location={this.props.location}
            history={this.props.history}
            setJoinedMailingList={this.props.setJoinedMailingList}
            ui={ui}
            user={user}
          />
        ) : (
          <FooterOld
            location={this.props.location}
            history={this.props.history}
            setJoinedMailingList={this.props.setJoinedMailingList}
            ui={ui}
            user={user}
          />
        )}
        {!window.__IS_SNAP__ && isLoggedIn(user) && (
          <>
            <CobrowseSDK />
            {this.shouldShowOnboardingOverlay() && <OpenSpotlightOptionsOverlay />}
            {this.shouldShowOnboarding() ? (
              <OnboardingModal />
            ) : (
              showingSpotlightTasksModal(ui) && <OnboardingModal onlyShowTab={ONBOARDING_MODAL_TABS.TASKS} />
            )}
            {isTermsAndConditionsModalVisible(ui) && <OnboardingModal onlyShowTab={ONBOARDING_MODAL_TABS.TERMS_AND_PRIVACY} />}
            {isMovingPins(ui) && <MovePinModal ui={ui} />}
            {isBrand(user) && isBonusModalVisible(ui) ? <BonusModal /> : null}
            {isBrand(user) && isBulkTalentModalVisible(ui) ? <BulkTalentModal /> : null}
            {isBrand(user) && isCodesModalVisible(ui) ? <CodesModal /> : null}
            {isBrand(user) && isBulkRequestModalVisible(ui) ? <BulkRequestModal /> : null}
            {isBrand(user) && isFulfillmentModalVisible(ui) ? <FulfillmentModal /> : null}
            {isBrand(user) && isBrandBudgetModalVisible(ui) ? <BrandBudgetModal /> : null}
            {isBrand(user) && isSubscriptionOfferModalVisible(ui) ? <SubscriptionOfferModal /> : null}
            {isBrand(user) && isUnpaidInvoicesModalVisible(ui) ? <UnpaidInvoicesModal /> : null}
            {isBrandModalVisible(ui) ? <BrandModal /> : null}
            {isBrand(user) ? (
              <ArtistModal close={this.props.closeArtistModal} artist_id={activeArtistModalTalent?.id} tab={activeArtistModalTalent?.tab} />
            ) : null}
            {!isBrand(user) && ui.addressModalIsVisible ? <AddressModal close={this.props.closeAddressModal} /> : null}
            {!isBrand(user) && ui.isConnectInstagramModalVisible ? <ConnectInstagramModal /> : null}
            {this.shouldShowChatOverlay() && <ChatOverlay />}
            {!isEmbedPage() && <SimulatedUserControl />}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user, ui, manager } = state;
  return { user, ui, manager };
};

export default connect(mapStateToProps, {
  hideUserLogin,
  requireAuth,
  loginUser,
  registerUser,
  applyToWaitlist,
  openAuthModal,
  toggleBodyScrollLock,
  toggleAdminControlMode,
  changeSearchVal,
  setJoinedMailingList,
  selectUserToManage,
  closeChatOverlay,
  closeArtistModal,
  closeBulkTalentModal,
  closeFulfillmentModal,
  closeBrandBudgetModal,
  closeSubscriptionOfferModal,
  closeUnpaidInvoicesModal,
  closeCodesModal,
  closeRequestModal,
  closeBonusModal,
  closeBrandModal,
  closeConnectInstagramModal,
  getUserManagesNotificationCounts,
  clearPinsToMove,
  setShopMyLabel,
  syncCurrentUser,
  logoutUser,
  syncTalent,
  getBrandAnalytics,
  updateCurrentUser,
  updateBrand,
  openAddressModal,
  closeAddressModal,
  setTermsAndConditionsVisible,
  storeAttributionFromUrl
})(withRouter(MainContainer));
