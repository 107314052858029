import React, { useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './DiscoverResults.scss';

import { openArtistModal, openChatOverlay, openRequestModal, openCodesModal } from '../../Actions/UIActions';
import { getDiscoverResultsLimit } from '../../Helpers/discover_helpers';

import Loader from '../Loader/Loader';

import ListUser from '../Lists/ListUser';
import { formatUsersForBulkRequests } from '../../Helpers/gifting_helpers';

const DiscoverResults = props => {
  const { page, results, lists, isSearchingUsers } = props;

  const observer = useRef();

  const lastResultElementRef = useCallback(
    node => {
      if (isSearchingUsers) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          if (!isSearchingUsers) {
            props.setPage(page + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [isSearchingUsers]
  );

  const openCodeOverlay = user =>
    props.openCodesModal({
      params: {
        User_id: user.User_id,
        name: user.name,
        showTemplates: true
      }
    });
  const openGiftingOverlay = user =>
    props.openRequestModal({
      params: {
        preselectedUsers: formatUsersForBulkRequests([user])
      }
    });

  const hasLists = lists.length > 0;
  return (
    <div className={cn('discover-results-container', { 'searching-first-page': isSearchingUsers && !page })}>
      {hasLists && (
        <div className='discover-results-section'>
          <div className='discover-results-section-title'>
            <div className='title'>Lists</div>
            <div className='line' />
          </div>
          <div className='discover-results-lists'>
            {lists.map(list => {
              return (
                <Link to={`/discover/${list.id}`} className='discover-results-list' key={list.id}>
                  <div className='title'>{list.title}</div>
                </Link>
              );
            })}
          </div>
        </div>
      )}
      <div className='discover-results-section'>
        {hasLists && (
          <div className='discover-results-section-title'>
            <div className='title'>Creators</div>
            <div className='line' />
          </div>
        )}
        <div className='discover-results-users'>
          {results.map((result, idx) => {
            const isFinalResult = results.length === idx + 1;
            const mightNeedMoreLoaded = isFinalResult && idx + 1 >= getDiscoverResultsLimit();
            return (
              <div className='discover-result-container' ref={mightNeedMoreLoaded ? lastResultElementRef : null} key={result.id}>
                <ListUser
                  index={idx}
                  discoverOrListUser={result}
                  user={props.user}
                  analytics={props.analytics}
                  talent={props.talent}
                  isEditing={false}
                  syncExternal={props.syncData}
                  openGiftingOverlay={openGiftingOverlay}
                  openCodeOverlay={openCodeOverlay}
                  openArtistModal={props.openArtistModal}
                  openChatOverlay={props.openChatOverlay}
                  ensureLoggedInThenCall={cb => cb()}
                />
              </div>
            );
          })}
        </div>
      </div>
      {isSearchingUsers && page > 0 && (
        <div className='loading-next-page-container'>
          <Loader />
        </div>
      )}
    </div>
  );
};

DiscoverResults.propTypes = {
  // From Redux
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  openCodesModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  syncData: PropTypes.func.isRequired,

  // From outside
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  results: PropTypes.array.isRequired,
  lists: PropTypes.array.isRequired,
  isSearchingUsers: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const { user, talent, analytics } = state;
  return { user, talent, analytics };
};

export default connect(mapStateToProps, {
  openArtistModal,
  openChatOverlay,
  openCodesModal,
  openRequestModal
})(DiscoverResults);
