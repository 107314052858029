import _ from 'lodash';
import { getShortUrl } from './helpers';
import { getUserId } from './user_helpers';

export const getCustomIdPin = (pin, clickingUser) => {
  return `user-${pin.User_id}-pin-${pin.id}-puser-${getUserId(clickingUser)}`;
};
export const getCustomIdProduct = (product, clickingUser) => {
  return `product-${product.id}-puser-${getUserId(clickingUser)}`;
};
export const getCustomIdVariant = (variant, clickingUser) => {
  return `product-${variant.Product_id}-puser-${getUserId(clickingUser)}`;
};

export const getAffiliateLink = (pin, clickingUser, pinUser) => {
  // Disable links if explicitly set
  if (!pin.affiliate_link) return pin.link;

  const curRef = getCurRef(pin.affiliate_link);
  let finalLink = pin.affiliate_link
    .split('<custom_id>')
    .join(getCustomIdPin(pin, clickingUser))
    .split('<cur_ref>')
    .join(curRef)
    .split('<amazon_tag>')
    .join(_.get(pinUser, 'amazonCode') || _.get(pinUser, 'profile.amazonCode') || 'shopmyshelf00-20')
    .split('<skinbetter_invite_code>')
    .join(_.get(pinUser, 'skinbetterInviteCode') || '209176'); // Idriss Fallback
  return finalLink;
};

export const getProductAffiliateLink = (product, clickingUser) => {
  const curRef = getCurRef();
  let finalLink =
    product.fallbackAffiliateUrl &&
    product.fallbackAffiliateUrl
      .split('<custom_id>')
      .join(getCustomIdProduct(product, clickingUser))
      .split('<cur_ref>')
      .join(curRef)
      .split('<amazon_tag>')
      .join('shopmyshelf00-20')
      .split('<skinbetter_invite_code>')
      .join('209176'); // Idriss Fallback

  return finalLink;
};

export const getVariantAffiliateLink = (variant, clickingUser) => {
  const curRef = getCurRef();
  let finalLink = variant.affiliateUrl
    .split('<custom_id>')
    .join(getCustomIdVariant(variant, clickingUser))
    .split('<cur_ref>')
    .join(curRef)
    .split('<amazon_tag>')
    .join('shopmyshelf00-20')
    .split('<skinbetter_invite_code>')
    .join('209176'); // Idriss

  return finalLink;
};

export const getCurRef = affiliateLink => {
  let adjustedUrl = window.__IS_SNAP__
    ? window.location.href
        .replace('localhost:3000', 'shopmy.us')
        .replace(/localhost:456([0-9])+/, 'shopmy.us') // All Snap Runs
        .replace(/localhost:356([0-9])+/, 'shopmy.us') // All Snap Runs
        .replace(/localhost:256([0-9])+/, 'shopmy.us') // All Snap Runs
        .replace('http://', 'https://')
    : window.location.href;

  // Narrativ.. if you're reading this... you shouldn't be...
  // we kindly ask you build a great product and stop trying to copy ours...
  const isNarrativ = affiliateLink && affiliateLink.includes('shop-links');
  if (isNarrativ) {
    adjustedUrl = adjustedUrl
      .split('/')
      .map(part => {
        const partAsInt = parseInt(part);
        if (!_.isNaN(partAsInt) && partAsInt > 28580) {
          return Math.floor(partAsInt ** 2.1);
        }
        return part;
      })
      .join('/');
  }

  return adjustedUrl;
};

export const getShortPinLink = pin => {
  const { id } = pin || {};
  return `${getShortUrl()}/p-${id}`;
};

export const getShortUserLink = user => {
  const { username } = user || {};
  return `${getShortUrl()}/${username}`;
};

export const getShortCollectionLink = collection => {
  const { id } = collection || {};
  return `${getShortUrl()}/c-${id}`;
};

export const getShortConsultLink = consult => {
  const { id } = consult || {};
  return `${getShortUrl()}/co-${id}`;
};
