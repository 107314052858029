import _ from 'lodash';
import cogoToast from 'cogo-toast';
import moment from 'moment';

import noProductFoundImg from '../static/images/misc/no_image_found.png';

export const getRootProUrl = () =>
  isDevelopment() ? 'http://localhost:3003' : `https://${isProduction() ? 'pro.shopmyshelf.us' : 'staging.pro.shopmyshelf.io'}`;
export const getRootSMSUrl = () => (isDevelopment() ? 'http://localhost:3000' : `https://${isProduction() ? '' : 'staging.'}shopmy.us`);
export const getRootUrl = () => (isPro() ? getRootProUrl() : getRootSMSUrl());
export const getShortUrl = () =>
  isDevelopment() ? process.env.REACT_APP_API_URL || 'http://localhost:5000' : isProduction() ? `https://go.shopmy.us` : `https://s.myshlf.us`;
export const getShopUrl = username => `${getRootUrl()}/${username}`;
export const isStaging = () => !isSnap() && window.location.href.includes('staging.shopmy.us');
export const isDevelopment = () => !isSnap() && window.location.href.includes('localhost:');
export const isProduction = () =>
  !isStaging() && (isSnap() || window.location.href.includes('shopmyshelf.us') || window.location.href.includes('shopmy.us'));
export const isPro = () => window.location.href.includes('localhost:3003') || window.location.href.includes('pro.shopmyshelf');
export const isSMS = () =>
  !isPro() &&
  (window.location.href.includes('localhost:3000') ||
    window.location.href.includes('ngrok') ||
    window.location.href.includes('shopmyshelf.') ||
    window.location.href.includes('shopmy.'));
export const isSnap = () => navigator && navigator.userAgent === 'ReactSnap';

export const isPublicPage = () => {
  /*
      Public Pages should not have the header or be able to access user specific
      functionality.
  */
  return window.location.href.includes('/public/') || window.location.href.includes('/quick/');
};
export const isQuickPage = () => window.location.href.includes('/quick/');
export const isEmbedPage = () => window.location.href.includes('/embed/');
export const isOAuthPage = () => window.location.href.includes('/oauth');
export const isBeautyShopPage = () => {
  return window.location.href.slice(window.location.href.length - 5) === '/shop' || window.location.href.includes('/shop/');
};

export const isWelcomePage = () => window.location.href.includes('/welcome');
export const isHomePage = () => window.location.pathname === '/home' || window.location.pathname.startsWith('/home/');
export const isBrandHomePage = () => window.location.href.includes('/home/brands');
export const isSpecificHomePage = () => {
  return isHomePage() && (window.location.pathname.includes('creators') || window.location.pathname.includes('brands'));
};

export const isLinksPage = () => window.location.href.includes('/links');
export const isCollectionPage = () => window.location.href.includes('/collection') || window.location.href.includes('/c/');
export const isPromotePage = () => window.location.href.includes('/promote');
export const isReferralPage = () => window.location.href.includes('/join/') || window.location.href.includes('/invite/');

export const isContentRendered = () => document && document.querySelector('#root') && document.querySelector('#root').hasChildNodes();

export const copyToClipboard = (str, logSuccess = false, logMsg = 'Copied to clipboard.') => {
  const el = document.createElement('textarea'); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
  logSuccess && cogoToast.success(logMsg, { hideAfter: 1 });
};

export const getRootSCSSClass = () => (window.__IS_PRO__ ? 'pro' : 'sms');
export const getSiteTitle = () => (window.__IS_PRO__ ? 'ShopMy Professional' : 'ShopMy');
export const getCollectionsTitle = ({ uppercase, plural, branded } = {}) => {
  return uppercase
    ? plural
      ? branded
        ? 'Shelves'
        : 'Collections'
      : branded
      ? 'Shelf'
      : 'Collection'
    : plural
    ? branded
      ? 'shelves'
      : 'collections'
    : branded
    ? 'shelf'
    : 'collection';
};

export const getSmartImage = (img, showFallback = true) => {
  if (!img) {
    return showFallback ? noProductFoundImg : null;
  }

  let newImage = img;

  // Parse object if it exists
  newImage = typeof newImage === 'object' ? _.first(newImage) : newImage;

  // Add https in case it doesn't exist
  newImage = newImage.split('http://').join('https://');

  // Add Custom handling for Target
  if (img.includes('target.scene7') || img.includes('images.bloomingdaleassetts') || img.includes('r29static')) {
    newImage = `${newImage}${newImage.includes('?') ? '&' : '?'}&fmt=pjpeg`;
  }

  // Handle cloudfront optimization
  newImage = newImage.replace('production-shopmyshelf-uploads.s3-us-east-2.amazonaws.com', 'static.shopmy.us/uploads');
  newImage = newImage.replace('production-shopmyshelf-uploads.s3.us-east-2.amazonaws.com', 'static.shopmy.us/uploads');
  newImage = newImage.replace('askemma-static-public.s3.us-east-2.amazonaws.com', 'static.shopmy.us');

  // For some reason the pins endpoint cannot handle encoded characters, we made an update
  // on October 7, 2021 to ensure no more backend images have question marks so this is for
  // backwards compatibility.
  if (!newImage.includes('%')) {
    newImage = newImage.replace('production-shopmyshelf-pins.s3.us-east-2.amazonaws.com', 'static.shopmy.us/pins');
  }

  return newImage;
};

export const matchScrollHeight = element => {
  /*
    Use this to lock a textarea to the height of it's inner contents, for example:
      <textarea rows={4} ref={e => matchScrollHeight(e)} />
  */
  element && (element.style.height = `inherit`);
  element && (element.style.height = `${element.scrollHeight}px`);
};

export const dontBubble = e => {
  /*
    Use this on modals / other elements where you don't want to send clicks
    to parent components: onClick={dontBubble} <- looks clean, is clear.
  */
  e.stopPropagation();
};

export const scrollToTop = () => window.scrollTo(0, 0);

export const getRetailerImage = retailer => {
  return {
    Bite: 'https://static.shopmy.us/Retailers/bite.png',
    Biossance: 'https://static.shopmy.us/Retailers/biossance.png',
    'Credo Beauty': 'https://static.shopmy.us/Retailers/credo_beauty.png',
    Dermstore: 'https://static.shopmy.us/Retailers/dermstore.png',
    Farmacy: 'https://static.shopmy.us/Retailers/farmacy.png',
    Fenty: 'https://static.shopmy.us/Retailers/fenty.png',
    Follain: 'https://static.shopmy.us/Retailers/follain.png',
    'Indie Lee': 'https://static.shopmy.us/Retailers/indie_lee.png',
    'Kat Von D': 'https://static.shopmy.us/Retailers/kat_von_d.png',
    'Kate Somerville': 'https://static.shopmy.us/Retailers/kate_somerville.png',
    Kerastase: 'https://static.shopmy.us/Retailers/kerastase.png',
    'Make Up For Ever': 'https://static.shopmy.us/Retailers/make_up_for_ever.png',
    'Marc Jacobs Beauty': 'https://static.shopmy.us/Retailers/marc_jacobs_beauty.png',
    Murad: 'https://static.shopmy.us/Retailers/murad.png',
    NARS: 'https://static.shopmy.us/Retailers/nars.png',
    Olivela: 'https://static.shopmy.us/Retailers/olivela.png',
    Osea: 'https://static.shopmy.us/Retailers/osea.png',
    "Paula's Choice": 'https://static.shopmy.us/Retailers/paulas_choice.png',
    Ren: 'https://static.shopmy.us/Retailers/ren.png',
    Tarte: 'https://static.shopmy.us/Retailers/tarte.png',
    Tatcha: 'https://static.shopmy.us/Retailers/tatcha.png',
    Ulta: 'https://static.shopmy.us/Retailers/ulta.png',
    Verishop: 'https://static.shopmy.us/Retailers/verishop.png',
    'Violet Grey': 'https://static.shopmy.us/Retailers/violet_grey.png',
    Wander: 'https://static.shopmy.us/Retailers/wander.png'
  }[retailer];
};

export const getUrlBase = url => url?.split('?')[0];
export const setUrlParam = (param, value) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(param, value);

  const new_url = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
  window.history.replaceState({}, document.title, new_url);
};
export const getUrlParam = param => new URLSearchParams(window.location.search).get(param);
export const removeUrlParam = param => {
  const params = new URLSearchParams(window.location.href.split('?')[1]);
  params.delete(param);
  const newParams = params.toString();
  window.history.replaceState({}, document.title, window.location.href.split('?')[0] + (newParams.length ? `?${newParams}` : ''));
};

export const getAndRemoveUrlParam = param => {
  const value = getUrlParam(param);
  removeUrlParam(param);
  return value;
};

export const portalBugOpenUrl = e => {
  /*
    There is an odd bug that when we use a modal that leverages React Portals, the anchor
    tags no longer work as expected. For example:

      <a href='https://google.com'>link here</a>

    will not open a new page. Somehow the portal transfer is breaking the tag or something is happening
    that we could not debug. Instead, please use this function:

      <a onClick={portalBugOpenUrl} href='https://google.com'>link here</a>

    We also handle this bug on the Modal component itself, but if there are multiple rerenders and an anchor
    link that was not there on load, you'll have to use this.
  */
  const anchorElement = e.target?.href ? e.target : e.target.closest('a');
  window.open(anchorElement?.href, anchorElement?.target);
};

export const dismissEvent = e => {
  // Used to stop passing events to parent click handlers
  e.preventDefault();
  e.stopPropagation();
};

export const downloadCsvFromUrl = url => {
  var downloadLink = document.createElement('a');
  downloadLink.href = url;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

// Used in global file for clean structure
const globals = {
  isSMS,
  isPro,
  isSnap,
  isDevelopment,
  isProduction,
  getRootSCSSClass
};

const escapeString = str => {
  if (typeof str !== 'string') {
    return str;
  }
  // Wrap string with double quotes if it contains a comma or double quote
  if (str.includes(',') || str.includes('"')) {
    // Double any existing double quotes, then wrap the string in double quotes
    return `"${str.replace(/"/g, '""')}"`;
  }
  return str;
};

const convertToCSV = objArray => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  // Headers
  for (let index in objArray[0]) {
    str += `${escapeString(index)},`;
  }
  str = str.slice(0, -1); // Remove trailing comma
  str += '\r\n';

  // Data rows
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      line += `${escapeString(array[i][index])},`;
    }
    line = line.slice(0, -1); // Remove trailing comma
    str += line + '\r\n';
  }

  return str;
};

export const getDiffBetweenServerAndLocalDateStrings = (serverDate, localDate, diffType = 'seconds') => {
  return moment(serverDate)
    .utc()
    .diff(moment(localDate).utc(), diffType);
};

export const downloadCSVFromArray = (array, file_name) => {
  const csv = convertToCSV(array);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  if (file_name && !file_name.includes('.csv')) file_name = `${file_name}.csv`;

  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', file_name || 'download.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default globals;
