export const orderTrackingCodeGeneral = `
  <script>
  \t\tconst cookies = document.cookie.split(';').reduce((res, cookie) => ({ ...res, [(cookie.split('=')[0] || '').trim()]: (cookie.split('=')[1] || '').trim() }), {});
  \t\tconst { sms_click_id, sms_click_time } = cookies;
  \t\tconst code = \`***THE CODE USED AT CHECKOUT***\`;
  \t\tfetch(\`https://api.shopmy.us/api/order_confirmation\`, {
  \t\t\tmethod: 'POST',
  \t\t\theaders: { 'Content-Type': 'application/json' },
  \t\t\tbody: JSON.stringify({
  \t\t\t\torderAmount: \`***THE ORDER TOTAL***\`,
  \t\t\t\torderId: \`***THE ORDER ID***\`,
  \t\t\t\tclickId: sms_click_id,
  \t\t\t\tcurrency: \`***THE ISO 4217 CURRENCY CODE, EX: USD***\`,
  \t\t\t\tis_returning_customer: false,
  \t\t\t\tpage_url: window.location.href,
  \t\t\t\tcode
  \t\t\t})
  \t\t}).then(r => r.json())
  \t\t.then(result => {
  \t\t\tconst now = new Date();
  \t\t\tdocument.cookie = \`sms_click_id=;expires=\${now.toUTCString()};path=/;\`;
  \t\t\tdocument.cookie = \`sms_click_time=;expires=\${now.toUTCString()};path=/;\`;
  \t\t});
  </script>
  `;

export const orderTrackingCodeShopify = `
  <script>
  \t\tconst cookies = document.cookie.split(';').reduce((res, cookie) => ({ ...res, [(cookie.split('=')[0] || '').trim()]: (cookie.split('=')[1] || '').trim() }), {});
  \t\tconst { sms_click_id, sms_click_time } = cookies;
  \t\tconst code = \`{{checkout.discount_applications[0].title}}\`;
  \t\tfetch(\`https://api.shopmy.us/api/order_confirmation\`, {
  \t\t\tmethod: 'POST',
  \t\t\theaders: { 'Content-Type': 'application/json' },
  \t\t\tbody: JSON.stringify({
  \t\t\t\torderAmount: \`{{ checkout.total_price | minus: checkout.shipping_price | minus: checkout.tax_price | money}}\`,
  \t\t\t\torderId: \`{{ order_number }}\`,
  \t\t\t\tclickId: sms_click_id,
  \t\t\t\tcurrency: \`{{ currency }}\`,
  \t\t\t\tis_returning_customer: {{ customer.orders_count }} > 1,
  \t\t\t\tpage_url: window.location.href,
  \t\t\t\tcode
  \t\t\t})
  \t\t}).then(r => r.json())
  \t\t.then(result => {
  \t\t\tconst now = new Date();
  \t\t\tdocument.cookie = \`sms_click_id=;expires=\${now.toUTCString()};path=/;\`;
  \t\t\tdocument.cookie = \`sms_click_time=;expires=\${now.toUTCString()};path=/;\`;
  \t\t});
  </script>
  `;

export const orderTrackingCodeWoocommerce = `
<script>
\t\tconst cookies = document.cookie.split(';').reduce((res, cookie) => ({ ...res, [cookie.split('=')[0].trim()]: cookie.split('=')
\t\t[1].trim() }), {});
\t\tconst { sms_click_id } = cookies;
\t\tconst code = "<?php echo wp_kses_post( $order->get_coupon_codes()[0] ); ?>";
\t\tfetch('https://api.shopmy.us/api/order_confirmation', {
\t\t\tmethod: 'POST',
\t\t\theaders: {
\t\t\t\t'Content-Type': 'application/json'
\t\t\t},
\t\t\tbody: JSON.stringify({
\t\t\t\torderAmount: <?php echo wp_kses_post( $order->get_total() ); ?>,
\t\t\t\torderId: <?php echo esc_html( $order->get_order_number() ); ?>,
\t\t\t\tclickId: sms_click_id,
\t\t\t\tcurrency: "<?php echo esc_html( $order->get_currency() ); ?>",
\t\t\t\tis_returning_customer: false,
\t\t\t\tcode,
\t\t\t\tpage_url: window.location.href
\t\t\t})
\t\t}).then(r => r.json())
\t\t\t.then(result => {
\t\t\tconst now = new Date();
\t\t\tdocument.cookie = \`sms_click_id=;expires=\${now.toUTCString()};path=/;\`;
\t\t\tdocument.cookie = \`sms_click_time=;expires=\${now.toUTCString()};path=/;\`;
\t\t});
</script>
  `;
