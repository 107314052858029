import React from 'react';
import PropTypes from 'prop-types';
import './OnboardingPanelIntro.scss';

import { getName } from '../../../Helpers/user_helpers';
import { getFirstName } from '../../../Helpers/formatting';

import introImage from '../../../static/images/onboarding/onboarding_intro.png';

const OnboardingPanelIntro = props => {
  const { user } = props;
  return (
    <div className='onboarding-panel-intro-container'>
      <img src={introImage} alt='Millions Of Products' />
      <div className='onboarding-header'>Hi, {getFirstName(getName(user))}</div>
      <div className='onboarding-subheader'>
        Welcome to ShopMy, your home for monetizing product recommendations and brand collaborations. To get started, we just have a few questions.
      </div>
    </div>
  );
};

OnboardingPanelIntro.propTypes = {
  user: PropTypes.object.isRequired
};

export default OnboardingPanelIntro;
