import APIClient from './index';
import _ from 'lodash';

export const searchMerchants = query => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Merchants/search`, { query })
      .then(response => resolve(_.get(response, ['data'])))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const fetchHighRateMerchants = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Merchants/high_rates?` + (window.__IS_PRO__ ? 'isPro=true' : 'isSMS=true'))
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, 'data')));
  });
};

export const fetchFeaturedMerchants = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Merchants/featured?` + (window.__IS_PRO__ ? 'isPro=true' : 'isSMS=true'))
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, 'data')));
  });
};
