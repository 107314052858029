import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './OnboardingPanelTermsAndPrivacy.scss';
import { hasAgreedToTermsAndConditions, hasAgreedToPrivacyPolicy } from '../../../Helpers/user_helpers';

const TERMS = 'TERMS';
const PRIVACY = 'PRIVACY';

const OnboardingPanelTermsAndPrivacy = props => {
  const { user, isOnlyPanel, handleUpdate, setCannotProceedMessage } = props;
  const [newPolicies, setNewPolicies] = useState({});

  const agreedToTerms = !!hasAgreedToTermsAndConditions(user);
  const agreedToPrivacy = !!hasAgreedToPrivacyPolicy(user);

  useEffect(() => {
    // mark which file is updated if only panel shown is this one
    if (isOnlyPanel) {
      const newPolicies = {};

      if (!agreedToTerms) {
        newPolicies[TERMS] = true;
      }

      if (!agreedToPrivacy) {
        newPolicies[PRIVACY] = true;
      }

      setNewPolicies(newPolicies);
    }
  }, []);

  useEffect(() => {
    if (!agreedToTerms && !agreedToPrivacy) {
      setCannotProceedMessage('Please review the Terms and Conditions and Privacy Policy to continue.');
    } else if (!agreedToTerms) {
      setCannotProceedMessage('Please review the Terms and Conditions to continue.');
    } else if (!agreedToPrivacy) {
      setCannotProceedMessage('Please review the Privacy Policy to continue.');
    } else {
      setCannotProceedMessage(null);
    }
  }, [user]);

  const handleAgree = async () => {
    const checked = !(agreedToTerms && agreedToPrivacy);

    const updates = {
      hasAgreedToTermsAndConditions: checked,
      hasAgreedToPrivacyPolicy: checked
    };

    // if user is onboarding (!isOnlyPanel), toggle location services on if user agreed to both
    // this leaves location services off if user has toggled it off in settings and there is a terms/privacy update later
    if (!isOnlyPanel) {
      if (checked) {
        updates.allowLocationServices = true;
      } else {
        updates.allowLocationServices = false;
      }
    }

    await handleUpdate(updates);
  };

  return (
    <div className='onboarding-panel-terms-container'>
      <div className='onboarding-header'>Terms and Conditions</div>
      <div className='onboarding-subheader'>
        {isOnlyPanel
          ? `Please review the updated ShopMy Terms and Conditions and Privacy Policy to continue.`
          : `Please review the ShopMy Terms and Conditions and Privacy Policy to continue.`}
      </div>
      <div className='policy-rows'>
        {[TERMS, PRIVACY].map(policyType => (
          <div className='policy-review-row' key={policyType}>
            {!!newPolicies[policyType] && <div className='new-policy-marker'>Updated</div>}
            <div className='open-policy-container'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={`https://static.shopmy.us/${
                  policyType === TERMS ? 'Miscellaneous/Shop+My+Shelf+End+User+Agreement.pdf' : 'Privacy_Policy.pdf'
                }`}
              >
                Click here
              </a>{' '}
              to review the
              <span className='policy-name'> {policyType === TERMS ? 'Terms and Conditions' : 'Privacy Policy'}</span>
            </div>
          </div>
        ))}
        <div className='policy-review-row'>
          <div
            className={cn('agree-policy-checkbox', {
              selected: agreedToTerms && agreedToPrivacy
            })}
            onClick={() => handleAgree()}
          >
            <div className='check' />
            <div className='check-text'>
              <div className='check-label'>I have read and agree to the Terms and Conditions and Privacy Policy</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OnboardingPanelTermsAndPrivacy.propTypes = {
  user: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  isOnlyPanel: PropTypes.bool,
  setCannotProceedMessage: PropTypes.func.isRequired
};

export default OnboardingPanelTermsAndPrivacy;
