import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';

import { copyToClipboard } from '../../../Helpers/helpers';
import { orderTrackingCodeGeneral } from '../../../Helpers/brand_setup_helpers';

const BrandSetupMiva = props => {
  const { brand, updateBrand, clickTrackingCode, developerKey } = props;
  const mivaOrderCancellationEndpoint = `https://api.shopmy.us/api/AffiliateWebhooks/miva/order/statusUpdate/${developerKey}`;

  const { integrationStatus } = brand || {};
  const isIntegrationStatusPending = integrationStatus === 'PENDING';
  const isIntegrationStatusComplete = integrationStatus === 'COMPLETE';

  return (
    <>
      <div className='section'>
        <h2 className='section-header'>Step One: Integrate Click Tracking</h2>
        <div>
          <div className='section-step'>
            <div className='step'>
              Add the one-line script below to the main site just before the closing {`</body>`} tag. This script must be on every page, as it takes
              the tracking URL and turns it into a first-party cookie.
            </div>
            <div onClick={() => copyToClipboard(clickTrackingCode, true)} className='code'>
              {clickTrackingCode}
              <div className='copy-overlay'>
                <div>Click to Copy</div>
                <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='section'>
        <h2 className='section-header'>Step Two: Integrate Order Tracking</h2>
        <div className='section-step'>
          <div className='step'>
            Add the following to the order confirmation page. You will need to update the bold items below with the correct values.
          </div>
          <div onClick={() => copyToClipboard(orderTrackingCodeGeneral, true)} className='code'>
            {orderTrackingCodeGeneral.split('\t').map((t, idx) =>
              t.split('***').map((p, idx) => (
                <span key={idx} className={cn(t ? 'text' : 'space', { 'needs-fill': idx === 1 })}>
                  {p}
                </span>
              ))
            )}
            <div className='copy-overlay'>
              <div>Click to Copy</div>
              <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
            </div>
          </div>
        </div>
      </div>

      <div className='section'>
        <h2 className='section-header'>Step Three: Handle Order Updates / Cancellations</h2>
        <div>
          Through MIVA you will have to create a webhook that will notify us when an order is updated or cancelled. This will be done through your
          admin dashboard.
        </div>
        <ol className='section-step'>
          <li>Login to your MIVA admin dashboard and navigate to order workflows.</li>
          <li>
            Create a new workflow and name it <b>ShopMyShelf Order Cancellation</b>
          </li>
          <li>
            Add a new action to the workflow and select <b>Webhook</b>
          </li>
          <li>
            Configure the webhook trigger to be <b>Order Status Updated</b>
          </li>
          <li>
            Configure the webhook action to be <b>POST</b> and paste the below url into the <b>URL</b> field
          </li>
          {developerKey ? (
            <li className='code' onClick={() => copyToClipboard(mivaOrderCancellationEndpoint, true)}>
              {mivaOrderCancellationEndpoint}
              <div className='copy-overlay'>
                <div>Click to Copy</div>
                <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
              </div>
            </li>
          ) : (
            <li className='code'>Please login to a brand account to see the url</li>
          )}
        </ol>
      </div>

      <div className='section'>
        <h2 className='section-header'>Step Four: Request Testing</h2>
        <div>
          Please make a discount code <b>SHOPMYSHELF_TEST</b> for 100% off then {brand ? 'click the button below' : 'email us'} and we will test the
          integration end-to-end and let you know the order ID so we can cancel it afterwards.
        </div>
        {brand && (
          <div
            className={cn('integration-btn', { complete: isIntegrationStatusComplete, pending: isIntegrationStatusPending })}
            onClick={async () => {
              if (isIntegrationStatusPending) {
                window.ALERT.info(
                  'We are currently in the process of reviewing your integration. If you have any further questions please reach us at team@shopmy.us'
                );
              } else {
                confirmAlert({
                  title: 'Just confirming',
                  message:
                    'Did you make a discount code SHOPMYSHELF_TEST for 100% off? We will use this to test the integration and then cancel the order upon completion of the test.',
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: async () => {
                        await updateBrand(brand, { integrationStatus: 'PENDING' });
                        window.ALERT.success('We will review your integration and reach out shortly!');
                      }
                    },
                    { label: 'No', onClick: () => {} }
                  ]
                });
              }
            }}
          >
            {isIntegrationStatusPending ? 'In Review' : isIntegrationStatusComplete ? 'Integration Complete!' : 'Request Integration Review'}
          </div>
        )}
      </div>
    </>
  );
};

BrandSetupMiva.propTypes = {
  brand: PropTypes.object.isRequired,
  updateBrand: PropTypes.func.isRequired,
  clickTrackingCode: PropTypes.string.isRequired
};

export default BrandSetupMiva;
