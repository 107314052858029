import _ from 'lodash';

import { getDomainFromUrl } from './formatting';

export const isValidGeoLinkRecommendation = link => {
  /*
    Since we are generating recommendations based off of other user's activity
    we want to ensure they aren't making incorrect decisions.
  */

  // Don't show multi-selected options
  if (link.countryDomain.includes(',')) return false;

  // Don't show US links (confusing for people)
  if (link.countryDomain === 'US') return false;

  // Only let GB links point to non gb
  if (link.link.includes('/GB/') && link.countryDomain !== 'GB') return false;
  if (link.link.includes('.co.uk/') && link.countryDomain !== 'GB') return false;

  // Only let canada links point to non canada
  if (link.link.includes('/ca/') && link.countryDomain !== 'CA') return false;
  if (link.link.includes('.ca/') && link.countryDomain !== 'CA') return false;

  // Only let australia links point to australia
  if (link.link.includes('.com.au') && link.countryDomain !== 'AU') return false;

  // Only let us links point to US
  if (link.link.includes('.us/') && link.countryDomain !== 'US') return false;
  if (link.link.includes('us.') && link.countryDomain !== 'US') return false;
  if (link.link.includes('/USA/') && link.countryDomain !== 'US') return false;

  // Other rules for standard .com sites
  const countrySet = {
    'sephora.com': ['US'],
    'dermstore.com': ['US'],
    'skinstore.com': ['US'],
    'walmart.com': ['US'],
    'skinceuticals.com': ['US'],
    'ulta.com': ['US'],
    'lookfantastic.com': ['UK', 'GB'],
    'nordstrom.com': ['US'],
    'credobeauty.com': ['US'],
    'maccosmetics.com': ['US'],
    'contentbeautywellbeing.com': ['UK', 'GB'],
    'currentbody.com': ['UK', 'GB'],
    'ubeauty.com': ['US'],
    'drsambunting.com': ['UK', 'GB'],
    'sokoglam.com': ['US'],
    'gurumakeupemporium.com': ['UK', 'GB'],
    'superdrug.com': ['UK', 'GB'],
    'boots.com': ['UK', 'GB'],
    'saksfifthavenue.com': ['US'],
    'awin1.com': [],
    'deciem.com': [],
    'sweedlashes.com': []
  }[getDomainFromUrl(link.link)];

  // If we found the domain in the set above but the country is different, block it
  if (!_.isNil(countrySet) && !countrySet.includes(link.countryDomain)) return false;

  return true;
};

const GEO_CODE_TO_DISPLAY_MAP = {
  US: 'United States',
  FI: 'Finland',
  CA: 'Canada',
  GB: 'United Kingdom',
  DE: 'Germany',
  MX: 'Mexico',
  AU: 'Australia',
  FR: 'France',
  ES: 'Spain',
  NL: 'Netherlands',
  IN: 'India',
  CN: 'China',
  IE: 'Ireland',
  IT: 'Italy',
  SG: 'Singapore',
  RU: 'Russia',
  SE: 'Sweden',
  PL: 'Poland',
  BE: 'Belgium',
  GR: 'Greece',
  IL: 'Israel',
  BR: 'Brazil',
  TR: 'Turkey',
  PH: 'Philippines',
  HR: 'Croatia',
  RO: 'Romania',
  NZ: 'New Zealand',
  CH: 'Switzerland',
  BY: 'Belarus',
  AE: 'United Arab Emirates',
  PT: 'Portugal',
  NO: 'Norway',
  DK: 'Denmark',
  CO: 'Colombia',
  PR: 'Puerto Rico',
  SA: 'Saudi Arabia',
  AT: 'Austria',
  JP: 'Japan',
  MY: 'Malaysia',
  CZ: 'Czech Republic',
  PK: 'Pakistan',
  HK: 'Hong Kong',
  SK: 'Slovakia',
  AR: 'Argentina',
  CL: 'Chile',
  DO: 'Dominican Republic',
  ID: 'Indonesia',
  UA: 'Ukraine',
  BG: 'Bulgaria',
  PE: 'Peru',
  ZA: 'South Africa',
  TH: 'Thailand',
  HU: 'Hungary',
  VN: 'Vietnam',
  PA: 'Panama',
  KR: 'South Korea',
  RS: 'Serbia',
  TN: 'Tunisia',
  EC: 'Ecuador',
  NG: 'Nigeria',
  SI: 'Slovenia',
  BD: 'Bangladesh',
  TT: 'Trinidad and Tobago',
  TW: 'Taiwan',
  EG: 'Egypt',
  VE: 'Venezuela',
  CR: 'Costa Rica',
  KW: 'Kuwait',
  MA: 'Morocco',
  EE: 'Estonia',
  JO: 'Jordan',
  KE: 'Kenya',
  CY: 'Cyprus',
  LT: 'Lithuania',
  HN: 'Honduras',
  SV: 'El Salvador',
  JM: 'Jamaica',
  LU: 'Luxembourg',
  IQ: 'Iraq',
  GT: 'Guatemala',
  QA: 'Qatar',
  LV: 'Latvia',
  MK: 'North Macedonia',
  NP: 'Nepal',
  KZ: 'Kazakhstan',
  DZ: 'Algeria',
  GH: 'Ghana',
  LB: 'Lebanon',
  AL: 'Albania',
  IS: 'Iceland',
  GE: 'Georgia',
  BA: 'Bosnia and Herzegovina',
  CW: 'Curaçao',
  AZ: 'Azerbaijan',
  LK: 'Sri Lanka',
  AM: 'Armenia',
  MT: 'Malta',
  AW: 'Aruba',
  BS: 'Bahamas',
  LC: 'Saint Lucia',
  NI: 'Nicaragua',
  PS: 'Palestine',
  MN: 'Mongolia',
  PY: 'Paraguay',
  BH: 'Bahrain',
  BB: 'Barbados',
  KG: 'Kyrgyzstan',
  TC: 'Turks and Caicos Islands',
  OM: 'Oman',
  AG: 'Antigua and Barbuda',
  GU: 'Guam',
  BO: 'Bolivia',
  ZM: 'Zambia',
  RW: 'Rwanda',
  CU: 'Cuba',
  MU: 'Mauritius',
  KH: 'Cambodia',
  GY: 'Guyana',
  UZ: 'Uzbekistan',
  MD: 'Moldova',
  ZW: 'Zimbabwe',
  BM: 'Bermuda',
  LA: 'Laos',
  SX: 'Sint Maarten',
  SC: 'Seychelles',
  MM: 'Myanmar',
  ME: 'Montenegro',
  SN: 'Senegal',
  UY: 'Uruguay',
  MV: 'Maldives',
  LY: 'Libya',
  BN: 'Brunei',
  BZ: 'Belize',
  KY: 'Cayman Islands',
  MP: 'Northern Mariana Islands',
  TZ: 'Tanzania',
  RE: 'Réunion',
  XK: 'Kosovo',
  BQ: 'Bonaire, Sint Eustatius, and Saba',
  GP: 'Guadeloupe',
  SL: 'Sierra Leone',
  AO: 'Angola',
  BW: 'Botswana',
  MG: 'Madagascar',
  MZ: 'Mozambique',
  GM: 'Gambia',
  GI: 'Gibraltar',
  TJ: 'Tajikistan',
  IM: 'Isle of Man',
  MO: 'Macau',
  VI: 'U.S. Virgin Islands',
  VC: 'Saint Vincent and the Grenadines',
  GG: 'Guernsey',
  LI: 'Liechtenstein',
  UG: 'Uganda',
  KN: 'Saint Kitts and Nevis',
  LR: 'Liberia',
  NA: 'Namibia',
  SO: 'Somalia',
  DM: 'Dominica',
  FO: 'Faroe Islands',
  CD: 'Democratic Republic of the Congo',
  FJ: 'Fiji',
  BI: 'Burundi',
  MW: 'Malawi',
  CI: "Côte d'Ivoire",
  CM: 'Cameroon',
  TL: 'Timor-Leste',
  PM: 'Saint Pierre and Miquelon',
  GD: 'Grenada',
  ET: 'Ethiopia',
  AF: 'Afghanistan',
  DJ: 'Djibouti',
  VG: 'British Virgin Islands',
  SR: 'Suriname',
  CV: 'Cape Verde',
  GN: 'Guinea',
  AD: 'Andorra',
  MC: 'Monaco',
  AX: 'Åland Islands',
  JE: 'Jersey',
  HT: 'Haiti',
  PF: 'French Polynesia',
  GQ: 'Equatorial Guinea',
  BJ: 'Benin',
  IR: 'Iran',
  TG: 'Togo',
  GF: 'French Guiana',
  LS: 'Lesotho',
  NC: 'New Caledonia',
  MR: 'Mauritania',
  SY: 'Syria',
  MQ: 'Martinique',
  NE: 'Niger'
};

export const getCountryDisplayFromCode = country_code => GEO_CODE_TO_DISPLAY_MAP[country_code] || country_code;

export const getCountryCodeFromDisplay = country_display => _.findKey(GEO_CODE_TO_DISPLAY_MAP, value => value === country_display);

export const addressFieldsByRegion = {
  NorthAmerica: ['address1', 'address2', 'city', 'state', 'zip'],
  Europe: ['address1', 'address2', 'city', 'zip'],
  AsiaGeneric: ['address1', 'address2', 'city', 'state', 'zip'], // A generic template for most Asian countries.
  Japan: ['prefecture', 'city', 'ward', 'address1', 'address2', 'zip'],
  China: ['province', 'city', 'district', 'address1', 'address2', 'zip'],
  SouthAmerica: ['address1', 'address2', 'city', 'state', 'zip'],
  Africa: ['address1', 'address2', 'city', 'zip'],
  Oceania: ['address1', 'address2', 'city', 'zip']
};

export const countryToRegionMapping = [
  { label: 'United States', value: 'NorthAmerica', key: 'US' },
  { label: 'Canada', value: 'NorthAmerica', key: 'CA' },
  { label: 'Mexico', value: 'NorthAmerica', key: 'MX' },
  { label: 'United Kingdom', value: 'Europe', key: 'GB' },
  { label: 'Germany', value: 'Europe', key: 'DE' },
  { label: 'France', value: 'Europe', key: 'FR' },
  { label: 'Spain', value: 'Europe', key: 'ES' },
  { label: 'Italy', value: 'Europe', key: 'IT' },
  { label: 'Russia', value: 'Europe', key: 'RU' },
  { label: 'Jersey', value: 'Europe', key: 'JE' },
  { label: 'India', value: 'AsiaGeneric', key: 'IN' },
  { label: 'South Korea', value: 'AsiaGeneric', key: 'KR' },
  { label: 'Japan', value: 'Japan', key: 'JP' },
  { label: 'China', value: 'China', key: 'CN' },
  { label: 'Singapore', value: 'AsiaGeneric', key: 'SG' },
  { label: 'Indonesia', value: 'AsiaGeneric', key: 'ID' },
  { label: 'Philippines', value: 'AsiaGeneric', key: 'PH' },
  { label: 'Vietnam', value: 'AsiaGeneric', key: 'VN' },
  { label: 'Malaysia', value: 'AsiaGeneric', key: 'MY' },
  { label: 'Thailand', value: 'AsiaGeneric', key: 'TH' },
  { label: 'Brazil', value: 'SouthAmerica', key: 'BR' },
  { label: 'Argentina', value: 'SouthAmerica', key: 'AR' },
  { label: 'Chile', value: 'SouthAmerica', key: 'CL' },
  { label: 'Colombia', value: 'SouthAmerica', key: 'CO' },
  { label: 'Peru', value: 'SouthAmerica', key: 'PE' },
  { label: 'Uruguay', value: 'SouthAmerica', key: 'UY' },
  { label: 'Venezuela', value: 'SouthAmerica', key: 'VE' },
  { label: 'South Africa', value: 'Africa', key: 'ZA' },
  { label: 'Kenya', value: 'Africa', key: 'KE' },
  { label: 'Nigeria', value: 'Africa', key: 'NG' },
  { label: 'Egypt', value: 'Africa', key: 'EG' },
  { label: 'Morocco', value: 'Africa', key: 'MA' },
  { label: 'Ghana', value: 'Africa', key: 'GH' },
  { label: 'Australia', value: 'Oceania', key: 'AU' },
  { label: 'New Zealand', value: 'Oceania', key: 'NZ' },
  { label: 'Fiji', value: 'Oceania', key: 'FJ' },
  { label: 'Sweden', value: 'Europe', key: 'SE' },
  { label: 'Norway', value: 'Europe', key: 'NO' },
  { label: 'Finland', value: 'Europe', key: 'FI' },
  { label: 'Denmark', value: 'Europe', key: 'DK' },
  { label: 'Iceland', value: 'Europe', key: 'IS' },
  { label: 'Switzerland', value: 'Europe', key: 'CH' },
  { label: 'Netherlands', value: 'Europe', key: 'NL' },
  { label: 'Belgium', value: 'Europe', key: 'BE' },
  { label: 'Austria', value: 'Europe', key: 'AT' },
  { label: 'Ireland', value: 'Europe', key: 'IE' },
  { label: 'Portugal', value: 'Europe', key: 'PT' },
  { label: 'Poland', value: 'Europe', key: 'PL' },
  { label: 'Greece', value: 'Europe', key: 'GR' },
  { label: 'Ukraine', value: 'Europe', key: 'UA' },
  { label: 'Czech Republic', value: 'Europe', key: 'CZ' },
  { label: 'Hungary', value: 'Europe', key: 'HU' },
  { label: 'Romania', value: 'Europe', key: 'RO' },
  { label: 'Bulgaria', value: 'Europe', key: 'BG' },
  { label: 'Croatia', value: 'Europe', key: 'HR' },
  { label: 'Luxembourg', value: 'Europe', key: 'LU' },
  { label: 'Slovenia', value: 'Europe', key: 'SI' },
  { label: 'Estonia', value: 'Europe', key: 'EE' },
  { label: 'Latvia', value: 'Europe', key: 'LV' },
  { label: 'Lithuania', value: 'Europe', key: 'LT' },
  { label: 'Slovakia', value: 'Europe', key: 'SK' },
  { label: 'Malta', value: 'Europe', key: 'MT' },
  { label: 'Cyprus', value: 'Europe', key: 'CY' },
  { label: 'Israel', value: 'AsiaGeneric', key: 'IL' },
  { label: 'Saudi Arabia', value: 'AsiaGeneric', key: 'SA' },
  { label: 'United Arab Emirates', value: 'AsiaGeneric', key: 'AE' },
  { label: 'Qatar', value: 'AsiaGeneric', key: 'QA' },
  { label: 'Bahrain', value: 'AsiaGeneric', key: 'BH' },
  { label: 'Oman', value: 'AsiaGeneric', key: 'OM' },
  { label: 'Kuwait', value: 'AsiaGeneric', key: 'KW' },
  { label: 'Jordan', value: 'AsiaGeneric', key: 'JO' },
  { label: 'Lebanon', value: 'AsiaGeneric', key: 'LB' },
  { label: 'Pakistan', value: 'AsiaGeneric', key: 'PK' },
  { label: 'Bangladesh', value: 'AsiaGeneric', key: 'BD' },
  { label: 'Nepal', value: 'AsiaGeneric', key: 'NP' },
  { label: 'Sri Lanka', value: 'AsiaGeneric', key: 'LK' },
  { label: 'Myanmar', value: 'AsiaGeneric', key: 'MM' },
  { label: 'Mongolia', value: 'AsiaGeneric', key: 'MN' },
  { label: 'Kazakhstan', value: 'AsiaGeneric', key: 'KZ' },
  { label: 'Uzbekistan', value: 'AsiaGeneric', key: 'UZ' },
  { label: 'Turkmenistan', value: 'AsiaGeneric', key: 'TM' },
  { label: 'Kyrgyzstan', value: 'AsiaGeneric', key: 'KG' },
  { label: 'Tajikistan', value: 'AsiaGeneric', key: 'TJ' },
  { label: 'Georgia', value: 'AsiaGeneric', key: 'GE' }
];
